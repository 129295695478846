import React, { useEffect, useState, useRef } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import axios from "axios";
import toast from "react-hot-toast";
import { checkLoginStatus } from "../../../js/main.js";
import { sendMail, useCatchHandler } from "../../../utils/utilities"
import { useDispatch, useSelector } from "react-redux";
import { showLoaderAction } from "../../../actions/index";
import { useNotification } from "../../../utils/utilities"
import TimeAgo from "javascript-time-ago";
import ReactTimeAgo from "react-time-ago";
import en from "javascript-time-ago/locale/en.json";
import { uniqBy } from "lodash";
import ReactPaginate from 'react-paginate';
import ListPlaceholder from "../../Placeholders/ListPlaceholder.js";

const { REACT_APP_MY_ENV } = process.env;

TimeAgo.addLocale(en);

function OutletReview() {
  const location = useLocation();
  const dispatch = useDispatch();
  const showLoaderReducer = useSelector((state) => state.rootReducer.showLoaderReducer)
  const showLoader = showLoaderReducer.show;
   const [outletDetail] = useState(location.state?.outlet);
  const [reviewText, setReviewText] = useState("");
  const [star, setStar] = useState(1);
  const [clientId, setClientId] = useState("");
  const [feedBack, setFeedBack] = useState("");
  const [feedBackStars, setFeedBackStars] = useState(1);
  const itemsPerPage = 5;
  const [totalFeedbackCount, setTotalFeedbackCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [encryptedFeedbackId, setEncryptedFeedbackId] = useState(0);
  const [outlet, setOutlet] = useState({});
  let token = localStorage.getItem("token");
  const navigate = useNavigate();
  const catchHandle = useCatchHandler();
  const sendNotification = useNotification();
  const pageCount = Math.ceil(totalFeedbackCount / itemsPerPage);
  let phoneNumber = localStorage.getItem("phoneNumber");
  const [loading, setLoading] = useState(false);
  const [showLoading, setShowLoading] = useState(true)
  const currentURL = window.location.href;

  const [scrollPosition, setScrollPosition] = useState(0);
  const scrollRef = useRef(null);

 useEffect(() => {
  const currentURL = window.location.href;
  const urlParts = currentURL.split('/');
  const slug = urlParts[urlParts.length - 1];
  OutletDetail(slug ? slug : outletDetail.outletSlug)
  // FeedBackList()
  checkLoginStatus();
  // getFeedBack();
  // $(".fa-star-s").click(function () {
  //   $(".fa-star-s").removeClass("far").removeClass("fas");
  //   let number = parseInt($(this).attr("data-star"));
  //   for (let i = 0; i <= 5; i++) {
  //     if (i > number) {
  //       $(`[data-star=` + [i] + `]`).addClass("far");
  //     } else {
  //       $(`[data-star=` + [i] + `]`).addClass("fas");
  //       setStar(i);
  //     }
  //   }
  // });
}, [encryptedFeedbackId]);

const handleStarClick = (number) => {
  setStar(number);
  };

  const handleScroll = () => {
    const container = document.getElementById('scroll');
    if (container.scrollTop + container.clientHeight >= container.scrollHeight - 20 && !loading) {
      if (feedBack.length < totalFeedbackCount) {
        setScrollPosition(container.scrollTop);
        FeedBackList(currentPage, clientId);
      }
    }
  };

  const callBack = () => {
    setFeedBack([])
  }

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollPosition;
    }
  }, [feedBack]);
 
  const OutletDetail = async (slug) => {
    const data = {
      encryptedUserId: localStorage.getItem("encryptedUserId"),
      isView: true,
       slug: outlet.slug ? outlet.slug : slug
      // slug: slug
    };
    dispatch(showLoaderAction(true));
    const response = await axios.post(REACT_APP_MY_ENV + "/outlet/", data).then(function (response) {
      if (response.data.status === 200) {
        dispatch(showLoaderAction(false));
        if (response.data.data) {
          setOutlet(response.data.data.outlet)
          getFeedBack(response.data.data.outlet.encryptedClientId);
          if(!loading)
            FeedBackList('',response.data.data.outlet.encryptedClientId)
          setClientId(response.data.data.outlet.encryptedClientId)
        }
      }
    })
  }

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected + 1);
    FeedBackList(selected + 1,clientId)
  };

  const onReviewChange = (e) => {
    setReviewText(e.target.value);
  };

  const handleSubmit = async (e) => {
    if (encryptedFeedbackId) {
      const data = {
        encryptedClientId: outlet.encryptedClientId ? outlet.encryptedClientId : (outlet.clientId).toString(),
        encryptedUserId: localStorage.getItem("encryptedUserId"),
        EncryptedFeedbackId: encryptedFeedbackId,
        stars: star,
        message: reviewText,
      };
      dispatch(showLoaderAction(true));
      const updateReview = await axios.post(REACT_APP_MY_ENV + "/update-outlet-feedback", data, { headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" } }).then(function (updateReview) {
        if (updateReview.data.status === 200) {
          dispatch(showLoaderAction(false));
          notificationSendForUpdateReview()
          toast.success(updateReview.data.message);
          // navigate("/outlets", { state: { outlet: outlet } });
        } else {
          dispatch(showLoaderAction(false));
          toast.error(updateReview.data.message);
        }
      }).catch(function (error) {
        dispatch(showLoaderAction(false));
        toast.error("Something went wrong, please contact Administrator!")
        catchHandle(error, "true", currentURL)
        const data = {
          Subject: "Front end error from update-outlet-feedback",
          BodyText: error.message
        }
        sendMail(data);
      });
    } else {
      const data = {
        encryptedClientId: outlet.encryptedClientId ? outlet.encryptedClientId : (outlet.clientId).toString(),
        encryptedUserId: localStorage.getItem("encryptedUserId"),
        stars: star,
        message: reviewText,
      };
      dispatch(showLoaderAction(true));
      const review = await axios.post(REACT_APP_MY_ENV + "/give-feedback", data, { headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" } }).then(function (review) {
        if (review.data.status === 200) {
          dispatch(showLoaderAction(false));
          notificationSendForAddReview();
          getFeedBack(clientId)
          toast.success(review.data.message);
          // navigate("/outlets", { state: { outlet: outlet } });
        } else {
          dispatch(showLoaderAction(false));
          toast.error(review.data.message);
        }
      }).catch(function (error) {
        dispatch(showLoaderAction(false));
        toast.error("Something went wrong, please contact Administrator!")
        catchHandle(error, "true", currentURL)
        const data = {
          Subject: "Front end error from give-feedback",
          BodyText: error.message
        }
        sendMail(data);
      });
    }
  };

  const notificationSendForAddReview = () => {
    const data = {
      ClientId: outlet.encryptedClientId ? outlet.encryptedClientId : (outlet.clientId).toString(),
      UserId: localStorage.getItem("encryptedUserId"),
      SentBy: "USER",
      Title: "New Review Added",
      Description: `${localStorage.getItem("username")} gave ${star} ${star > 1 ? "stars" : "star"} and said "${reviewText}"`,
      RedirectURL: "/reviews",
    }
    sendNotification(data)
  }

  const notificationSendForUpdateReview = () => {
    const data = {
      ClientId: outlet.encryptedClientId ? outlet.encryptedClientId : (outlet.clientId).toString(),
      UserId: localStorage.getItem("encryptedUserId"),
      SentBy: "USER",
      Title: "Review Updated",
      Description: `${localStorage.getItem("username")} gave ${star} ${star > 1 ? "stars" : "star"} and said  "${reviewText}"`,
      RedirectURL: "/reviews",
    }
    sendNotification(data)
  }

  const getFeedBack = async (clientId) => {
    const data = {
      encryptedUserId: localStorage.getItem("encryptedUserId"),
      // encryptedClientId: outlet.encryptedClientId ? outlet.encryptedClientId : (outlet.clientId).toString(),
      encryptedClientId: clientId
    };
    dispatch(showLoaderAction(true));
    const response = await axios.post(REACT_APP_MY_ENV + "/get-feedback-detail", data, { headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" } }).then(function (response) {
      if (response.data.status === 200) {
        if (response.data.data) {
          let feedBackStars = response.data.data.stars;
          let encryptedFeedbackId = response.data.data.encryptedFeedbackId;
          let message = response.data.data.message;
          if (feedBackStars) {
            for (let i = 0; i <= 5; i++) {
              if (i > feedBackStars) {
                $(`[data-star=` + [i] + `]`).addClass("far");
              } else {
                $(`[data-star=` + [i] + `]`).addClass("fas");
                setStar(i);
              }
            }
          }
          setFeedBackStars(feedBackStars);
          setEncryptedFeedbackId(encryptedFeedbackId);
          setReviewText(message);
        }
      }
    }).catch(function (error) {
      dispatch(showLoaderAction(false));
      toast.error("Something went wrong, please contact Administrator!")
      catchHandle(error, "true", currentURL)
      const data = {
        Subject: "Front end error from get-feedback-detail",
        BodyText: error.message
      }
      sendMail(data);
    });

    dispatch(showLoaderAction(false));
  };

  const FeedBackList = async (pageNumber, clientId) => {
    setLoading(true)
    let data = {
      ClientId: clientId,
      pageNumber: pageNumber ? pageNumber : currentPage,
      pageSize: itemsPerPage
    };
    if(showLoading){
      dispatch(showLoaderAction(true));
    }
    const res = await axios.post(REACT_APP_MY_ENV + "/feedback-list", data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }).then(function (res) {
      if (res.data.status === 200) {
        setTotalFeedbackCount(res.data.data.totalFeedbackCount)
        if (encryptedFeedbackId) {
          const filteredFeedbackList =
            res.data.data.userFeedbackList.filter(
              (feedback) => feedback.userPhoneNumber !== phoneNumber
            );
          // setFeedBack(feedBack => [...feedBack, ...filteredFeedbackList]);
          setFeedBack(uniqBy([...feedBack, ...filteredFeedbackList], 'id'));
        }
        else {
          callBack()
          // setFeedBack(feedBack => [...feedBack, ...res.data.data.userFeedbackList]);
          setFeedBack(uniqBy([...feedBack, ...res.data.data.userFeedbackList], 'id'));
        }
				setCurrentPage(currentPage => currentPage + 1);
        setLoading(false)
        setShowLoading(false)
        dispatch(showLoaderAction(false));
      } else {
        dispatch(showLoaderAction(false));
        // setFeedBack([]);
        setTimeout(function () {
          $("#no-reviews").html("No reviews yet!");
        }, 200);
      }
    }).catch(function (error) {
      dispatch(showLoaderAction(false));
      toast.error("Something went wrong, please contact Administrator!")
      catchHandle(error, "true", currentURL)
      const data = {
        Subject: "Front end error from feedback-list",
        BodyText: error.message
      }
      sendMail(data);
    });
  };

  return (
    <>
      {showLoader ? (<ListPlaceholder
      showAvatar={false}
      noOfItem={1}
      skeletonName = {outlet && outlet.outletName ? outlet.outletName : "Review"}
       />)
      :
      <div class="page light page-content-review">
        <div className='containerCard'>
          <div class="navbar navbar-style-1">
            <div class="navbar-inner">
              <Link to={-1} id="link-back" state={{ outlet: outlet }} class="link back">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.439312 13.0606L5.75391 18.3752C6.04683 18.6682 6.43069 18.8146 6.81459 18.8146C7.1985 18.8146 7.58236 18.6682 7.87528 18.3752C8.46103 17.7894 8.46103 16.8397 7.87528 16.2539L5.12133 13.5H22.5C23.3284 13.5 24 12.8284 24 12C24 11.1716 23.3284 10.5 22.5 10.5H5.12133L7.87528 7.7461C8.46103 7.1603 8.46103 6.21057 7.87528 5.62477C7.28944 5.03898 6.33975 5.03898 5.75391 5.62477L0.439312 10.9394C-0.146437 11.5251 -0.146437 12.4749 0.439312 13.0606Z" fill="black" />
                </svg>
              </Link>
              <div class="title">{outlet.name ? outlet.name : outlet.outletName}</div>
            </div>
          </div>
          <div class="page-content inner-page review-inner-page pt-0" id="scroll" onScroll={() => handleScroll()} ref={scrollRef}>
            <div class="write-reviews-box box-content container">
              <div class="rating-info review-info-content">
                <ul id="review-star" className="rating-list">
            {[1, 2, 3, 4, 5].map((number) => (
              <li key={number}>
                <i
                  data-star={number}
                  className={`${
                    number <= star ? "fas" : "far"
                  } fa-star fa-star-s`}
                  onClick={() => handleStarClick(number)}
                ></i>
              </li>
            ))}
          </ul>
              </div>
              <form class="form-elements">
                <div class="list">
                  <ul class="row">
                    <li class="item-content feedback-content item-input col-100 item-input-with-value">
                      <div class="item-inner">
                        <div class="item-input-wrap">
                          <textarea placeholder="Write your review here" maxLength={250} value={reviewText} onChange={onReviewChange} class="form-control"></textarea>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </form>
            </div>
            <div class="footer-button footer-button-height">
              <Link onClick={handleSubmit} class="button-large button button-fill">
                {encryptedFeedbackId ? "UPDATE REVIEW" : "SUBMIT REVIEW"}
              </Link>
            </div>
            <div className=" pt-0">
              <div class="list media-list search-list searchbar-found search-list-bar review-list container">
                <ul>
                  {feedBack && feedBack.length > 0 ?
                    feedBack.map((data, index) => {
                      const createdAt = new Date(data.createdAt);
                      const today = new Date();
                      let CreatedAtDate;
                      if (createdAt.toDateString() === today.toDateString()) {
                        CreatedAtDate = <ReactTimeAgo date={createdAt} locale="en-US" />;
                      } else {
                        const formattedDate = createdAt.toLocaleString('en-US', {
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit',
                          hour: '2-digit',
                          minute: '2-digit',
                          hour12: true,
                        });
                        CreatedAtDate = formattedDate;
                      }
                      return (
                        <li className="listing" key={index}>
                          <div class="item-link item-content">
                            <div class="item-inner">
                              <div class="item-title-row">
                                <h6 class="item-title">{data.username ? data.username : "No Name"}</h6>
                                <div class="item-after item-subtitle">
                                  {CreatedAtDate}
                                </div>
                              </div>
                              <div class="rating-info review-info">
                                <ul id="review-star" className="rating-list review-star">
                                  {[1, 2, 3, 4, 5].map((starIndex) => (
                                    <li key={starIndex}>
                                      <i
                                        className={`fa-star ${starIndex > data.stars ? 'far' : 'fas'}`}
                                      ></i>
                                    </li>
                                  ))}
                                </ul>
                                <div>

                                </div>
                              </div>
                              <p class="item-feedback-text">{data.message}</p>

                            </div>
                          </div>
                        </li>
                      )
                    })
                    :
                    ""
                  }
                </ul>
              </div>
              {/* {feedBack && feedBack.length > 0 ?
                <div>
                  <ReactPaginate
                    pageCount={pageCount}
                    pageRangeDisplayed={10}
                    marginPagesDisplayed={0}
                    onPageChange={handlePageClick}
                    previousLabel={"Prev"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                    forcePage={ currentPage === 1 ? 0 : currentPage - 1}
                  />
                </div>
                : ""} */}
            </div>
          </div>
        </div>
      </div>
      }
    </>
  );
}

export default OutletReview;
