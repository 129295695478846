import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { checkLoginStatus } from "../../../js/main.js";
import { showLoaderAction, activeMenuAction } from '../../../actions/index.js';
import axios from "axios";
import moment from "moment";
import { Link } from 'react-router-dom';
import '../Dashboard/clientDashboard.css';
import { useCatchHandler, showToast } from "../../../utils/utilities"
import ClientFooter from "../../Master/ClientFooter";
import { sendMail } from '../../../utils/utilities';
import ReportsPlaceholder from '../../Placeholders/ReportsPlaceholder.js';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const { REACT_APP_MY_ENV } = process.env;

const GetTokenReports = () => {

  let token = localStorage.getItem("clientToken");

  const dispatch = useDispatch();
  const catchHandle = useCatchHandler();
  const [totalTokens, setTotalTokens] = useState();
  const [inQueueTokens, setInQueueTokens] = useState();
  const [removedTokens, setRemovedTokens] = useState();
  const [completedTokens, setCompletedTokens] = useState();
  const [cancelledTokens, setCancelledTokens] = useState();
  const [favouritesCount, setFavouritesCount] = useState();
  const [reviewCount, setReviewCount] = useState();
  const [userCount, setUserCount] = useState();
  const [filterOptions] = useState(['Today', 'ThisWeek', 'DAY', 'ThisMonth', 'LastMonth', 'ThisYear', 'LastYear', 'AllTime'])
  const [defaultFilter, setDefaultFilter] = useState(null);
  const [dayFilter, setDayFilter] = useState(false);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [dateFilter, setDateFilter] = useState(new Date());

  const showLoaderReducer = useSelector((state) => state.rootReducer.showLoaderReducer);
  const showLoader = showLoaderReducer.show;
  const currentURL = window.location.href;

  useEffect(() => {
    setDefaultFilter('Today');
    dispatch(activeMenuAction('get-reports'));
    checkLoginStatus("CLIENT");
    getUserTokenReports();
  }, [])

  const getUserTokenReports = async (filter, date) => {
    try {
      const requestData = {
        encryptedClientId: localStorage.getItem("encryptedClientId"),
        filter: filter ? filter : "Today",
        // data:"0"
        date: date
      };
      dispatch(showLoaderAction(true));
      let response = await axios.post(REACT_APP_MY_ENV + '/get-reports', requestData, {
        headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" }
      })

      if (response.data.status == 200) {
        dispatch(showLoaderAction(false));
        setTotalTokens(response.data.data.totalTokens);
        setInQueueTokens(response.data.data.inQueueTokens)
        setRemovedTokens(response.data.data.removedTokens)
        setCompletedTokens(response.data.data.completedTokens)
        setCancelledTokens(response.data.data.cancelledTokens)
        setFavouritesCount(response.data.data.favouriteOutletCount)
        setUserCount(response.data.data.userCount)
        setReviewCount(response.data.data.reviewCount)
      }
      else {
        dispatch(showLoaderAction(false));
        showToast(response.data.message, "error");
      }
    }
    catch (error) {
      catchHandle(error, "false", currentURL)
      dispatch(showLoaderAction(false));
      const data = {
        Subject: "Front end error from get-reports",
        BodyText: error.message
      }
      sendMail(data);
      showToast("Something went wrong, please contact Administrator!", "error")
    }
  }

  const handleFilter = async (e) => {
    setDefaultFilter(e.target.value);
    if (e.target.value != "DAY") {
      getUserTokenReports(e.target.value)
      setDayFilter(false)
    }
    else {
      setDayFilter(true)
      setOpenCalendar(true)
    }
  }

  const handleFieldChange = async (date,dateSting) => {
    setDateFilter(date)
    getUserTokenReports("DAY", date)
    setOpenCalendar(false)
  }

  return (
    <>
      {showLoader ? (<ReportsPlaceholder skeletonName="Reports" />)
      :
      <div className="outlet-dashboard page">
        <div className='containerCard'>
          <div className="navbar navbar-style-1 page-content">
            <div className="navbar-inner navbar-bg-theme">
              <Link to={-1} className="link back">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.439312 13.0606L5.75391 18.3752C6.04683 18.6682 6.43069 18.8146 6.81459 18.8146C7.1985 18.8146 7.58236 18.6682 7.87528 18.3752C8.46103 17.7894 8.46103 16.8397 7.87528 16.2539L5.12133 13.5H22.5C23.3284 13.5 24 12.8284 24 12C24 11.1716 23.3284 10.5 22.5 10.5H5.12133L7.87528 7.7461C8.46103 7.1603 8.46103 6.21057 7.87528 5.62477C7.28944 5.03898 6.33975 5.03898 5.75391 5.62477L0.439312 10.9394C-0.146437 11.5251 -0.146437 12.4749 0.439312 13.0606Z" fill="white" />
                </svg>
              </Link>
              <div className="title text-white">Reports</div>
            </div>
          </div>
          <div className="page-content inner-page pt-5">
            <div className="container dashboard-container">
              <div className="section mb-100">
              {localStorage.getItem("ShowFilters") == "true" &&
                <div class="list mb-list">
                  <ul class="row">
                    <li class="item-content col-100 item-input item-input-with-value">
                      <div class="item-inner">
                        <select
                          name="categoryId"
                          class="form-control m-1 p-1"
                          value={defaultFilter}
                          onChange={handleFilter}
                        >
                          <option value="" className="text-secondary">Select</option>
                          {filterOptions.map((filter) => {
                            const modifiedFilter = filter === "ThisWeek" ? "This Week" : filter === "DAY" ? "Day" : filter === "ThisMonth" ? "This Month" : filter === "LastMonth" ? "Last Month" : filter === "ThisYear" ? "This Year" : filter === "LastYear" ? "Last Year" : filter === "AllTime" ? "All Time" : filter;
                            return (
                              <option key={filter} value={filter}>
                                {modifiedFilter}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </li>
                  </ul>
                </div>
                }
                {dayFilter &&
                  <div className="row margin-top-5 mb-10">
                    <div className="col-6">
                      <div className="stat-box">
                          <DatePicker id='datePicker'
                            selected={dateFilter}
                            open={openCalendar}
                            onFocus={() => setOpenCalendar(true)}
                            onSelect={(date, dateSting) => handleFieldChange(date, dateSting)}
                            dateFormat="dd/MM/YYYY"
                            className='d-none' maxDate={new Date()} />
                      </div>
                    </div>
                  </div>
                }
                {/* <div className="row mt-0">
                <div className="col-6">
                  <div className="stat-box">
                    <div className="title"><strong>Total Tokens</strong></div>
                    <div className="value text-custom-blue">{totalTokens}</div>
                  </div>
                </div>
              </div> */}
                <div className="row mt-0 reports-row">
                  <div className="col-6 mt-10">
                    <div className="stat-box">
                      <div className="title"><strong>Total Tokens</strong></div>
                      <div className="value text-custom-blue">{totalTokens}</div>
                    </div>
                  </div>
                  <div className="col-6 mt-10">
                    <div className="stat-box">
                      <div className="title"><strong>In-Queue</strong></div>
                      <div className="value text-inQueue">{inQueueTokens}</div>
                    </div>
                  </div>
                  <div className="col-6 mt-10">
                    <div className="stat-box">
                      <div className="title"><strong>Cancelled</strong></div>
                      <div className="value text-cancel">{cancelledTokens}</div>
                    </div>
                  </div>
                  <div className="col-6 mt-10">
                    <div className="stat-box">
                      <div className="title"><strong>Removed</strong></div>
                      <div className="value text-remove">{removedTokens}</div>
                    </div>
                  </div>
                  <div className="col-6 mt-10">
                    <div className="stat-box">
                      <div className="title"><strong>Completed</strong></div>
                      <div className="value text-success">{completedTokens}</div>
                    </div>
                  </div>
                  <div className="col-6 mt-10">
                    <div className="stat-box">
                      <div className="title"><strong>New Customers</strong></div>
                      <div className="value custom-dark-blue ">{userCount}</div>
                    </div>
                  </div>
                  <div className="col-6 mt-10">
                    <div className="stat-box">
                      <div className="title"><strong>Favourites</strong></div>
                      <div className="value custom-dark-orange">{favouritesCount}</div>
                    </div>
                  </div>
                  <div className="col-6 mt-10">
                    <div className="stat-box">
                      <div className="title"><strong>Reviews</strong></div>
                      <div className="value custom-dark-green">{reviewCount}</div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="col-6 mt-10">
                  <div className="stat-box">
                    <div className="title"><strong>Added to Favourites</strong></div>
                    <div className="value text-custom-blue">
                      {favouritesCount}
                    </div>
                  </div>
                </div> */}

            </div>
          </div>
          <ClientFooter />
        </div>
      </div>
      }
    </>
  )
}

export default GetTokenReports