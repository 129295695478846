import { React, useRef, useState, useEffect } from 'react'
import { QrReader } from "react-qr-reader"
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { activeMenuAction, showLoaderAction } from "../../../actions/index";
import SpinerLoader from "../../SpinerLoader/spinerLoader";
import axios from "axios";
import jsQR from "jsqr";
import Footer from '../../Master/Footer';
import { sendMail, showToast } from '../../../utils/utilities';
const { REACT_APP_WEBAPP_URL, REACT_APP_MY_ENV } = process.env;

const QrScanner = () => {
  const fileInputRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [isClient] = useState(location.state?.isClient);
  const [isUpiId] = useState(location.state?.isUpiId);
  const [isFlashOn, setIsFlashOn] = useState(false);
  let token = localStorage.getItem("token");

  useEffect(() => {
    dispatch(activeMenuAction('dashboard'));
  }, [])
  const dispatch = useDispatch();

  const showLoaderReducer = useSelector((state) => state.rootReducer.showLoaderReducer)
  const showLoader = showLoaderReducer.show;

  const handleScan = async (scanData) => {
    if (scanData && scanData !== "") {
      if (isClient) {
        if (scanData) {
          try {
            const url = new URL(scanData);
            const path = url.pathname;
            const parts = path.split('/');
            const lastPart = parts[parts.length - 1];
            navigate("/outlet-sign-up", { state: { qrUuid: lastPart } });
          } catch (error) {
            console.toast("Error parsing URL:", error);
          }
        }
      } else {
        if (scanData) {
          let isValidUrlPatternOfEnv = isValidURLBasedOnEnv(scanData)
          let isValidUpi = isValidURLBasedOnUpiId(scanData)
          if (isValidUrlPatternOfEnv) {
            vibrateDevice();
            if(scanData && !scanData.toString().includes('https'))
            {
              window.location.href = scanData.toString().replace('http', 'https'); 
            }
            else{
              window.location.href = scanData.toString();
            }
          } else if (isValidUpi) {
            vibrateDevice();
            getClientQrUuid(isValidUpi)
          } else {
            vibrateDevice();
            showToast("Invalid QR code!", "error");
          }
        } else {
          showToast("Invalid QR code!", "error");
        }
      }
    }
  };

  function vibrateDevice() {
    if ('vibrate' in navigator) {
      navigator.vibrate(200);
    } else if (navigator.mozVibrate) {
      navigator.mozVibrate(200);
    } else {
      console.log('Vibration API not supported');
    }
  }

  const handleError = (err) => {
    showToast(err, "error");
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      readQRCode(file)
        .then((result) => {
          let isValidUrlPatternOfEnv = isValidURLBasedOnEnv(result)
          let isValidUpi = isValidURLBasedOnUpiId(result)
          if (isClient) {
            if (isUpiId) {
              let upiId = isValidURLBasedOnUpiId(result)
              let upiIdName = getUpiName(result)
              navigate("/outlet-sign-up", { state: { clientUpiId: upiId, clientUpiIdName: upiIdName } });
            } else {
              const parts = result.split('/');
              const lastPart = parts[parts.length - 1];
              navigate("/outlet-sign-up", { state: { qrUuid: lastPart } });
            }
          }
          else {
            if (result) {
              if (isValidUrlPatternOfEnv) {
                window.location.href = result.toString().replace('http', 'https');
              }
              else if (isValidUpi) {
                getClientQrUuid(isValidUpi);
              }
              else {
                showToast("Invalid QR code!", "error");
              }
            } else {
              showToast("Invalid QR code!", "error");
            }
          }
        })
        .catch((error) => {
          showToast("Invalid QR code!", "error");
        });
    }
  };

  const openFileDialog = () => {
    fileInputRef.current.click();
  };

  function readQRCode(imageFile) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const imageData = new Image();
        imageData.src = e.target.result;
        imageData.onload = () => {
          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");
          canvas.width = imageData.width;
          canvas.height = imageData.height;
          context.drawImage(imageData, 0, 0);
          const imageDataArray = context.getImageData(
            0,
            0,
            imageData.width,
            imageData.height
          ).data;
          const code = jsQR(imageDataArray, imageData.width, imageData.height);
          if (code) {
            resolve(code.data);
          } else {
            reject("No QR code found in the image.");
          }
        };
      };
      reader.readAsDataURL(imageFile);
    });
  }

  function isValidURLBasedOnEnv(url) {
    let urlPattern = new RegExp(`${REACT_APP_WEBAPP_URL}/get-token/[a-zA-Z0-9]+`);
    if (urlPattern.test(url)) {
      return true;
    }
    else {
      
      urlPattern = new RegExp(`http://app.tokenlelo.com/get-token/[a-zA-Z0-9]+`);

      let httpsPattern = new RegExp(`https://app.tokenlelo.com/get-token/[a-zA-Z0-9]+`);

      if (urlPattern.test(url) || httpsPattern.test(url)) {
        return true;
      }
    }

    return false;
  }

  function isValidURLBasedOnUpiId(url) {
    const regex = /pa=([^&]*)/;
    const match = regex.exec(url);

    if (match && match[1]) {
      const upiId = match[1];
      return upiId;
    } else {
      return null;
    }
  }

  const getClientQrUuid = async (Id) => {
    let data = {
      upiId: Id
    }
    const response = await axios.post(REACT_APP_MY_ENV + "/get-client-qruuid/", data, { headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" } }).then(function (response) {
      if (response.data.status === 200) {
        navigate(`/get-token/${response.data.data.qrUuid}`);
      } else {
        showToast("Invalid QR code!", "error");
      }
    }).catch(function (error) {
      dispatch(showLoaderAction(false));
      const data = {
        Subject: "Front end error from get-client-qruuid",
        BodyText: error.message
      }
      sendMail(data);
      showToast("Something went wrong, please contact Administrator!", "error")
    });
  }

  function getUpiName(url) {
    const regex = /pn=([^&]*)/;
    const match = regex.exec(url);

    if (match && match[1]) {
      const upiIdName = match[1];
      return upiIdName;
    } else {
      return null;
    }
  }

  const toggleFlash = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } });
      const track = stream.getVideoTracks()[0];
      await track.applyConstraints({
        advanced: [{ torch: !isFlashOn }]
      });
      stream.getTracks().forEach((track) => track.stop());
      setIsFlashOn(!isFlashOn);
    } catch (error) {
      showToast(error, "error");
    }
  };


  return (
    <>
      {showLoader && (<SpinerLoader />)}
      <div class="page qrScannerPage">
        <div className='containerCard qrScanner'>
          <div class="navbar navbar-style-1">
            <div class="navbar-inner">
              <Link to={-1} class="link back">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.439312 13.0606L5.75391 18.3752C6.04683 18.6682 6.43069 18.8146 6.81459 18.8146C7.1985 18.8146 7.58236 18.6682 7.87528 18.3752C8.46103 17.7894 8.46103 16.8397 7.87528 16.2539L5.12133 13.5H22.5C23.3284 13.5 24 12.8284 24 12C24 11.1716 23.3284 10.5 22.5 10.5H5.12133L7.87528 7.7461C8.46103 7.1603 8.46103 6.21057 7.87528 5.62477C7.28944 5.03898 6.33975 5.03898 5.75391 5.62477L0.439312 10.9394C-0.146437 11.5251 -0.146437 12.4749 0.439312 13.0606Z" fill="black" />
                </svg>
              </Link>
              <div class="title">Scan QR</div>
            </div>
          </div>
          <div class="page-content inner-page pb-0 pt-0">

            <QrReader
              className='mainSccanner'
              constraints={{
                facingMode: { exact: 'environment' },
                aspectRatio: { ideal: 1 }
              }}
              // containerStyle={{ width: '100%', height: '100%' }}
              // videoStyle={{ width: '100%', height: '100%' }}
              // cameraStyle={{ height: '100%', width: '100%' }}
              delay={200}
              onError={handleError}
              onResult={handleScan}

            />

            <ul className='SccannerList'>
              {/* <li>
                <Link className="link " onClick={openFileDialog}>
                  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20 5V19H6V5H20ZM20 3H6C4.9 3 4 3.9 4 5V19C4 20.1 4.9 21 6 21H20C21.1 21 22 20.1 22 19V5C22 3.9 21.1 3 20 3ZM15.14 11.86L12.14 15.73L10 13.14L7 17H19L15.14 11.86Z" fill="black" />
                  </svg>
                </Link>
              </li> */}
               <li>
                <Link href="" className="link active" onClick={() => toggleFlash()}>
                  {isFlashOn ? 
                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.11609 25C8.97329 25.0001 8.8322 24.9697 8.70273 24.9108C8.57325 24.8519 8.45854 24.766 8.36665 24.6591C8.27476 24.5523 8.20792 24.427 8.17083 24.2922C8.13374 24.1574 8.12729 24.0162 8.15195 23.8787V23.873L9.6493 15.834H3.85219C3.69128 15.8339 3.53366 15.7894 3.39757 15.7055C3.26147 15.6215 3.15246 15.5016 3.08314 15.3597C3.01382 15.2177 2.98703 15.0594 3.00586 14.9032C3.02469 14.7469 3.08837 14.5991 3.18954 14.4768L14.8642 0.36626C14.9971 0.201336 15.1816 0.083505 15.3894 0.0307857C15.5973 -0.0219336 15.8171 -0.00663926 16.0152 0.07433C16.2133 0.155299 16.3789 0.297487 16.4866 0.479149C16.5942 0.660811 16.6381 0.871948 16.6114 1.08027C16.6114 1.0959 16.6071 1.111 16.6044 1.12663L15.1018 9.16774H20.8978C21.0587 9.16776 21.2163 9.21231 21.3524 9.29625C21.4885 9.38019 21.5975 9.50008 21.6669 9.64205C21.7362 9.78403 21.763 9.94229 21.7441 10.0985C21.7253 10.2548 21.6616 10.4026 21.5605 10.5249L9.88421 24.6354C9.79246 24.7488 9.67575 24.8404 9.54276 24.9035C9.40977 24.9667 9.26392 24.9996 9.11609 25Z" fill="black"/>
                    </svg>
                      :  
                    <svg height="25" viewBox="0 0 512 512" width="25" fill="white" xmlns="http://www.w3.org/2000/svg"><title/>
                        <path d="M432,448a15.92,15.92,0,0,1-11.31-4.69l-352-352A16,16,0,0,1,91.31,68.69l352,352A16,16,0,0,1,432,448Z"/><path d="M431.5,204A16,16,0,0,0,416,192H307.19L335.4,37.63c.05-.3.1-.59.13-.89A18.45,18.45,0,0,0,302.73,23L210.15,137.46a4,4,0,0,0,.29,5.35l151,151a4,4,0,0,0,5.94-.31l60.8-75.16A16.37,16.37,0,0,0,431.5,204Z" fill="white"/><path d="M301.57,369.19l-151-151a4,4,0,0,0-5.93.31L83.8,293.64A16.37,16.37,0,0,0,80.5,308,16,16,0,0,0,96,320H204.83L176.74,474.36l0,.11A18.37,18.37,0,0,0,209.24,489l92.61-114.46A4,4,0,0,0,301.57,369.19Z" fill="white"/>
                    </svg>
                  }
                </Link>
              </li>
            </ul>
            {/* <Link className="button-large button button-fill " onClick={openFileDialog}> upload from gallery
                </Link>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleImageUpload}
                /> */}
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleImageUpload}
            />
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};
export default QrScanner