import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import $ from 'jquery';
import "../../../App.css";
import "../../../css/framework7-bundle.css";
import "../../../css/app.css";
import "../../../css/font-awesome/css/all.min.css";
import "../../../css/line-awesome/css/line-awesome.min.css";
import "../../../css/style.css";
import "../../../css/perfect-scrollbar.css";
import "../../../img/f7-icon-square.png";
import "../../../img/f7-icon.png";
import "../../../js/main.js";
import axios from "axios";
import { sendMail, useCatchHandler, showToast } from "../../../utils/utilities"
import { useDispatch, useSelector } from "react-redux";
import { showLoaderAction } from "../../../actions/index";
import SpinerLoader from "../../SpinerLoader/spinerLoader";
import tokenlogo from "../../../img/logo-new.png";
const { REACT_APP_MY_ENV } = process.env;

function ClientSignIn() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const catchHandle = useCatchHandler();
  const showLoaderReducer = useSelector((state) => state.rootReducer.showLoaderReducer)
  const showLoader = showLoaderReducer.show;
  const [clientId, setClientId] = useState("");
  const [password, setPassword] = useState("");
  const [clientIdError, setClientIdError] = useState({});
  const [passwordError, setPasswordError] = useState({});
  let isLogin = localStorage.getItem("isLogin");

  useEffect(() => {
    if (localStorage.getItem('clientToken')) {
      isClientLoggedIn()
      localStorage.removeItem("CityName")
      localStorage.removeItem("CityId")
    }
    if(isLogin){
      navigate('/dashboard');
    }

    const isLoggedIn = localStorage.getItem('isLogin') === 'true';
    const isClient = localStorage.getItem('isClientLogin') === 'true';

    const urlParams = new URLSearchParams(window.location.search);
    const outletLoginId = urlParams.get('outletlogin');
    if (outletLoginId) {
      clientLogin(outletLoginId, true);
    }
    else if ((window.location.pathname === "/sign-in") && isClient) {
      if (isClient) {
        navigate('/outlet-dashboard');
      } else if (isLoggedIn) {
        navigate('/dashboard');
      }
    }
  }, []);

  const isClientLoggedIn = () => {
    let isClientLogin = localStorage.getItem('isClientLogin')
    if (isClientLogin) {
      navigate('/outlet-dashboard');
    }
  }

  const isClientIn = () => {
    let isLoggedIn = localStorage.getItem('isLogin');
    let isClientLogin = localStorage.getItem('isClientLogin')
    if (isClientLogin) {
      navigate('/outlet-dashboard');
    }
  }

  const onSubmit = async (e, outletLoginId, isAdmin) => {
    e.preventDefault();
    const isValid = formValidation();

    if (outletLoginId && isAdmin == true) {
      isValid = true;
    }

    if (isValid) {
      const data = {
        PhoneOrEmail: clientId,
        Password: password,
      };
      dispatch(showLoaderAction(true));
      const res = await axios.post(REACT_APP_MY_ENV + "/client-login", data).then(function (res) {
        if (res.data.status === 200) {
          dispatch(showLoaderAction(false));
          localStorage.setItem("outletname", res.data.data.outletName);
          localStorage.setItem("outletSlug", res.data.data.slug);
          localStorage.setItem("encryptedClientId", res.data.data.encryptedId);
          localStorage.setItem("clientToken", res.data.data.token);
          localStorage.setItem("isClientLogin", true);
          localStorage.removeItem("CityName")
          localStorage.removeItem("CityId")
          showToast(res.data.message, "success");

          navigate("/outlet-dashboard", { state: { outletImg: res.data.data.logoFilePathURL } });
        }
        else {
          dispatch(showLoaderAction(false));
          showToast(res.data.message, "error");
        }
      }).catch(function (error) {
        dispatch(showLoaderAction(false));
        catchHandle(error)
        const data = {
          Subject: "Front end error from client-login",
          BodyText: error.message
        }
        sendMail(data);
        showToast("Something went wrong, please contact Administrator!", "error")
      })
    }
  };

  const formValidation = () => {
    let clientIdError = {};
    let passwordError = {};
    let isValid = true;

    if (!clientId) {
      clientIdError.clientIdEmpty =
        "Please enter email address or phone number";
      isValid = false;
    } else if (
      isNaN(clientId) &&
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(clientId)
    ) {
      clientIdError.emailInvalid = "Please enter valid email address";
      isValid = false;
    } else if (!isNaN(clientId) && !/^(\+\d{1,3}[- ]?)?\d{10}$/.test(clientId)) {
      clientIdError.phoneInvalid = "Please enter valid phone number";
      isValid = false;
    }

    if (!password) {
      passwordError.passwordEmpty =
        "Please enter password";
      isValid = false;
    }

    setClientIdError(clientIdError);
    setPasswordError(passwordError);
    return isValid;
  };

  function showHidePassword() {
    var passInput = $('.show-pass').parent().find('input');
    var inputType = passInput.attr('type');
    if (inputType == 'password') {
      $('.show-pass').toggleClass('active');
      passInput.attr('type', 'text');
    } else if (inputType == 'text') {
      $('.show-pass').removeClass('active');
      passInput.attr('type', 'password');
    }
  }

  const clientLogin = async (outletLoginId, isAdmin) => {
    const data = {
      PhoneOrEmail: outletLoginId,
      IsAdmin: isAdmin
    };

    dispatch(showLoaderAction(true));
    const res = await axios.post(REACT_APP_MY_ENV + "/client-login", data).then(function (res) {
      if (res.data.status === 200) {
        dispatch(showLoaderAction(false));
        localStorage.setItem("outletname", res.data.data.outletName);
        localStorage.setItem("outletSlug", res.data.data.slug);
        localStorage.setItem("encryptedClientId", res.data.data.encryptedId);
        localStorage.setItem("clientToken", res.data.data.token);
        localStorage.setItem("isClientLogin", true);
        showToast(res.data.message, "success");
        const queryParams = new URLSearchParams(window.location.search);
        const redirectParam = queryParams.get('r');
        if (redirectParam) {
          navigate(`/` + redirectParam);
        } else {
          navigate("/outlet-dashboard", { state: { outletImg: res.data.data.logoFilePathURL } });
        }
      } else {
        dispatch(showLoaderAction(false));
        showToast(res.data.message, "error");
      }
    }).catch(function (error) {
      dispatch(showLoaderAction(false));
      catchHandle(error)
      const data = {
        Subject: "Front end error from client-login",
        BodyText: error.message
      }
      sendMail(data);
      showToast("Something went wrong, please contact Administrator!", "error")
    })
  }

  return (
    <>
      {showLoader && (<SpinerLoader />)}
      <div className="page account-area">
        <div className="page-content">
          <div className="logo-space">  <div className="logo-request-singpage">
               <img src={tokenlogo}  onError={(error) => { error.target.src = tokenlogo }}/></div></div>
          <div className="fixed-content container">
            <div className="tabs">
              <div className="tab tab-active form-elements tabs">
                <form onSubmit={onSubmit} className="tab tab-active" id="tabA1">
                  <div class="title-head d-flex-arrow align-items-center mb-3">
                    <div>
                      <Link to="/sign-in" class="paddingBottom  me-1">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M0.439312 13.0606L5.75391 18.3752C6.04683 18.6682 6.43069 18.8146 6.81459 18.8146C7.1985 18.8146 7.58236 18.6682 7.87528 18.3752C8.46103 17.7894 8.46103 16.8397 7.87528 16.2539L5.12133 13.5H22.5C23.3284 13.5 24 12.8284 24 12C24 11.1716 23.3284 10.5 22.5 10.5H5.12133L7.87528 7.7461C8.46103 7.1603 8.46103 6.21057 7.87528 5.62477C7.28944 5.03898 6.33975 5.03898 5.75391 5.62477L0.439312 10.9394C-0.146437 11.5251 -0.146437 12.4749 0.439312 13.0606Z" fill="black" />
                        </svg>
                      </Link>
                    </div>
                    <div>
                      <h2 class="title mb-0"><Link to="">Outlet Sign In</Link></h2>
                    </div>
                  </div>
                  <div className="list">
                    <ul className="row">
                      <li className="item-content item-input col-100">
                        <div className="item-inner">
                          <div className="item-title item-label profile-font">
                            Email/Phone
                          </div>
                          <div className="item-input-wrap profile-font">
                            <input
                              type="text"
                              placeholder="Enter Email/Phone"
                              value={clientId}
                              autocomplete="new-password"
                              onChange={(e) => {
                                setClientId(e.target.value);
                              }}
                              className="form-control"
                              maxLength={clientId.match(/^\d+$/) ? 10 : 50}
                            />
                            {clientIdError &&
                              Object.keys(clientIdError).map((key) => {
                                return (
                                  <div style={{ color: "red" }}>
                                    {clientIdError[key]}
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input col-100">
                        <div class="item-inner">
                          <div class="item-title item-label profile-font">
                            Password
                          </div>
                          <div class="item-input-wrap profile-font">
                            <input type="password" maxLength="16" name="password" id="password" placeholder="Enter password" value={password} autocomplete="new-password"
                              onChange={(e) => {
                                setPassword(e.target.value);
                              }}
                              className="form-control"
                            />
                            <div class="show-pass">
                              <svg width="24" height="24" onClick={showHidePassword} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M22.919 11.606C22.786 11.3 19.572 4.00002 12 4.00002C4.42801 4.00002 1.21401 11.3 1.08101 11.606C1.02764 11.7305 1.00012 11.8646 1.00012 12C1.00012 12.1355 1.02764 12.2695 1.08101 12.394C1.21401 12.7 4.42801 20 12 20C19.572 20 22.786 12.7 22.919 12.394C22.9724 12.2695 22.9999 12.1355 22.9999 12C22.9999 11.8646 22.9724 11.7305 22.919 11.606ZM12 18C6.60001 18 3.83301 13.411 3.11001 12C3.83501 10.614 6.64801 6.00002 12 6.00002C17.394 6.00002 20.165 10.586 20.89 12C20.164 13.386 17.352 18 12 18Z" fill="#309F5F" />
                                <path d="M12 8C11.2089 8 10.4355 8.2346 9.77772 8.67412C9.11993 9.11365 8.60723 9.73836 8.30448 10.4693C8.00173 11.2002 7.92252 12.0044 8.07686 	12.7804C8.2312 13.5563 8.61216 14.269 9.17157 14.8284C9.73098 15.3878 10.4437 15.7688 11.2196 15.9231C11.9956 16.0775 12.7998 15.9983 13.5307 15.6955C14.2616 15.3928 14.8864 14.8801 15.3259 14.2223C15.7654 13.5645 16 12.7911 16 12C16 10.9391 15.5786 9.92172 14.8284 9.17157C14.0783 8.42143 13.0609 8 12 8ZM12 14C11.6044 14 11.2178 13.8827 10.8889 13.6629C10.56 13.4432 10.3036 13.1308 10.1522 12.7654C10.0009 12.3999 9.96126 11.9978 10.0384 11.6098C10.1156 11.2219 10.3061 10.8655 10.5858 10.5858C10.8655 10.3061 11.2219 10.1156 11.6098 10.0384C11.9978 9.96126 12.3999 10.0009 12.7654 10.1522C13.1308 10.3036 13.4432 10.56 13.6629 10.8889C13.8827 11.2178 14 11.6044 14 12C14 12.5304 13.7893 13.0391 13.4142 13.4142C13.0391 13.7893 12.5304 14 12 14Z" fill="#309F5F" />
                              </svg>
                            </div>
                            {passwordError &&
                              Object.keys(passwordError).map((key) => {
                                return (
                                  <div style={{ color: "red" }}>
                                    {passwordError[key]}
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="clearfix profile-font">
                    <button
                      type="submit"
                      className="button-large button button-fill"
                    >
                      LOGIN
                    </button>
                    <p className="form-text">
                      Forgot Password?{" "}
                      <Link to="/forget-password"
                        data-route-tab-id="tabA2"
                        className="tab-link underline"
                      >
                        Reset Password
                      </Link>
                    </p>
                  </div>
                  {/* <div className="text-align-center account-footer profile-font">
                    <p className="mt-0">Don't have an account?</p>
                    <Link
                      to="/outlet-sign-up/"
                      className="button-large button button-fill button-gray"
                    >
                      CREATE AN ACCOUNT
                    </Link>
                  </div> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ClientSignIn;