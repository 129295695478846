import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate} from 'react-router-dom';
import '../../../App.css';
import '../../../css/framework7-bundle.css'
import '../../../css/app.css'
import '../../../css/font-awesome/css/all.min.css'
import '../../../css/line-awesome/css/line-awesome.min.css'
import '../../../css/style.css'
import '../../../css/perfect-scrollbar.css'
import '../../../img/f7-icon-square.png'
import '../../../img/f7-icon.png'
import '../../../js/main.js';
import axios from 'axios';
import toast from 'react-hot-toast';
import $ from 'jquery';
import { useDispatch, useSelector } from "react-redux";
import { showLoaderAction, ClientMenuDetailAction } from "../../../actions/index";
import SpinerLoader from "../../SpinerLoader/spinerLoader";
import { sendMail, useCatchHandler, showToast } from "../../../utils/utilities"
import { useNotification } from "../../../utils/utilities"
const { REACT_APP_MY_ENV } = process.env;

function VerifyOTP() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [registration] = useState(location.state?.isRegistration);
  const [userRegistered] = useState(location.state?.userRegistered);
  const [name] = useState(location.state?.name);
  const [phoneNumber] = useState(location.state?.phoneNumber);
  const [clientId] = useState(location.state?.clientId)
  const [clientMenuDetail] = useState(location.state?.clientMenuDetail)
  const [enableMenu] = useState(location.state?.enableMenu)
  const [qrUuid] = useState(location.state?.qrUuid)
  const [directUrl] = useState(location.state?.directUrl)
  const showLoaderReducer = useSelector((state) => state.rootReducer.showLoaderReducer)
  const showLoader = showLoaderReducer.show;
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState({});
  const [resendTimer, setResendTimer] = useState(120);
  const [showResendLink, setShowResendLink] = useState(false);

  const catchHandle = useCatchHandler();
  const sendNotification = useNotification();

  useEffect(() => {

    $("input[dist-name='only-numeric']").on("keypress", function (e) {
      var keyCode = e.which;
      if ((keyCode < 48 || keyCode > 57)) {
        return false;
      }
    });

    const timeoutId = setTimeout(() => {
      setShowResendLink(true);
    }, 120000);

    return () => clearTimeout(timeoutId);
    
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setResendTimer(prevTimer => prevTimer - 1);
    }, 1000);
  
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (resendTimer === 0) {
      setShowResendLink(true);
    }
  }, [resendTimer]);

  const getToken = async (token) => {
    const data = {
      name:name,
      phoneNumber:phoneNumber,
      isClient: false
    };
    dispatch(showLoaderAction(true));
    let response = await axios.post(REACT_APP_MY_ENV + "/token/" + qrUuid, data).then(function (response) {
    if (response.data.status === 200) {
      dispatch(showLoaderAction(false));
      showToast(`You got Token No. ${response.data.data.token}`,"success");
      localStorage.setItem("isLogin", true);
      localStorage.setItem("token", token);
      localStorage.setItem("encryptedUserId", response.data.data.userId);
      localStorage.setItem("username", name ? name : localStorage.getItem("username") ? localStorage.getItem("username") : response.data.data.name);
      localStorage.setItem("phoneNumber", phoneNumber ? phoneNumber : localStorage.getItem("phoneNumber"));
      notificationSendForGetToken(response.data.data.token)
      if(enableMenu == true && response.data.data.userTokenId != 0){
        createUserOrder(response.data.data.userId, response.data.data.userTokenId)
      }
      navigate("/my-tokens")
    } else {
      dispatch(showLoaderAction(false));
      showToast(response.data.message, "error");
    }})
    .catch(function (error) {
      dispatch(showLoaderAction(false));
      const data = {
        Subject: "Front end error from token",
        BodyText: error.message
      }
      sendMail(data);
      showToast("Something went wrong, please contact Administrator!", "error")
    });
  }

  const notificationSendForGetToken = (tokenNumber) => {
    const data = {
      ClientId: clientId,
      UserId: localStorage.getItem("encryptedUserId"),
      SentBy: "USER",
      Title: "New Token",
      Description: `${localStorage.getItem("username")} - Token No. ${tokenNumber}`,
      RedirectURL: "/outlet-dashboard"
    }
    sendNotification(data)
  }

  const onSubmit = (e) => {
    e.preventDefault();
    const isValid = formValidation();
    if (isValid) {
    verifyOneTimePassword();
  }
}

  const verifyOneTimePassword = () => {
    var data = {
      encryptedUserId : localStorage.getItem("encryptedUserId"),
      otp: otp,
      isRegistration: registration ? registration : false
    };

    var config = {
      method: 'post',
      url: REACT_APP_MY_ENV +'/verify-otp',
      headers: {
        'Content-Type': 'application/json'
      },
      data: JSON.stringify(data)
    };
    dispatch(showLoaderAction(true));
    axios(config)
      .then(function (response) {
        if(response.data.status === 200){
          dispatch(showLoaderAction(false));
          localStorage.setItem("isLogin", true);
          localStorage.setItem("token", response.data.data.token);
          localStorage.setItem("username", response.data.data.name);
          localStorage.setItem("phoneNumber", response.data.data.phoneNumber);
          if(userRegistered == true){
            getToken(response.data.data.token);
          }
          else{
            showToast(response.data.message, "success");
            if(directUrl)
            {
              navigate("/"+directUrl);
            }
            else{
              navigate("/dashboard");
            }
          }
          
        }else{
          dispatch(showLoaderAction(false));
          showToast(response.data.message, "error");
        }     
      })
      .catch(function (error) {
        dispatch(showLoaderAction(false));
        showToast("Something went wrong, please contact Administrator!", "error")
      });    
  }
  
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      onSubmit(e);
    }
  };

  const formValidation = () => {
    let otpError = {};
    let isValid = true;

    if (!otp) {
      otpError.otpEmpty = "Please enter OTP";
      isValid = false;
    }
		else if (!isNaN(otp) && !(/^\d{4}$$/.test(otp))) {
      otpError.otpInvalid = "Invalid OTP";
      isValid = false;
    }
    setOtpError(otpError);
    setTimeout(() => {
      setOtpError("");
    }, 3000);
    return isValid;

  }

  const resendOtp = () => {
    var data = JSON.stringify(phoneNumber);

      var config = {
        method: "post",
        url: REACT_APP_MY_ENV + "/user-login",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      dispatch(showLoaderAction(true));
      axios(config)
        .then(function (response) {
          if (response.data.status === 200) {
            dispatch(showLoaderAction(false));
            localStorage.setItem(
              "encryptedUserId",
              response.data.data.encryptedUserId
            );
            localStorage.setItem("isLogin", false);
            setShowResendLink(false)
            setResendTimer(120);
            showToast("OTP sent successfully", "success");
            navigate("/verify-otp", { state:{ directUrl:directUrl } });
          } else {
            dispatch(showLoaderAction(false));
            showToast(response.data.message, "error");
          }
        })
        .catch(function (error) {
          dispatch(showLoaderAction(false));
          catchHandle(error, "true")
          const data = {
            Subject: "Front end error from user-login",
            BodyText: error.message
          }
          sendMail(data);
          showToast("Something went wrong, please contact Administrator!", "error")
        });
  };

  const createUserOrder = async (userId, userTokenId) => {
    const data = {
      clientId: clientId,
      userId: userId,
      userTokenId: userTokenId,
      grandTotal: clientMenuDetail.grandTotal,
      comment: clientMenuDetail.comment,
      userOrderDetails: clientMenuDetail.clientMenuDetail
    };
    dispatch(showLoaderAction(true));
    const response = await axios.post(REACT_APP_MY_ENV + "/create-user-order", data).then(function (response) {
      if (response.data.status === 200) {
        dispatch(showLoaderAction(false));
        dispatch(ClientMenuDetailAction([]));
      } else {
        dispatch(showLoaderAction(false));
        showToast(response.data.message, "error");
        dispatch(ClientMenuDetailAction([]));
      }
    }).catch(function (error) {
      dispatch(showLoaderAction(false));
      showToast("Something went wrong, please contact Administrator!", "error")
      const data = {
        Subject: "Front end error from token",
        BodyText: error.message
      }
      sendMail(data)
    });
  }

  return (
    <>
    {showLoader && (<SpinerLoader />)}  
      <div className="page account-area">
        <div className="page-content">
          <div className="dz-height"></div>
          <div className="fixed-content container">
            <div className="tabs">
              <div className="tab tab-active form-elements tabs">
                <form onSubmit={onSubmit} className="tab tab-active" id="verfyOtpForm">
                <div class="title-head d-flex-arrow align-items-center mb-3">
              
                    <Link to={-1} className=" paddingBottom  me-1">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.439312 13.0606L5.75391 18.3752C6.04683 18.6682 6.43069 18.8146 6.81459 18.8146C7.1985 18.8146 7.58236 18.6682 7.87528 18.3752C8.46103 17.7894 8.46103 16.8397 7.87528 16.2539L5.12133 13.5H22.5C23.3284 13.5 24 12.8284 24 12C24 11.1716 23.3284 10.5 22.5 10.5H5.12133L7.87528 7.7461C8.46103 7.1603 8.46103 6.21057 7.87528 5.62477C7.28944 5.03898 6.33975 5.03898 5.75391 5.62477L0.439312 10.9394C-0.146437 11.5251 -0.146437 12.4749 0.439312 13.0606Z" fill="black" />
                      </svg>
                    </Link>
                  
                    <h2 class="title mb-0">Verify OTP</h2>
                  
                 
              </div>
                  <div className="list">
                    <ul className="row">
                      <li className="item-content item-input col-100">
                        <div className="item-inner profile-font">
                          <div className="item-title item-label">OTP</div>
                          <div className="item-input-wrap item mb-1">
                            <input type="text" maxLength="4" placeholder="1234" autocomplete="off" value={otp} onChange={(e) => { setOtp(e.target.value) }} onKeyDown={handleKeyDown} className="form-control only-numeric" id="numeric" dist-name="only-numeric" />
                            {otpError && Object.keys(otpError).map((key) => {
                              return <span style={{ color: "red" }}>{otpError[key]}</span>
                            })}
                          </div>
                        </div>
                      </li>                      
                    </ul>
                  </div>
                  <div className="clearfix profile-font">
                    <button type='submit' className="button-large button button-fill">Verify</button>
                  </div>
                  <Link to="#" className={`button text-capitalize ${showResendLink ? '' : 'disabled'}`} onClick={showResendLink ? resendOtp : undefined}>
                  Resend OTP
                    {!showResendLink && (
                      <span className="button text-capitalize">in {Math.max(Math.floor(resendTimer / 60), 0).toString().padStart(2, '0')}:{Math.max(resendTimer % 60, 0).toString().padStart(2, '0')}
                      </span>
                    )}
                  </Link>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>    
    </>
  );
}

export default VerifyOTP;