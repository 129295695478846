import axios from 'axios';
import React from 'react'
import { useEffect } from 'react';
import { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import TimeAgo from "javascript-time-ago";
import ReactTimeAgo from "react-time-ago";
import en from "javascript-time-ago/locale/en.json";
import { useDispatch, useSelector } from "react-redux";
import { NotificationCountAction, showLoaderAction } from "../../actions/index";
import { useCatchHandler, showToast } from "../../utils/utilities";
import ReactPaginate from 'react-paginate';
import { activeMenuAction } from "../../actions/index";
import ClientFooter from "../Master/ClientFooter"
import Footer from '../Master/Footer';
import { sendMail } from '../../utils/utilities';
import { confirmAlert } from 'react-confirm-alert';
import { uniqBy } from "lodash";
import 'react-confirm-alert/src/react-confirm-alert.css';
import ListPlaceholder from '../../components/Placeholders/ListPlaceholder.js';

TimeAgo.addLocale(en);

const { REACT_APP_MY_ENV } = process.env;

const Notification = () => {
  let token = localStorage.getItem("clientToken") ? localStorage.getItem("clientToken") : localStorage.getItem("token");
  let IsUser = localStorage.getItem("isLogin");
  const dispatch = useDispatch();
  const showLoaderReducer = useSelector((state) => state.rootReducer.showLoaderReducer)
  const showLoader = showLoaderReducer.show;
  const [notificationList, setNotificationList] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const catchHandle = useCatchHandler();
  const currentURL = window.location.href;

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [notificationsCount, setNotificationsCount] = useState(0);
  const pageCount = Math.ceil(notificationsCount / itemsPerPage);
  const [loading, setLoading] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const scrollRef = useRef(null);

  const notificationCountReducer = useSelector(
    (state) => state.rootReducer.notificationCountReducer);
  var notificationCount = notificationCountReducer.count;

  useEffect(() => {
    fetchNotificationList(1)
    localStorage.getItem("isClientLogin") ?
      dispatch(activeMenuAction('notification-list')) : dispatch(activeMenuAction('notification-list'))
    if (localStorage.getItem("isLogin", true)) {
      setIsLoggedIn(true);
    }
  }, []);

  const handleScroll = () => {
    const container = document.getElementById('scroll');
    if (container.scrollTop + container.clientHeight >= container.scrollHeight - 20 && !loading) {
      if (notificationList.length < notificationsCount) {
        setScrollPosition(container.scrollTop);
        fetchNotificationList();
      }
    }
  };

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollPosition;
    }
  }, [notificationList]);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected + 1);
    fetchNotificationList(selected + 1)
  };

  const fetchNotificationList = (pageNumber) => {
    setLoading(true)
    let data = {};
    if (IsUser) {
      data = {
        UserId: localStorage.getItem("encryptedUserId"),
        pageNumber: pageNumber ? pageNumber : currentPage,
        pageSize: itemsPerPage
      };
    } else {
      data = {
        ClientId: localStorage.getItem("encryptedClientId"),
        pageNumber: pageNumber ? pageNumber : currentPage,
        pageSize: itemsPerPage
      };
    }
    // dispatch(showLoaderAction(true));
    axios.post(REACT_APP_MY_ENV + "/notification-list", data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }).then(function (res) {
      if (res.data.status === 200) {
        if (pageNumber == 1) {
          setNotificationList(res.data.data.notificationList)
        } else {
          setNotificationList(uniqBy([...notificationList, ...res.data.data.notificationList], 'notificationId'));
        }
        // setNotificationList(notificationList => [...notificationList, ...res.data.data.notificationList]);
        // setNotificationList(uniqBy([...notificationList, ...res.data.data.notificationList], 'notificationId'));
        setNotificationsCount(res.data.data.totalNotificationCount);
        setCurrentPage(currentPage => currentPage + 1);
        setLoading(false)
        dispatch(showLoaderAction(false));
      } else {
        dispatch(showLoaderAction(false));
        setNotificationList([]);
      }
    }).catch(function (error) {
      if (!IsUser) {
        catchHandle(error, "false", currentURL)
        dispatch(showLoaderAction(false));
        const data = {
          Subject: "Front end error from notification-list",
          BodyText: error.message
        }
        sendMail(data);
        showToast("Something went wrong, please contact Administrator!", "error")
      }
      else {
        catchHandle(error, "true", currentURL)
      }
    });
  };

  const notificationUpdate = async (notificationId) => {
    let data = {
      NotificationId: notificationId
    };
    const res = await axios.post(REACT_APP_MY_ENV + "/update-notification", data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }).then(function (res) {
      if (res.data.status === 200) {
        const updatedNotificationIndex = notificationList.findIndex(item => item.notificationId === notificationId);
        if (updatedNotificationIndex !== -1) {
          const updatedNotificationList = [...notificationList];
          updatedNotificationList[updatedNotificationIndex].notificationStatus = "Read";
          setNotificationList(updatedNotificationList);
          const unreadNotificationsCount = updatedNotificationList.filter(item => item.notificationStatus !== "Read").length;
          dispatch(NotificationCountAction(unreadNotificationsCount));
        }
        dispatch(showLoaderAction(false));
      } else {
        dispatch(showLoaderAction(false));
      }
    }).catch(function (error) {
      if (!IsUser) {
        catchHandle(error, "false", currentURL)
        dispatch(showLoaderAction(false));
        showToast("Something went wrong, please contact Administrator!", "error")
      }
      else {
        catchHandle(error, "true", currentURL)
      }
      const data = {
        Subject: "Front end error from update-notification",
        BodyText: error.message
      }
      sendMail(data);
    });
  };

  const updateAllNotification = async () => {
    setCurrentPage(1);
    const requestData = {
      ClientId: localStorage.getItem("encryptedClientId"),
      UserId: localStorage.getItem("encryptedUserId"),
      IsMarkAllRead: true
    }
    const res = await axios.post(REACT_APP_MY_ENV + "/update-notification", requestData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }).then(function (res) {
      if (res.data.status === 200) {
        dispatch(NotificationCountAction(0));
        fetchNotificationList(1);
        dispatch(showLoaderAction(false));
      } else {
        dispatch(showLoaderAction(false));
      }
    }).catch(function (error) {
      if (!IsUser) {
        catchHandle(error, "false", currentURL)
        dispatch(showLoaderAction(false));
        showToast("Something went wrong, please contact Administrator!", "error")
      }
      else {
        catchHandle(error, "true", currentURL)
      }
      const data = {
        Subject: "Front end error from update-notification",
        BodyText: error.message
      }
      sendMail(data);
    });
  }

  const clearAllNotification = async () => {
    const data = {
      ClientId: localStorage.getItem("encryptedClientId"),
      UserId: localStorage.getItem("encryptedUserId")
    }

    const res = await axios.delete(REACT_APP_MY_ENV + "/delete-notifications", {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data
    }).then(function (res) {
      if (res.data.status === 200) {
        dispatch(NotificationCountAction(0));
        fetchNotificationList(1);
        dispatch(showLoaderAction(false));
      } else {
        dispatch(showLoaderAction(false));
      }
    }).catch(function (error) {
      if (!IsUser) {
        catchHandle(error, "false", currentURL)
        dispatch(showLoaderAction(false));
        showToast("Something went wrong, please contact Administrator!", "error")
      }
      else {
        catchHandle(error, "true", currentURL)
      }
      const data = {
        Subject: "Front end error from delete-notifications",
        BodyText: error.message
      }
      sendMail(data);
    });
  }

  const ConfirmReadAll = () => {
    confirmAlert({
      title: (
        <span className="confirm-title">
          Confirmation
        </span>
      ),
      message: 'Are you sure you want to read all notifications?',
      buttons: [
        {
          label: 'Yes',
          className: 'red-button',
          onClick: () => updateAllNotification()
        },
        {
          label: 'Cancel',
          className: 'gray-button',
        }
      ]
    });
  }

  const ConfirmClearAll = () => {
    confirmAlert({
      title: (
        <span className="confirm-title">
          Confirmation
        </span>
      ),
      message: 'Are you sure you want to clear all notifications?',
      buttons: [
        {
          label: 'Yes',
          className: 'red-button',
          onClick: () => clearAllNotification()
        },
        {
          label: 'Cancel',
          className: 'gray-button',
        }
      ]
    });
  }

  const hasUnreadNotifications = notificationList.some(
    (data) => data.notificationStatus === "Unread"
  );

  return (
    <>
      {showLoader ? (<ListPlaceholder showClientFooter={IsUser ? false : true} skeletonName='Notifications' showAvatar={false} />)
        :
        <div class="page">
          <div className='containerCard'>
            <div class="navbar navbar-style-1">
              <div class="navbar-inner navbar-bg-theme">
                <Link to={-1} class="link back">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.439312 13.0606L5.75391 18.3752C6.04683 18.6682 6.43069 18.8146 6.81459 18.8146C7.1985 18.8146 7.58236 18.6682 7.87528 18.3752C8.46103 17.7894 8.46103 16.8397 7.87528 16.2539L5.12133 13.5H22.5C23.3284 13.5 24 12.8284 24 12C24 11.1716 23.3284 10.5 22.5 10.5H5.12133L7.87528 7.7461C8.46103 7.1603 8.46103 6.21057 7.87528 5.62477C7.28944 5.03898 6.33975 5.03898 5.75391 5.62477L0.439312 10.9394C-0.146437 11.5251 -0.146437 12.4749 0.439312 13.0606Z" fill="white" />
                  </svg>
                </Link>
                <div class="title text-align-right notification-center text-white">Notifications</div>
                {notificationList && notificationList.length > 0 ?
                  <div class="d-flex align-items-center notification-button-size">
                    {hasUnreadNotifications && (
                      <Link onClick={ConfirmReadAll} className="notification-button text-white">
                        <span>Mark all as read</span>
                      </Link>
                    )}
                    <Link onClick={ConfirmClearAll} className="ml-2 notification-button2 text-white">
                      <span>Clear All</span>
                    </Link>
                  </div>
                  : ""}
              </div>
            </div>

            <div class="page-content inner-page pt-0" id="scroll" onScroll={() => handleScroll()} ref={scrollRef}>
              <div class="notification-list">
                <ul>
                  {notificationList && notificationList.length > 0 ? (notificationList.map((data, index) => {
                    const createdAt = new Date(data.createdDate);
                    const today = new Date();
                    let CreatedAtDate;

                    if (createdAt.toDateString() === today.toDateString()) {
                      CreatedAtDate = <ReactTimeAgo date={createdAt} locale="en-US" />;
                    } else {
                      const formattedDate = createdAt.toLocaleString('en-US', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: true,
                      });
                      CreatedAtDate = formattedDate;
                    }
                    return (
                      <Link key={index} to={data.redirectUrl ? data.redirectUrl : ""} onClick={() => data.notificationStatus === "Unread" && notificationUpdate(data.notificationId)}>
                        <li>
                          <h6 class="item-title">{data.notificationStatus === "Unread" ? <i class="blue-dot fa fa-circle"></i> : ""} {data.title} <span class="item-time"><i class="fa fa-clock-o"></i> {CreatedAtDate}</span></h6>
                          <div class="item-text">{data.description.trim()}</div>
                        </li>
                      </Link>
                    )
                  })) : <p className='no-notification-msg'>No notifications yet!</p>}
                </ul>
              </div>
              {/* {notificationList && notificationList.length > 0 ?
              <div className="notification-pagination" >
                <ReactPaginate
                  pageCount={pageCount}
                  pageRangeDisplayed={10}
                  marginPagesDisplayed={0}
                  onPageChange={handlePageClick}
                  previousLabel={"Prev"}
                  nextLabel={"Next"}
                  breakLabel={"..."}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                  forcePage={ currentPage === 1 ? 0 : currentPage - 1}
                />
              </div>
              : ""} */}
              {localStorage.getItem("isClientLogin") ?
                <ClientFooter /> : <Footer />}
            </div>
          </div>
        </div>
      }
    </>
  )
}
export default Notification