import React, { useState, useEffect } from "react";
import { useNavigate, Link } from 'react-router-dom';
import "../../../css/style.css";
import "../../../css/custom.css";
import "../../../css/font-awesome/css/all.min.css";
import "../../../css/line-awesome/css/line-awesome.min.css";
import axios from "axios";
import { checkLoginStatus } from '../../../js/main.js';
import { useDispatch, useSelector } from "react-redux";
import { showLoaderAction } from "../../../actions/index";
import SpinerLoader from "../../SpinerLoader/spinerLoader";
import { sendMail, useCatchHandler, showToast } from "../../../utils/utilities"
import { activeMenuAction } from "../../../actions/index";
import moment from "moment";
const { REACT_APP_MY_ENV } = process.env;

function UpdateProfile() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showLoaderReducer = useSelector((state) => state.rootReducer.showLoaderReducer)
  const showLoader = showLoaderReducer.show;
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [birthDate, setBirthDate] = useState(null);
  const [anniversaryDate, setAnniversaryDate] = useState(null);

  const [emailAddress, setEmailAddress] = useState("");

  const [nameError, setNameError] = useState({});
  const [phoneNumberError, setPhoneNumberError] = useState({});
  const [emailAddressError, setEmailAddressError] = useState({});
  let token = localStorage.getItem("token");
  const catchHandle = useCatchHandler();
  const currentURL = window.location.href;

  useEffect(() => {
    dispatch(activeMenuAction('update-profile'));
    checkLoginStatus();
    getUserDetail()
  }, []);


  const formValidation = () => {
    let nameError = {};
    let phoneNumberError = {};
    let emailAddressError = {};

    let isValid = true;

    if (!name.trim()) {
      nameError.nameEmpty = "Please enter the name";
      isValid = false;
    }

    if (!phoneNumber) {
      phoneNumberError.phoneNumberEmpty = "Please enter the phone number";
      isValid = false;
    } else if (!/^(\+\d{1,3}[- ]?)?\d{10}$/.test(phoneNumber)) {
      phoneNumberError.phoneInvalid =
        "Invalid phone number, phone number should be of 10 digits";
      isValid = false;
    }

    if(emailAddress != null){
      if (emailAddress.trim()) {
        if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailAddress.trim())) {
          emailAddressError.emailInvalid = "Invalid email address";
          isValid = false;
        }
      }
    }

    setNameError(nameError);
    setPhoneNumberError(phoneNumberError);
    setEmailAddressError(emailAddressError);
    return isValid;
  };

  const getUserDetail = async () => {
    const data = {
      EncryptedUserId: localStorage.getItem("encryptedUserId")
    }

    dispatch(showLoaderAction(true));
    const response = await axios.post(REACT_APP_MY_ENV + "/get-user", data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }).then(function (response) {

      if (response.data.status == 200) {
        setName(response.data.data.name);
        localStorage.setItem("username", response.data.data.name)
        setPhoneNumber(response.data.data.phoneNumber);
        setEmailAddress(response.data.data.emailAddress);
        setBirthDate(response.data.data.birthDate ? moment(response.data.data.birthDate).format('YYYY-MM-DD') : null)
        setAnniversaryDate(response.data.data.anniversaryDate ? moment(response.data.data.anniversaryDate).format('YYYY-MM-DD') : null)
      }
      else {
        dispatch(showLoaderAction(false));
        showToast(response.data.message, "error");
      }
    }).catch(function (error) {
      catchHandle(error, "true", currentURL)
      dispatch(showLoaderAction(false));
      showToast("Something went wrong, please contact Administrator!", "error")
      const data = {
        Subject: "Front end error from get-user",
        BodyText: error.message
      }
      sendMail(data);
    });

    dispatch(showLoaderAction(false));
  }

  const UpdateUserDetail = async () => {
    const data = {
      name: name,
      phoneNumber: phoneNumber,
      emailAddress: emailAddress != null ? emailAddress.trim() : "",
      AnniversaryDate:anniversaryDate ? anniversaryDate : null,
      birthDate:birthDate ? birthDate : null,
      EncryptedUserId: localStorage.getItem("encryptedUserId")
    }

    dispatch(showLoaderAction(true));
    const response = await axios.post(REACT_APP_MY_ENV + "/update-profile", data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }).then(function (response) {

      if (response.data.status == 200) {
        dispatch(showLoaderAction(false));
        showToast(response.data.message, "success");
        getUserDetail()
        navigate("/sidebar");
      }
      else if (response.data.status === 204) {
        dispatch(showLoaderAction(false));
        showToast(response.data.message, "error");
      } else if (response.data.status === 500) {
        dispatch(showLoaderAction(false));
        showToast(response.data.message, "error");
      } else {
        dispatch(showLoaderAction(false));
        showToast(response.data.message, "error");
      }
    }).catch(function (error) {
      catchHandle(error, "true", currentURL)
      dispatch(showLoaderAction(false));
      showToast("Something went wrong, please contact Administrator!", "error")
      const data = {
        Subject: "Front end error from update-profile",
        BodyText: error.message
      }
      sendMail(data);
    });
    dispatch(showLoaderAction(false));
  }

  const onSubmit = (e) => {
    e.preventDefault();
    const isValid = formValidation();
    if (isValid) {
      UpdateUserDetail()
    }
  };

  const getCurrentDate = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const day = currentDate.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  return (
    <>
      {showLoader && (<SpinerLoader />)}
      <div class="page account-area">
        <div class="page-content">
          <div class="dz-height"></div>
          <div class="fixed-content container">
            <div class="form-elements">
              <form onSubmit={onSubmit}>
                <div class="navbar  pb-2">
                  <div class="navbar-inner padding-0 pb-2">
                    <div class="">
                      <Link to="/sidebar" class="paddingBottom back">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M0.439312 13.0606L5.75391 18.3752C6.04683 18.6682 6.43069 18.8146 6.81459 18.8146C7.1985 18.8146 7.58236 18.6682 7.87528 18.3752C8.46103 17.7894 8.46103 16.8397 7.87528 16.2539L5.12133 13.5H22.5C23.3284 13.5 24 12.8284 24 12C24 11.1716 23.3284 10.5 22.5 10.5H5.12133L7.87528 7.7461C8.46103 7.1603 8.46103 6.21057 7.87528 5.62477C7.28944 5.03898 6.33975 5.03898 5.75391 5.62477L0.439312 10.9394C-0.146437 11.5251 -0.146437 12.4749 0.439312 13.0606Z" fill="black" />
                        </svg>
                      </Link>
                    </div>
                    <div className="title text-left">Update Profile</div>
                  </div>
                </div>
                <div class="list">
                  <ul class="row">
                    <li class="item-content col-100 item-input item-input-with-value">
                      <div class="item-inner">
                        <div class="item-title item-label profile-font">
                          Name<span className="danger">*</span>
                        </div>
                        <div class="item-input-wrap profile-font">
                          <input
                            type="text"
                            autocomplete="off"
                            value={name}
                            onChange={(e) => {
                              setName(e.target.value);
                            }}
                            class="form-control"
                          />
                          <span id="errorMessage"></span>
                          {Object.keys(nameError).map((key) => {
                            return (
                              <div style={{ color: "red" }}>
                                {nameError[key]}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </li>
                    <li class="item-content col-100 item-input">
                      <div class="item-inner">
                        <div class="item-title item-label profile-font">
                          Phone Number
                        </div>
                        <div class="item-input-wrap profile-font disabled">
                          <input
                            type="text"
                            autocomplete="off"
                            value={phoneNumber}
                            onChange={(e) => {
                              setPhoneNumber(e.target.value);
                            }}
                            class="form-control"
                          />
                          {Object.keys(phoneNumberError).map((key) => {
                            return (
                              <div style={{ color: "red" }}>
                                {phoneNumberError[key]}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </li>
                    <li class="item-content col-100 item-input">
                      <div class="item-inner">
                        <div class="item-title item-label profile-font">
                          Email
                        </div>
                        <div class="item-input-wrap profile-font">
                          <input
                            type="text"
                            id="dz-password"
                            autocomplete="off"
                            value={emailAddress}
                            onChange={(e) => {
                              setEmailAddress(e.target.value);
                            }}
                            class="form-control"
                          />
                          {Object.keys(emailAddressError).map((key) => {
                            return (
                              <div style={{ color: "red" }}>
                                {emailAddressError[key]}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </li>
                    <li class="item-content col-50 item-input">
                      <div class="item-inner">
                        <div class="item-title item-label profile-font">
                        Birth Date
                        </div>
                        <div class="item-input-wrap profile-font">
                          <input
                            type="date"
                            id="dz-password"
                            autocomplete="off"
                            value={birthDate}
                            onChange={(e) => {
                              setBirthDate(e.target.value);
                            }}
                            class="form-control"
                            max={getCurrentDate()} 
                          />
                        </div>
                      </div>
                    </li>
                    <li class="item-content col-50 item-input">
                      <div class="item-inner">
                        <div class="item-title item-label profile-font">
                        Anniversary Date
                        </div>
                        <div class="item-input-wrap profile-font">
                          <input
                            type="date"
                            id="anniversaryDate"
                            value={anniversaryDate}
                            onChange={(e) => {
                              setAnniversaryDate(e.target.value);
                            }}
                            class="form-control"
                            max={getCurrentDate()} 
                          />
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="clearfix profile-font">
                  <button
                    type="submit" id="yes"
                    className="button-large button button-fill profile-font"
                  >
                    UPDATE
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UpdateProfile;
