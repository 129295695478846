import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../../css/framework7-bundle.css";
import "../../css/app.css";
import "../../css/font-awesome/css/all.min.css";
import "../../css/line-awesome/css/line-awesome.min.css";
import "../../css/style.css";
import "../../css/perfect-scrollbar.css";
import "../../img/f7-icon-square.png";
import "../../img/f7-icon.png";
import "../../css/custom.css";
import eyeIcon from "../../img/eye-black.svg";
import axios from "axios";
import { useCatchHandler, showToast } from '../../utils/utilities';
import { useDispatch, useSelector } from "react-redux";
import { showLoaderAction } from "../../actions/index";
import SpinerLoader from "../SpinerLoader/spinerLoader";
import logo from "../../img/logo-new.png";
import { sendMail } from "../../utils/utilities";
const { REACT_APP_MY_ENV } = process.env;

const OutletRequest = () => {
    const navigate = useNavigate();
  const catchHandle = useCatchHandler();
  const { requestFrom } = useParams();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    name:'',
    email: '',
    phoneNumber: '',
    description:'',
    address:'',
    AttachmentName:'',
    AttachmentFile:'',
    AttachmentNameURL :''
  });
  const [img, setImg] = useState("");
  const [file, setFile] = useState(undefined);
  const showLoaderReducer = useSelector((state) => state.rootReducer.showLoaderReducer)
  const showLoader = showLoaderReducer.show;
  const [outletError, setOutletError] = useState({});
  const [logoFileImgError, setLogoFileImgError] = useState({});
  const [phoneNumberError, setPhoneNumberError] = useState({});
  const [emailAddressError, setEmailAddressError] = useState({});
  const validLogoFileExtensions = ['jpg', 'jpeg', 'png', 'docx', 'csv','doc', 'xlsx', 'xls', 'msword','pdf'];

  useEffect(() => {
    const storedRequestFrom = localStorage.getItem('requestFrom');
    if (storedRequestFrom) {
      setFormData((prevFormData) => ({ ...prevFormData, requestFrom: storedRequestFrom }));
    } else {
      setFormData((prevFormData) => ({ ...prevFormData, requestFrom }));
    }
  }, [requestFrom]);

  useEffect(() => {
    localStorage.setItem('requestFrom', formData.requestFrom);
  }, [formData.requestFrom]);

  const formValidation = () => {
    let outletError = {};
    let phoneNumberError = {};
    let logoFileImgError = {};

    let isValid = true;
    const maxFileSizeInBytes = 2 * 1024 * 1024;

    if (!formData.name) {
      outletError.outletEmpty = "Please enter outlet name";
      isValid = false;
    }

    if (!formData.phoneNumber) {
      phoneNumberError.phoneNumberEmpty = "Please enter phone number";
      isValid = false;
    } else if (!/^(\+\d{1,3}[- ]?)?\d{10}$/.test(formData.phoneNumber)) {
      phoneNumberError.phoneInvalid =
        "Please enter valid phone number";
      isValid = false;
    }
    if (formData.emailAddress) {
        if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(formData.emailAddress)) {
          emailAddressError.emailInvalid = "Please enter valid email address";
          isValid = false;
        }
      }

    if (formData.AttachmentName) {
      if (!validLogoFileExtensions.includes(formData.AttachmentName.name.split('.').pop().toLowerCase())) {
        logoFileImgError.logoFileImgInvalid = "Invalid file extension. Please upload a file with extensions jpg, jpeg, png, doc, xls, pdf"
        isValid = false;
      }
      if (formData.AttachmentName.size > maxFileSizeInBytes) {
        logoFileImgError.logoFileImgSize = "File size exceeds the maximum allowed 2 MB";
        isValid = false;
      }
    }

    setOutletError(outletError);
    setPhoneNumberError(phoneNumberError);
    setEmailAddressError(emailAddressError);
    setLogoFileImgError(logoFileImgError)

    return isValid;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (formValidation()) {
      const Data = new FormData();
      Data.append("AttachmentFile", formData.AttachmentName);
      Data.append("Name", formData.name);
      Data.append("PhoneNumber", formData.phoneNumber);
      Data.append("Email", formData.emailAddress === undefined ? "" : formData.emailAddress );
      Data.append("Address", formData.address);
      Data.append("Description", formData.description);
      Data.append("RequestFrom", requestFrom);
      dispatch(showLoaderAction(true));
      const res = await axios.post(REACT_APP_MY_ENV + "/add-outlet-requests", Data).then(function (res) {
        if (res.data.status === 200) {
          dispatch(showLoaderAction(false));
          showToast(res.data.message, "success");
          setTimeout(() => {
            window.location.href = "https://tokenlelo.com";
          }, 2000)
        }
        else {
          dispatch(showLoaderAction(false));
          showToast(res.data.message, "error");
        }
      }).catch(function (error) {
        dispatch(showLoaderAction(false));
        const data = {
          Subject: "Front end error from outlet-request",
          BodyText: error.message
        }
        sendMail(data);
        showToast("Something went wrong, please contact Administrator!", "error")
      })
    }
  };

  const handleChange = (e) => {
  if (e.target.name == 'AttachmentName') {
      if (e.target.files[0]) {
        setFormData({
          ...formData,
          AttachmentName: e.target.files[0],
          AttachmentFile: URL.createObjectURL(e.target.files[0])
        });
      }
      else {
        setFormData({
          ...formData,
          AttachmentName: null,
          AttachmentFile: ''
        });
      }
    } else {
        setFormData({
        ...formData,
        [e.target.name]: e.target.value
      });
    }
  }

  return (
    <>
      {showLoader && (<SpinerLoader />)}
      <div class="page account-area outlet-sign-up">
        <div class="page-content">
          <div class="dz-height"></div>
          <div class="fixed-content container pt-0">
            <div class="form-elements">
              <form onSubmit={onSubmit}>
              <div class="title-head d-flex-arrow mt-15">
                  <div>
                  </div>
                  <div>
                  <h4 class="title mb-0 text-left">Submit Outlet Request</h4>
                  </div>
                </div>
                <p class="profile-font  mb-15 p-0">Get your outlet registered at Token Lelo</p>
              <div className="logo-request">
               <img src={logo} /></div>
                <div class="list">
                  <ul class="row">
                    <li class="item-content col-100 item-input item-input-with-value">
                      <div class="item-inner">
                        <div class="item-title item-label profile-font">
                          Outlet Name<span className="danger">*</span>
                        </div>
                        <div class="item-input-wrap profile-font">
                          <input
                            type="text"
                            name="name"
                            placeholder="Enter Outlet Name"
                            value={formData.name}
                            autocomplete="off"
                            maxLength="255"
                            onChange={
                                handleChange}
                            class="form-control"
                          />
                          {Object.keys(outletError).map((key) => {
                            return (
                              <div style={{ color: "red" }}>
                                {outletError[key]}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </li>
                    <li class="item-content col-100 item-input">
                      <div class="item-inner">
                        <div class="item-title item-label profile-font">
                          Phone Number<span className="danger">*</span>
                        </div>
                        <div class="item-input-wrap profile-font">
                          <input
                            type="text"
                            name="phoneNumber"
                            placeholder="Enter Phone Number"
                            value={formData.phoneNumber}
                            autocomplete="off"
                            maxLength="10"
                            onChange={
                                handleChange}
                            class="form-control"
                            onKeyPress={(e) => {
                              const isNumeric = /^[0-9\b]+$/.test(e.key);

                              if (!isNumeric) {
                                e.preventDefault();
                              }
                            }}
                          />
                          {Object.keys(phoneNumberError).map((key) => {
                            return (
                              <div style={{ color: "red" }}>
                                {phoneNumberError[key]}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </li>
                    <li class="item-content col-100 item-input">
                      <div class="item-inner">
                        <div class="item-title item-label profile-font">
                          Email Address
                        </div>
                        <div class="item-input-wrap profile-font">
                          <input
                            type="text"
                            name="emailAddress"
                            placeholder="Enter Email Address"
                            id="dz-password"
                            value={formData.emailAddress}
                            autocomplete="off"
                            maxLength="100"
                            onChange={
                                handleChange}
                            class="form-control"
                          />
                             {Object.keys(emailAddressError).map((key) => {
                            return (
                              <div style={{ color: "red" }}>
                                {emailAddressError[key]}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </li>
                    <li class="item-content col-100 item-input">
                      <div class="item-inner">
                        <div class="item-title item-label profile-font">
                          Address
                        </div>
                        <div class="item-input-wrap profile-font">
                          <textarea id="address"
                            rows="10"
                            cols="15"
                            name="address"
                            autocomplete="off"
                            value={formData.address}
                            maxLength="500"
                            placeholder="Enter Address"
                            onChange={
                                handleChange}
                            class="form-control" />
                        </div>
                      </div>
                    </li>
                    <li class="item-content col-100 item-input">
                      <div class="item-inner">
                        <div class="item-title item-label profile-font">
                          Description
                        </div>
                        <div class="item-input-wrap profile-font">
                        <textarea id="description"
                            rows="10"
                            cols="15"
                            name="description"
                            autocomplete="off"
                            value={formData.description}
                            maxLength="500"
                            placeholder="Enter Description"
                            onChange={
                                handleChange}
                            class="form-control" />
                        </div>
                      </div>
                    </li>    
                    <li class="item-content item-input col-100">
                      <div class="row justify-content-start item-inner flex-direction-row">
                      <div class="col-8 item-input-wrap profile-font">
                          <input type="file" name="AttachmentName" id="AttachmentName"
                            onChange={
                              handleChange}
                            autocomplete="off"
                            accept=".jpg, .jpeg, .png, image/heif"
                            className="form-control qrFile"
                          />
                          {Object.keys(logoFileImgError).map((key) => {
                            return (
                              <div style={{ color: "red" }}>
                                {logoFileImgError[key]}
                              </div>
                            );
                          })}
                          <span> {<a href={formData.AttachmentFile ? formData.AttachmentFile : "javascript:void(0);"} target="_blank" className="link request-file">
                            {formData.AttachmentFile ? <img src={eyeIcon} alt="icon" /> : ""}
                          </a>}</span>
                        </div>
                    </div>
                    </li>
                  </ul>
                </div>
                <div class="clearfix profile-font">
                  <button
                    type="submit"
                    className="button-large button button-fill">
                    SUBMIT
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OutletRequest