import React, { useState, useEffect} from 'react';
import axios from "axios";
import { showLoaderAction, ClientMenuDetailAction } from "../../../actions/index";
import { sendMail, showToast } from "../../../utils/utilities"
import { useDispatch } from "react-redux";

const { REACT_APP_MY_ENV } = process.env;

const ClientMenu = ({clientId}) => {
  const dispatch = useDispatch();
  const [clientMenuDetail, setClientMenuDetail] = useState([]);
  const [grandTotal, setGrandTotal] = useState(0);
  const [comment, setComment] = useState("");
  let ClientID = localStorage.getItem("encryptedClientId") || clientId.toString();

  let token = localStorage.getItem("clientToken") ? localStorage.getItem("clientToken") : localStorage.getItem("token") ;

  useEffect(() => {
        getClientMenu ()
  }, [])

  useEffect(() => {
    const filteredClientMenuDetail = clientMenuDetail.filter(item => item.quantity > 0);
    const updatedClientMenu = filteredClientMenuDetail.map(item => ({
      ...item,
      total: item.quantity * item.rate
    }));
    
    if (updatedClientMenu.length > 0) {
      const grandTotal = updatedClientMenu.reduce((total, item) => total + item.total, 0);
      setGrandTotal(grandTotal);
    } else {
      setGrandTotal(0);
    }
  
    dispatch(ClientMenuDetailAction(updatedClientMenu, grandTotal, comment));
  }, [clientMenuDetail, grandTotal, comment]);
  

  const getClientMenu = async () => {
    const data = {
      clientId: ClientID
    };
    // dispatch(showLoaderAction(true));
    let response = await axios.post(REACT_APP_MY_ENV + "/get-client-menu", data, { headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" } })
    .then(function (response) {
      // dispatch(showLoaderAction(false));
      if (response.data.status === 200) {
        if(response.data && response.data.data && response.data.data.clientMenu){
          dispatch(showLoaderAction(false));
          const updatedClientMenu = response.data.data.clientMenu.map(item => ({
            clientMenuId: item.id,
            itemName: item.itemName,
            rate: item.rate,
            isAvailable: item.isAvailable,
            description: item.description,
            quantity: 0,
          }));
          setClientMenuDetail(updatedClientMenu)
        }
      }
     else {
        showToast(response.data.message, "error");
      }
    }).catch(function (error) {
      // dispatch(showLoaderAction(false));
      showToast("Something went wrong, please contact Administrator!", "error")
      const data = {
        Subject: "Front end error from token",
        BodyText: error.message
      }
      sendMail(data)
    });
  }

  const handleQuantityChange = (action, index) => {
    setClientMenuDetail(prevMenu => prevMenu.map((item, i) => {
      if (i === index) {
        if (action === "increment") {
          return { ...item, quantity: item.quantity + 1 };
        } else if (action === "decrement" && item.quantity > 0) {
          return { ...item, quantity: item.quantity - 1 };
        }
      }
      return item;
    }));
  };

  return (
    <>
      <div class="navbar navbar-style-1 border-bottom-color mt-5">
        <div class="navbar-inner ">
          <div class="title" style={{ width: "100%" }}>Select Items</div>
        </div>
      </div>
      <div className='containerCard stat-box stat-box-pad state-box-border mb-4'>
        <div class="page-content pt-0 " >
          <div class="list media-list search-list searchbar-found menu-item-card search-list-bar  daily-token-mb-1 daily-token-m-0 user-list">
            <ul>
              <li>
                <div class="item-link item-content  daily-token-mt-0">
                  <div class="item-inner">
                    <div class="item-title-row item-details justify-content-between title-center-menu align-items-center">
                      <h4 class="item-title ">Name</h4>
                      <h4 class="item-title rate-title ">Rate</h4>
                      <h4 class="item-title title-center-qnty">Qty.</h4>
                    </div>
                  </div>
                </div>
              </li>
              {clientMenuDetail && clientMenuDetail.length > 0 ?
                clientMenuDetail && clientMenuDetail.map((data, index) => {
                  return (
                    <li key={index}>
                      <div class="item-link item-content client-menu-list daily-token-mt-0">
                        <div class="item-inner">
                          <div class="item-title-row item-details">
                            <h6 class="item-title menu-item-name"> {data.itemName}</h6>
                            {/* <h6 class="item-title rate-hd "></h6> */}
                            <div class="stepper stepper-init stepper-round stepper-margin">
                              <div class="stepper-button-minus" onClick={() => handleQuantityChange("decrement", index)}>
                              </div>
                              <div class="stepper-input-wrap">
                                <input type="text" min="0" max="100" step="1" value={data.quantity} readOnly />
                              </div>
                              <div class="stepper-button-plus" onClick={() => handleQuantityChange("increment", index)}>
                              </div>
                            </div>
                          </div>
                          <div className='description-title'>
                            ₹ {data.rate}
                          </div>
                        
                        </div>
                      </div>
                    </li>
                  )
                })
                :
                <span id="no-users">Loading...</span>
              }
              <li >
                <div class="item-link item-content client-menu-list daily-token-mt-0">
                  <div class="item-inner">
                    <div class="item-title-row item-details title-center-menu">
                      <h6 class="item-title"> Total</h6>
                      <div class="stepper-margin item-title">
                        ₹ {grandTotal}
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="page-content inner-page pt-10">
        <div className="section ">
          <div className="row ">
            <div className="col-12 ">
              <div className="stat-box">
                <textarea
                  id="comment"
                  rows="3"
                  cols="15"
                  value={comment}
                  maxLength="500"
                  autocomplete="off"
                  placeholder="Comment"
                  className="form-control"
                  onChange={(e) => {
                    setComment(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ClientMenu