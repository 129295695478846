import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "../../../css/style.css";
import "../../../css/custom.css";
import "../../../css/font-awesome/css/all.min.css";
import "../../../css/line-awesome/css/line-awesome.min.css";
import { showLoaderAction } from "../../../actions/index";
import axios from "axios";
import toast from "react-hot-toast";
import ListPlaceholder from "../../Placeholders/ListPlaceholder.js";
import useRazorPay from "react-razorpay";
import { v4 as uuidv4 } from "uuid";
import { isEmptyObject } from "jquery";
import { activeMenuAction } from "../../../actions/index";
import SpinerLoader from "../../SpinerLoader/spinerLoader.js";

const { REACT_APP_MY_ENV, REACT_APP_RAZOR_PAY_KEY } = process.env;

const WalletDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const showLoaderReducer = useSelector((state) => state.rootReducer.showLoaderReducer);
  const showLoader = showLoaderReducer.show;
  const [Razorpay] = useRazorPay();

  const [addMoney, setAddMoney] = useState("");
  const [walletDetails, setWalletDetails] = useState({});
  const [addMoneyInputShow, setAddMoneyInputShow] = useState(false);
  const [addMoneyError, setAddMoneyError] = useState({});
  const [showRazorPayLoader, setShowRazorPayLoader ] = useState(false);

  useEffect(() => {
    dispatch(activeMenuAction('wallet'));
    const isLogin = localStorage.getItem("isLogin");
    if (isLogin !== "true") {
      navigate("/sign-in");
    } else {
      fetchWalletDetails();
    }
  }, []);

  useEffect(() => {
    document.querySelectorAll("input[dist-name='only-numeric']").forEach(function (input) {
      input.addEventListener("keypress", function (e) {
        var keyCode = e.which || e.keyCode;
        if (keyCode < 48 || keyCode > 57) {
          e.preventDefault();
        }
      });
    });
  });

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const fetchWalletDetails = async () => {
    dispatch(showLoaderAction(true));
    const data = {
      UserId: localStorage.getItem("encryptedUserId"),
    };
    const walletDetails = await axios.post(REACT_APP_MY_ENV + "/get-wallet", data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    if (walletDetails.data.status === 200) {
      setWalletDetails(walletDetails.data.data);
    }else {
      toast.error(walletDetails.data.message);
    }
    dispatch(showLoaderAction(false));
  };

  const createOrder = async () => {
    const orderId = uuidv4();
    const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");
    if (!res) {
      toast.error("Razorpay SDK failed to load, Are you online?");
      return;
    }
    let paymentRes = {
      UserId: parseInt(localStorage.getItem("encryptedUserId")),
      PhoneNumber: localStorage.getItem("phoneNumber"),
      OrderId: orderId,
      Amount: addMoney,
      Currency: "INR",
    };

    const result = await axios.post(REACT_APP_MY_ENV + "/create-payment-order", paymentRes);
    if (result.data.status === 200) return result.data.data;
    else return false;
  };

  const getPaymentDetails = async (paymentId) => {
    const paymentData = { PaymentId: paymentId };
    const paymentDetails = await axios.post(REACT_APP_MY_ENV + "/payment-details", paymentData);
    if (paymentDetails.data.status === 200) return paymentDetails.data.data;
    else toast.error(paymentDetails.data.message);
  };

  const handleAdd = async () => {
    if (AddMoneyFormValidation()) {
      setShowRazorPayLoader(true);
      const orderResult = await createOrder();
      if (orderResult) {
        setShowRazorPayLoader(false);
        const options = {
          key: REACT_APP_RAZOR_PAY_KEY,
          currency: orderResult.currency,
          amount: orderResult.amount,
          order_id: orderResult.payment_order_id,
          name: "Token लेलो",
          description: `Add Money`,
          image: "https://api.tokenlelo.com/logo/TokenLelo-VLogo-Black.png",
          handler: async function (response) {
            if (response && response.razorpay_payment_id) {
              const result = await getPaymentDetails(response.razorpay_payment_id);
              const paymentData = {
                UserId: parseInt(localStorage.getItem("encryptedUserId")),
                PaymentOrderId: orderResult.id,
                TransactionId: response.razorpay_payment_id, // razorpay_payment_id
                AddAmount: result.amount,
                FromRazorPay: true,
                // PaymentResponse: JSON.stringify(result),
                Status: result.status, // captured , authorized , failed
              };
              const paymentResult = await createPaymentTransaction(paymentData);
              if (paymentResult) {
                toast.success("Money added successfully");
                setAddMoney("")
              } else {
                toast.error("Payment unsuccessful");
              }
              fetchWalletDetails();
              setAddMoneyInputShow(false);
              setAddMoneyError({});
            } else {
              toast.error("Something went wrong while fetching payment details, please try again later");
            }
          },
          prefill: {
            contact: localStorage.getItem("phoneNumber"),
            // email: "test321@mailinator.com",
          },
          notes: {
            address: "Razorpay",
          },
          theme: {
            color: "#655fff",
          },
        };
        let paymentObj = new window.Razorpay(options);
        paymentObj.on("payment.failed", async function (response) {
          const paymentFailedData = {
            UserId: parseInt(localStorage.getItem("encryptedUserId")),
            PaymentOrderId: orderResult.id, // TODO
            // TransactionId: orderResult.receipt, //response.razorpay_payment_id, // TODO
            TransactionId: response.error?.metadata?.payment_id || "",
            AddAmount: addMoney,
            FromRazorPay: true,
            // PaymentResponse: response.error,
            Status: "failed", // captured , authorized , failed
          };
          const paymentFailedResult = await createPaymentTransaction(paymentFailedData);
          if (paymentFailedResult?.data?.status === 200) {
            toast.error("Payment failed!");
          } else {
            toast.error("Something went wrong, please try again after sometime");
          }
        });
        paymentObj.open();
      } else {
        toast.error("Something went wrong, please try again later");
      }
    }
  };

  const createPaymentTransaction = async (paymentData) => {
    const paymentResult = await axios.post(REACT_APP_MY_ENV + "/create-wallet-transaction", paymentData);
    if (paymentResult.data.status === 200) return paymentResult.data.data;
    return false;
  };

  const AddMoneyFormValidation = () => {
    let addMoneyError = {};

    let isValid = true;
    if (!addMoney) {
      addMoneyError.addMoneyEmpty = "Please enter amount";
      isValid = false;
    }

    setAddMoneyError(addMoneyError);
    return isValid;
  };
  const handleButtonClick = (amount) => {
    setAddMoney(amount);
  };
  return (
    <>
      {showRazorPayLoader && (<SpinerLoader />)}
      {showLoader ? (
        <ListPlaceholder skeletonName={"Wallet"} noOfItem={1} showFooter={false} />
      ) : (
        <div class="page my-token-page commane-page">
          <div className="containerCard">
            <div class="navbar navbar-style-1 navbar-bg-theme">
              <div class="navbar-inner">
                <Link to={"/sidebar"} class="link back">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.439312 13.0606L5.75391 18.3752C6.04683 18.6682 6.43069 18.8146 6.81459 18.8146C7.1985 18.8146 7.58236 18.6682 7.87528 18.3752C8.46103 17.7894 8.46103 16.8397 7.87528 16.2539L5.12133 13.5H22.5C23.3284 13.5 24 12.8284 24 12C24 11.1716 23.3284 10.5 22.5 10.5H5.12133L7.87528 7.7461C8.46103 7.1603 8.46103 6.21057 7.87528 5.62477C7.28944 5.03898 6.33975 5.03898 5.75391 5.62477L0.439312 10.9394C-0.146437 11.5251 -0.146437 12.4749 0.439312 13.0606Z" fill="white" />
                  </svg>
                </Link>
                <div class="title text-white">Wallet</div>
              </div>
            </div>
            <div class="page-content wallet_screen">
              <div class=" container">
                <div class="">
                  <div className="section mb-10">
                    <div className="row-12 mt-2">
                      <div className="col-6 w-40" style={{ textAlign: "center" }}>
                        <div className="stat-box">
                            <div className="wallet_title">
                              {walletDetails.balance === null || walletDetails.balance <= 10 ? (
                                <div className="wallet-text low-balance">Low Balance</div>
                              ) : (
                                <div className="wallet-text">Balance</div>
                              )}
                            </div>
                            <div className="wallet-amount">
                              <b className={`${walletDetails.balance === null || walletDetails.balance <= 10 ? 'low-balance' : ''}`}>
                                <span className={`${walletDetails.balance === null || walletDetails.balance <= 10 ? 'low-balance' : ''}`}>₹</span>
                                {walletDetails && walletDetails.balance ? walletDetails.balance : <span className="low-balance">0</span>}
                              </b>
                            </div>
                          <div className="">
                              <>
                                <div className="wallerInput">
                                  <div className="label">
                                    Topup Wallet
                                  </div>
                                  <div className="walletInputInner">
                                    <span>₹</span>
                                    <input className="form-control" maxLength="4"  value={addMoney}  dist-name="only-numeric" placeholder="Enter Amount" onChange={(e) => setAddMoney(e.target.value)} />
                                  </div>
                                  {Object.keys(addMoneyError).map((key) => {
                                    return (
                                      <div className="smalltext" style={{ color: "red" }}>
                                        {addMoneyError[key]}
                                      </div>
                                    );
                                  })}
                                </div>
                                
                                <div className="recommentedtext"> Recommended</div>
          
                                  {/* <p class="row">
                                    <button class="col button btn-outline-info  panel-open btn-info-color" onClick={() => setAddMoney("1000")}>₹ 1,000</button>
                                    <button class="col button button-raised button-fill btn-info-color" value="1500">₹ 1,500</button>
                                    <button class="col button button-raised button-fill btn-info-color" value="2000">₹ 2,000</button>
                                  </p> */}
                                   <div className="amountList">
                                    <button className="amount" onClick={() => handleButtonClick('1000')}> <span>₹</span> 1,000</button>
                                   <button className="amount" onClick={() => handleButtonClick('1500')}><span>₹</span> 1,500</button>
                                    <button className="amount" onClick={() => handleButtonClick('2000')}><span>₹</span> 2,000</button>
                                </div>
                              </>
                            {!isEmptyObject(walletDetails) ?
                                <button
                                  type="submit"
                                  disabled={!addMoney || (walletDetails.balance <= 0 || walletDetails.balance == null)}
                                  className={`button-large button button-fill profile-font ${walletDetails.balance === null || walletDetails.balance <= 10 ? 'balance-button ' : ''}`}
                                  onClick={() => {
                                    addMoneyInputShow ? handleAdd() : setAddMoneyInputShow(true);
                                  }}
                                >PROCEED TO TOPUP</button>
                              :
                                <button
                                  type="submit"
                                  className="button-large button button-fill outlet-btn"
                                  disabled={true}
                                >
                               PROCEED TO TOPUP
                              </button>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {walletDetails.balance != null?
                <div className="list">  
                        <Link to="/transaction-history" className="wallet-History">  
                            
                            <div class="title"><i className="fa fa-history"></i>Wallet Transaction History</div> 
                        </Link> 
                  </div> 
                  : ""}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default WalletDetails;
