import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../../App.css";
import "../../../css/framework7-bundle.css";
import "../../../css/app.css";
import "../../../css/font-awesome/css/all.min.css";
import "../../../css/line-awesome/css/line-awesome.min.css";
import "../../../css/style.css";
import "../../../css/perfect-scrollbar.css";
import "../../../img/f7-icon-square.png";
import "../../../img/f7-icon.png";
import "../../../js/main.js";
import axios from "axios";
import { useCatchHandler, showToast } from "../../../utils/utilities"
import { useDispatch, useSelector } from "react-redux";
import { showLoaderAction } from "../../../actions/index";
import SpinerLoader from "../../SpinerLoader/spinerLoader";
import tokenlogo from "../../../img/logo-new.png";
import { sendMail } from "../../../utils/utilities";
const currentURL = window.location.href;
const { REACT_APP_MY_ENV } = process.env;

function SignIn() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const catchHandle = useCatchHandler();
  const showLoaderReducer = useSelector((state) => state.rootReducer.showLoaderReducer)
  const showLoader = showLoaderReducer.show;
  const [userId, setUserId] = useState("");
  const [userIdError, setUserIdError] = useState({});
  let isLogin = localStorage.getItem("isLogin");
  let isClient = localStorage.getItem('isClientLogin');

  const DOUBLE_TAP_DELAY = 300; 
  const [lastTapTime, setLastTapTime] = useState(null);

  const url = window.location.href;
  const urlObject = new URL(url);
  const searchParams = urlObject.searchParams;
  const directUrl = searchParams.get("r");
  

  useEffect(() => {
    if(isClient){
      navigate('/outlet-dashboard');
      localStorage.removeItem("CityName")
      localStorage.removeItem("CityId")
    }else if(isLogin){
      navigate('/dashboard');
    }
    isLoggedIn();
    if ((window.location.pathname === "/sign-in") && isClient) {
      if (isClient) {
        navigate('/outlet-dashboard');
      } else if (isLogin) {
        navigate('/dashboard');
      }
    }
  }, [])

  const isLoggedIn = () => {
    let isLogin = localStorage.getItem("isLogin");
    if (isLogin) {
      navigate('/dashboard');
    }
  }

  const onSubmit = (e) => {
    e.preventDefault();
    const isValid = formValidation();

    if (isValid) {
      var data = JSON.stringify(userId);

      var config = {
        method: "post",
        url: REACT_APP_MY_ENV + "/user-login",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      dispatch(showLoaderAction(true));
      axios(config)
        .then(function (response) {
          if (response.data.status === 200) {
            dispatch(showLoaderAction(false));
            localStorage.setItem(
              "encryptedUserId",
              response.data.data.encryptedUserId
            );
            localStorage.setItem("isLogin", false);
            navigate("/verify-otp", { state:{ directUrl:directUrl, phoneNumber:userId } });
          } else {
            dispatch(showLoaderAction(false));
            showToast(response.data.message, "error");
          }
        })
        .catch(function (error) {
          dispatch(showLoaderAction(false));
          catchHandle(error, "true", currentURL)
          const data = {
            Subject: "Front end error from user-login",
            BodyText: error.message
          }
          sendMail(data);
          showToast("Something went wrong, please contact Administrator!", "error")
        });
    }
  };

  const formValidation = () => {
    let userIdError = {};
    let isValid = true;

    if (!userId) {
      userIdError.userIdEmpty =
        "Please enter the email address or phone number";
      isValid = false;
    } else if (
      isNaN(userId) &&
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(userId)
    ) {
      userIdError.emailInvalid = "Invalid email address";
      isValid = false;
    } else if (!isNaN(userId) && !/^(\+\d{1,3}[- ]?)?\d{10}$/.test(userId)) {
      userIdError.phoneInvalid = "Invalid phone number";
      isValid = false;
    }

    setUserIdError(userIdError);
    return isValid;
  };
  
  const handleDoubleClick = (e) => {
    e.preventDefault();
    const currentTime = new Date().getTime();
    const tapDuration = currentTime - (lastTapTime || 0);
    if (tapDuration < DOUBLE_TAP_DELAY) {
      navigate("/outlet-sign-in");
    } else {
      setLastTapTime(currentTime);
    }
  };

  return (
    <>
      {showLoader && (<SpinerLoader />)}
      <div className="page account-area">
        <div className="page-content">
          <div className="logo-space"><div className="logo-request-singpage">
               <img src={tokenlogo} onError={(error) => { error.target.src = tokenlogo }} /></div></div>
          <div className="fixed-content container">
            <div className="tabs">
              <div className="tab tab-active form-elements tabs">
                <form onSubmit={onSubmit} className="tab tab-active" id="tabA1">
                  <div className="title-head">
                    <h2 className="title"> <Link to="" onClick={(e) => handleDoubleClick(e)}>Sign In</Link></h2>
                  </div>
                  <div className="list">
                    <ul className="row">
                      <li className="item-content item-input col-100 mt-1">
                        <div className="item-inner">
                          <div className="item-title item-label profile-font">
                            Email/Phone
                          </div>
                          <div className="item-input-wrap profile-font">
                            <input
                              type="text"
                              placeholder="Enter email or phone"
                              autocomplete="off"
                              value={userId}
                              onChange={(e) => {
                                setUserId(e.target.value);
                              }}
                              className="form-control"
                            />
                            {userIdError &&
                              Object.keys(userIdError).map((key) => {
                                return (
                                  <div style={{ color: "red" }}>
                                    {userIdError[key]}
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="clearfix profile-font">
                    <button
                      type="submit"
                      className="button-large button button-fill"
                    >
                      LOGIN
                    </button>
                  </div>
                  <div className="text-align-center account-footer profile-font">
                    <p className="mt-0">Dont have any account?</p>
                    <Link
                      to="/sign-up/"
                      className="button-large button button-fill button-gray"
                    >
                      CREATE AN ACCOUNT
                    </Link>
                  </div>
                  <div className="mt-10 text-align-center profile-font">
                    <Link
                      to="/dashboard"
                      className="button-large button button-fill button-gray"
                    >
                      Skip
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignIn;