import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { showLoaderAction } from "../../../actions/index";
import moment from "moment/moment";
import defaultOutlet from "../../../img/outlet-default.png";
import { useCatchHandler, showToast } from "../../../utils/utilities"
import { activeMenuAction } from "../../../actions/index";
import { sendMail } from "../../../utils/utilities";
import DetailsPlaceholder from "../../Placeholders/DetailsPlaceholder";

const { REACT_APP_MY_ENV } = process.env;

const ClientViewProfile = () => {
  const dispatch = useDispatch();
  const showLoaderReducer = useSelector((state) => state.rootReducer.showLoaderReducer)
  const showLoader = showLoaderReducer.show;
  const [clientDetail, setClientDetail] = useState("");
  let token = localStorage.getItem("clientToken");
  const catchHandle = useCatchHandler();
  const currentURL = window.location.href;

  useEffect(() => {
    dispatch(activeMenuAction('outlet-view-profile'));
    getClientDetails();
  }, []);

  const getClientDetails = async () => {
    const data = {
      encryptedClientId: localStorage.getItem("encryptedClientId"),
    };
    dispatch(showLoaderAction(true));
    const res = await axios.post(REACT_APP_MY_ENV + "/get-client-user", data, { headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" } }).then(function (res) {
      if (res.data.status === 200) {
        dispatch(showLoaderAction(false));
        setClientDetail(res.data.data.user);
      } else {
        dispatch(showLoaderAction(false));
        showToast(res.data.message, "error");
      }
    }).catch(function (error) {
      catchHandle(error, "false", currentURL)
      dispatch(showLoaderAction(false));
      const data = {
        Subject: "Front end error from get-client-user",
        BodyText: error.message
      }
      sendMail(data);
      showToast("Something went wrong, please contact Administrator!", "error")
    });
  };


  return (
    <>
    {showLoader ? (<DetailsPlaceholder isProfile={true} skeletonName="Profile"/>)
      :
      <div class="page profile-wraper">
      <div className='containerCard '>
        <div class="navbar navbar-style-1 navbar-transparent">
          <div class="navbar-inner navbar-profile">
            <Link to={-1} class="link back">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.439312 13.0606L5.75391 18.3752C6.04683 18.6682 6.43069 18.8146 6.81459 18.8146C7.1985 18.8146 7.58236 18.6682 7.87528 18.3752C8.46103 17.7894 8.46103 16.8397 7.87528 16.2539L5.12133 13.5H22.5C23.3284 13.5 24 12.8284 24 12C24 11.1716 23.3284 10.5 22.5 10.5H5.12133L7.87528 7.7461C8.46103 7.1603 8.46103 6.21057 7.87528 5.62477C7.28944 5.03898 6.33975 5.03898 5.75391 5.62477L0.439312 10.9394C-0.146437 11.5251 -0.146437 12.4749 0.439312 13.0606Z" fill="black" />
              </svg>
            </Link>
            <div class="title profile-font">Profile</div>
            <Link to="/outlet-sidebar" class="link panel-open" data-panel="left">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14 12C14 10.8955 13.1046 10 12 10C10.8954 10 10 10.8955 10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12Z" fill="black" />
                <path d="M14 20C14 18.8954 13.1046 18 12 18C10.8954 18 10 18.8954 10 20C10 21.1045 10.8954 22 12 22C13.1046 22 14 21.1045 14 20Z" fill="black" />
                <path d="M14 4C14 2.89544 13.1046 2.00001 12 2.00001C10.8954 2.00001 10 2.89544 10 4C10 5.10457 10.8954 6 12 6C13.1046 6 14 5.10457 14 4Z" fill="black" />
              </svg>
            </Link>
          </div>
        </div>

        <div class="page-content page-baground-color content-area pt-0">
          <div class="profile-info bg-primary">
            <div class="container">
              <div class="author">
                <img src={clientDetail.logoFilePathURL} alt={clientDetail.name}
                  onError={(error) => { error.target.src = defaultOutlet }} />
                <div class="badge-lg">
                  {clientDetail.ratings ? clientDetail.ratings.toFixed(1) : <span className="text-12" >No Reviews</span>}
                  <svg height="17" width="24" viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg" fill="#30304d" className="star-mb">
                    <path d="m21.645 8.769-5.817-.85-2.928-5.866a1.042 1.042 0 0 0 -1.79 0l-2.938 5.866-5.817.85a1 1 0 0 0 -.492 1.76l4.805 3.963-1.515 6.79a1 1 0 0 0 1.56 1.03l5.287-3.803 5.287 3.8a1 1 0 0 0 1.56-1.03l-1.515-6.79 4.805-3.963a1 1 0 0 0 -.492-1.76zm-6.059 4.571a1 1 0 0 0 -.339.989l1.076 4.826-3.739-2.689a1 1 0 0 0 -1.168 0l-3.739 2.689 1.076-4.826a1 1 0 0 0 -.339-.989l-3.538-2.918 4.1-.6a1 1 0 0 0 .75-.542l2.274-4.545 2.272 4.545a1 1 0 0 0 .75.542l4.1.6z" fill="#30304d" stroke="" stroke-width="1" />
                  </svg>
                </div>
              </div>
              <div class="profile-content">
                <h2 class="name">{clientDetail.name}</h2>
                <div class="info-container">
                  <div className="left-content">
                    <span class="time-text">
                      <i class="las la-clock mr-clock margin-left-1"></i>
                      {clientDetail.openTime ? moment(clientDetail.openTime, "hh:mm:ss").format("hh:mm A") : ""}
                      {clientDetail.closeTime ? " - " : ""}
                      {clientDetail.closeTime ? moment(clientDetail.closeTime, "hh:mm:ss").format("hh:mm A") : ""}
                    </span>
                    <span className="margin-left-1">{clientDetail.outletStatus === 1 ?
                      <i class="text-success fa fa-circle small-icon-outlet"></i> :
                      <i class="text-danger-soft fa fa-circle small-icon "></i>
                    }</span>
                  </div>
                  <div className="right-content">
                  </div>
                </div>
                <p>
                  <span>{clientDetail.address === "undefined" ? "" : clientDetail.address  }</span>
                </p>
              </div>
              <div class="content-info tabbar">
                <Link to="/outlet-update-profile" class="tab-link">
                  <svg enableBackground="new 0 0 461.75 461.75" height="24" viewBox="0 0 461.75 461.75" width="24" xmlns="http://www.w3.org/2000/svg" fill={"#30304d"}>
                    <g>
                      <path d="m23.099 461.612c2.479-.004 4.941-.401 7.296-1.177l113.358-37.771c3.391-1.146 6.472-3.058 9.004-5.587l226.67-226.693 75.564-75.541c9.013-9.016 9.013-23.63 0-32.645l-75.565-75.565c-9.159-8.661-23.487-8.661-32.645 0l-75.541 75.565-226.693 226.67c-2.527 2.53-4.432 5.612-5.564 9.004l-37.794 113.358c-4.029 12.097 2.511 25.171 14.609 29.2 2.354.784 4.82 1.183 7.301 1.182zm340.005-406.011 42.919 42.919-42.919 42.896-42.896-42.896zm-282.056 282.056 206.515-206.492 42.896 42.896-206.492 206.515-64.367 21.448z" fill={"#30304d"} />
                    </g>
                  </svg>
                </Link>
                {clientDetail.address === "undefined" ? "" : clientDetail.address === null ? "" : clientDetail.address &&
                  <a target="_blank" href={`https://www.google.com/maps?q=${clientDetail.address}`} class="tab-link">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.9993 5.48404C9.59314 5.48404 7.64258 7.4346 7.64258 9.84075C7.64258 12.2469 9.59314 14.1975 11.9993 14.1975C14.4054 14.1975 16.356 12.2469 16.356 9.84075C16.356 7.4346 14.4054 5.48404 11.9993 5.48404ZM11.9993 12.0191C10.7962 12.0191 9.82096 11.0438 9.82096 9.84075C9.82096 8.6377 10.7962 7.66242 11.9993 7.66242C13.2023 7.66242 14.1776 8.6377 14.1776 9.84075C14.1776 11.0438 13.2023 12.0191 11.9993 12.0191Z" fill="#30304d" />
                      <path d="M21.793 9.81896C21.8074 4.41054 17.4348 0.0144869 12.0264 5.09008e-05C6.61797 -0.0143851 2.22191 4.35827 2.20748 9.76664C2.16044 15.938 5.85106 21.5248 11.546 23.903C11.6884 23.9674 11.8429 24.0005 11.9991 24C12.1565 24.0002 12.3121 23.9668 12.4555 23.9019C18.1324 21.5313 21.8191 15.9709 21.793 9.81896ZM11.9992 21.7127C7.30495 19.646 4.30485 14.9691 4.38364 9.84071C4.38364 5.63477 7.79323 2.22518 11.9992 2.22518C16.2051 2.22518 19.6147 5.63477 19.6147 9.84071V9.91152C19.6686 15.0154 16.672 19.6591 11.9992 21.7127Z" fill="#30304d" />
                    </svg>
                  </a>
                }
                <Link to="/reviews" id="addFeedBack" className="tab-link">
                  <svg height="27" width="27" viewBox="0 0 23 23" xmlns="http://www.w3.org/2000/svg" fill={"#30304d"}>
                    <path d={
                      "m21.645 8.769-5.817-.85-2.928-5.866a1.042 1.042 0 0 0 -1.79 0l-2.938 5.866-5.817.85a1 1 0 0 0 -.492 1.76l4.805 3.963-1.515 6.79a1 1 0 0 0 1.56 1.03l5.287-3.803 5.287 3.8a1 1 0 0 0 1.56-1.03l-1.515-6.79 4.805-3.963a1 1 0 0 0 -.492-1.76zm-6.059 4.571a1 1 0 0 0 -.339.989l1.076 4.826-3.739-2.689a1 1 0 0 0 -1.168 0l-3.739 2.689 1.076-4.826a1 1 0 0 0 -.339-.989l-3.538-2.918 4.1-.6a1 1 0 0 0 .75-.542l2.274-4.545 2.272 4.545a1 1 0 0 0 .75.542l4.1.6z"} fill={"#30304d"} />
                  </svg>
                </Link>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
      }
    </>
  );
};

export default ClientViewProfile;
