import toast from 'react-hot-toast';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { showLoaderAction } from "../../src/actions/index";
import axios from 'axios';
import * as signalR from "@microsoft/signalr";
import { NotificationCountAction } from "../actions/index"
import toastNotificationTone from "../sound/toast-notification-tone.mp3"
import { Howl } from 'howler';

const { REACT_APP_MY_ENV, REACT_APP_NOTIFICATION_HUB_URL, REACT_APP_WEBAPP_URL, REACT_APP_TL_APP_VERSION } = process.env;

export const getGreetings = () => {
  let welcomeText = "";
  const hour = new Date().getHours();
  if (hour >= 4 && hour < 12) welcomeText = "Good Morning";
  else if (hour >= 12 && hour < 18) welcomeText = "Good Afternoon";
  else welcomeText = "Good Evening";
  return welcomeText;
};

export const Logout = async () => {
  const requestData = {
    ClientId: localStorage.getItem("encryptedClientId")
  }
  let response = await axios.post(REACT_APP_MY_ENV + "/client-logout", requestData);
  if (response.data.status === 200) {
    localStorage.clear();
    setTimeout(() => {
    }, 10);
  }else{
    // showToast(response.data.message, "error");
  }
}

export function useCatchHandler() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const catchHandle = (error, user, currentURL) => {
    let isOutLet = user === "true" ? false : true;

    const value = currentURL.replace(REACT_APP_WEBAPP_URL, '');
    const result = value.replace('/', '');

    if (error.response && error.response.status === 401) {
      if(isOutLet){
        Logout()
        localStorage.clear();
        toast.error('You have been logged out, please re-login again!');
        dispatch(showLoaderAction(false));
        navigate(`/outlet-sign-in?r=${result}`);
      }else{
      toast.error('You have been logged out, please re-login again!');
      localStorage.clear();
      dispatch(showLoaderAction(false));
      navigate(`/sign-in?r=${result}`);
      }
    } else {
      toast.error(error);
      dispatch(showLoaderAction(false));
    }
  };

  return catchHandle;
};

export const useNotification = () => {
  const dispatch = useDispatch();
  return async (data) => {
    let token = localStorage.getItem("clientToken") || localStorage.getItem("token");
    const dataObj = {
      ClientId: data.ClientId,
      UserId: data.UserId,
      SentBy: data.SentBy,
      Title: data.Title,
      Description: data.Description,
      RedirectURL: data.RedirectURL,
      Data: data.Data,
    };

    const res = await axios.post(REACT_APP_MY_ENV + "/send-notification", dataObj, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }).then(function (res) {
      if (res.data.status !== 200) {
        toast.error(res.data.message);
      }
    }).catch(function (error) {
      dispatch(showLoaderAction(false));
      toast.error("Something went wrong, please contact Administrator!")
      const data = {
        Subject: "Front end error from send-notification",
        BodyText: error.message
      }
      sendMail(data);
    });
  }
}

const notificationSound = new Howl({
  src: [toastNotificationTone],
})

export const establishNotificationHubConnection = async (queryParameter, id, dispatch) => {
  const connection = new signalR.HubConnectionBuilder()
    .withUrl(REACT_APP_NOTIFICATION_HUB_URL + queryParameter)
    .configureLogging(signalR.LogLevel.Information)
    .build();

  try {
    await connection.start();
    if (connection.state === signalR.HubConnectionState.Connected) {
      connection.invoke("SendNotification", id, "", "", 0);
    } else {
      console.error("SignalR connection is not in the 'Connected' state.");
    }
  } catch (error) {
    console.error("SignalR connection error: ", error);
  }

  connection.on("ReceiveNotification", (clientId, title, description, notificationCount) => {
    if (notificationCount && notificationCount > 0) {
      dispatch(NotificationCountAction(notificationCount));
    }
    if (title && title + " " + description != localStorage.getItem("Description")) {
      localStorage.setItem("Description", title + " " + description)
      navigator.vibrate([100]);
      toast.custom((t) => (
        <div
          className="toast-row w-full"
        >
          <div className="ml-3 flex-1">
            <p className="text-sm font-medium text-gray-900">
              {title}
            </p>
            <p className="mt-1 text-sm text-gray-500">
              {description}
            </p>
          </div>
          <div className="flex border-l border-gray-200 pos-right">
            <button
              onClick={() => toast.dismiss(t.id)}
              className="custom-button w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            >
              Close
            </button>
          </div>
        </div>
      ), { duration: 3000 })
      notificationSound.play();
    }    
    if(notificationCount > 0){
    showNotification(title, description);
    }
  });
};

const showNotification = (title, description) => {
  if (Notification.permission === 'granted') {
    var options = {
      body: description,
      dir: 'ltr',
    };
    new Notification(title, options);
  } else {
    console.log('Notification permission not granted');
  }
};

export const sendMail = async (data, method = "post") => {
  const url = REACT_APP_MY_ENV + "/send-email";

  const response = await axios({
    method,
    url,
    data
  });
}

export function showToast(message, type) {
  if (type == "success") {
    toast.success(message, {
      autoClose: false,
      duration: 3000,
    });
  }
  else {
    toast.error(message, {
      autoClose: false,
      duration: 3000,
    });
  }
}

export function checkUpdate() {
  let version = localStorage.getItem('version');
  if (version !== REACT_APP_TL_APP_VERSION) {
    if ('caches' in window) {
      caches.keys().then(names => {
        names.forEach(name => {
          caches.delete(name);
        });
      });
    }

    localStorage.setItem('version', REACT_APP_TL_APP_VERSION);

    window.location.reload(true);
  }
}



