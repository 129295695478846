import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from 'react-router-dom';
import "../../../css/style.css";
import "../../../css/custom.css";
import "../../../css/font-awesome/css/all.min.css";
import "../../../css/line-awesome/css/line-awesome.min.css";
import axios from "axios";
import moment from "moment/moment";
import TimeAgo from "javascript-time-ago";
import { uniqBy } from "lodash";
import ReactTimeAgo from "react-time-ago";
import en from "javascript-time-ago/locale/en.json";
import { useDispatch, useSelector } from "react-redux";
import { showLoaderAction } from "../../../actions/index";
import SpinerLoader from "../../SpinerLoader/spinerLoader";
import { useCatchHandler, showToast } from "../../../utils/utilities"

const { REACT_APP_MY_ENV } = process.env;

const CustomersDetail = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [userId] = useState(location.state?.userId);
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const showLoaderReducer = useSelector((state) => state.rootReducer.showLoaderReducer)
  const showLoader = showLoaderReducer.show;
  let token = localStorage.getItem("clientToken");
  const [scrollPosition, setScrollPosition] = useState(0);
  const scrollRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [showLoading, setShowLoading] = useState(true)

  const [firstTokenDateTime, setFirstTokenDateTime] = useState("");
  const [tokenUsersHistory, setTokenUsersHistory] = useState([]);
  const [dailyTokensCount, setDailyTokensCount] = useState(0);
  const [userDetail, setUserDetail] = useState("");
  const [completedToken, setCompletedToken] = useState("");

  const catchHandle = useCatchHandler();
  const currentURL = window.location.href;

  useEffect(() => {
    const currentURL = window.location.href;
    const urlParts = currentURL.split('/');
    const userDetailId = urlParts[urlParts.length - 1];
    GetUserDetail(userDetailId)
    getTokenUsersList(userDetailId)

  }, [])

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollPosition;
    }
  }, [tokenUsersHistory]);

  const GetUserDetail = (userDetailId) => {
    const requestData = {
      isAdmin: false,
      encryptedClientId: localStorage.getItem("encryptedClientId"),
      encryptedUserId: userId ? userId : userDetailId
    }
    dispatch(showLoaderAction(true));
    axios.post(REACT_APP_MY_ENV + "/get-user-detail/", requestData).then(function (response) {
      if (response.data.status === 200) {
        dispatch(showLoaderAction(false));
        setUserDetail(response.data.data.user)
        setCompletedToken(response.data.data.completedToken)
        setFirstTokenDateTime(response.data.data.firstToken)
      }
    }).catch(function (error) {
      catchHandle(error, "true", currentURL)
      showToast("Something went wrong, please contact Administrator!", "error")
      dispatch(showLoaderAction(false));
      const data = {
        Subject: "Front end error customer details",
        BodyText: error.message
      }
    });
  };

  const getTokenUsersList = (userDetailId, pageNumber) => {
    setLoading(true)
    const requestData = {
      ClientId: localStorage.getItem("encryptedClientId"),
      UserId: userDetailId,
      pageNumber: pageNumber ? pageNumber : currentPage,
      pageSize: itemsPerPage,
    }
    if (showLoading) {
      dispatch(showLoaderAction(true));
    }
    axios.post(REACT_APP_MY_ENV + "/user-token-list", requestData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }).then(function (response) {
      if (response.data.status === 200) {
        dispatch(showLoaderAction(false));
        setDailyTokensCount(response.data.data.totalToken)
        if (response.data.data.userTokenList.length > 0) {

          // setTokenUsersHistory(uniqBy([...tokenUsersHistory, ...response.data.data.userTokenList], 'tokenNumber'));
          setCurrentPage(currentPage => currentPage + 1);
          setLoading(false)
          // setShowLoading(false);
         setTokenUsersHistory(response.data.data.userTokenList)
        }
      }
      else {
        dispatch(showLoaderAction(false));
        setTokenUsersHistory([]);
        setTimeout(function () {
        }, 200);
      }
    }).catch(function (error) {
      catchHandle(error, "false", currentURL)
      dispatch(showLoaderAction(false));
      const data = {
        Subject: "Front end error from get-token-users",
        BodyText: error.message
      }
    });
  }

  const handleScroll = () => {
    const container = document.getElementById('scroll');
    if (container.scrollTop + container.clientHeight >= container.scrollHeight - 20 && !loading) {
      if (tokenUsersHistory.length < dailyTokensCount) {
        setScrollPosition(container.scrollTop);
        getTokenUsersList(); 
      }
    }
  };
  return (
    <>
      {showLoader && (<SpinerLoader />)}
      <div className="page page-home dashboard-page client-dashboard">
        <div className='containerCard'>
          <div class="navbar navbar-style-1">
            <div class="navbar-inner navbar-bg-theme ">
              <Link to={-1} class="link back">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.439312 13.0606L5.75391 18.3752C6.04683 18.6682 6.43069 18.8146 6.81459 18.8146C7.1985 18.8146 7.58236 18.6682 7.87528 18.3752C8.46103 17.7894 8.46103 16.8397 7.87528 16.2539L5.12133 13.5H22.5C23.3284 13.5 24 12.8284 24 12C24 11.1716 23.3284 10.5 22.5 10.5H5.12133L7.87528 7.7461C8.46103 7.1603 8.46103 6.21057 7.87528 5.62477C7.28944 5.03898 6.33975 5.03898 5.75391 5.62477L0.439312 10.9394C-0.146437 11.5251 -0.146437 12.4749 0.439312 13.0606Z"
                    fill="white"
                  />
                </svg>
              </Link>
              <div class="title text-white">Customer Details</div>
            </div>
          </div>
          <div className="page-content inner-page pt-10">
            <div class="container">
        
                <div className="section mb-10">
                  <div className="row mt-2">
                    <div className="col-6 w-49">
                      <div className="stat-box">
                        <div className="title customer-text"><strong>Name</strong></div>
                        <div className="title customer-value-text">
                          {userDetail.name ? userDetail.name : "No Name"}
                        </div>
                      </div>
                    </div>
                    <div className="col-6 w-49">
                      <div className="stat-box">
                        <div className="title customer-text"><strong>Phone</strong></div>
                        <div className="title customer-value-text">
                          {userDetail.phoneNumber}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" mb-10">
                  <div className="row mt-2">
                    <div className="col-12 ">
                      <div className="stat-box">
                        <div className="title customer-text .customer-value-text"><strong>Email</strong></div>
                        <div className="title customer-value-text">
                          {userDetail.emailAddress ? userDetail.emailAddress : "-"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="section mb-10">
                  <div className="row mt-2">
                    <div className="col-6 w-49">
                      <div className="stat-box">
                        <div className="title customer-text"><strong>No. of Visits</strong></div>
                        <div className="title customer-value-text">
                          {completedToken.completedToken ? completedToken.completedToken : "0"}
                        </div>
                      </div>
                    </div>
                    <div className="col-6 w-49">
                      <div className="stat-box">
                        <div className="title customer-text"><strong>Since Date</strong></div>
                        <div className="title customer-value-text">
                          {firstTokenDateTime.firstTokenDateTime ? moment(firstTokenDateTime.firstTokenDateTime).format("DD-MM-YY") : "-"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              <div className="section wallet-card-section pt-1 mb-1">
                <div className="wallet-card wallet-card-padding">
                  <div class="navbar navbar-style-1 border-bottom-color mt-5">
                    <div class="navbar-inner ">
                      <div class="title" style={{ width: "100%" }}>Past Tokens</div>
                    </div>
                  </div>
                  <div className='containerCard stat-box stat-box-pad state-box-border'>
                    <div class="page-content pt-0 " 
                    >
                      <div  id="scroll" onScroll={() => handleScroll()} ref={scrollRef} class="list media-list search-list searchbar-found search-list-bar daily-token-mb-1 daily-token-m-0 user-list">
                        <ul>
                          {tokenUsersHistory && tokenUsersHistory.length > 0 ?
                            tokenUsersHistory && tokenUsersHistory.map((user, index) => {
                              const createdAt = new Date(user.date);
                              const today = new Date();
                              let tokenDate;

                              if (createdAt.toDateString() === today.toDateString()) {
                                tokenDate = <ReactTimeAgo date={createdAt} locale="en-US" />;
                              } else {
                                const formattedDate = createdAt.toLocaleString('en-US', {
                                  year: 'numeric',
                                  month: '2-digit',
                                  day: '2-digit',
                                  hour: '2-digit',
                                  minute: '2-digit',
                                  hour12: true,
                                });
                                tokenDate = formattedDate;
                              }
                              return (
                                <li key={index}>
                                  <div class="item-link item-content daily-token-mt-0">
                                    <div class="item-inner">
                                      <div class="item-title-row">
                                        <h6 class="item-title"> Token No.{user.tokenNumber}</h6>
                                        <span class="badge-oven">
                                          {user.tokenStatus === "InQueue" ? <span class="bg-blue-ongoing">Running</span> : user.tokenStatus === "Completed" ? <span class="bg-green-completed">Completed</span> : user.tokenStatus === "Removed" ? <span class="bg-org-removed">No Show</span> : user.tokenStatus === "Cancelled" ? <span class="bg-red-cancelled">Cancelled</span> : null}
                                        </span>
                                      </div>
                                      <div className=' '>
                                        {tokenDate}
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              )
                            })
                            :
                            <span id="no-users">Loading...</span>
                          }
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>  
             </div>
        </div>
      </div>
    </>
  );
}

export default CustomersDetail;
