import React, { useState } from "react";
import { Link, useNavigate} from 'react-router-dom';
import '../../../css/framework7-bundle.css'
import '../../../css/app.css'
import '../../../css/font-awesome/css/all.min.css'
import '../../../css/line-awesome/css/line-awesome.min.css'
import '../../../css/style.css'
import '../../../css/perfect-scrollbar.css'
import '../../../img/f7-icon-square.png'
import '../../../img/f7-icon.png'
import axios from "axios";
import { useCatchHandler, showToast } from "../../../utils/utilities"
import { useDispatch, useSelector } from "react-redux";
import { showLoaderAction } from "../../../actions/index";
import SpinerLoader from "../../SpinerLoader/spinerLoader";
import tokenlogo from "../../../img/logo-new.png";
const { REACT_APP_MY_ENV } = process.env;

function SignUp() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const catchHandle = useCatchHandler();
  const showLoaderReducer = useSelector((state) => state.rootReducer.showLoaderReducer)
  const showLoader = showLoaderReducer.show;
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailAddress, setEmailAddress] = useState("");

  const [nameError, setNameError] = useState({});
  const [phoneNumberError, setPhoneNumberError] = useState({});
  const [emailAddressError, setEmailAddressError] = useState({});

  const formValidation = () => {
    let nameError = {};
    let phoneNumberError = {};
    let emailAddressError = {};

    let isValid = true;

    if (!name.trim()) {
      nameError.nameEmpty = "Please enter the name";
      isValid = false;
    }

    if (!phoneNumber) {
      phoneNumberError.phoneNumberEmpty = "Please enter the phone number";
      isValid = false;
    } else if (!/^(\+\d{1,3}[- ]?)?\d{10}$/.test(phoneNumber)) {
      phoneNumberError.phoneInvalid =
        "Invalid phone number, phone number should be of 10 digits";
      isValid = false;
    }

    if (emailAddress) {
      if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailAddress)) {
        emailAddressError.emailInvalid = "Invalid email address";
        isValid = false;
      }
    }

    setNameError(nameError);
    setPhoneNumberError(phoneNumberError);
    setEmailAddressError(emailAddressError);
    return isValid;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const isValid = formValidation();
    if (isValid) {
      var data = JSON.stringify({ name, phoneNumber, emailAddress });

      var config = {
        method: "post",
        url: REACT_APP_MY_ENV+"/register-user",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      dispatch(showLoaderAction(true));
      axios(config)
        .then(function (response) {
          if (response.data.status === 200) {
            localStorage.setItem(
              "encryptedUserId",
              response.data.data
            );
            dispatch(showLoaderAction(false));
            navigate("/verify-otp" , { state: { isRegistration: true } } );
          }else {
            dispatch(showLoaderAction(false));
            showToast(response.data.message, "error");
          }
        })
        .catch(function (error) {
          dispatch(showLoaderAction(false));
		      showToast("Something went wrong, please contact Administrator!", "error")
        });
    }
  };

  return (
    <>
    {showLoader && (<SpinerLoader />)}
      <div class="page account-area">
        <div class="page-content">
          <div class="logo-space">  <div className="logo-request-singpage">
               <img src={tokenlogo} onError={(error) => { error.target.src = tokenlogo }} /></div></div>
          <div class="fixed-content container pt-5">
            <div class="form-elements">
              <form onSubmit={onSubmit}>
                <div class="title-head d-flex-arrow">
                  <div>
                    <Link to={-1} class="paddingBottom mt-1">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.439312 13.0606L5.75391 18.3752C6.04683 18.6682 6.43069 18.8146 6.81459 18.8146C7.1985 18.8146 7.58236 18.6682 7.87528 18.3752C8.46103 17.7894 8.46103 16.8397 7.87528 16.2539L5.12133 13.5H22.5C23.3284 13.5 24 12.8284 24 12C24 11.1716 23.3284 10.5 22.5 10.5H5.12133L7.87528 7.7461C8.46103 7.1603 8.46103 6.21057 7.87528 5.62477C7.28944 5.03898 6.33975 5.03898 5.75391 5.62477L0.439312 10.9394C-0.146437 11.5251 -0.146437 12.4749 0.439312 13.0606Z" fill="black" />
                      </svg>
                    </Link>
                  </div>
                  <div>
                  <h2 class="title text-left">Sign Up</h2>
                  </div>
                </div>
                <p class="profile-font p-0">Don't have accout! Get registered</p>
                <div class="list">
                  <ul class="row">
                    <li class="item-content col-100 item-input item-input-with-value">
                      <div class="item-inner">
                        <div class="item-title item-label profile-font">Name<span className="danger">*</span></div>
                        <div class="item-input-wrap profile-font">
                          <input
                            type="text"
                            placeholder="Name"
                            value={name}
                            autocomplete="off"
                            onChange={(e) => {
                              setName(e.target.value);
                            }}
                            class="form-control"
                          />
                          <span id="errorMessage"></span>
                          {Object.keys(nameError).map((key) => {
                            return (
                              <div style={{ color: "red" }}>
                                {nameError[key]}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </li>
                    <li class="item-content col-100 item-input">
                      <div class="item-inner">
                        <div class="item-title item-label profile-font">Phone Number<span className="danger">*</span></div>
                        <div class="item-input-wrap profile-font">
                          <input
                            type="text"
                            placeholder="Phone Number"
                            autocomplete="off"
                            maxLength={10}
                            value={phoneNumber}
                            onChange={(e) => {
                              setPhoneNumber(e.target.value);
                            }}
                            class="form-control"
                          />
                          {Object.keys(phoneNumberError).map((key) => {
                            return (
                              <div style={{ color: "red" }}>
                                {phoneNumberError[key]}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </li>
                    <li class="item-content col-100 item-input">
                      <div class="item-inner">
                        <div class="item-title item-label profile-font">Email</div>
                        <div class="item-input-wrap profile-font">
                          <input
                            type="text"
                            placeholder="Email Address"
                            id="dz-password"
                            autocomplete="off"
                            value={emailAddress}
                            onChange={(e) => {
                              setEmailAddress(e.target.value);
                            }}
                            class="form-control"
                          />
                          {Object.keys(emailAddressError).map((key) => {
                            return (
                              <div style={{ color: "red" }}>
                                {emailAddressError[key]}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="clearfix profile-font">
                  <button
                    type="submit"
                    className="button-large button button-fill"
                  >
                    REGISTER
                  </button>
                </div>
                <div class="text-align-center account-footer profile-font">
                  <p class="mt-0">Already have an account?</p>
                  <Link
                    to="/sign-in"
                    class="button-large button button-fill button-gray profile-font"
                  >
                    SIGN IN
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignUp;
