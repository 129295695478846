import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import defaultOutlet from '../../../img/outlet-default.png';
import { showLoaderAction } from "../../../actions/index";
import SpinerLoader from "../../SpinerLoader/spinerLoader";
import { useCatchHandler, showToast } from "../../../utils/utilities";
import { sendMail } from "../../../utils/utilities";
const { REACT_APP_MY_ENV } = process.env;

const ClientUpdateProfile = () => {
  const dispatch = useDispatch();
  const showLoaderReducer = useSelector((state) => state.rootReducer.showLoaderReducer)
  const showLoader = showLoaderReducer.show;
  const [img, setImg] = useState("");
  const [file, setFile] = useState(undefined);
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [emailUpdated, setEmailUpdated] = useState("");
  const [openTime, setOpenTime] = useState("");
  const [closeTime, setCloseTime] = useState("");
  const [address, setAddress] = useState("");
  const [approxServeMins, setApproxMinServeMins] = useState("");
  const [logoFilePath, setLogoFilePath] = useState("");
  const validLogoFileExtensions = ['jpg', 'jpeg', 'png'];

  const [nameError, setNameError] = useState({});
  const [phoneNumberError, setPhoneNumberError] = useState({});
  const [emailError, setEmailError] = useState({});
  const [logoFileImgError, setLogoFileImgError] = useState({});
  const [approxMinError, setApproxMinError] = useState({});

  let token = localStorage.getItem("clientToken");
  const navigate = useNavigate();
  const catchHandle = useCatchHandler();

  const currentURL = window.location.href;

  useEffect(() => {
    getClientDetails();
  }, []);

  const uploadHandler = async (e) => {
    setImg(URL.createObjectURL(e.target.files[0]));
    setFile(e.target.files[0]);
  };

  const formValidation = () => {
    let outletError = {};
    let phoneNumberError = {};
    let emailAddressError = {};
    let logoFileImgError = {};
    let approxMinError = {};
    let isValid = true;
    const maxFileSizeInBytes = 2 * 1024 * 1024;

    if (!name.trim()) {
      outletError.outletEmpty = "Please enter outlet name";
      isValid = false;
    }
    if (!approxServeMins) {
      approxMinError.approxMinEmpty = "Please enter Approx Serve Minutes";
      isValid = false;
    }else if (parseInt(approxServeMins) <= 0) {
      approxMinError.approxMinEmpty = "Approx Serve Minutes should be greater than zero(0)";
      isValid = false;
    }

    if (!phoneNumber) {
      phoneNumberError.phoneNumberEmpty = "Please enter phone number";
      isValid = false;
    } else if (!/^(\+\d{1,3}[- ]?)?\d{10}$/.test(phoneNumber)) {
      phoneNumberError.phoneInvalid = "Please enter valid phone number";
      isValid = false;
    }

    if (emailUpdated) {
      if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailUpdated)) {
        emailAddressError.emailInvalid = "Please enter valid email address";
        isValid = false;
      }
    }
    if (file) {
      if (!validLogoFileExtensions.includes(file.name.split('.').pop().toLowerCase())) {
        logoFileImgError.logoFileImgInvalid = "Invalid file extension. Please upload a file with extensions jpg, jpeg, png"
        isValid = false;
      }
      if (file.size > maxFileSizeInBytes) {
        logoFileImgError.logoFileImgSize = "File size exceeds the maximum allowed 2 MB";
        isValid = false;
      }
    }

    setApproxMinError(approxMinError);
    setLogoFileImgError(logoFileImgError)
    setNameError(outletError);
    setPhoneNumberError(phoneNumberError);
    setEmailError(emailAddressError);

    return isValid;
  };
  const getClientDetails = async (e) => {
    const data = {
      EncryptedClientId: localStorage.getItem("encryptedClientId"),
    };
    dispatch(showLoaderAction(true));
    const res = await axios.post(REACT_APP_MY_ENV + "/get-client-user", data, { headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" } }).then(function (res) {
      if (res.data.status === 200) {
        dispatch(showLoaderAction(false));
        setName(res.data.data.user.name);
        localStorage.setItem("outletname", res.data.data.user.name)
        setPhoneNumber(res.data.data.user.phoneNumber);
        setEmail(res.data.data.user.email);
        setOpenTime(res.data.data.user.openTime);
        setCloseTime(res.data.data.user.closeTime);
        setAddress(res.data.data.user.address);
        setApproxMinServeMins(res.data.data.user.approxServeMins);
        setLogoFilePath(res.data.data.user.logoFilePath)
        setImg(res.data.data.user.logoFilePathURL)
      } else {
        dispatch(showLoaderAction(false));
        showToast(res.data.message, "error");
      }
    }).catch(function (error) {
      catchHandle(error,"false",currentURL)
      dispatch(showLoaderAction(false));
      const data = {
        Subject: "Front end error from get-client-user",
        BodyText: error.message
      }
      sendMail(data);
      showToast("Something went wrong, please contact Administrator!", "error")
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    let EncryptedClientId = localStorage.getItem("encryptedClientId");
    if (formValidation()) {
      const formData = new FormData();
      formData.append("IsUpdateProfile", true)
      formData.append("EncryptedClientId", EncryptedClientId)
      formData.append("Name", name);
      formData.append("PhoneNumber", phoneNumber);
      formData.append("Email", email ? email : emailUpdated);
      formData.append("OpenTime", openTime);
      formData.append("CloseTime", closeTime);
      formData.append("Address", address);
      formData.append("LogoFilePath", file);
      formData.append("ApproxServeMins", approxServeMins);
      formData.append("LogoFileName", logoFilePath)
      dispatch(showLoaderAction(true));
      const res = await axios.post(REACT_APP_MY_ENV + "/client-update-profile", formData, { headers: { Authorization: `Bearer ${token}`, "Content-Type": "multipart/form-data" } }).then(function (res) {
        if (res.data.status === 200) {
          dispatch(showLoaderAction(false));
          showToast(res.data.message, "success");
          getClientDetails()
          navigate("/outlet-view-profile");
        } else {
          dispatch(showLoaderAction(false));
          showToast(res.data.message, "error");
        }
      }).catch(function (error) {
        catchHandle(error,"false",currentURL)
        dispatch(showLoaderAction(false));
        const data = {
          Subject: "Front end error from client-update-profile",
          BodyText: error.message
        }
        sendMail(data);
        showToast("Something went wrong, please contact Administrator!", "error")
      });
    }
  };

  const handleChangePassword = async (e) => {
    navigate("/outlet-change-password");
  };

  return (
    <>
      {showLoader && (<SpinerLoader />)}
      <div className="page account-area">
        <div className="page-content">
          <div className="dz-height"></div>
          <div className="fixed-content container pt-0">
            <div className="form-elements">
              <form onSubmit={onSubmit}>
                <div className="navbar navbar-style-1">
                  <div className="navbar-inner navbar-inner-padding">
                    <Link to={-1} className="link back">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.439312 13.0606L5.75391 18.3752C6.04683 18.6682 6.43069 18.8146 6.81459 18.8146C7.1985 18.8146 7.58236 18.6682 7.87528 18.3752C8.46103 17.7894 8.46103 16.8397 7.87528 16.2539L5.12133 13.5H22.5C23.3284 13.5 24 12.8284 24 12C24 11.1716 23.3284 10.5 22.5 10.5H5.12133L7.87528 7.7461C8.46103 7.1603 8.46103 6.21057 7.87528 5.62477C7.28944 5.03898 6.33975 5.03898 5.75391 5.62477L0.439312 10.9394C-0.146437 11.5251 -0.146437 12.4749 0.439312 13.0606Z"
                          fill="black"
                        />
                      </svg>
                    </Link>
                    <div className="title text-left">Update Profile</div>
                  </div>
                </div>
                <div className="list">
                  <ul className="row">
                      <li className="item-content item-input col-100">
                        <div className="item-inner item-mb">
                          <div className="item-input-wrap  profile-image">
                            <img src={img ? img : defaultOutlet} onError={(error) => {error.target.src = defaultOutlet}} alt="img"/>
                          </div>
                        </div>
                      </li>
                    <li className="item-content item-input col-100" style= {{display: 'none'}}>
                      <div className="item-inner">
                        <div className="item-title item-label profile-font">Upload Logo</div>
                        <div className="item-input-wrap profile-font">
                          <input type="file" name="file" id="file" onChange={uploadHandler} accept="image/png, image/jpeg" className="form-control qrFile" />
                          {Object.keys(logoFileImgError).map((key) => {
                            return (
                              <div style={{ color: "red" }}>
                                {logoFileImgError[key]}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </li>
                    <li className="item-content col-100 item-input item-input-with-value">
                      <div className="item-inner">
                        <div className="item-title item-label profile-font">Outlet Name<span className="danger">*</span></div>
                        <div className="item-input-wrap profile-font">
                          <input
                            type="text"
                            value={name}
                            autocomplete="off"
                            onChange={(e) => {
                              setName(e.target.value);
                            }}
                            className="form-control"
                          />
                          <span id="errorMessage"></span>
                          {Object.keys(nameError).map((key) => {
                            return <div style={{ color: "red" }}>{nameError[key]}</div>;
                          })}
                        </div>
                      </div>
                    </li>
                    <li className="item-content col-100 item-input">
                      <div className="item-inner">
                        <div className="item-title item-label profile-font">Phone Number<span className="danger">*</span></div>
                        <div className="item-input-wrap profile-font">
                          <input
                            type="text"
                            value={phoneNumber}
                            autocomplete="off"
                            maxLength={10}
                            onChange={(e) => {
                              setPhoneNumber(e.target.value);
                            }}
                            className="form-control"
                          />
                          {Object.keys(phoneNumberError).map((key) => {
                            return <div style={{ color: "red" }}>{phoneNumberError[key]}</div>;
                          })}
                        </div>
                      </div>
                    </li>
                    {email !== "" || email ?
                    <li className="item-content col-100 item-input">
                      <div className="item-inner">
                        <div className="item-title item-label profile-font">Email Address</div>
                        <div className="item-input-wrap profile-font disabled">
                          <input
                            type="text"
                            id="dz-password"
                            autocomplete="off"
                            value={email}
                            onChange={(e) => {
                              setEmail(e.target.value);
                            }}
                            className="form-control"
                          />
                          {Object.keys(emailError).map((key) => {
                            return <div style={{ color: "red" }}>{emailError[key]}</div>;
                          })}
                        </div>
                      </div>
                    </li> : 
                     <li className="item-content col-100 item-input">
                      <div className="item-inner">
                        <div className="item-title item-label profile-font">Email Address</div>
                        <div className="item-input-wrap profile-font ">
                          <input
                            type="text"
                            id="emailUpdated"
                            autocomplete="off"
                            value={emailUpdated}
                            onChange={(e) => {
                              setEmailUpdated(e.target.value);
                            }}
                            className="form-control"
                          />
                        </div>
                      </div>
                    </li>  }
                    <li className="item-content col-100 item-input">
                      <div className="item-inner">
                        <div className="item-title item-label profile-font">Open Time</div>
                        <div className="item-input-wrap profile-font">
                          <input
                            type="time"
                            id="openTime"
                            value={openTime}
                            maxLength="7"
                            onChange={(e) => {
                              setOpenTime(e.target.value);
                            }}
                            className="form-control"
                          />
                        </div>
                      </div>
                    </li>
                    <li className="item-content col-100 item-input">
                      <div className="item-inner">
                        <div className="item-title item-label profile-font">Close Time</div>
                        <div className="item-input-wrap profile-font">
                          <input
                            type="time"
                            id="closeTime"
                            value={closeTime}
                            maxLength="7"
                            onChange={(e) => {
                              setCloseTime(e.target.value);
                            }}
                            className="form-control"
                          />
                        </div>
                      </div>
                    </li>
                    <li className="item-content col-100 item-input">
                      <div className="item-inner">
                        <div className="item-title item-label profile-font">Address</div>
                        <div className="item-input-wrap profile-font">
                          <textarea
                            id="address"
                            rows="10"
                            cols="15"
                            value={address === "undefined" ? "": address}
                            maxLength="500"
                            autocomplete="off"
                            placeholder="Enter Address"
                            onChange={(e) => {
                              setAddress(e.target.value);
                            }}
                            className="form-control"
                          />
                        </div>
                      </div>
                    </li>
                    <li className="item-content col-100 item-input item-input-with-value">
                      <div className="item-inner">
                        <div className="item-title item-label profile-font">Approx Serve Minutes<span className="danger">*</span></div>
                        <div className="item-input-wrap profile-font">
                          <input
                            type="text"
                            placeholder="Enter Approx Serve Minutes"
                            value={approxServeMins}
                            onChange={(e) => {
                              const inputText = e.target.value;
                              if (inputText.length <= 6) {
                                setApproxMinServeMins(e.target.value);
                              }
                            }}
                            onKeyPress={(e) => {
                              const isNumeric = /^[0-9\b]+$/.test(e.key);

                              if (!isNumeric) {
                                e.preventDefault();
                              }
                            }}
                            maxLength={5}
                            className="form-control"
                          />
                          {Object.keys(approxMinError).map((key) => {
                            return <div style={{ color: "red" }}>{approxMinError[key]}</div>;
                          })}
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="clearfix profile-font mb-20">
                  <button type="submit" id="yes" className="button-large button button-fill profile-font">
                    UPDATE
                  </button>
                </div>
              </form>
              <div className="clearfix profile-font">
                <button
                  type="submit"
                  id="yes"
                  className="button-large button button-fill profile-font"
                  onClick={() => {
                    handleChangePassword();
                  }}
                >
                  Change Password
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientUpdateProfile;
