import axios from 'axios';
import React from 'react'
import { useEffect } from 'react';
import { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import TimeAgo from "javascript-time-ago";
import ReactTimeAgo from "react-time-ago";
import en from "javascript-time-ago/locale/en.json";
import { useDispatch, useSelector } from "react-redux";
import { showLoaderAction } from "../../../actions/index";
import { sendMail, useCatchHandler, showToast } from "../../../utils/utilities"
import $ from "jquery";
import { uniqBy } from "lodash";
import ReactPaginate from 'react-paginate';
import { activeMenuAction } from "../../../actions/index";
import Footer from '../../Master/Footer';
import ListPlaceholder from "../../Placeholders/ListPlaceholder.js"

TimeAgo.addLocale(en);

const { REACT_APP_MY_ENV } = process.env;

const ClientFeedBackList = () => {
    let token = localStorage.getItem("token");
    const dispatch = useDispatch();
    const showLoaderReducer = useSelector((state) => state.rootReducer.showLoaderReducer)
    const showLoader = showLoaderReducer.show;
    const [feedBack, setFeedBack] = useState("");
    const catchHandle = useCatchHandler();
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [showLoading, setShowLoading] = useState(true)
    const itemsPerPage = 10;
    const [totalFeedbackCount, setTotalFeedbackCount] = useState(0);
    const pageCount = Math.ceil(totalFeedbackCount / itemsPerPage);

    useEffect(() => {
        dispatch(activeMenuAction('feedback'));
        FeedBackList()
    }, []);

    const handlePageClick = ({ selected }) => {
        setCurrentPage(selected + 1);
        FeedBackList(selected + 1)
    };

    const currentURL = window.location.href;

    const [scrollPosition, setScrollPosition] = useState(0);
    const scrollRef = useRef(null);

    const handleScroll = () => {
		const container = document.getElementById('scroll');
		if (container.scrollTop + container.clientHeight >= container.scrollHeight - 20 && !loading) {
			if (feedBack.length < totalFeedbackCount) {
				setScrollPosition(container.scrollTop);
				FeedBackList();
			}
		}
	};

    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollTop = scrollPosition;
        }
    }, [feedBack]);

    const FeedBackList = async (pageNumber) => {
        setLoading(true)
        let data = {
            UserId: localStorage.getItem("encryptedUserId"),
            pageNumber: pageNumber ? pageNumber : currentPage,
            pageSize: itemsPerPage
        };

        if(showLoading){
            dispatch(showLoaderAction(true));
        }
    
        const res = await axios.post(REACT_APP_MY_ENV + "/feedback-list", data, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        }).then(function (res) {
            if (res.data.status === 200) {
                setTotalFeedbackCount(res.data.data.totalFeedbackCount)
				// setFeedBack(feedBack => [...feedBack, ...res.data.data.userFeedbackList]);
                setFeedBack(uniqBy([...feedBack, ...res.data.data.userFeedbackList], 'id'));
				setCurrentPage(currentPage => currentPage + 1);
                setLoading(false)
                dispatch(showLoaderAction(false));
                setShowLoading(false)
            } else {
                dispatch(showLoaderAction(false));
                setFeedBack([]);
                setTimeout(function () {
                    $("#no-reviews").html("No reviews yet!");
                }, 200);
            }
        }).catch(function (error) {
            dispatch(showLoaderAction(false));
            showToast("Something went wrong, please contact Administrator!", "error")
            catchHandle(error,"true",currentURL)
            const data = {
                Subject: "Front end error from feedback-list",
                BodyText: error.message
            }
            sendMail(data);
        });
    };

    return (
        <>
			{showLoader ? (<ListPlaceholder skeletonName={"Reviews"} />)
            :
            <div class="page">
            <div className='containerCard'>
                <div class="navbar navbar-style-1">
                    <div class="navbar-inner navbar-bg-theme">
                        <Link to="/sidebar" class="link back">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.439312 13.0606L5.75391 18.3752C6.04683 18.6682 6.43069 18.8146 6.81459 18.8146C7.1985 18.8146 7.58236 18.6682 7.87528 18.3752C8.46103 17.7894 8.46103 16.8397 7.87528 16.2539L5.12133 13.5H22.5C23.3284 13.5 24 12.8284 24 12C24 11.1716 23.3284 10.5 22.5 10.5H5.12133L7.87528 7.7461C8.46103 7.1603 8.46103 6.21057 7.87528 5.62477C7.28944 5.03898 6.33975 5.03898 5.75391 5.62477L0.439312 10.9394C-0.146437 11.5251 -0.146437 12.4749 0.439312 13.0606Z" fill="white" />
                            </svg>
                        </Link>
                        <div class="title text-white">Reviews</div>
                    </div>
                </div>
                <div class="page-content inner-page pt-0" id="scroll" onScroll={() => handleScroll()} ref={scrollRef}>

                    <div class="list media-list search-list searchbar-found search-list-bar review-list container">
                        <ul>
                            {feedBack && feedBack.length > 0 ?
                                feedBack && feedBack.map((data, index) => {
                                    const updatedAt = data.updatedAt ? new Date(data.updatedAt) : null;
                                    const createdAt = new Date(data.createdAt);
                                    const today = new Date();
                                    let displayDate;
                                    if (updatedAt && updatedAt.toDateString() === today.toDateString()) {
                                        displayDate = <ReactTimeAgo date={updatedAt} locale="en-US" />;
                                    } else {
                                        const formattedDate = updatedAt ? updatedAt.toLocaleString('en-US', {
                                            year: 'numeric',
                                            month: '2-digit',
                                            day: '2-digit',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            hour12: true,
                                        }) : createdAt.toLocaleString('en-US', {
                                            year: 'numeric',
                                            month: '2-digit',
                                            day: '2-digit',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            hour12: true,
                                        });
                                        displayDate = formattedDate;
                                    }
                                    return (
                                        <li className='listing' key={index}>
                                            <Link to={"/outlet-review/" + data.outletSlug} state={{ outlet: data }} class="item-link item-content item-content-review">
                                                <div class="item-inner">
                                                    <div class="item-title-row">
                                                        <h6 class="item-title">{data.outletName ? data.outletName : "No Name"}</h6>
                                                        <div class="item-after item-subtitle">

                                                            { displayDate}

                                                        </div>
                                                    </div>
                                                    <div class="rating-info review-info">
                                                        <ul id="review-star" className="rating-list review-star">
                                                            {[1, 2, 3, 4, 5].map((starIndex) => (
                                                                <li key={starIndex}>
                                                                    <i
                                                                        className={`fa-star ${starIndex > data.stars ? 'far' : 'fas'}`}
                                                                    ></i>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                    <p class="item-feedback-text">{data.message}</p>
                                                </div>
                                            </Link>
                                        </li>
                                    )
                                })
                                :
                                <p id="no-reviews" className='noreviews-margin'>Loading...</p>
                            }
                        </ul>
                    </div>
                    {/* {feedBack && feedBack.length > 0 ?
                        <div>
                            <ReactPaginate
                                pageCount={pageCount}
                                pageRangeDisplayed={10}
                                marginPagesDisplayed={0}
                                onPageChange={handlePageClick}
                                previousLabel={"Prev"}
                                nextLabel={"Next"}
                                breakLabel={"..."}
                                containerClassName={"pagination"}
                                activeClassName={"active"}
                                forcePage={ currentPage === 1 ? 0 : currentPage - 1}
                            />
                        </div>
                        : ""}*/}
                        <Footer /> 
                </div>
            </div>
            </div>
            }
        </>
    )
}
export default ClientFeedBackList