import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import $ from "jquery";
import "../../../App.css";
import "../../../css/framework7-bundle.css";
import "../../../css/app.css";
import "../../../css/font-awesome/css/all.min.css";
import "../../../css/line-awesome/css/line-awesome.min.css";
import "../../../css/style.css";
import "../../../css/perfect-scrollbar.css";
import "../../../img/f7-icon-square.png";
import "../../../img/f7-icon.png";
import "../../../js/main.js";
import axios from "axios";
import tokenlogo from "../../../img/logo-new.png";
import { useCatchHandler, showToast } from "../../../utils/utilities"
import { useDispatch, useSelector } from "react-redux";
import { showLoaderAction } from "../../../actions/index";
import SpinerLoader from "../../SpinerLoader/spinerLoader";
import { sendMail } from "../../../utils/utilities";
const { REACT_APP_MY_ENV } = process.env;

function ResetPassword() {
	let {id} = useParams(); 
	const navigate = useNavigate();
  const dispatch = useDispatch();
  const catchHandle = useCatchHandler();
  const showLoaderReducer = useSelector((state) => state.rootReducer.showLoaderReducer)
  const showLoader = showLoaderReducer.show;
	const [password, setPassword] = useState("");
  const [confPassword, setConfPassword] = useState("");
  const [validate, setValidate] = useState("");
  const [clientId, setClientId] = useState("");

  const [passwordError, setPasswordError] = useState({});
	const [confPasswordError, setConfPasswordError] = useState({});

  const currentURL = window.location.href;

  useEffect(() => {
		validateClient(id);
  }, []);

	async function validateClient(id){
		const res = await axios.get(
			REACT_APP_MY_ENV + "/validate-client/"+id);
			if(res.data.status === 200){
				setValidate(true);
				setClientId(id);
			}		
	}

  const onSubmit = async (e) => {
    e.preventDefault();
    const isValid = formValidation();
    if (isValid) {
      const data= {
        "encryptedId":id,
        "newPassword":password,
      }
      const res = await axios.post(
        REACT_APP_MY_ENV + "/reset-password/", data).then(function (res) {
          dispatch(showLoaderAction(true));
      if (res.data.status === 200) {
        dispatch(showLoaderAction(false));
        showToast(res.data.message, "success");
        navigate('/outlet-sign-in');
      } else {
        dispatch(showLoaderAction(false));
        showToast(res.data.message, "error");
      }
    }).catch(function (error) {
      catchHandle(error,"false", currentURL)
      const data = {
        Subject: "Front end error from reset-password",
        BodyText: error.message
      }
      sendMail(data);
      dispatch(showLoaderAction(false));
      showToast("Something went wrong, please contact Administrator!", "error")
    });
    }
  };

	function showHidePwd() {		
		var passInput = $('.show-pass').parent().find('input');
		var inputType = passInput.attr('type');
		if( inputType == 'password'){
			$('.show-pass').toggleClass('active');
			passInput.attr('type','text');
		}else if(inputType == 'text'){
			$('.show-pass').removeClass('active');
			passInput.attr('type','password');
		}
  }

  function showHideConfPwd() {		
    var passInput = $('.show-pass-conf').parent().find('input');
    var inputType = passInput.attr('type');
    if( inputType == 'password'){
      $('.show-pass-conf').toggleClass('active');
      passInput.attr('type','text');
    }else if(inputType == 'text'){
      $('.show-pass-conf').removeClass('active');
      passInput.attr('type','password');
    }
  }

  const formValidation = () => {
		let passwordError = {};
		let confPasswordError = {};
    let isValid = true;

    if (!password) {
      passwordError.passwordEmpty =
        "Please enter password";
      isValid = false;
    } 

		if (!confPassword) {
      confPasswordError.confPasswordEmpty =
        "Please enter confirm password";
      isValid = false;
    }else if(password != confPassword){
			confPasswordError.confPasswordNotMatch =
        "Confirm password does not match";
      isValid = false;
		}

    setPasswordError(passwordError);
		setConfPasswordError(confPasswordError);
    return isValid;
  };

  return (
    <>
    {showLoader && (<SpinerLoader />)}
      <div className="page account-area">
			{validate === true ? <>
        <div className="page-content">
        <div className="logo-space"><div className="logo-request-singpage">
        <img src={tokenlogo} onError={(error) => { error.target.src = tokenlogo }} /></div></div>
          <div className="fixed-content container">
            <div className="tabs">
              <div className="tab tab-active form-elements tabs">
                <form onSubmit={onSubmit} className="tab tab-active" id="tabA1">
                  <div className="title-head">
                    <h2 className="title">Reset Password</h2>
                  </div>
                  <div className="list">
                    <ul className="row">
										<li class="item-content item-input col-100">
                        <div class="item-inner">
                          <div class="item-title item-label profile-font">
														Password
													</div>
                          <div class="item-input-wrap profile-font">
                            <input type="password" 
															name="password" 
															id="password" 
                              autocomplete="new-password"
															value={password}
															maxLength="1000"
															placeholder="Enter Password" 
                              onChange={(e) => {
                                setPassword(e.target.value);
                              }}
															className="form-control"
                            />
															<div class="show-pass">
																<svg width="24" height="24" onClick={showHidePwd} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<path d="M22.919 11.606C22.786 11.3 19.572 4.00002 12 4.00002C4.42801 4.00002 1.21401 11.3 1.08101 11.606C1.02764 11.7305 1.00012 11.8646 1.00012 12C1.00012 12.1355 1.02764 12.2695 1.08101 12.394C1.21401 12.7 4.42801 20 12 20C19.572 20 22.786 12.7 22.919 12.394C22.9724 12.2695 22.9999 12.1355 22.9999 12C22.9999 11.8646 22.9724 11.7305 22.919 11.606ZM12 18C6.60001 18 3.83301 13.411 3.11001 12C3.83501 10.614 6.64801 6.00002 12 6.00002C17.394 6.00002 20.165 10.586 20.89 12C20.164 13.386 17.352 18 12 18Z" fill="#309F5F"/>
																	<path d="M12 8C11.2089 8 10.4355 8.2346 9.77772 8.67412C9.11993 9.11365 8.60723 9.73836 8.30448 10.4693C8.00173 11.2002 7.92252 12.0044 8.07686 	12.7804C8.2312 13.5563 8.61216 14.269 9.17157 14.8284C9.73098 15.3878 10.4437 15.7688 11.2196 15.9231C11.9956 16.0775 12.7998 15.9983 13.5307 15.6955C14.2616 15.3928 14.8864 14.8801 15.3259 14.2223C15.7654 13.5645 16 12.7911 16 12C16 10.9391 15.5786 9.92172 14.8284 9.17157C14.0783 8.42143 13.0609 8 12 8ZM12 14C11.6044 14 11.2178 13.8827 10.8889 13.6629C10.56 13.4432 10.3036 13.1308 10.1522 12.7654C10.0009 12.3999 9.96126 11.9978 10.0384 11.6098C10.1156 11.2219 10.3061 10.8655 10.5858 10.5858C10.8655 10.3061 11.2219 10.1156 11.6098 10.0384C11.9978 9.96126 12.3999 10.0009 12.7654 10.1522C13.1308 10.3036 13.4432 10.56 13.6629 10.8889C13.8827 11.2178 14 11.6044 14 12C14 12.5304 13.7893 13.0391 13.4142 13.4142C13.0391 13.7893 12.5304 14 12 14Z" fill="#309F5F"/>
																</svg>
															</div>
														{passwordError &&
                              Object.keys(passwordError).map((key) => {
                                return (
                                  <div style={{ color: "red" }}>
                                    {passwordError[key]}
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                    </li>
										<li class="item-content item-input col-100">
                        <div class="item-inner">
                          <div class="item-title item-label profile-font">
														Confirm Password
													</div>
                          <div class="item-input-wrap profile-font">
                            <input type="password" 
															name="confirmPassword" 
															id="confirmPassword" 
															placeholder="Enter Confirm Password" 
															maxLength="1000"
															value={confPassword}
                              onChange={(e) => {
                                setConfPassword(e.target.value);
                              }}
															className="form-control"
                            />
															<div class="show-pass-conf">
																<svg width="24" height="24" onClick={showHideConfPwd} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<path d="M22.919 11.606C22.786 11.3 19.572 4.00002 12 4.00002C4.42801 4.00002 1.21401 11.3 1.08101 11.606C1.02764 11.7305 1.00012 11.8646 1.00012 12C1.00012 12.1355 1.02764 12.2695 1.08101 12.394C1.21401 12.7 4.42801 20 12 20C19.572 20 22.786 12.7 22.919 12.394C22.9724 12.2695 22.9999 12.1355 22.9999 12C22.9999 11.8646 22.9724 11.7305 22.919 11.606ZM12 18C6.60001 18 3.83301 13.411 3.11001 12C3.83501 10.614 6.64801 6.00002 12 6.00002C17.394 6.00002 20.165 10.586 20.89 12C20.164 13.386 17.352 18 12 18Z" fill="#309F5F"/>
																	<path d="M12 8C11.2089 8 10.4355 8.2346 9.77772 8.67412C9.11993 9.11365 8.60723 9.73836 8.30448 10.4693C8.00173 11.2002 7.92252 12.0044 8.07686 	12.7804C8.2312 13.5563 8.61216 14.269 9.17157 14.8284C9.73098 15.3878 10.4437 15.7688 11.2196 15.9231C11.9956 16.0775 12.7998 15.9983 13.5307 15.6955C14.2616 15.3928 14.8864 14.8801 15.3259 14.2223C15.7654 13.5645 16 12.7911 16 12C16 10.9391 15.5786 9.92172 14.8284 9.17157C14.0783 8.42143 13.0609 8 12 8ZM12 14C11.6044 14 11.2178 13.8827 10.8889 13.6629C10.56 13.4432 10.3036 13.1308 10.1522 12.7654C10.0009 12.3999 9.96126 11.9978 10.0384 11.6098C10.1156 11.2219 10.3061 10.8655 10.5858 10.5858C10.8655 10.3061 11.2219 10.1156 11.6098 10.0384C11.9978 9.96126 12.3999 10.0009 12.7654 10.1522C13.1308 10.3036 13.4432 10.56 13.6629 10.8889C13.8827 11.2178 14 11.6044 14 12C14 12.5304 13.7893 13.0391 13.4142 13.4142C13.0391 13.7893 12.5304 14 12 14Z" fill="#309F5F"/>
																</svg>
															</div>
															{Object.keys(confPasswordError).map((key) => {
															return (
																<div style={{ color: "red" }}>
																	{confPasswordError[key]}
																</div>
															);
															})}
                          </div>
                        </div>
                    </li>
                    </ul>
                  </div>
                  <div class="clearfix">
                    <button
                      type="submit"
                      className="button-large button button-fill"
                    >
                      RESET
                    </button>
                  </div>
                </form>
								</div>
            </div>
          </div>
        </div>
					</>
					: <>
					<div class="page-content">
					<div class="block block-strong">
						<p>Sorry</p>
							<p>Something went wrong, please contact Administrator!</p>
					</div>
					</div>
					</>}
      </div>
    </>
  );
}

export default ResetPassword;
