import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from 'react-router-dom';
import "../../../App.css";
import "../../../css/framework7-bundle.css";
import "../../../css/app.css";
import "../../../css/font-awesome/css/all.min.css";
import "../../../css/line-awesome/css/line-awesome.min.css";
import "../../../css/style.css";
import "../../../css/perfect-scrollbar.css";
import "../../../img/f7-icon-square.png";
import "../../../img/f7-icon.png";
import defaultOutlet from '../../../img/outlet-default.png';
import moment from "moment/moment";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { showLoaderAction } from "../../../actions/index";
import { confirmAlert } from "react-confirm-alert";
import { sendMail, useCatchHandler, showToast } from "../../../utils/utilities"
import "react-confirm-alert/src/react-confirm-alert.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNotification } from "../../../utils/utilities"
import ReactPaginate from 'react-paginate';
import { activeMenuAction } from "../../../actions/index";
import Footer from '../../Master/Footer';
import { uniqBy } from "lodash";
import ListPlaceholder from "../../Placeholders/ListPlaceholder.js"

const { REACT_APP_MY_ENV } = process.env;

function Outlets() {
	const showLoaderReducer = useSelector((state) => state.rootReducer.showLoaderReducer)
	const showLoader = showLoaderReducer.show;
	const dispatch = useDispatch();
	const location = useLocation();
	const [categoryId] = useState(location.state?.categoryId);
	const [dashboard] = useState(location.state?.dashboard);
	const [outlets, setOutlets] = useState([]);
	const [categoryList, setCategoryList] = useState([]);
	const [isFilterClicked, setIsFilterClicked] = useState(false);
	const [defaultSelectedCategory, setDefaultSelectedCategory] = useState(null);
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [IsTrue, setIsTrue] = useState(false);
	const [isFavourite, setIsFavourite] = useState();
	const [textSearch, setTextSearch] = useState("");
	const [loading, setLoading] = useState(false);
	const [showLoading, setShowLoading] = useState(true)
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 10;
	const [outletTotalCount, setOutletTotalCount] = useState(0);
	const [onLoading, setOnLoading] = useState(true); 
	const pageCount = Math.ceil(outletTotalCount / itemsPerPage);

	let token = localStorage.getItem("token");
	const catchHandle = useCatchHandler();
	const sendNotification = useNotification();

	const currentURL = window.location.href;
	let isManualFilter = false;

	const [scrollPosition, setScrollPosition] = useState(0);
    const scrollRef = useRef(null);

	useEffect(() => {
		dispatch(activeMenuAction('outlets'));
		if (localStorage.getItem("isLogin", true)) {
			setIsLoggedIn(true);
		}
		if (dashboard === true) {
			setDefaultSelectedCategory(categoryId)
			if (categoryId) {
				isManualFilter = true;
			}
			getCategoryList()
			getOutlets( categoryId, currentPage);
			setIsFilterClicked(true);
		} else {
			if (outlets.length <= 0)
				getOutlets()
		}
		if (!onLoading) {
			if (textSearch) {
				const delayDebounceFn = setTimeout(() => {
					handleSearchChange();
				}, 1000);

				return () => clearTimeout(delayDebounceFn);
			}
			else {
				const delayDebounceFn = setTimeout(() => {
					handleSearchChange();
				}, 1000);

				return () => clearTimeout(delayDebounceFn);
			}
		}
	}, [textSearch]);

	const handleScroll = () => {
		const container = document.getElementById('scroll');
		if (container.scrollTop + container.clientHeight >= container.scrollHeight - 20 && !loading) {
			if (outlets.length < outletTotalCount) {
				setScrollPosition(container.scrollTop);
				getOutlets();
			}
		}
	};

	const handlePageClick = ({ selected }) => {
		// isManualFilter = false;
		setCurrentPage(selected + 1);
		getOutlets(categoryId, selected + 1,textSearch)
	};

	const callBack = () => {
		setOutlets([])
	}

	useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollTop = scrollPosition;
        }
    }, [outlets]);

	const getOutlets = (category_Id, pageNumber,text_Search) => {
		setLoading(true)
		let data = {
			searchText: textSearch ? textSearch : text_Search ? text_Search : "",
			pageNumber:  isManualFilter ? 1 : pageNumber ? pageNumber : currentPage,
			// pageNumber: (textSearch || text_Search || isManualFilter) ? 1 : (pageNumber ? pageNumber : currentPage),
			pageSize: itemsPerPage,
			ClientCategoryId: isManualFilter ? (category_Id ? category_Id.toString() : null) || (categoryId ? categoryId.toString() : null) : (defaultSelectedCategory ? defaultSelectedCategory.toString() : null),
			encryptedUserId: localStorage.getItem("encryptedUserId"),
			cityId:localStorage.getItem("CityId")
		}
		if(showLoading){
			dispatch(showLoaderAction(true));
		}

		axios.post(REACT_APP_MY_ENV + "/outlets", data).then(function (response) {
			if (response.data.status === 200) {
				dispatch(showLoaderAction(false));
				if(textSearch){
					const searchInput = document.getElementById("searchInput");
					if(searchInput && (pageNumber ? pageNumber : currentPage) > 1){
						setOutlets(outlets => [...outlets, ...response.data.data.searchOutlet]);
					}
					else{
						setOutlets(response.data.data.searchOutlet);
					}
				  }
				  else{
					setOutlets(outlets => [...outlets, ...response.data.data.searchOutlet]);
				  }
				setCurrentPage(currentPage => currentPage + 1);
				setOutletTotalCount(response.data.data.totalClientsCount);
				setIsTrue(true)
				setLoading(false);
				setShowLoading(false)
			} else {
				dispatch(showLoaderAction(false));
				// setOutlets([])
			}
		}).catch(function (error) {
			dispatch(showLoaderAction(false));
			showToast("Something went wrong, please contact Administrator!", "error")
			catchHandle(error, "true", currentURL)
			const data = {
				Subject: "Front end error from outlets",
				BodyText: error.message
			}
			sendMail(data);
		});
	}

	const onSearchTextChange = (e) => {
		setTextSearch(e.target.value)
		setOnLoading(false)
	};

	const handleSearchChange = () => {
		callBack()
		setCurrentPage(1)
		setScrollPosition(0)
		let currentPage = 1
		if (categoryList.length <= 0) {
			getOutlets("",currentPage,textSearch);
		} else if (categoryList.length >= 0) {
			getOutlets(defaultSelectedCategory || categoryId, currentPage, textSearch);
			// setTextSearch("");
		}
	}

	const getToken = (qrUuid) => (e) => {
		const data = {
			name: localStorage.getItem("username"),
			phoneNumber: localStorage.getItem("phoneNumber"),
		};
		dispatch(showLoaderAction(true));
		axios
			.post(REACT_APP_MY_ENV + "/token/" + qrUuid, data)
			.then(function (response) {
				if (response.data.status === 200) {
					dispatch(showLoaderAction(false));
					showToast(response.data.message, "success");
				} else {
					dispatch(showLoaderAction(false));
					showToast(response.data.message, "error");
				}
			}).catch(function (error) {
				dispatch(showLoaderAction(false));
				showToast("Something went wrong, please contact Administrator!", "error")
				catchHandle(error, "true", currentURL)
				const data = {
					Subject: "Front end error from token",
					BodyText: error.message
				}
				sendMail(data);
			});
	}

	const getCategoryList = async () => {
		const response = await axios.get(REACT_APP_MY_ENV + "/category-list").then(function (response) {
			if (response.data.status === 200) {
				setCategoryList(response.data.data.categoryList)
				const categoryList = response.data.data.categoryList;
				if (!categoryId) {
					const firstCategory = categoryList[0];
					setDefaultSelectedCategory(firstCategory.categoryId);
					getOutlets(firstCategory.categoryId, currentPage)
				}
			} else {
				showToast(response.data.message, "error");
			}
		}).catch(function (error) {
			dispatch(showLoaderAction(false));
			showToast("Something went wrong, please contact Administrator!", "error")
			catchHandle(error, "true", currentURL)
			const data = {
				Subject: "Front end error from category-list",
				BodyText: error.message
			}
			sendMail(data);
		});
	};

	const handleCategoryClick = (categoryId) => {
		callBack()
		setScrollPosition(0)
		// setTimeout(() => {
			setCurrentPage(1);
		// }, 1000);
		isManualFilter = true;
		if (textSearch) {
			getOutlets(categoryId, 1);
			// document.getElementById("searchInput").value = "";
			setIsFilterClicked(true);
		}
		else {
			setDefaultSelectedCategory(categoryId);
			getOutlets(categoryId, 1);
			// document.getElementById("searchInput").value = "";
		}
	}

	const handleFilterClick = (e) => {
		callBack()
		setCurrentPage(1)
		setScrollPosition(0)
		if (categoryList.length <= 0) {
			isManualFilter = true
			if (categoryId) {
				getCategoryList()
				setDefaultSelectedCategory(categoryId)
				getOutlets(categoryId, 1)
				setIsFilterClicked(true);
			} else {
				getCategoryList()
				// setDefaultSelectedCategory(defaultSelectedCategory)
				// getOutlets(defaultSelectedCategory, 1);
				setIsFilterClicked(true);
			}
		}
		else {
			isManualFilter = true
			setIsFilterClicked(false);
			setCategoryList([])
			setDefaultSelectedCategory(null);
			getOutlets('', 1);
			// document.getElementById("searchInput").value = "";
		}
	};

	const addToFavourite = (id) => async (e) => {
		const data = {
			encryptedClientId: id,
			encryptedUserId: localStorage.getItem("encryptedUserId"),
		};
		dispatch(showLoaderAction(true));

		const addFavouriteOutlet = await axios.post(REACT_APP_MY_ENV + "/add-outlet-to-favourite", data, { headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" } }).then(function (addFavouriteOutlet) {
			if (addFavouriteOutlet.data.status === 200) {
				const updatedOutlets = [...outlets];
				const outletToUpdate = updatedOutlets.find(
					(outlet) => outlet.encryptedClientId === id
				);
				if (outletToUpdate) {
					// isManualFilter = true;
					setIsFavourite(outletToUpdate);
					getOutlets(defaultSelectedCategory, currentPage)
				}
				notificationSendForAddToFav()
				dispatch(showLoaderAction(false));
				setOutlets(outlets =>
					outlets.map(outlet => {
						if (outlet.encryptedClientId === id) {
							return { ...outlet, isFavouriteOutlet: true };
						}
						return outlet;
					})
				);
				showToast(addFavouriteOutlet.data.message, "success");
			} else {
				dispatch(showLoaderAction(false));
				showToast(addFavouriteOutlet.data.message, "error");
			}
		}).catch(function (error) {
			dispatch(showLoaderAction(false));
			showToast("Something went wrong, please contact Administrator!", "error")
			catchHandle(error, "true", currentURL)
			const data = {
				Subject: "Front end error from add-outlet-to-favourite",
				BodyText: error.message
			}
			sendMail(data);
		});
	};

	const ConfirmRemove = (encryptedClientId) => async (e) => {
		confirmAlert({
			title: (
				<span className="confirm-title">
					Confirmation
				</span>
			),
			message: 'Are you sure you want to remove this outlet from Favourites?',
			buttons: [
				{
					label: 'Yes',
					className: 'red-button',
					onClick: () => removeFavourite(encryptedClientId)
				},
				{
					label: 'Cancel',
					className: 'gray-button',
				}
			]
		});
	}
	const removeFavourite = async (encryptedClientId) => {

		const data = {
			encryptedUserId: localStorage.getItem("encryptedUserId"),
			encryptedClientId: encryptedClientId,
		};
		dispatch(showLoaderAction(true));
		const removedFavouriteOutlet = await axios.delete(REACT_APP_MY_ENV + "/remove-favourite-outlet",
			{ headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" }, data }
		).then(function (removedFavouriteOutlet) {
			if (removedFavouriteOutlet.data.status === 200) {
				dispatch(showLoaderAction(false));
				notificationSendForRemoveFromFav()
				showToast(removedFavouriteOutlet.data.message, "success");
				setIsFavourite(false);
				// isManualFilter = true;
				getOutlets(defaultSelectedCategory, currentPage)
				setOutlets(outlets =>
					outlets.map(outlet => {
						if (outlet.encryptedClientId === encryptedClientId) {
							return { ...outlet, isFavouriteOutlet: false };
						}
						return outlet;
					})
				);
			} else {
				dispatch(showLoaderAction(false));
				showToast(removedFavouriteOutlet.data.message, "error");
			}
		}).catch(function (error) {
			dispatch(showLoaderAction(false));
			showToast("Something went wrong, please contact Administrator!", "error")
			catchHandle(error, "true", currentURL)
			const data = {
				Subject: "Front end error from remove-favourite-outlet",
				BodyText: error.message
			}
			sendMail(data);
		});
	};

	const notificationSendForAddToFav = () => {
		const data = {
			ClientId: outlets[0].encryptedClientId,
			UserId: localStorage.getItem("encryptedUserId"),
			SentBy: "USER",
			Title: "Added to Favourites",
			Description: `${localStorage.getItem("username")} added our outlet into Favourites`,
		}
		sendNotification(data)
	}

	const notificationSendForRemoveFromFav = () => {
		const data = {
			ClientId: outlets[0].encryptedClientId,
			UserId: localStorage.getItem("encryptedUserId"),
			SentBy: "USER",
			Title: "Removed from Favourites",
			Description: `${localStorage.getItem("username")} removed our outlet from Favourites`,
		}
		sendNotification(data)
	}

	const handleSubmit = (e) => {
		e.preventDefault();
		onSearchTextChange({ target: { value: textSearch } });
	};

	const handleKeyDown = (e) => {
		if (e.key === 'Enter') {
		  handleSearchChange();
		}
	  };

	return (
		<>
			{showLoader ? (<ListPlaceholder skeletonName={"Outlets"} />)
			:
			<>
			<div class="page outlets-page commane-page ">
				<div className="containerCard">
					<div class="navbar navbar-bg-theme navbar-style-1">
						<div class="navbar-inner">
							<Link to={-1} class="link back">
								<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M0.439312 13.0606L5.75391 18.3752C6.04683 18.6682 6.43069 18.8146 6.81459 18.8146C7.1985 18.8146 7.58236 18.6682 7.87528 18.3752C8.46103 17.7894 8.46103 16.8397 7.87528 16.2539L5.12133 13.5H22.5C23.3284 13.5 24 12.8284 24 12C24 11.1716 23.3284 10.5 22.5 10.5H5.12133L7.87528 7.7461C8.46103 7.1603 8.46103 6.21057 7.87528 5.62477C7.28944 5.03898 6.33975 5.03898 5.75391 5.62477L0.439312 10.9394C-0.146437 11.5251 -0.146437 12.4749 0.439312 13.0606Z" fill="white" />
								</svg>
							</Link>
							<div class="title navbar-title-theme">Outlets</div>
						</div>
					</div>

					<div class="page-content inner-page pt-20" id="scroll" onScroll={() => handleScroll()} ref={scrollRef}>
						<div class="searchbar-backdrop"></div>
						<div class="container">
							<div class="filter-bx">
								<form
									 onSubmit={handleSubmit}
									data-search-container=".search-list" data-search-in=".item-title" class="searchbar searchbar-init search-box list-search-bx">
									<div class="searchbar-inner">
										<div class="searchbar-input-wrap searchbar-input-wrap-pad">
											<input type="text" className='date-daily-token-input' id="searchInput" autocomplete="off" onChange={onSearchTextChange} placeholder="Search Outlet..." onKeyDown={handleKeyDown} value={textSearch}/>
										</div>
									</div>
								</form>
								<Link to="#"
									className={`${"filter-btn  filter-btn-clicked"}`}
									onClick={handleSearchChange}
								>
									<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16"> <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" /> </svg>
								</Link>
								<Link to="#"
									className={`${!isFilterClicked ? "filter-btn filter-btn-lf filter-btn-clicked" : "filter-btn"}`}
									onClick={handleFilterClick}>
									<svg enable-background="new 0 0 507.918 507.918" height="24" viewBox="0 0 507.918 507.918" width="24" xmlns="http://www.w3.org/2000/svg" >
										<g><path d="m184.697 215.473v269.358c0 12.751 10.336 23.087 23.087 23.087h92.349c12.751 0 23.087-10.336 23.087-23.087v-269.358l133.905-178.534c7.65-10.201 5.583-24.672-4.617-32.322-3.996-2.997-8.857-4.617-13.852-4.617h-369.395c-12.751 0-23.087 10.336-23.087 23.087 0 4.995 1.62 9.856 4.617 13.852zm207.784-169.299-110.818 147.758c-2.997 3.996-4.617 8.857-4.617 13.852v253.959h-46.174v-253.958c0-4.995-1.62-9.856-4.617-13.852l-110.82-147.759z" fill="#000000" /></g>
									</svg>
								</Link>
							</div>
						</div>

						<div class="list search-list mt-0 mb-20 container dz-list searchbar-found item-list">
							<div
								data-space-between="20"
								data-slides-per-view="auto"
								data-centered-slides="false"
								className="swiper-container swiper-init scrolling-wrapper tabbar tab-style-2 outlet-list-m"
							>
								<div className="swiper-wrapper scrolling-wrapper">
									{categoryList.map((category) => (
										<div className="swiper-slide" key={category.categoryId}>
											<Link id="filterCategory"
												to={""}
												className={`tab-link ${defaultSelectedCategory === category.categoryId ? 'tab-link-active' : ""}`}
												onClick={() => handleCategoryClick(category.categoryId, setDefaultSelectedCategory(category.categoryId))}
											>
												{category.categoryName}
											</Link>
										</div>
									))}
								</div>
							</div>
							<div class="tabs">
								<div id="tab-1" class="tab tab-active">
									<ul class="row">
										{outlets.length > 0 ? outlets.map((outlet) => {
											return (
												<li class="store-card card-list col-100 medium-50 mb-15">
													<div class="card-media">
														<Link to={"/outlet/" + outlet.slug} state={{ outlet: outlet }} ><LazyLoadImage className="imgCard" src={outlet.logoFilePathURL ? outlet.logoFilePathURL : defaultOutlet} onError={(error) => { error.target.src = defaultOutlet }} alt={outlet.name} /></Link>
													</div>
													<div class="card-info outlet-info title-container">
														<h6 class="title responsive-title"><Link to={"/outlet/" + outlet.slug} state={{ outlet: outlet }}>{outlet.name}</Link></h6>
														<p className="time">{outlet.openTime ? moment(outlet.openTime, 'hh:mm:ss').format('hh:mm A') : ""}{outlet.closeTime ? " - " : ""}{outlet.closeTime ? moment(outlet.closeTime, 'hh:mm:ss').format('hh:mm A') : ""}</p>
														<p className="time info-bottom-pad rate">{outlet.ratings ?
															(<Link>
																<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<path d="M14.43 5.84598L10.552 5.27932L8.60004 1.36865C8.53844 1.26533 8.45107 1.17978 8.34648 1.12037C8.24189 1.06096 8.12366 1.02972 8.00338 1.02972C7.88309 1.02972 7.76486 1.06096 7.66027 1.12037C7.55568 1.17978 7.46831 1.26533 7.40671 1.36865L5.44804 5.27932L1.57004 5.84598C1.44247 5.86472 1.32308 5.92005 1.22631 6.00526C1.12955 6.09047 1.05957 6.20192 1.02486 6.32609C0.990146 6.45027 0.992187 6.58185 1.03073 6.70489C1.06928 6.82793 1.14268 6.93715 1.24204 7.01932L4.44538 9.66132L3.43538 14.188C3.40586 14.3199 3.41715 14.4577 3.46773 14.583C3.51832 14.7084 3.60582 14.8154 3.71863 14.8899C3.83144 14.9644 3.96422 15.0028 4.09937 15.0001C4.23452 14.9974 4.36566 14.9536 4.47537 14.8747L8.00004 12.3393L11.5247 14.8727C11.6344 14.9516 11.7656 14.9954 11.9007 14.9981C12.0359 15.0008 12.1686 14.9624 12.2815 14.8879C12.3943 14.8134 12.4818 14.7064 12.5324 14.581C12.5829 14.4557 12.5942 14.3179 12.5647 14.186L11.5547 9.65932L14.758 7.01732C14.8574 6.93515 14.9308 6.82593 14.9693 6.70289C15.0079 6.57985 15.0099 6.44827 14.9752 6.32409C14.9405 6.19992 14.8705 6.08847 14.7738 6.00326C14.677 5.91805 14.5576 5.86272 14.43 5.84398V5.84598ZM10.3907 8.89332C10.2959 8.97167 10.2246 9.07473 10.1847 9.1911C10.1448 9.30747 10.1379 9.4326 10.1647 9.55265L10.882 12.77L8.38938 10.9773C8.27596 10.8957 8.13976 10.8518 8.00004 10.8518C7.86032 10.8518 7.72413 10.8957 7.61071 10.9773L5.11804 12.77L5.83538 9.55265C5.8622 9.4326 5.85529 9.30747 5.8154 9.1911C5.77551 9.07473 5.70421 8.97167 5.60938 8.89332L3.25071 6.94798L5.98404 6.54798C6.09033 6.53249 6.1913 6.49154 6.27835 6.42863C6.36541 6.36572 6.43597 6.2827 6.48404 6.18665L8.00004 3.15665L9.51471 6.18665C9.56278 6.2827 9.63334 6.36572 9.7204 6.42863C9.80746 6.49154 9.90842 6.53249 10.0147 6.54798L12.748 6.94798L10.3907 8.89332Z" fill="#464646" />
																</svg><span className="ratingPadding">{outlet.ratings}</span>
															</Link>) : <p className="No-review">No reviews</p>}
														</p>
														<div className="info-bottom">

															{outlet.outletStatus == 0 ? (<Link>
																<li className="price favoriteCardWidth">Closed</li>
															</Link>) : (
																<Link to={"/outlet/" + outlet.slug} state={{ outlet: outlet }}>
																	<li className="price favoriteCardWidth">Open</li>
																</Link>)}
															<div class="dz-meta">
																<ul>

																	<li className="">
																		{outlet.outletStatus === 1 ? (
																			<Link to={"/get-token/" + outlet.qrUuid} state={{ outlet: outlet }} >
																				<svg
																					xmlns="http://www.w3.org/2000/svg"
																					height="1.2em"
																					viewBox="0 0 576 512"
																					width="28"
																					className="get-token-icon"
																				>
																					<path d="M64 64C28.7 64 0 92.7 0 128v64c0 8.8 7.4 15.7 15.7 18.6C34.5 217.1 48 235 48 256s-13.5 38.9-32.3 45.4C7.4 304.3 0 311.2 0 320v64c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V320c0-8.8-7.4-15.7-15.7-18.6C541.5 294.9 528 277 528 256s13.5-38.9 32.3-45.4c8.3-2.9 15.7-9.8 15.7-18.6V128c0-35.3-28.7-64-64-64H64zm64 112l0 160c0 8.8 7.2 16 16 16H432c8.8 0 16-7.2 16-16V176c0-8.8-7.2-16-16-16H144c-8.8 0-16 7.2-16 16zM96 160c0-17.7 14.3-32 32-32H448c17.7 0 32 14.3 32 32V352c0 17.7-14.3 32-32 32H128c-17.7 0-32-14.3-32-32V160z" />
																				</svg>
																			</Link>) : ''}
																		{isLoggedIn && (outlet.isFavouriteOutlet === false ? (
																			<a onClick={addToFavourite(outlet.encryptedClientId)} state={{ outlet: outlets }} id="addToFavourite" class="tab-link">
																				<svg width="24" height="24" viewBox="0 0 512.000000 512.000000" xmlns="http://www.w3.org/2000/svg">
																					<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill={isFavourite ? "#4A3749" : "#000000"} stroke="none">
																						<path
																							d="M1262 4830 c-319 -40 -586 -171 -812 -399 -203 -204 -325 -420 -395
																							-701 -124 -487 -34 -967 264 -1418 191 -289 438 -554 891 -958 288 -257 1167
																							-1007 1210 -1032 40 -24 55 -27 140 -27 85 0 100 3 140 27 43 25 924 776 1210
																							1032 455 406 700 670 891 958 298 451 388 931 264 1418 -70 281 -192 497 -395
																							701 -202 203 -418 320 -701 380 -142 30 -404 33 -528 5 -346 -75 -611 -248
																							-853 -556 l-28 -35 -27 35 c-239 302 -500 475 -833 551 -99 23 -327 33 -438
																							19z m334 -305 c284 -50 529 -214 723 -485 33 -47 74 -103 90 -126 74 -104 228
																							-104 302 0 16 23 57 79 90 126 265 370 634 544 1036 489 446 -61 794 -373 927
																							-832 105 -363 59 -744 -132 -1087 -160 -287 -427 -588 -892 -1005 -225 -201
																							-1171 -1015 -1180 -1015 -10 0 -952 811 -1180 1015 -715 641 -997 1041 -1065
																							1510 -44 303 19 629 172 886 230 387 678 599 1109 524z"
																							fill="#4A3749"
																						></path>
																					</g>
																				</svg>
																			</a>
																		) : (
																			<a onClick={ConfirmRemove(outlet.encryptedClientId)} id="removeFavourite" class="tab-link">
																				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
																					<path d="M12 4.435c-1.989-5.399-12-4.597-12 3.568 0 4.068 3.06 9.481 12 14.997 8.94-5.516 12-10.929 12-14.997 0-8.118-10-8.999-12-3.568z" fill="#000000" />
																				</svg>
																			</a>
																		))}
																	</li>
																</ul>
															</div>
														</div>
													</div>
													<div class="swipeout-actions-right">
														<Link to="#" class="swipeout-delete">
															<svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
																<path d="M21.707 6.40708L15.707 0.407082C15.5195 0.219611 15.2652 0.114296 15 0.114296C14.7348 0.114296 14.4805 0.219611 14.293 0.407082L0.293 14.4071C0.105451 14.5946 5.66374e-05 14.8489 0 15.1141V21.1141C0 21.3793 0.105357 21.6336 0.292893 21.8212C0.48043 22.0087 0.734784 22.1141 1 22.1141H7C7.26519 22.114 7.51951 22.0086 7.707 21.8211L21.707 7.82108C21.8945 7.63355 21.9998 7.37924 21.9998 7.11408C21.9998 6.84891 21.8945 6.59461 21.707 6.40708ZM6.586 20.1141H2V15.5281L12 5.52808L16.586 10.1141L6.586 20.1141ZM18 8.70008L13.414 4.11408L15 2.52808L19.586 7.11408L18 8.70008Z" fill="white" />
															</svg>
														</Link>
													</div>
												</li>
											)
										}) : outlets && IsTrue ?
											(<div class="simple-list">
												<ul>
													<li>No outlets available!</li>
												</ul>
											</div>) : ""
										}
									</ul>
								</div>
							</div>
						</div>
						{/* {outlets && outlets.length > 0 ?
							<div>
								<ReactPaginate
									pageCount={pageCount}
									pageRangeDisplayed={5}
									marginPagesDisplayed={0}
									onPageChange={handlePageClick}
									previousLabel={"Prev"}
									nextLabel={"Next"}
									breakLabel={"..."}
									containerClassName={"pagination"}
									activeClassName={"active"}
									forcePage={textSearch || currentPage === 1 ? 0 : currentPage - 1}
								/>
							</div>
							: ""}					 */}
							<Footer />
					</div>
				</div>
			</div>
			</>
			}	
		</>
	)
}

export default Outlets;