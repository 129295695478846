import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "../../../App.css";
import "../../../css/framework7-bundle.css";
import "../../../css/app.css";
import "../../../css/font-awesome/css/all.min.css";
import "../../../css/line-awesome/css/line-awesome.min.css";
import "../../../css/style.css";
import "../../../css/perfect-scrollbar.css";
import "../../../img/f7-icon-square.png";
import "../../../img/f7-icon.png";
import axios from "axios";
import { checkLoginStatus } from "../../../js/main.js";
import { getGreetings, checkUpdate } from "../../../utils/utilities.js";
import { ClientSettingAction } from "../../../actions";
import defaultOutlet from "../../../img/outlet-default.png";
import { showLoaderAction, activeMenuAction } from "../../../actions/index";
import "../Dashboard/clientDashboard.css";
import { useCatchHandler, showToast } from "../../../utils/utilities"
import { useNotification } from "../../../utils/utilities"
import { establishNotificationHubConnection } from "../../../utils/utilities"
import ClientFooter from "../../Master/ClientFooter";
import * as signalR from "@microsoft/signalr";
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { NotificationCountAction } from "../../../actions/index.js"
import { sendMail } from "../../../utils/utilities.js";
import ClientDashboardPlaceholder from "../../Placeholders/ClientDashboardPlaceholder.js";
import sandClockTimer from "../../../img/sand-clock-timer.png"

const { REACT_APP_MY_ENV, REACT_APP_TOKEN_HUB_URL } = process.env;

function Dashboard() {
  let token = localStorage.getItem("clientToken");
  let slug = localStorage.getItem("outletSlug");
  let qrUuid = localStorage.getItem("qrUuid");
  const catchHandle = useCatchHandler();
  const showLoaderReducer = useSelector(
    (state) => state.rootReducer.showLoaderReducer
  );
  const showLoader = showLoaderReducer.show;

  const [greetings, setGreetings] = useState([]);
  const [isOutletOpened, setIsOutletOpened] = useState(false);
  const [outletGoingToken, setOutletGoingToken] = useState("");
  const onGoingTokenRef = useRef(outletGoingToken);
  onGoingTokenRef.current = outletGoingToken;

  const [totalTokens, setTotalTokens] = useState(parseInt(0));
  const [tokenStatus, setTokenStatus] = useState();
  const [tokenIncrement, setTokenIncrement] = useState(0);
  const dispatch = useDispatch();
  const [logoImg, setLogoImg] = useState("");
  const [username, setUserName] = useState();
  const [userPhonenumber, setUserPhoneNumber] = useState();
  const [outletDetail, setOutletDetail] = useState();
  const [userId, setUserId] = useState("");
  const [userDataId, setUserDataId] = useState("");
  const [userTokenId, setUserTokenId] = useState("");
  const [remarkText, setRemarksText] = useState("");
  const [timeoutId, setTimeoutId] = useState(null);
  let [searchText, setSearchText] = useState();
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [userOrders, setUserOrders] = useState([])
  const [grandTotal, setGrandTotal] = useState([])
  const sendNotification = useNotification();
  let clientId = localStorage.getItem("encryptedClientId")
  const currentURL = window.location.href;

  let paramsData = {
    clientId: localStorage.getItem("encryptedClientId")
  }

  let objString = '?' + new URLSearchParams(paramsData).toString();

  const connection = new HubConnectionBuilder()
    .withUrl(REACT_APP_TOKEN_HUB_URL + objString)
    .configureLogging(LogLevel.Information)
    .build();

  useEffect(() => {
    checkUpdate();
    dispatch(activeMenuAction('outlet-dashboard'));
    checkLoginStatus("CLIENT");
    getClientSetting();
    getClientDetails();
    const greetings = getGreetings();
    setGreetings(greetings);

    establishNotificationHubConnection(objString, localStorage.getItem("encryptedClientId"), dispatch);
  }, []);

  useEffect(() => {
    getOutletStatus();
  }, [isOutletOpened,remarkText]);

  const establishHubConnection = async () => {
    try {
      const clientId = localStorage.getItem("encryptedClientId");
      let totalToken = totalTokens;
      let status = tokenStatus;
      await connection.start();
      if (connection.state === signalR.HubConnectionState.Connected) {
        connection.invoke("SetTotalToken", clientId, totalToken, status);
      } else {
        console.error("SignalR connection is not in the 'Connected' state.");
      }
    } catch (error) {
      console.error("SignalR connection error: ", error);
    }
  }

  connection.on("GetTotalToken", (clientId, updatedTotalTokens, status) => {
    if (updatedTotalTokens && updatedTotalTokens > 0) {
      setTotalTokens(updatedTotalTokens);
    }
    // setTokenStatus(status);
    if (updatedTotalTokens == 1) {
      setOutletGoingToken(1)
    }
    if (status === "Cancelled" || status === "InQueue") {
      setTokenStatus(status)
      getOutletStatus()
    }
    if (updatedTotalTokens === 1) {
      if (onGoingTokenRef.current === updatedTotalTokens) {
        //  notificationSendForYourTurn(false, updatedTotalTokens, userDataId);
      }
    }
  });

  let clientSettingReducer = useSelector(
    (state) => state.rootReducer.clientSettingReducer
  );
  var clientSetting = clientSettingReducer.ClientSetting;

  const getClientDetails = () => {
    const data = {
      EncryptedClientId: localStorage.getItem("encryptedClientId"),
    };
    // dispatch(showLoaderAction(true));
    axios.post(
      REACT_APP_MY_ENV + "/get-client-user/",
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    ).then(function (response) {
      if (response.data.status === 200) {
        dispatch(showLoaderAction(false));
        setLogoImg(response.data.data.user.logoFilePathURL);
        setOutletDetail(response.data.data.user);
        localStorage.setItem("qrUuid", response.data.data.user.qrUuid);
      } else {
        dispatch(showLoaderAction(false));
        showToast(response.data.message, "error");
      }
    }).catch(function (error) {
      catchHandle(error, "false", currentURL)
      dispatch(showLoaderAction(false));
      const data = {
        Subject: "Front end error from get-client-user",
        BodyText: error.message
      }
      sendMail(data);
      showToast("Something went wrong, please contact Administrator!", "error")
    });
  }

  const getOutletStatus = async () => {
    const response = await axios.get(
      REACT_APP_MY_ENV + "/client-token/" + slug,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    ).then(function (response) {
      if (response.data.status === 500) {
        showToast(response.data.message, "error");
      } else {
        establishHubConnection();
        setIsOutletOpened(response.data.data.isOutletOpened);
        setOutletGoingToken(response.data.data.onGoingToken);
        setTotalTokens(response.data.data.totalTokens);
        setTokenStatus(response.data.data.tokenStatus);
        setUserName(response.data.data.userName);
        setUserDataId(response.data.data.userId);
        setUserPhoneNumber(response.data.data.userPhoneNumber);
        setUserTokenId(response.data.data.userTokenId)
        setRemarksText(response.data.data.remark);
        dispatch(NotificationCountAction(response.data.data.notificationCount));
      }
    }).catch(function (error) {
      catchHandle(error, "false", currentURL)
      dispatch(showLoaderAction(false));
      const data = {
        Subject: "Front end error from client-token",
        BodyText: error.message
      }
      sendMail(data);
      showToast("Something went wrong, please contact Administrator!", "error")
    });
  };

  const updateOutletStatus = async () => {
    const data = {
      EncryptedClientId: localStorage.getItem("encryptedClientId"),
      IsClose: isOutletOpened === false ? false : true,
    };
    dispatch(showLoaderAction(true));
    const response = await axios.post(
      REACT_APP_MY_ENV + "/open-close-outlet/",
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    ).then(async function (response) {
      if (response.data.status === 500) {
        dispatch(showLoaderAction(false));
        showToast(response.data.message, "error");
      } else {
        await getOutletStatus();
        showToast(response.data.message, "success");
        dispatch(showLoaderAction(false));
      }
    }).catch(function (error) {
      dispatch(showLoaderAction(false));
      const data = {
        Subject: "Front end error from open-close-outlet",
        BodyText: error.message
      }
      sendMail(data);
      showToast("Something went wrong, please contact Administrator!", "error")
      catchHandle(error, "false", currentURL)
    });
  };

  const nextPreviousToken = async (isPrevious) => {
    if (isPrevious) {
      setTokenIncrement(tokenIncrement - 1);
    } else {
      setTokenIncrement(tokenIncrement + 1);
    }
    const requestData = {
      EncryptedClientId: localStorage.getItem("encryptedClientId"),
      IsPrevious: isPrevious,
    };
    // dispatch(showLoaderAction(true));
    const response = await axios.post(
      REACT_APP_MY_ENV + "/next-previous-token",
      requestData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    ).then(function (response) {
      if (response.data.status === 200) {
        dispatch(showLoaderAction(false));
        if (response.data.data.tokenStatus == 'InQueue') {
          setTimeout((tokenNumber) => {
            if (onGoingTokenRef.current === tokenNumber) {
              notificationSendForYourTurn(isPrevious, tokenNumber, response.data.data.userId);
            }
          }, 7000, response.data.data.onGoingToken);
        }
        setTimeout(() => {
          upComingNotification()
        }, 2000);
        setOutletGoingToken(response.data.data.onGoingToken);
        setTotalTokens(response.data.data.totalTokens);
        setUserId(response.data.data.userId);
        setUserDataId(response.data.data.userId);
        setUserName(response.data.data.name);
        setTokenStatus(response.data.data.tokenStatus)
        setUserPhoneNumber(response.data.data.phoneNumber);
        setUserTokenId(response.data.data.userTokenId)
        setRemarksText(response.data.data.remarks);
      }
    }).catch(function (error) {
      dispatch(showLoaderAction(false));
      const data = {
        Subject: "Front end error from next-previous-token",
        BodyText: error.message
      }
      sendMail(data);
      showToast("Something went wrong, please contact Administrator!", "error")
      catchHandle(error, "false", currentURL)
    });
  };

  const getClientSetting = async () => {
    let data = {
      encryptedClientId: localStorage.getItem("encryptedClientId"),
    };
    // dispatch(showLoaderAction(true));
    const res = await axios.post(
      REACT_APP_MY_ENV + "/get-client-setting",
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    ).then(function (res) {
      if (res.data.status === 200) {
        dispatch(showLoaderAction(false));
        dispatch(ClientSettingAction(res.data.data.allowPrevious));
        localStorage.setItem("ShowFilters", res.data.data.showFilters)
        localStorage.setItem("EnableMenu", res.data.data.enableMenu)
        localStorage.setItem("RemarksEnabled", res.data.data.remarksEnabled)
      }
    }).catch(function (error) {
      dispatch(showLoaderAction(false));
      const data = {
        Subject: "Front end error from get-client-setting",
        BodyText: error.message
      }
      sendMail(data);
      showToast("Something went wrong, please contact Administrator!", "error")
      catchHandle(error, "false", currentURL)
    });
  };

  const removeToken = async () => {
    let data = {
      OngoingToken: outletGoingToken,
      encryptedClientId: localStorage.getItem("encryptedClientId"),
    };
    // dispatch(showLoaderAction(true));
    const res = await axios.post(REACT_APP_MY_ENV + "/remove-token", data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }).then(function (res) {
      if (res.data.status === 200) {
        dispatch(showLoaderAction(false));
        notificationSendForCancelToken()
        showToast("Token marked as no-show", "success");
        var userDetail = res.data.data.userViewDetail;
        if (Object.keys(userDetail).length > 0 && userDetail.phoneNumber != null) {
          setOutletGoingToken(userDetail.ongoingToken);
          setTotalTokens(userDetail.totalTokens);
          setUserName(userDetail.name);
          setTokenStatus(userDetail.tokenStatus)
          setUserPhoneNumber(userDetail.phoneNumber);
          setUserTokenId(userDetail.userTokenId);
          if(tokenStatus === "InQueue" ){
            notificationSendForYourTurn(true, userDetail.ongoingToken, userDetail.userId)
          }
        } else {
          setTokenStatus("Removed")
        }
      } else {
        dispatch(showLoaderAction(false));
        showToast(res.data.message, "error");
      }
    }).catch(function (error) {
      catchHandle(error, "false", currentURL)
      dispatch(showLoaderAction(false));
      const data = {
        Subject: "Front end error from remove-token",
        BodyText: error.message
      }
      sendMail(data);
      showToast("Something went wrong, please contact Administrator!", "error")

    });
  };

  const completeToken = async () => {
    let data = {
      EncryptedClientId: localStorage.getItem("encryptedClientId"),
      phoneNumber : userPhonenumber
    };
    // dispatch(showLoaderAction(true));
    const res = await axios.post(REACT_APP_MY_ENV + "/complete-token", data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }).then(function (res) {
      if (res.data.status === 200) {
        dispatch(showLoaderAction(false));
        notificationSendForCompleteToken()
        showToast(res.data.message, "success");
        var userDetail = res.data.data.userViewDetail;
        if (Object.keys(userDetail).length > 0 && userDetail.phoneNumber != null) {
          setOutletGoingToken(userDetail.ongoingToken);
          setTotalTokens(userDetail.totalTokens);
          setUserName(userDetail.name);
          setTokenStatus(userDetail.tokenStatus)
          setUserPhoneNumber(userDetail.phoneNumber);
          setUserTokenId(userDetail.userTokenId);
          if(tokenStatus === "InQueue" ){
            notificationSendForYourTurn(true, userDetail.ongoingToken, userDetail.userId)
          }
        }
        else {
          setTokenStatus("Completed")
        }
      } else {
        dispatch(showLoaderAction(false));
        showToast(res.data.message, "error");
      }
    }).catch(function (error) {
      catchHandle(error, "false", currentURL)
      dispatch(showLoaderAction(false));
      const data = {
        Subject: "Front end error from complete-token",
        BodyText: error.message
      }
      sendMail(data);
      showToast("Something went wrong, please contact Administrator!", "error")
    });
  };

  const notificationSendForCompleteToken = () => {
    const data = {
      ClientId: localStorage.getItem("encryptedClientId"),
      UserId: userDataId,
      SentBy: "CLIENT",
      Title: "Token Completed",
      Description: `Your Token No. ${outletGoingToken} is marked as completed at ${localStorage.getItem("outletname")}`,
      RedirectURL: "/my-tokens",
    }
    sendNotification(data)
  }

  const notificationSendForCancelToken = () => {
    const data = {
      ClientId: localStorage.getItem("encryptedClientId"),
      UserId: userDataId,
      SentBy: "CLIENT",
      Title: "Token Cancelled",
      Description: `Your Token No. ${outletGoingToken} is cancelled by ${localStorage.getItem("outletname")}`,
      RedirectURL: "/my-tokens",
    }
    sendNotification(data)
  }

  const notificationSendForYourTurn = (isPrevious, token, userId) => {
    const data = {
      ClientId: localStorage.getItem("encryptedClientId"),
      UserId: userId ? userId : userDataId,
      SentBy: "CLIENT",
      Title: "Your Turn",
      Description: `Voila..! It is your turn now at ${localStorage.getItem("outletname")}`,
      RedirectURL: "/my-tokens",
    }
    sendNotification(data);
  }


  const handlePrevious = async () => {
    if (outletGoingToken > 0 && clientSetting !== false) {
      nextPreviousToken(true);
    }
  };

  const handleNext = async () => {
    if (outletGoingToken < totalTokens) {
      nextPreviousToken();
    }
  };

  const handleNoShow = async () => {
    if (totalTokens !== 0 && tokenStatus === "InQueue") {
      removeToken();
    }
  };

  const handleComplete = async () => {
    if (tokenStatus === "InQueue") {
      completeToken();
    }
  };

  const upComingNotification = async () => {
    let data = {
      ClientId: localStorage.getItem("encryptedClientId")
    };
    const res = await axios.post(REACT_APP_MY_ENV + "/send-upcoming-notifications", data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }).then(function (res) {
      if (res.data.status === 200) {
      } else {
        showToast(res.data.message, "error");
      }
    }).catch(function (error) {
      catchHandle(error, "false", currentURL)
      dispatch(showLoaderAction(false));
      const data = {
        Subject: "Front end error from send-upcoming-notifications",
        BodyText: error.message
      }
      sendMail(data);
      showToast("Something went wrong, please contact Administrator!", "error")
    });
  };

  const CustomTooltip = ({ text, children }) => {
    return (
      <div className="tooltip-container">
        {text && <span className="tooltip-text">{text}</span>}
        {children}
      </div>
    );
  }

  const onSearchResult = (e) => {
    setSearchText(e.target.value.trim())

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    setTimeoutId(setTimeout(() => {
      if (e.target.value && e.target.value != '')
        onSearchHandleClick(e.target.value.trim());
    }, 1500));
  };


  const onSearchHandleClick = async (searchValue) => {
    let requestData = {
      ClientId: localStorage.getItem("encryptedClientId"),
      SearchText: searchValue ?? searchText
    }

    // dispatch(showLoaderAction(true));
    const response = await axios.post(
      REACT_APP_MY_ENV + "/get-user-token-detail",
      requestData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    ).then(function (response) {
      if (response.data.status === 200) {
        dispatch(showLoaderAction(false));
        if (response.data.data.tokenStatus == 'InQueue') {
          setTimeout((tokenNumber) => {
            if (onGoingTokenRef.current === tokenNumber) {
              notificationSendForYourTurn('', tokenNumber, response.data.data.userId);
            }
          }, 7000, response.data.data.onGoingToken);
        }
        setTimeout(() => {
          upComingNotification()
        }, 2000);
        setOutletGoingToken(response.data.data.onGoingToken);
        setUserId(response.data.data.userId);
        setUserDataId(response.data.data.userId);
        setUserName(response.data.data.name);
        setTokenStatus(response.data.data.tokenStatus)
        setUserPhoneNumber(response.data.data.phoneNumber);
      } else {
        dispatch(showLoaderAction(false));
        showToast("Token not found!", "error")
      }
    }).catch(function (error) {
      dispatch(showLoaderAction(false));
      const data = {
        Subject: "Front end error from get-user-token-detail in outlet-dashboard",
        BodyText: error.message
      }
      sendMail(data);
      showToast("Something went wrong, please contact Administrator!", "error")
      catchHandle(error, "false", currentURL)
    });

  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (searchText && searchText != '') onSearchHandleClick();
      clearTimeout(timeoutId);
    }
  };

  const getUserOrder = () => {
    const data = {
      userTokenId: userTokenId.toString(),
    };
    axios.post(
      REACT_APP_MY_ENV + "/get-user-orders",
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    ).then(function (response) {
      if (response.data.status === 200) {
        dispatch(showLoaderAction(false));
        setPopoverOpen(!popoverOpen);
        setUserOrders(response.data.data.orderList);
        setGrandTotal(response.data.data.grandTotal)
      } else {
        dispatch(showLoaderAction(false));
        showToast("Order details not available!", "error");
      }
    }).catch(function (error) {
      catchHandle(error, "false", currentURL)
      dispatch(showLoaderAction(false));
      const data = {
        Subject: "Front end error from get-client-user",
        BodyText: error.message
      }
      sendMail(data);
      showToast("Something went wrong, please contact Administrator!", "error")
    });
  }

  const togglePopover = () => {
    if (localStorage.getItem("EnableMenu") == "true" && userTokenId) {
      getUserOrder()
    }
    else{
      showToast("Order details not available!", "error")
    }
  };

  return (
    <>
      {showLoader ? (
        <ClientDashboardPlaceholder greetings={greetings} />
      ) : (
        <div className="page page-home dashboard-page client-dashboard">
        
          <div className='containerCard'>
            <div className="dashboardHeader navbar-bg-theme">
              <Link className="notification-bar">
                <div class="info">
                  <span class="text text-white">{greetings}</span>
                  <h2 class="title text-white">{localStorage.getItem("outletname")}</h2>
                </div>
                <Link to="/outlet-view-profile">
                  <div class="media pt-">
                    <img
                      src={logoImg}
                      alt={localStorage.getItem("outletname")}
                      onError={(error) => {
                        error.target.src = defaultOutlet;
                      }}
                    />
                  </div>
                </Link>
              </Link>
            </div>
            <div className="page-content inner-page pt-10">
              <div class="container">
                <div class="filter-bx mb-10">
                  <form data-search-container=".search-list" data-search-in=".item-title" class="searchbar searchbar-init search-box list-search-bx margin-bottom-0">
                    <div class="searchbar-inner">
                      <div class="searchbar-input-wrap  searchbar-input-wrap-pad ">
                        <input type="text" autocomplete="off" className="date-daily-token-input " name="searchToken" placeholder="Search Token Number" onChange={onSearchResult} value={searchText} onKeyDown={handleKeyDown} />
                      </div>
                    </div>
                  </form>
                </div>

                <div className="section mb-10">
                  <div className="row mt-2">
                    <div className="col-4">
                      <div className="stat-box">
                        <div className="title">
                          <strong>Total</strong>
                        </div>
                        <div className="value text-success">{totalTokens}</div>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="stat-box">
                        <div className="title">
                          <strong>Running</strong>
                        </div>
                        <div className="value text-custom-blue">
                          {outletGoingToken}{" "}
                          {tokenStatus === "Completed" ? (
                            <CustomTooltip text="Completed">
                              <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" width="20" height="20" viewBox="0 0 256 256" xmlSpace="preserve">
                                <g
                                  style={{
                                    stroke: "none",
                                    strokeWidth: 0,
                                    strokeDasharray: "none",
                                    strokeLinecap: "butt",
                                    strokeLinejoin: "miter",
                                    strokeMiterlimit: 10,
                                    fill: "none",
                                    fillRule: "nonzero",
                                    opacity: 1,
                                  }}
                                  transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
                                >
                                  <path
                                    d="M 43.077 63.077 c -0.046 0 -0.093 -0.001 -0.14 -0.002 c -1.375 -0.039 -2.672 -0.642 -3.588 -1.666 L 23.195 43.332 c -1.84 -2.059 -1.663 -5.22 0.396 -7.06 c 2.059 -1.841 5.22 -1.664 7.06 0.396 l 12.63 14.133 l 38.184 -38.184 c 1.951 -1.952 5.119 -1.952 7.07 0 c 1.953 1.953 1.953 5.119 0 7.071 L 46.612 61.612 C 45.674 62.552 44.401 63.077 43.077 63.077 z"
                                    style={{
                                      stroke: "none",
                                      strokeWidth: 1,
                                      strokeDasharray: "none",
                                      strokeLinecap: "butt",
                                      strokeLinejoin: "miter",
                                      strokeMiterlimit: 10,
                                      fill: "rgb(0,165,16)",
                                      fillRule: "nonzero",
                                      opacity: 1,
                                    }}
                                    transform="matrix(1 0 0 1 0 0)"
                                    strokeLinecap="round"
                                  />
                                  <path
                                    d="M 45 90 C 20.187 90 0 69.813 0 45 C 0 20.187 20.187 0 45 0 c 2.762 0 5 2.239 5 5 s -2.238 5 -5 5 c -19.299 0 -35 15.701 -35 35 s 15.701 35 35 35 s 35 -15.701 35 -35 c 0 -2.761 2.238 -5 5 -5 s 5 2.239 5 5 C 90 69.813 69.813 90 45 90 z"
                                    style={{
                                      stroke: "none",
                                      strokeWidth: 1,
                                      strokeDasharray: "none",
                                      strokeLinecap: "butt",
                                      strokeLinejoin: "miter",
                                      strokeMiterlimit: 10,
                                      fill: "rgb(0,165,16)",
                                      fillRule: "nonzero",
                                      opacity: 1,
                                    }}
                                    transform="matrix(1 0 0 1 0 0)"
                                    strokeLinecap="round"
                                  />
                                </g>
                              </svg>
                            </CustomTooltip>
                          ) : tokenStatus === "Cancelled" ? (
                            <CustomTooltip text="Cancelled">
                              <svg xmlns="http://www.w3.org/2000/svg" fill={"#cd5c5c"} height="20px" className="d-flex-icon ongoingIcon" viewBox="0 0 512 512">
                                <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" />
                              </svg>
                            </CustomTooltip>
                          ) : tokenStatus === "Removed" ? (
                            <CustomTooltip text="No Show">
                              <svg xmlns="http://www.w3.org/2000/svg" fill={"#cd5c5c"} height="20px" viewBox="0 0 512 512">
                                <path d="M367.2 412.5L99.5 144.8C77.1 176.1 64 214.5 64 256c0 106 86 192 192 192c41.5 0 79.9-13.1 111.2-35.5zm45.3-45.3C434.9 335.9 448 297.5 448 256c0-106-86-192-192-192c-41.5 0-79.9 13.1-111.2 35.5L412.5 367.2zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z" />
                              </svg>
                            </CustomTooltip>
                          ) : tokenStatus === "InQueue" ? (
                            <CustomTooltip text="Running">
                              <img src={sandClockTimer} width="18px" height="18px" className="daily-token-inq-image" alt="" />
                            </CustomTooltip>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="wallet-card pd-12">
                        {
                          <Link className="button" state={{ outlet: outletDetail }} to={"/generate-token/" + qrUuid}>
                            <div className="icon-wrapper">
                              <svg fill={"#a890ff"} xmlns="http://www.w3.org/2000/svg" height="1.25em" viewBox="0 0 448 512">
                                <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                              </svg>
                            </div>
                          </Link>
                        }
                      </div>
                    </div>
                  </div>
                </div>

                <div className="section mb-10">
                  <div className="row mt-2">
                    <div className="col-6 w-55">
                      <div className="stat-box">
                        <div className="title">
                          <strong>Name</strong>
                        </div>
                        <div className="title">{username ? username : "No Name"}</div>
                      </div>
                    </div>
                    <div className="col-6 w-42">
                      <div className="stat-box">
                        <div className="title">
                          <strong>Phone</strong>
                        </div>
                        <div className="title">{userPhonenumber ? userPhonenumber : "-"}</div>
                      </div>
                    </div>
                  </div>
                </div>
                {remarkText ?
                <div className="section mb-10 mt-10">
                  <div className="row mt-2">
                    <div className="col-12">
                      <div className="stat-box">
                        <div className="title "><span className="bg-blue-ongoing-dash">{remarkText ? remarkText : "No remark"}</span></div>
                      </div>
                    </div>
                  </div>
                </div>:""
                    } 
                <div className="section wallet-card-section pt-1">
                  <div className="wallet-card">
                    <div className="wallet-footer">
                      <div className="item">
                        <Link
                          className={`${totalTokens === 0 || outletGoingToken === 1 ? "disabled" : ""}`}
                          to="#"
                          onClick={() => {
                            handlePrevious();
                          }}
                        >
                          <div className="icon-wrapper bg-warning card-overlay">
                            <svg xmlns="http://www.w3.org/2000/svg" fill={"#ffffff"} height="1.2em" viewBox="0 0 448 512">
                              <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
                            </svg>
                          </div>
                          <strong>Previous</strong>
                        </Link>
                      </div>
                      <div className="item">
                        <Link
                          className={`${totalTokens === 0 || outletGoingToken === totalTokens ? "disabled" : ""}`}
                          to="#"
                          onClick={() => {
                            handleNext();
                          }}
                        >
                          <div className="icon-wrapper card-overlay color-theme-blue">
                            <svg xmlns="http://www.w3.org/2000/svg" height="1.2em" fill={"#ffffff"} viewBox="0 0 448 512">
                              <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
                            </svg>
                          </div>
                          <strong>Next</strong>
                        </Link>
                      </div>
                      <div className="item">
                        <Link
                          to="#"
                          className={`${tokenStatus !== "InQueue" ? "disabled" : ""}`}
                          onClick={() => {
                            handleNoShow();
                          }}
                        >
                          <div className="icon-wrapper card-overlay color-theme-red">
                            <svg fill={"#ffffff"} xmlns="http://www.w3.org/2000/svg" height="1.2em" viewBox="0 0 512 512">
                              <path d="M367.2 412.5L99.5 144.8C77.1 176.1 64 214.5 64 256c0 106 86 192 192 192c41.5 0 79.9-13.1 111.2-35.5zm45.3-45.3C434.9 335.9 448 297.5 448 256c0-106-86-192-192-192c-41.5 0-79.9 13.1-111.2 35.5L412.5 367.2zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z" />
                            </svg>
                          </div>
                          <strong>No Show</strong>
                        </Link>
                      </div>
                      <div className="item">
                        <Link
                          to="#"
                          className={`${tokenStatus !== "InQueue" ? "disabled" : ""}`}
                          onClick={() => {
                            handleComplete();
                          }}
                        >
                          <div className="icon-wrapper card-overlay color-theme-green">
                            <svg fill={"#ffffff"} xmlns="http://www.w3.org/2000/svg" height="1.2em" viewBox="0 0 448 512">
                              <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                            </svg>
                          </div>
                          <strong>Completed</strong>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
                <div className="popover popover-menu modal-in popover-on-bottom popover-on-right" style={{ display: popoverOpen ? "block" : "none", top: "100px", left: "50%", transform: "translateX(-50%)" }}>
                  <div className="popover-arrow on-top"></div>
                  <div className="popover-angle"></div>
                  <div className="popover-inner">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col">
                          <div className="navbar">
                            <div className="navbar-inner justify-content-between">
                              <div className="title"><strong>Order Details</strong></div>
                              <div className="popover-close" onClick={() => setPopoverOpen(false)}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                  <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                                  <path d="M10 10l4 4m0 -4l-4 4" />
                                </svg>
                              </div>
                            </div>
                          </div>

                          <div className="containerCard stat-box stat-box-pad state-box-border mb-4">
                            <div className="page-content pt-0">
                              <div className="list media-list search-list searchbar-found menu-item-card search-list-bar daily-token-mb-1 daily-token-m-0 user-list">
                                <div className="item-link item-content title-bottom-border daily-token-mt-0">
                                  <div className="item-inner height-0">
                                    <div className="item-title-row item-details justify-content-between title-center-menu align-items-center">
                                      <h4 className="item-title title-font-size">Item</h4>
                                      <h4 className="item-title title-font-size">Total</h4>
                                    </div>
                                  </div>
                                </div>

                                <div className="scrollable">
                                  {userOrders && userOrders.length > 0 ? (
                                    userOrders.map((data, index) => (
                                      <ul key={index}>
                                        <li>
                                          <div className="item-link item-content menu-item-inner client-menu-list daily-token-mt-0">
                                            <div className="item-inner menu-item-inner">
                                              <div className="item-title-row item-details">
                                                <h6 className="item-title item-title-popup text-font-size">{data.itemName}</h6>
                                                <h6 className="item-title item-title-popup text-font-size">{data.quantity} x ₹ {data.rate}</h6>
                                                <h6 className="item-title item-title-popup text-font-size">₹ {data.quantity * data.rate}</h6>
                                              </div>
                                              {data.description && <div>
                                                <p className="description-title font-size-des">{data.description}</p>
                                              </div>}
                                            </div>
                                          </div>
                                        </li>
                                      </ul>
                                    ))
                                  ) : (
                                    <span id="no-users">Loading...</span>
                                  )}
                                </div>

                                <div className="grand-total-height">
                                  <div className="item-title-row item-details title-center-menu">
                                    <p className="item-title title-font-size grandtotal-margin"><strong>Total</strong></p>
                                    <div className="item-title title-font-size grandtotal-margin"><strong>₹ {grandTotal}</strong></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                { localStorage.getItem("EnableMenu") == "true" && userTokenId &&
                    <div className="container padding mb-60 mt-5">
                      <button className={`button-large button button-fill mr-10  gray-button button-fill view-order-btn`} onClick={togglePopover}>
                        View Order
                      </button>
                    </div>
                }
              {/* Outlet status button start */}
              <div className="toolbar toolbar-bottom container footer-button padding mb-50 mt-40">
                <button
                  className={`button-large button button-fill ${isOutletOpened && "outlet-btn"} mr-10`}
                  onClick={() => {
                    updateOutletStatus();
                  }}
                >
                  {isOutletOpened === false ? "Open Outlet" : "Close Outlet"}
                </button>
              </div>

              {/* Outlet status button end */}
              <ClientFooter />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Dashboard;
