import axios from 'axios';
import React from 'react'
import { useEffect } from 'react';
import { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import TimeAgo from "javascript-time-ago";
import ReactTimeAgo from "react-time-ago";
import en from "javascript-time-ago/locale/en.json";
import { useDispatch, useSelector } from "react-redux";
import { showLoaderAction } from "../../../actions/index";
import { useCatchHandler, showToast } from "../../../utils/utilities"
import { activeMenuAction } from "../../../actions/index";
import $ from "jquery";
import { uniqBy } from "lodash";
import { sendMail } from '../../../utils/utilities';
import ClientFooter from '../../Master/ClientFooter';
import ListPlaceholder from "../../Placeholders/ListPlaceholder.js"

TimeAgo.addLocale(en);

const { REACT_APP_MY_ENV } = process.env;

const ClientFeedBackList = () => {
	let token = localStorage.getItem("clientToken");
	const dispatch = useDispatch();
	const showLoaderReducer = useSelector((state) => state.rootReducer.showLoaderReducer)
	const showLoader = showLoaderReducer.show;
	const [feedBack, setFeedBack] = useState("");
	const [loading, setLoading] = useState(false);
	const [showLoading, setShowLoading] = useState(true)
	const catchHandle = useCatchHandler();

	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 10;
	const [totalFeedbackCount, setTotalFeedbackCount] = useState(0);
	const pageCount = Math.ceil(totalFeedbackCount / itemsPerPage);

	const [scrollPosition, setScrollPosition] = useState(0);
    const scrollRef = useRef(null);

	useEffect(() => {
		if (scrollRef.current) {
            scrollRef.current.scrollTop = scrollPosition;
        }
		dispatch(activeMenuAction('feedback-list'));
		FeedBackList()
	}, [feedBack]);

	const handleScroll = () => {
		const container = document.getElementById('scroll');
		if (container.scrollTop + container.clientHeight >= container.scrollHeight - 20 && !loading) {
			if (feedBack.length < totalFeedbackCount) {
				setScrollPosition(container.scrollTop);
				FeedBackList();
			}
		}
	};


	const handlePageClick = ({ selected }) => {
		setCurrentPage(selected + 1);
		FeedBackList(selected + 1)
	};
	const currentURL = window.location.href;
	const FeedBackList = async (pageNumber) => {
		setLoading(true)
		let data = {
			ClientId: localStorage.getItem("encryptedClientId"),
			pageNumber: pageNumber ? pageNumber : currentPage,
			pageSize: itemsPerPage
		};

		if(showLoading){
			dispatch(showLoaderAction(true));
		}

		axios.post(REACT_APP_MY_ENV + "/feedback-list", data, {
			headers: {
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			},
		}).then(function (res) {
			if (res.data.status === 200) {
				setTotalFeedbackCount(res.data.data.totalFeedbackCount)
				// setFeedBack(feedBack => [...feedBack, ...res.data.data.userFeedbackList]);
				setFeedBack(uniqBy([...feedBack, ...res.data.data.userFeedbackList], 'id'));
				setCurrentPage(currentPage => currentPage + 1);
				setLoading(false);
				setShowLoading(false)
				dispatch(showLoaderAction(false));
			} else {
				dispatch(showLoaderAction(false));
				setTimeout(function () {
					$("#no-reviews").html("No reviews yet!");
				}, 200);
			}
		}).catch(function (error) {
			catchHandle(error, "false", currentURL)
			dispatch(showLoaderAction(false));
			const data = {
				Subject: "Front end error from feedback-list",
				BodyText: error.message
			}
			sendMail(data);
			showToast("Something went wrong, please contact Administrator!", "error")
		});
	};

	return (
		<>	
           {showLoader ? (<ListPlaceholder showAvatar={false} showClientFooter={true} skeletonName='Reviews' />)
		   :
			<div class="page">
				<div className='containerCard'>
					<div class="navbar navbar-style-1">
						<div class="navbar-inner navbar-bg-theme">
							<Link to={-1} class="link back">
								<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M0.439312 13.0606L5.75391 18.3752C6.04683 18.6682 6.43069 18.8146 6.81459 18.8146C7.1985 18.8146 7.58236 18.6682 7.87528 18.3752C8.46103 17.7894 8.46103 16.8397 7.87528 16.2539L5.12133 13.5H22.5C23.3284 13.5 24 12.8284 24 12C24 11.1716 23.3284 10.5 22.5 10.5H5.12133L7.87528 7.7461C8.46103 7.1603 8.46103 6.21057 7.87528 5.62477C7.28944 5.03898 6.33975 5.03898 5.75391 5.62477L0.439312 10.9394C-0.146437 11.5251 -0.146437 12.4749 0.439312 13.0606Z" fill="white" />
								</svg>
							</Link>
							<div class="title text-white">Reviews</div>
						</div>
					</div>
					<div class="page-content inner-page pt-0" id="scroll" onScroll={() => handleScroll()} ref={scrollRef}>
						<div class="list media-list search-list searchbar-found search-list-bar review-list container">
							<ul>
								{feedBack && feedBack.length > 0 ?
									feedBack && feedBack.map((data, index) => {
										const createdAt = new Date(data.createdAt);
										const today = new Date();
										let CreatedAtDate;

										if (createdAt.toDateString() === today.toDateString()) {
											CreatedAtDate = <ReactTimeAgo date={createdAt} locale="en-US" />;
										} else {
											const formattedDate = createdAt.toLocaleString('en-US', {
												year: 'numeric',
												month: '2-digit',
												day: '2-digit',
												hour: '2-digit',
												minute: '2-digit',
												hour12: true,
											});
											CreatedAtDate = formattedDate;
										}
										return (
												<li className='listing review-list-padding' key={index}>
													<div class="item-link item-content">
														<div class="item-inner">
														<Link to={"/customer-detail/" + (data.userId)} state={{ userId: data.userId }} >
															<div class="item-title-row">
																<h6 class="item-title">{data.username ? data.username : "No Name"}</h6>
																<div class="item-after item-subtitle">
																	{CreatedAtDate}
																</div>
															</div>
															</Link>
															<div class="rating-info review-info">
															<Link to={"/customer-detail/" + (data.userId)} state={{ userId: data.userId }} >
																<ul id="review-star" className="rating-list review-star">
																	{[1, 2, 3, 4, 5].map((starIndex) => (
																		<li key={starIndex}>
																			<i
																				className={`fa-star ${starIndex > data.stars ? 'far' : 'fas'}`}
																			></i>
																		</li>
																	))}
																</ul>
																</Link>
																<div>
																	{/* {data.userPhoneNumber &&
																		<span class=" ">
																			<a className=" get_token_color tab-link Phone-icon-gt " href={`tel:${data.userPhoneNumber}`}>
																				<svg enable-background="new 0 0 507.983 507.983" height="18" fill="#777777" viewBox="0 0 507.950 507.950" width="20" xmlns="http://www.w3.org/2000/svg">
																					<g>
																						<path d="m200.75 148.678c11.79-27.061 5.828-58.58-15.03-79.466l-48.16-48.137c-15.999-16.19-38.808-23.698-61.296-20.178-22.742 3.34-42.496 17.4-53.101 37.794-23.286 43.823-29.276 94.79-16.784 142.817 30.775 121.9 198.319 289.559 320.196 320.104 16.452 4.172 33.357 6.297 50.33 6.326 32.253-.021 64.009-7.948 92.487-23.087 35.138-18.325 48.768-61.665 30.443-96.803-3.364-6.451-7.689-12.352-12.828-17.502l-48.137-48.16c-20.894-20.862-52.421-26.823-79.489-15.03-12.631 5.444-24.152 13.169-33.984 22.787-11.774 11.844-55.201-5.31-98.675-48.76s-60.581-86.877-48.876-98.698c9.658-9.834 17.422-21.361 22.904-34.007zm-6.741 165.397c52.939 52.893 124.14 88.562 163.919 48.76 5.859-5.609 12.688-10.108 20.155-13.275 9.59-4.087 20.703-1.9 28.028 5.518l48.137 48.137c5.736 5.672 8.398 13.754 7.157 21.725-1.207 8.191-6.286 15.298-13.645 19.093-33.711 18.115-73.058 22.705-110.033 12.836-104.724-26.412-260.078-181.765-286.489-286.627-9.858-37.009-5.26-76.383 12.86-110.126 3.823-7.318 10.924-12.358 19.093-13.552 1.275-.203 2.564-.304 3.856-.3 6.714-.002 13.149 2.683 17.869 7.457l48.137 48.137c7.407 7.321 9.595 18.421 5.518 28.005-3.153 7.516-7.652 14.394-13.275 20.294-39.804 39.686-4.18 110.817 48.713 163.918z" />
																					</g>
																				</svg>
																			</a>
																		</span>} */}

																	{/* {data.email &&
																		<span className='leftSide-email-token'>
																			<a className=" get_token_color tab-link Phone-icon-gt " href={`mailto:${data.email}`}>
																				<svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16"> <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" /> </svg>
																			</a>
																		</span>
																	} */}
																</div>
															</div>
															<p class="item-feedback-text">{data.message}</p>
															{/* <div>
														{data.userPhoneNumber &&
															<span class=" ">
																<a className=" get_token_color tab-link Phone-icon-gt " href={`tel:${data.userPhoneNumber}`}>
																	<svg enable-background="new 0 0 507.983 507.983" height="18" fill="#777777" viewBox="0 0 507.950 507.950" width="20" xmlns="http://www.w3.org/2000/svg">
																		<g>
																			<path d="m200.75 148.678c11.79-27.061 5.828-58.58-15.03-79.466l-48.16-48.137c-15.999-16.19-38.808-23.698-61.296-20.178-22.742 3.34-42.496 17.4-53.101 37.794-23.286 43.823-29.276 94.79-16.784 142.817 30.775 121.9 198.319 289.559 320.196 320.104 16.452 4.172 33.357 6.297 50.33 6.326 32.253-.021 64.009-7.948 92.487-23.087 35.138-18.325 48.768-61.665 30.443-96.803-3.364-6.451-7.689-12.352-12.828-17.502l-48.137-48.16c-20.894-20.862-52.421-26.823-79.489-15.03-12.631 5.444-24.152 13.169-33.984 22.787-11.774 11.844-55.201-5.31-98.675-48.76s-60.581-86.877-48.876-98.698c9.658-9.834 17.422-21.361 22.904-34.007zm-6.741 165.397c52.939 52.893 124.14 88.562 163.919 48.76 5.859-5.609 12.688-10.108 20.155-13.275 9.59-4.087 20.703-1.9 28.028 5.518l48.137 48.137c5.736 5.672 8.398 13.754 7.157 21.725-1.207 8.191-6.286 15.298-13.645 19.093-33.711 18.115-73.058 22.705-110.033 12.836-104.724-26.412-260.078-181.765-286.489-286.627-9.858-37.009-5.26-76.383 12.86-110.126 3.823-7.318 10.924-12.358 19.093-13.552 1.275-.203 2.564-.304 3.856-.3 6.714-.002 13.149 2.683 17.869 7.457l48.137 48.137c7.407 7.321 9.595 18.421 5.518 28.005-3.153 7.516-7.652 14.394-13.275 20.294-39.804 39.686-4.18 110.817 48.713 163.918z" />
																		</g>
																	</svg>
																</a>
															</span>}
													
															{data.email &&
																	<span className='leftSide-email-token'>
																		<a className=" get_token_color tab-link Phone-icon-gt " href={`mailto:${data.email }`}>
																			<svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16"> <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" /> </svg>
																		</a>
																	</span>
																}
													</div> */}
														</div>
													</div>
												</li>
										)
									})
									:
									<p id="no-reviews" className='noreviews-margin'>Loading...</p>
								}
							</ul>
						</div>
						{/* {feedBack && feedBack.length > 0 ?
							<div>
								<ReactPaginate
									pageCount={pageCount}
									pageRangeDisplayed={10}
									marginPagesDisplayed={0}
									onPageChange={handlePageClick}
									previousLabel={"Prev"}
									nextLabel={"Next"}
									breakLabel={"..."}
									containerClassName={"pagination"}
									activeClassName={"active"}
									forcePage={ currentPage === 1 ? 0 : currentPage - 1}
								/>
							</div>
							: ""} */}
							<ClientFooter /> 
					</div>
				</div>
			</div>
			}
		</>
	)
}
export default ClientFeedBackList