import React, { useEffect } from "react";

const AdsComponent = (props) => {
  const { dataAdSlot } = props;

  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) { }
  }, []);

  return (
    <>
      <div className="page page-home dashboard-page client-dashboard">
        <div className='containerCard'>
          <div className="dashboardHeader navbar-bg-theme">
          </div>
          <div className="page-content inner-page pt-10">
            <div className="container" style={{ border: "solid black 2px" }}>
              <ins className="adsbygoogle"
                style={{ display: "block" }}
                data-ad-client="ca-pub-2825343210090706"
                data-ad-slot="5403903674"
                data-ad-format="auto"
                data-full-width-responsive="true"
                data-adtest="on"
              ></ins>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdsComponent;
