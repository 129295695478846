import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import "../../../css/style.css";
import "../../../css/custom.css";
import "../../../css/font-awesome/css/all.min.css";
import "../../../css/line-awesome/css/line-awesome.min.css";
import axios from "axios";
import defaultOutlet from "../../../img/outlet-default.png";
import moment from "moment/moment";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useDispatch, useSelector } from "react-redux";
import { showLoaderAction, ClientMenuDetailAction} from "../../../actions/index";
import { sendMail, useCatchHandler } from "../../../utils/utilities"
import { useNotification, showToast} from "../../../utils/utilities"
import toast from "react-hot-toast";
import ListPlaceholder from "../../Placeholders/ListPlaceholder.js";
import useRazorPay from "react-razorpay";
import { v4 as uuidv4 } from "uuid";
import ClientMenu from "../../Client/ClientMenu/ClientMenu.js"
import SpinerLoader from "../../SpinerLoader/spinerLoader.js";

const { REACT_APP_MY_ENV, REACT_APP_RAZOR_PAY_KEY, REACT_APP_RAZOR_PAY_KEY_SECRET } = process.env;

function GetToken() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [outlet] = useState(location.state?.outlet);
  const showLoaderReducer = useSelector((state) => state.rootReducer.showLoaderReducer)
  const showLoader = showLoaderReducer.show;
  let token = localStorage.getItem("token");
  const [Razorpay] = useRazorPay();

  const [qrUuid, setQrUuid] = useState("");
  const [outletDetail, setOutletDetail] = useState("");
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [addMoney, setAddMoney] = useState(0);
  const [addMoneyInputShow, setAddMoneyInputShow] = useState(false);

  const [phoneNumberError, setPhoneNumberError] = useState({});
  const [addMoneyError, setAddMoneyError] = useState({});
  const [quantityError, setQuantityError] = useState({});
  const [showRazorPayLoader, setShowRazorPayLoader ] = useState(false); 
  const [remark, setRemark] = useState("");

  const catchHandle = useCatchHandler();
  const sendNotification = useNotification();

  const currentURL = window.location.href;

  const clientMenuDetailReducer = useSelector((state) => state.rootReducer.clientMenuDetailReducer)
  const clientMenuDetail = clientMenuDetailReducer.clientMenuDetail;
  const grandTotal = clientMenuDetailReducer.grandTotal;
  const comment = clientMenuDetailReducer.comment;

  useEffect(() => {
    if (outletDetail.userTokenNumber && outletDetail.userTokenNumber > 0) {
      navigate("/my-tokens")
    }
    const currentURL = window.location.href;
    const urlParts = currentURL.split('/');
    const qrUuid = urlParts[urlParts.length - 1];
    setQrUuid(qrUuid)
    GetTokenDetail(qrUuid)
      const isLogin = localStorage.getItem("isLogin");
      if (isLogin === 'true') {
      GetTokenDetail()
      setIsLoggedIn(true);
    }
  }, [outletDetail.userTokenNumber,outletDetail.totalTokens])

  useEffect(() => {
    document.querySelectorAll("input[dist-name='only-numeric']").forEach(function(input) {
      input.addEventListener("keypress", function(e) {
          var keyCode = e.which || e.keyCode;
          if (keyCode < 48 || keyCode > 57) {
              e.preventDefault();
          }
      });
    });
  })

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const createOrder = async () => {
    const orderId = uuidv4();
    const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");
    if (!res) {
      toast.error("Razorpay SDK failed to load, Are you online?");
      return;
    }
    let paymentRes = {
      UserId: parseInt(localStorage.getItem("encryptedUserId")),
      PhoneNumber: phoneNumber
        ? phoneNumber
        : localStorage.getItem("phoneNumber"),
      OrderId: orderId,
      Amount: addMoney,
      Currency: "INR",
    };

    const result = await axios.post(REACT_APP_MY_ENV + "/create-payment-order", paymentRes);
    if (result.data.status === 200) return result.data.data;
    else return false;
  };

  const getPaymentDetails = async (paymentId) => {
    const paymentData = { PaymentId: paymentId };
    const paymentDetails = await axios.post(REACT_APP_MY_ENV + "/payment-details", paymentData);
    if (paymentDetails.data.status === 200) return paymentDetails.data.data;
    else toast.error(paymentDetails.data.message);
  };

  const handleAddAndPay = async () => {
    if (AddMoneyFormValidation()) {
      setShowRazorPayLoader(true);
      const orderResult = await createOrder();
      if (orderResult) {
        setShowRazorPayLoader(false);
        const options = {
          key: REACT_APP_RAZOR_PAY_KEY,
          currency: orderResult.currency,
          amount: orderResult.amount,
          order_id: orderResult.payment_order_id,
          name: "Token लेलो",
          description: `${outletDetail.name} token purchase`,
          image: "https://api.tokenlelo.com/logo/TokenLelo-VLogo-Black.png",
          handler: async function (response) {
            if (response && response.razorpay_payment_id) {
              const result = await getPaymentDetails(response.razorpay_payment_id);
              const paymentData = {
                UserId: parseInt(localStorage.getItem("encryptedUserId")),
                ClientId: outletDetail.encryptedClientId,
                PaymentOrderId: orderResult.id,
                TransactionId: response.razorpay_payment_id, // razorpay_payment_id
                AddAmount: result.amount,
                FromRazorPay: true,
                // PaymentResponse: JSON.stringify(result),
                Status: result.status, // captured , authorized , failed
              };
              const paymentResult = await createPaymentTransaction(paymentData);
              if (paymentResult) {
                toast.success("Payment successful");
                await getToken();
                await GetTokenDetail();
              } else {
                toast.error("Payment unsuccessful");
              }
            } else {
              toast.error("Something went wrong while fetching payment details, please try again later");
            }
          },
          prefill: {
            contact: phoneNumber ? phoneNumber : localStorage.getItem("phoneNumber"),
            // email: "test321@mailinator.com",
          },
          notes: {
            address: "Razorpay",
          },
          theme: {
            color: "#655fff",
          },
        };
        let paymentObj = new window.Razorpay(options);
        paymentObj.on("payment.failed", async function (response) {
          const paymentFailedData = {
            UserId: parseInt(localStorage.getItem("encryptedUserId")),
            ClientId: outletDetail.encryptedClientId, 
            PaymentOrderId: orderResult.id, // TODO
            TransactionId: orderResult.receipt,  //response.razorpay_payment_id, // TODO
            AddAmount: addMoney,
            FromRazorPay: true,
            // PaymentResponse: response.error,
            Status: "failed", // captured , authorized , failed
          };
          const paymentFailedResult = await createPaymentTransaction(paymentFailedData);
          if(paymentFailedResult?.data?.status === 200){
            toast.error("Payment failed!");
          } else {
            toast.error("Something went wrong, please try again after sometime");
          }
        });
        paymentObj.open();
      } else {
        toast.error("Something went wrong, please try again later");
      }
    }
  };

  const createPaymentTransaction = async (paymentData) => {
    const paymentResult = await axios.post(REACT_APP_MY_ENV + "/create-wallet-transaction", paymentData);
    if (paymentResult.data.status === 200) return paymentResult.data.data;
    return false;
  };

  const handlePay = async () => {
    const payData = {
      UserId: parseInt(localStorage.getItem("encryptedUserId")),
      ClientId: outletDetail.encryptedClientId, 
      TransactionId: uuidv4(),  //response.razorpay_payment_id, // TODO
      FromRazorPay: false,
      // PaymentResponse: "Wallet dr transaction",
      Status: "captured", // captured , authorized , failed
    };
    const payResult = await createPaymentTransaction(payData);
    if (payResult) {
      toast.success("Payment successful");
      await getToken();
      await GetTokenDetail();
    } else {
      toast.error("Payment unsuccessful");
    }
  };

  const formValidation = () => {
    let phoneNumberError = {};

    let isValid = true;

    if (!phoneNumber) {
      phoneNumberError.phoneNumberEmpty = "Please enter the phone number";
      isValid = false;
    } else if (!/^(\+\d{1,3}[- ]?)?\d{10}$/.test(phoneNumber)) {
      phoneNumberError.phoneInvalid =
        "Invalid phone number, phone number should be of 10 digits";
      isValid = false;
    }

    if (outletDetail.enableMenu == true) {
      const allQuantitiesZero = clientMenuDetail.every(item => item.quantity === 0);
      if (allQuantitiesZero) {
        quantityError.allQuantitiesZero = "Please select atleast one item to get the token!";
        isValid = false;
      }
      setQuantityError(quantityError);
    }

    setPhoneNumberError(phoneNumberError);
    return isValid;
  };

  const formValidationLoggedIn = () => {
    let quantityError = {};

    let isValid = true;

    if (outletDetail.enableMenu == true) {
      const allQuantitiesZero = clientMenuDetail.every(item => item.quantity === 0);
      if (allQuantitiesZero) {
        quantityError.allQuantitiesZero = "Please select atleast one item to get the token!";
        isValid = false;
      }
      setQuantityError(quantityError);
    }

    return isValid;
  };

  const AddMoneyFormValidation = () => {
    let addMoneyError = {};

    let isValid = true;
    if (!addMoney) {
      addMoneyError.addMoneyEmpty = "Please enter amount";
      isValid = false;
    } else if ((outletDetail.tokenCost - outletDetail.walletBalance) > addMoney){
      addMoneyError.addMoneyInvalid = "Please enter valid amount";
        // "Amount must be greater or same as token cost";
      isValid = false;
    }

    setAddMoneyError(addMoneyError);
    return isValid;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (localStorage.getItem("isLogin")) {
      if(formValidationLoggedIn()) getToken();
    } else {
      if (formValidation()) getToken();
    }
  };

  const getToken = async () => {
    const data = {
      name: name ? name : localStorage.getItem("username"),
      phoneNumber: phoneNumber ? phoneNumber : localStorage.getItem("phoneNumber"),
      remarks: remark ? remark :"",
      isClient: false
    };
    dispatch(showLoaderAction(true));
    await axios.post(REACT_APP_MY_ENV + "/token/" + qrUuid, data).then(function (response) {
      if (response.data.status === 200) {
        dispatch(showLoaderAction(false));
        showToast(`You got Token No. ${response.data.data.token}`,"success");
        // localStorage.setItem("isLogin", true);
        // localStorage.setItem("token", response.data.data.authToken);
        localStorage.setItem("encryptedUserId", response.data.data.userId);
        localStorage.setItem("username", name ? name : localStorage.getItem("username") ? localStorage.getItem("username") : response.data.data.name);
        localStorage.setItem("phoneNumber", phoneNumber ? phoneNumber : localStorage.getItem("phoneNumber"));
        notificationSendForGetToken(response.data.data.token)
        if(outletDetail.enableMenu == true && response.data.data.userTokenId != 0){
          createUserOrder(response.data.data.clientId, response.data.data.userId, response.data.data.userTokenId)
        }
        navigate("/my-tokens")
      } else {
        dispatch(showLoaderAction(false));
        showToast(response.data.message, "error");
      }
    }).catch(function (error) {
      catchHandle(error, "true", currentURL)
      showToast("Something went wrong, please contact Administrator!","error")
      dispatch(showLoaderAction(false));
      const data = {
        Subject: "Front end error from token",
        BodyText: error.message
      }
      sendMail(data)
    });
  }
  function isValidUUID(uuid) {
    const uuidRegex = /^[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}$/;
    return uuidRegex.test(uuid);
  }
  
  function isValidQRUUID(qrUuid) {
    return isValidUUID(qrUuid) && !qrUuid.includes('*') && !qrUuid.includes('!@#');
  }
  const login = async () => {
    //  if (isValidQRUUID(qrUuid)) {
    const isValid = formValidation();
    if (isValid) {
      var data = JSON.stringify(phoneNumber);
      var config = {
        method: "post",
        url: REACT_APP_MY_ENV + "/user-login",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      dispatch(showLoaderAction(true));
      axios(config)
        .then(function (response) {
          if (response.data.status === 200) {
            dispatch(showLoaderAction(false));
            localStorage.setItem(
              "encryptedUserId",
              response.data.data.encryptedUserId
            );
            localStorage.setItem("isLogin", false);
            navigate("/verify-otp", { state: { userRegistered: true, name: name, phoneNumber: phoneNumber, clientId: outletDetail.encryptedClientId, qrUuid: qrUuid, clientMenuDetail: clientMenuDetailReducer, enableMenu: outletDetail.enableMenu } });
          }
          else if (response.data.status === 403) {
            register()
          } else {
            dispatch(showLoaderAction(false));
            showToast(response.data.message, "error");
          }
        })
        .catch(function (error) {
          catchHandle(error, "true", currentURL)
          showToast("Something went wrong, please contact Administrator!", "error")
          dispatch(showLoaderAction(false));
          const data={
            Subject: "Front end error from user-login",
            BodyText: error.message
          }
          sendMail(data);
        });
    }
  // } else {
  //   toast.error("Invalid Request!")
  //   navigate('/dashboard')
  // }
  };

  const register = async () => {
    const isValid = formValidation();
    if (isValid) {
      var data = JSON.stringify({ name, phoneNumber });
      var config = {
        method: "post",
        url: REACT_APP_MY_ENV + "/register-user",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      dispatch(showLoaderAction(true));
      axios(config)
        .then(function (response) {
          if (response.data.status === 200) {
            localStorage.setItem(
              "encryptedUserId",
              response.data.data
            );
            dispatch(showLoaderAction(false));
            navigate("/verify-otp", { state: { userRegistered: true, name: name, phoneNumber: phoneNumber, clientId: outletDetail.encryptedClientId, qrUuid: qrUuid,  clientMenuDetail: clientMenuDetailReducer, enableMenu: outletDetail.enableMenu } })
          } else {
            dispatch(showLoaderAction(false));
            showToast(response.data.message, "error");
          }
        })
        .catch(function (error) {
          catchHandle(error, "true", currentURL)
          showToast("Something went wrong, please contact Administrator!", "error")
          dispatch(showLoaderAction(false));
          const data={
            Subject: "Front end error from register-user",
            BodyText: error.message
          }
          sendMail(data);
        });
    }
  }

  const GetTokenDetail = async (qrUuidParam) => {
    const requestData = {
      encryptedUserId: localStorage.getItem("encryptedUserId"),
      qrUuid: qrUuidParam ? qrUuidParam : qrUuid
    }
    dispatch(showLoaderAction(true));
    let response = await axios.post(REACT_APP_MY_ENV + "/outlet/", requestData).then(function (response) {
      if (response.data.status === 200) {
        dispatch(showLoaderAction(false));
        setOutletDetail(response.data.data.outlet)
        if(response.data.data.outlet.status == 0)
        {
          showToast("Outlet is in-active, please contact Administor!","error")
        }
      }
    }).catch(function (error) {
      catchHandle(error, "true",currentURL)
      showToast("Something went wrong, please contact Administrator!", "error")
      dispatch(showLoaderAction(false));
      const data = {
        Subject: "Front end error from get token",
        BodyText: error.message
      }
      sendMail(data);
    });
  };

  const cancelToken = async (slug, tokenNumber) => {
    const outletSlug = slug;
    let data = {
      encryptedUserId: localStorage.getItem("encryptedUserId"),
      slug: slug
    };
    dispatch(showLoaderAction(true));
    const response = await axios.post(REACT_APP_MY_ENV + "/cancel-token/" + outletSlug, data, { headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" } }).then(function (response) {
      if (response.data.status == 500) {
        dispatch(showLoaderAction(false));
        showToast(response.data.message, "error");
      } else {
        GetTokenDetail()
        dispatch(showLoaderAction(false));
        notificationSendForCancelToken(tokenNumber);
        showToast(response.data.message, "success");
      }
    }).catch(function (error) {
      dispatch(showLoaderAction(false));
      showToast("Something went wrong, please contact Administrator!","error")
      catchHandle(error, "true", currentURL)
      const data = {
        Subject: "Front end error from cancel-token",
        BodyText: error.message
      }
      sendMail(data)
    });
  };

  const handleConfirmPay = async (e) => {
    confirmAlert({
      title: (
          <span className="confirm-title">Pay from Wallet</span>
      ),
      message: (<p>Confirm payment of <b>{outletDetail.tokenCost}₹</b>?</p>),
      buttons: [
        {
          label: "Yes",
          className: "gray-button",
          onClick: () => handlePay(),
        },
        {
          label: "Cancel",
          className: "gray-button",
        },
      ],
    });
  };

  const ConfirmRemove = (slug, tokenNumber) => async (e) => {
    confirmAlert({
      title: (
        <span className="confirm-title">
          Confirmation
        </span>
      ),
      message: "Are you sure you want to remove this token",
      buttons: [
        {
          label: 'Yes',
          className: 'red-button',
          onClick: () => cancelToken(slug, tokenNumber),
        },
        {
          label: 'Cancel',
          className: 'gray-button',
        }
      ]
    });
  };

  const notificationSendForGetToken = (tokenNumber) => {
    const data = {
      ClientId: outletDetail.encryptedClientId,
      UserId: localStorage.getItem("encryptedUserId"),
      SentBy: "USER",
      Title: "New Token",
      Description: `${localStorage.getItem("username")} - Token No. ${tokenNumber}`,
      RedirectURL: "/outlet-dashboard"
    }
    sendNotification(data)
  }

  const notificationSendForCancelToken = (tokenNumber) => {
    const data = {
      ClientId: outletDetail.encryptedClientId,
      UserId: localStorage.getItem("encryptedUserId"),
      SentBy: "USER",
      Title: "Token Cancelled",
      Description: `${localStorage.getItem("username")} - Token No. ${tokenNumber}`,
      RedirectURL: "/outlet-dashboard"
    }
    sendNotification(data)
  }

  const createUserOrder = async (clientId, userId, userTokenId) => {
    const data = {
      clientId: clientId,
      userId: userId,
      userTokenId: userTokenId,
      grandTotal: grandTotal,
      comment: comment,
      userOrderDetails: clientMenuDetail
    };
    dispatch(showLoaderAction(true));
    const response = await axios.post(REACT_APP_MY_ENV + "/create-user-order", data, { headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" } }).then(function (response) {
      if (response.data.status === 200) {
        dispatch(showLoaderAction(false));
        dispatch(ClientMenuDetailAction([]));
      } else {
        dispatch(showLoaderAction(false));
        showToast(response.data.message, "error");
        dispatch(ClientMenuDetailAction([]));
      }
    }).catch(function (error) {
      dispatch(showLoaderAction(false));
      showToast("Something went wrong, please contact Administrator!", "error")
      const data = {
        Subject: "Front end error from token",
        BodyText: error.message
      }
      sendMail(data)
    });
  }
  return (
    <>
      {showRazorPayLoader && (<SpinerLoader />)}
      {showLoader ? (<ListPlaceholder skeletonName={"Get Token"} noOfItem={1} showFooter={false} />)
        :
        <>
          <div class="page my-token-page commane-page">
            <div className="containerCard">
              <div class="navbar navbar-style-1 navbar-bg-theme">
                <div class="navbar-inner">
                  <Link to={-1} class="link back">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.439312 13.0606L5.75391 18.3752C6.04683 18.6682 6.43069 18.8146 6.81459 18.8146C7.1985 18.8146 7.58236 18.6682 7.87528 18.3752C8.46103 17.7894 8.46103 16.8397 7.87528 16.2539L5.12133 13.5H22.5C23.3284 13.5 24 12.8284 24 12C24 11.1716 23.3284 10.5 22.5 10.5H5.12133L7.87528 7.7461C8.46103 7.1603 8.46103 6.21057 7.87528 5.62477C7.28944 5.03898 6.33975 5.03898 5.75391 5.62477L0.439312 10.9394C-0.146437 11.5251 -0.146437 12.4749 0.439312 13.0606Z"
                        fill="white"
                      />
                    </svg>
                  </Link>
                  <div class="title text-white"> {outletDetail.userTokenNumber > 0 ? "Your token" : "Get Token"}</div>
                </div>
              </div>
              {outletDetail.status != 0 ?
                <div class="page-content inner-page pt-10">
                  <div class="list search-list mt-0 mb-0 container dz-list searchbar-found item-list">
                    <div class="m-ticket mb-1">
                      {outletDetail.outletStatus === 1 ? <p class="m-ticket-vt">Token<strong>{outletDetail.estimatedToken && outletDetail.userTokenNumber === null ? outletDetail.estimatedToken : outletDetail.userTokenNumber}</strong></p> : ""}
                      <div class="movie-details">
                        <img src={outletDetail.logoFilePathURL ? outletDetail.logoFilePathURL : defaultOutlet} alt={outletDetail.name} onError={(error) => { error.target.src = defaultOutlet }} class="poster" />
                        <div class="movie">
                          <h4 className="outlet-name">{outletDetail.name}</h4>
                          {outletDetail.outletStatus === 1 ? <>
                            <div className="OngoingToken">
                              {outletDetail.ongoingToken > 0
                                ? `Running Token`
                                : ""}
                              <strong>
                                {outletDetail.ongoingToken > 0
                                  ? `${outletDetail.ongoingToken}`
                                  : ""}
                              </strong>
                            </div>
                          </>
                            : <><p><i class="las la-clock"></i> {outletDetail.openTime ? moment(outletDetail.openTime, "hh:mm:ss").format("hh:mm A") : ""}
                              {outletDetail.closeTime ? " - " : ""}
                              {outletDetail.closeTime ? moment(outletDetail.closeTime, "hh:mm:ss").format("hh:mm A") : ""}</p> <p className="outlet-address">{outletDetail.address === "undefined" ? "" : outletDetail.address}</p></>}
                        </div>
                      </div>
                      {outletDetail.outletStatus === 1 && outletDetail.totalTokens > 0 ? (
                        <div class="info-cancel no-margin">
                          Your turn will be in approx. {outletDetail.approxServeMins ? outletDetail.approxServeMins : ""} mins
                        </div>
                      ) : (outletDetail.outletStatus === 0 ? (
                        <p class="info-cancel">Closed</p>
                      ) : (outletDetail.outletStatus === 1 && outletDetail.totalTokens == 0) && <div class="info-cancel no-margin">
                        Voila..! You are the first in line
                      </div>)}
                      {(!isLoggedIn && !outletDetail.outletStatus === 0) || (!isLoggedIn && outletDetail.outletStatus === 1) ? (
                        <ul class="row">
                          <li class="item-content col-100 item-input item-input-with-value mb-1">
                            <div class="item-inner">
                              <div class="item-input-wrap profile-font">
                                <input
                                  type="text"
                                  placeholder="Name (optional)"
                                  autocomplete="off"
                                  value={name}
                                  onChange={(e) => {
                                    setName(e.target.value);
                                  }}
                                  class="form-control"
                                />
                              </div>
                            </div>
                          </li>
                          <li class="item-content col-100 item-input">
                            <div class="item-inner">
                              <div class="item-input-wrap profile-font">
                                <input
                                  type="text"
                                  maxLength={10}
                                  autocomplete="off"
                                  placeholder="Phone Number"
                                  value={phoneNumber}
                                  onChange={(e) => {
                                    setPhoneNumber(e.target.value);
                                  }}
                                  onKeyPress={(e) => {
                                    const isNumeric = /^[0-9\b]+$/.test(e.key);

                                    if (!isNumeric) {
                                      e.preventDefault();
                                    }
                                  }}
                                  class="form-control"
                                />
                                {Object.keys(phoneNumberError).map((key) => {
                                  return (
                                    <div className="smalltext" style={{ color: "red" }}>
                                      {phoneNumberError[key]}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </li>
                          {outletDetail.remarksEnabled === true ? 
                          <li class="item-content col-100 item-input">
                            <div class="item-inner">
                              <div class="item-input-wrap profile-font">
                              <textarea id="remark"
                            rows="5"
                            // cols="30"
                            name="remark"
                            autocomplete="off"
                            value={remark}
                            maxLength="500"
                            placeholder="Enter remark..."
                            onChange={(e) => {
                              setRemark(e.target.value);
                              // handleChange(e);
                            }}
                            class="form-control mt-3 w-100" />
                              </div>
                            </div>
                          </li> : "" }
                        </ul>
                      ) : ""}

                      {(outletDetail.tokenCost === null || outletDetail.tokenCost === 0) ? null :
                        <div className="page-content inner-page pt-10">
                          <div class="container">
                            <div className="section mb-10">
                              <div className="row-12 mt-2">
                                <div className="col-6 w-40" style={{ textAlign: "center" }}>
                                  <div className="stat-box">
                                    <div className="title"><strong>Token Cost</strong></div>
                                    <div className="item-title item-label profile-font"><b>{outletDetail.tokenCost}₹</b></div>
                                  </div>
                                  <div className="stat-box">
                                    <div className="title"><strong>Wallet Balance</strong></div>
                                    <div className="item-title item-label profile-font"><b>{outletDetail.walletBalance !== null ? outletDetail.walletBalance : 0}₹</b></div>
                                  </div>
                                </div>
                                {outletDetail.walletBalance < outletDetail.tokenCost &&
                                  <div className="col-6 w-40">
                                    <div className="stat-box">
                                      {addMoneyInputShow &&
                                        <>
                                          <div className="stat-box">
                                            <div className="title item-title item-label profile-font"><strong>Add Money(₹)</strong></div>
                                            <div className="item-input-wrap profile-font mb-12">
                                              <input className="form-control" maxLength="4" dist-name="only-numeric" placeholder="Enter Amount" onChange={(e) => setAddMoney(e.target.value)} />
                                            </div>
                                            {Object.keys(addMoneyError).map((key) => {
                                              return (
                                                <div className="smalltext" style={{ color: "red" }}>
                                                  {addMoneyError[key]}
                                                </div>
                                              );
                                            })}
                                          </div>
                                        </>
                                      }
                                      <button type="submit" className="button-large button button-fill profile-font" onClick={() => { addMoneyInputShow ? handleAddAndPay() : setAddMoneyInputShow(true) }}
                                      >Add Money And Pay</button>
                                      {addMoneyInputShow &&
                                        <div className="stat-box">
                                          <button type="submit" className="button-large button button-fill profile-font" onClick={() => { setAddMoneyInputShow(false); setAddMoneyError({}) }} >Cancel</button>
                                        </div>
                                      }
                                    </div>
                                  </div>
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      }

                      <div class="total-amount">
                        {outletDetail.outletStatus === 1 && (outletDetail.userTokenNumber === 0 || outletDetail.userTokenNumber === null) &&
                          <>
                            {(outletDetail.tokenCost !== null && outletDetail.tokenCost > 0) && (outletDetail.walletBalance >= outletDetail.tokenCost) &&
                              <p className="pl-15 link text-theme pr-20" onClick={() => !isLoggedIn ? login() : handleConfirmPay()}> Pay</p>
                            }
                            {/* {(outletDetail.tokenCost === null || outletDetail.tokenCost <= 0) &&
                              <p class="pl-15 link text-theme" onClick={!isLoggedIn ? login : onSubmit}> Get Token</p>
                            } */}
                          </>
                        }
                        {outletDetail.outletStatus !== 1 ?
                          <>
                            <p class="pr-15 ms-1 get_token_color tab-link Phone-icon-gt">
                              <a className="m-auto get_token_color" href={`tel:${outletDetail.phoneNumber}`} >
                                <svg enable-background="new 0 0 507.983 507.983" height="11" fill="#777777" viewBox="0 0 507.983 507.983" width="22" xmlns="http://www.w3.org/2000/svg">
                                  <g>
                                    <path d="m200.75 148.678c11.79-27.061 5.828-58.58-15.03-79.466l-48.16-48.137c-15.999-16.19-38.808-23.698-61.296-20.178-22.742 3.34-42.496 17.4-53.101 37.794-23.286 43.823-29.276 94.79-16.784 142.817 30.775 121.9 198.319 289.559 320.196 320.104 16.452 4.172 33.357 6.297 50.33 6.326 32.253-.021 64.009-7.948 92.487-23.087 35.138-18.325 48.768-61.665 30.443-96.803-3.364-6.451-7.689-12.352-12.828-17.502l-48.137-48.16c-20.894-20.862-52.421-26.823-79.489-15.03-12.631 5.444-24.152 13.169-33.984 22.787-11.774 11.844-55.201-5.31-98.675-48.76s-60.581-86.877-48.876-98.698c9.658-9.834 17.422-21.361 22.904-34.007zm-6.741 165.397c52.939 52.893 124.14 88.562 163.919 48.76 5.859-5.609 12.688-10.108 20.155-13.275 9.59-4.087 20.703-1.9 28.028 5.518l48.137 48.137c5.736 5.672 8.398 13.754 7.157 21.725-1.207 8.191-6.286 15.298-13.645 19.093-33.711 18.115-73.058 22.705-110.033 12.836-104.724-26.412-260.078-181.765-286.489-286.627-9.858-37.009-5.26-76.383 12.86-110.126 3.823-7.318 10.924-12.358 19.093-13.552 1.275-.203 2.564-.304 3.856-.3 6.714-.002 13.149 2.683 17.869 7.457l48.137 48.137c7.407 7.321 9.595 18.421 5.518 28.005-3.153 7.516-7.652 14.394-13.275 20.294-39.804 39.686-4.18 110.817 48.713 163.918z" />
                                  </g>
                                </svg>
                                Call
                              </a>
                            </p>
                            {outletDetail.outletStatus == 0 && outletDetail.address === null ? "" :
                              <p class="pr-15"> <a target="_blank" href={`https://www.google.com/maps?q=${outletDetail.address}`} class="tab-link get_token_color">
                                <svg width="24" height="11" viewBox="0 0 24 24" fill="#777777" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M11.9993 5.48404C9.59314 5.48404 7.64258 7.4346 7.64258 9.84075C7.64258 12.2469 9.59314 14.1975 11.9993 14.1975C14.4054 14.1975 16.356 12.2469 16.356 9.84075C16.356 7.4346 14.4054 5.48404 11.9993 5.48404ZM11.9993 12.0191C10.7962 12.0191 9.82096 11.0438 9.82096 9.84075C9.82096 8.6377 10.7962 7.66242 11.9993 7.66242C13.2023 7.66242 14.1776 8.6377 14.1776 9.84075C14.1776 11.0438 13.2023 12.0191 11.9993 12.0191Z" />
                                  <path d="M21.793 9.81896C21.8074 4.41054 17.4348 0.0144869 12.0264 5.09008e-05C6.61797 -0.0143851 2.22191 4.35827 2.20748 9.76664C2.16044 15.938 5.85106 21.5248 11.546 23.903C11.6884 23.9674 11.8429 24.0005 11.9991 24C12.1565 24.0002 12.3121 23.9668 12.4555 23.9019C18.1324 21.5313 21.8191 15.9709 21.793 9.81896ZM11.9992 21.7127C7.30495 19.646 4.30485 14.9691 4.38364 9.84071C4.38364 5.63477 7.79323 2.22518 11.9992 2.22518C16.2051 2.22518 19.6147 5.63477 19.6147 9.84071V9.91152C19.6686 15.0154 16.672 19.6591 11.9992 21.7127Z" />
                                </svg>
                                Get Directions
                              </a>
                              </p>}
                          </>
                          :
                          outletDetail.userTokenNumber > 0 &&
                          <>
                            <p class="pl-15"><i class="las la-clock" /> In-Queue</p>
                            <p class="pr-15 link text-danger" onClick={ConfirmRemove(outletDetail.slug, outletDetail.userTokenNumber)}>Cancel</p>
                          </>
                        }
                      </div>
                    </div>
                  </div>
        <div className='client-menu-card'>
        <div className="page-content inner-page  mb-0 pt-0">
            <div class="container mb-0 ">
                  <div className="">
                    <div className="">
                      {outletDetail.enableMenu == true && <ClientMenu clientId={outletDetail.encryptedClientId} />}
                    </div>
                    {Object.keys(quantityError).map((key) => {
                      return (
                        <div style={{ color: "red" }}>
                          {quantityError[key]}
                        </div>
                      );
                    })}
                  {/* </div> */}
                  </div>
                  </div>
                  </div>
                  </div>
                  {outletDetail.outletStatus === 1 && (outletDetail.userTokenNumber === 0 || outletDetail.userTokenNumber === null) &&
                    <>
                      {(outletDetail.tokenCost === null || outletDetail.tokenCost <= 0) &&
                      <div className="container ">
                        <div className={`clearfix profile-font ${outletDetail.enableMenu == true ? "mt-10" : "mt-5"}`}>
                          <button
                            type="submit" id="yes"
                            className="button-large button button-fill profile-font"
                            onClick={!isLoggedIn ? login : onSubmit}
                          >
                            Get Token
                          </button>
                        </div>
                        </div>
                      }
                    </>
                  }
                </div>
                :
                (<div class="simple-list">
                  <ul>
                    <li>Outlet is in-active</li>
                  </ul>
                </div>)
              }
            </div>
          </div>
        </>
      }
    </>
  );
}

export default GetToken;
