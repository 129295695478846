import React from "react";
import { Link } from "react-router-dom";
import ClientFooter from "../Master/ClientFooter";

const ClientDashboardPlaceholder = ({ greetings = "Good Morning" }) => {

  return (
    <>
      <div className="page page-home dashboard-page client-dashboard">
        <div className="containerCard">
          <div className="dashboardHeader navbar-bg-theme">
            <Link className="notification-bar">
              <div class="info">
                <span class="text text-white">{greetings}</span>
                <h2 class="title text-white">
                  {localStorage.getItem("outletname")}
                </h2>
              </div>
              <Link to="javascript:void(0)">
                <div class="media pt">
                 
                  <img
                    style={{ background: "#cccccc" }}
                    className="skeleton-text skeleton-effect-wave"
                    // src={logoImg}
                    // alt={localStorage.getItem("outletname")}
                    // onError={(error) => {
                    //   error.target.src = defaultOutlet;
                    // }}
                  />
                </div>
              </Link>
            </Link>
          </div>
          <div className="page-content inner-page pt-10">
            <div class="container">
              <div className="section mb-10">
                <div className="row mt-2">
                  <div className="col-4">
                    <div className="stat-box">
                      {/* <div
                        class="skeleton-text skeleton-effect-wave"
                        style={{ marginLeft: "0px", marginRight: "0px" }}
                      >
                        <div class="card-header">Card Header</div>
                        <div class="card-content card-content-padding">
                          Lorem
                        </div>
                      </div> */}
                      <div className="title skeleton-text skeleton-effect-wave">
                        <strong>Total</strong>
                      </div>
                      <div className="value skeleton-text skeleton-effect-wave">
                        0
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="stat-box">
                      <div className="title skeleton-text skeleton-effect-wave">
                        <strong>Running</strong>
                      </div>
                      <div className="value skeleton-text skeleton-effect-wave">
                        0
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="wallet-card pd-12">
                      <Link
                        className="button skeleton-text skeleton-effect-wave"
                        to="javascript:void(0)"
                      >
                        <div className="icon-wrapper">
                          {/* <svg
                            fill={"#a890ff"}
                            xmlns="http://www.w3.org/2000/svg"
                            height="1.25em"
                            viewBox="0 0 448 512"
                          >
                            <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                          </svg> */}
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className="section mb-10">
                <div className="row mt-2">
                  <div className="col-6 w-55">
                    <div className="stat-box">
                      <div className="title skeleton-text skeleton-effect-wave">
                        <strong>Name text</strong>
                      </div>
                      <div className="title skeleton-text skeleton-effect-wave">
                        text
                      </div>
                    </div>
                  </div>
                  <div className="col-6 w-42">
                    <div className="stat-box">
                      <div className="title skeleton-text skeleton-effect-wave">
                        <strong>Name text</strong>
                      </div>
                      <div className="title skeleton-text skeleton-effect-wave">
                        text
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="section wallet-card-section pt-1">
                <div className="wallet-card">
                  <div className="wallet-footer">
                    <div className="item">
                      <Link
                        // className={`${
                        //   totalTokens === 0 ||
                        //   outletGoingToken === 1 ||
                        //   isOutletOpened === false
                        //     ? "disabled"
                        //     : ""
                        // }`}
                        to="#"
                        // onClick={() => {
                        //   handlePrevious();
                        // }}
                      >
                        <div style={{backgroundColor: "#cccccc"}} className="icon-wrapper card-overlay skeleton-text skeleton-effect-wave">
                          {/* <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill={"#ffffff"}
                            height="1.2em"
                            viewBox="0 0 448 512"
                          >
                            <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
                          </svg> */}
                        </div>
                        <strong className="skeleton-text skeleton-effect-wave">
                          Previous
                        </strong>
                      </Link>
                    </div>
                    <div className="item">
                      <Link
                        // className={`${
                        //   totalTokens === 0 ||
                        //   outletGoingToken === totalTokens ||
                        //   isOutletOpened === false
                        //     ? "disabled"
                        //     : ""
                        // }`}
                        to="#"
                        // onClick={() => {
                        //   handleNext();
                        // }}
                      >
                        <div style={{backgroundColor: "#cccccc"}} className="icon-wrapper card-overlay skeleton-text skeleton-effect-wave">
                          {/* <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="1.2em"
                            fill={"#ffffff"}
                            viewBox="0 0 448 512"
                          >
                            <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
                          </svg> */}
                        </div>
                        <strong className="skeleton-text skeleton-effect-wave">
                          Next
                        </strong>
                      </Link>
                    </div>
                    <div className="item">
                      <Link
                        to="#"
                        // className={`${
                        //   tokenStatus !== "InQueue" || isOutletOpened === false
                        //     ? "disabled"
                        //     : ""
                        // }`}
                        // onClick={() => {
                        //   handleNoShow();
                        // }}
                      >
                        <div style={{backgroundColor: "#cccccc"}} className="icon-wrapper card-overlay skeleton-text skeleton-effect-wave">
                          {/* <svg
                            fill={"#ffffff"}
                            xmlns="http://www.w3.org/2000/svg"
                            height="1.2em"
                            viewBox="0 0 512 512"
                          >
                            <path d="M367.2 412.5L99.5 144.8C77.1 176.1 64 214.5 64 256c0 106 86 192 192 192c41.5 0 79.9-13.1 111.2-35.5zm45.3-45.3C434.9 335.9 448 297.5 448 256c0-106-86-192-192-192c-41.5 0-79.9 13.1-111.2 35.5L412.5 367.2zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z" />
                          </svg> */}
                        </div>
                        <strong className="skeleton-text skeleton-effect-wave">
                          No Show
                        </strong>
                      </Link>
                    </div>
                    <div className="item">
                      <Link
                        to="#"
                        // className={`${
                        //   tokenStatus !== "InQueue" || isOutletOpened === false
                        //     ? "disabled"
                        //     : ""
                        // }`}
                        // onClick={() => {
                        //   handleComplete();
                        // }}
                      >
                        <div style={{backgroundColor: "#cccccc"}} className="icon-wrapper card-overlay skeleton-text skeleton-effect-wave">
                          {/* <svg
                            fill={"#ffffff"}
                            xmlns="http://www.w3.org/2000/svg"
                            height="1.2em"
                            viewBox="0 0 448 512"
                          >
                            <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                          </svg> */}
                        </div>
                        <strong className="skeleton-text skeleton-effect-wave">
                          Completed
                        </strong>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Outlet status button start */}
            <div className="toolbar toolbar-bottom container footer-button padding mb-50">
              <button className={`button-large button button-fill`}>
                <span className="skeleton-text skeleton-effect-wave">
                  Open Outlet
                </span>
              </button>
            </div>

            {/* Outlet status button end */}
            <ClientFooter />
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientDashboardPlaceholder;
