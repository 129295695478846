import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import $ from 'jquery';
import "../../../css/framework7-bundle.css";
import "../../../css/app.css";
import "../../../css/font-awesome/css/all.min.css";
import "../../../css/line-awesome/css/line-awesome.min.css";
import "../../../css/style.css";
import "../../../css/perfect-scrollbar.css";
import "../../../img/f7-icon-square.png";
import "../../../img/f7-icon.png";
import "../../../css/custom.css";
import axios from "axios";
import jsQR from "jsqr";
import { useCatchHandler, showToast } from "../../../utils/utilities"
import { useDispatch, useSelector } from "react-redux";
import { showLoaderAction } from "../../../actions/index";
import SpinerLoader from "../../SpinerLoader/spinerLoader";
import { uniqBy } from "lodash";
import { UpiIdAction, ClientDataAction } from "../../../actions/index";
import tokenlogo from "../../../img/logo-new.png";
import { sendMail } from "../../../utils/utilities";
const { REACT_APP_WEBAPP_URL, REACT_APP_MY_ENV } = process.env;

function ClientSignUp() {
  const catchHandle = useCatchHandler();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [qrUuid, setQrUuid] = useState("");
  const showLoaderReducer = useSelector((state) => state.rootReducer.showLoaderReducer)
  const showLoader = showLoaderReducer.show;
  const [outlet, setOutlet] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [noOfDevice, setNoOfDevice] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [openTime, setOpenTime] = useState('10:00');
  const [closeTime, setCloseTime] = useState("19:00");
  const [address, setAddress] = useState("");
  const [password, setPassword] = useState("");
  const [confPassword, setConfPassword] = useState("");
  const [file, setFile] = useState(undefined);
  const [qrFile, setQrFile] = useState(undefined);
  const [img, setImg] = useState("");
  const [qrFileImg, setQrFileImg] = useState("");
  const [approxMin, setApproxMin] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [planList, setPlanList] = useState([]);
  const [planId, setPlanId] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [agentCode, setAgentCode] = useState("");
  const [paymentMode, setPaymentMode] = useState("");
  const [upiIds, setUpiIds] = useState([]);
  const [upiIdStatic, setUpiIdStatic] = useState("");
  const [paidAmount, setPaidAmount] = useState("");

  const [transactionError, setTransactionError] = useState({});
  // const [upiStaticIdError, setUpiStaticIdError] = useState({});
  const [paymentModeError, setPaymentModeError] = useState({})
  const [planError, setPlanError] = useState({});
  const [agentCodeError, setAgentCodeError] = useState({});
  const [categoryErr, setCategoryErr] = useState({});
  const [outletError, setOutletError] = useState({});
  const [qrUuidError, setQrUuidError] = useState({});
  const [logoFileImgError, setLogoFileImgError] = useState({});
  const [noOfDeviceError, setNoOFDeviceError] = useState({});
  const [qrFileImgError, setQrFileImgError] = useState({});
  const [phoneNumberError, setPhoneNumberError] = useState({});
  const [approxMinError, setApproxMinError] = useState({});
  const [emailAddressError, setEmailAddressError] = useState({});
  const [passwordError, setPasswordError] = useState({});
  const [confPasswordError, setConfPasswordError] = useState({});
  const [paidAmountError, setPaidAmountError] = useState({});

  const [clientUpiId] = useState(location.state?.clientUpiId);
  const [clientUpiIdName] = useState(location.state?.clientUpiIdName);
  const validLogoFileExtensions = ['jpg', 'jpeg', 'png'];
  const validQrFileExtensions = ['jpg', 'jpeg', 'png', 'svg'];
  const paymentModeList = ['Cash', 'Online'];

  const clientUpiDetailReducer = useSelector((state) => state.rootReducer.clientUpiDetailReducer);
  var clientUpiDetail = clientUpiDetailReducer.UpiDetail;

  const clientDataReducer = useSelector((state) => state.rootReducer.clientDataReducer);
  var clientData = clientDataReducer.clientData;

  useEffect(() => {
    if (clientUpiId) {
      const newUpiDetail = {
        upiId: clientUpiId,
        upiName: clientUpiIdName,
      };
      const updatedUpiIds = uniqBy([...clientUpiDetail, newUpiDetail]);
      setUpiIds(updatedUpiIds);
      dispatch(UpiIdAction(updatedUpiIds));
    }
    getCategoryList()
    getPlanList()
  }, [])

  // const validateUPIId = (upiIdStatic) => {
  //   const isValidFormat = upiIdStatic && /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(upiIdStatic);
  //   return isValidFormat;
  // };

  const formValidation = () => {
    let outletError = {};
    let qrUuidError = {};
    let phoneNumberError = {};
    let emailAddressError = {};
    let qrFileImgError = {};
    let passwordError = {};
    let confPasswordError = {};
    let categoryErr = {};
    let logoFileImgError = {};
    let planError = {};
    let paymentModeError = {};
    let transactionError = {};
    let agentCodeError = {};
    let approxMinError = {};
    let paidAmountError = {};
    // let upiIdError = {};
    let isValid = true;
    const maxFileSizeInBytes = 2 * 1024 * 1024;

    if (!clientData.outletName) {
      outletError.outletEmpty = "Please enter outlet name";
      isValid = false;
    }

    if (!clientData.approxMin) {
      approxMinError.approxMinEmpty = "Please enter Approx Serve Minutes";
      isValid = false;
    }
    else if (parseInt(clientData.approxMin) <= 0) {
      approxMinError.approxMinEmpty = "Approx Serve Minutes should be greated than zero(0)";
      isValid = false;
    }

    if (!clientData.phoneNumber) {
      phoneNumberError.phoneNumberEmpty = "Please enter phone number";
      isValid = false;
    } else if (!/^(\+\d{1,3}[- ]?)?\d{10}$/.test(clientData.phoneNumber)) {
      phoneNumberError.phoneInvalid =
        "Please enter valid phone number.";
      isValid = false;
    }

    if (!clientData.categoryId) {
      categoryErr.empty = "Please select category";
      isValid = false;
    }

    if (clientData.emailAddress) {
      if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(clientData.emailAddress)) {
        emailAddressError.emailInvalid = "Please enter valid email address";
        isValid = false;
      }
    }

    if (!clientData.qrcode) {
      qrFileImgError.qrFileImgErrorEmpty = "Please upload qr code";
      isValid = false;
    }
    else if (!validQrFileExtensions.includes(clientData.qrcode.name.split('.').pop().toLowerCase())) {
      qrFileImgError.qrFileImgInvalid = "Invalid file extension. Please upload a file with extensions jpg, jpeg, png, or svg"
      isValid = false;
    }

    if (!clientData.password) {
      passwordError.passwordEmpty =
        "Please enter password";
      isValid = false;
    }

    if (clientData.logo) {
      if (!validLogoFileExtensions.includes(clientData.logo.name.split('.').pop().toLowerCase())) {
        logoFileImgError.logoFileImgInvalid = "Invalid file extension. Please upload a file with extensions jpg, jpeg, png"
        isValid = false;
      }
      if (clientData.logo.size > maxFileSizeInBytes) {
        logoFileImgError.logoFileImgSize = "File size exceeds the maximum allowed 2 MB";
        isValid = false;
      }
    }
    if (!clientData.confirmPassword) {
      confPasswordError.confPasswordEmpty =
        "Please enter confirm password";
      isValid = false;
    } else if (clientData.password != clientData.confirmPassword) {
      confPasswordError.confPasswordNotMatch =
        "Confirm password does not match";
      isValid = false;
    }

    if (!clientData.planId) {
      planError.empty = "Please select plan";
      isValid = false;
    }

    if (!clientData.paymentMode) {
      paymentModeError.empty = "Please select payment mode";
      isValid = false;
    }

    if (clientData.paymentMode === "Online" && !clientData.transactionId) {
      transactionError.empty = "Please enter transactionId id";
      isValid = false;
    }
    if (!clientData.agentCode) {
      agentCodeError.empty = "Please enter agent code";
      isValid = false;
    }

    if (!clientData.paidAmount) {
      paidAmountError.empty = "Please enter paid amount"
      isValid = false;
    }

    // if (upiIdStatic) {
    //   if (!validateUPIId(upiIdStatic)) {
    //     upiIdError.invalid = "Invalid UPI ID";
    //     isValid = false;
    //   }
    // }

    // setUpiStaticIdError(upiIdError);  
    setCategoryErr(categoryErr);
    setOutletError(outletError);
    setQrUuidError(qrUuidError);
    setQrFileImgError(qrFileImgError)
    setPhoneNumberError(phoneNumberError);
    setEmailAddressError(emailAddressError);
    setPasswordError(passwordError);
    setConfPasswordError(confPasswordError);
    setLogoFileImgError(logoFileImgError)
    setPlanError(planError);
    setPaymentModeError(paymentModeError);
    setTransactionError(transactionError);
    setAgentCodeError(agentCodeError);
    setApproxMinError(approxMinError);
    setPaidAmountError(paidAmountError);

    return isValid;
  };

  const uploadHandler = async (e) => {
    setImg(URL.createObjectURL(e.target.files[0]))
    setFile(e.target.files[0]);
  }

  function getUpiName(upiId) {
    const atIndex = upiId.indexOf('@');
    if (atIndex !== -1) {
      return upiId.substring(atIndex + 1);
    } else {
      return null;
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    if (formValidation()) {
      const formData = new FormData();
      formData.append("name", clientData.outletName.trim());
      formData.append("QrUuid", clientData.qrCodeQrUuid);
      formData.append("PhoneNumber", clientData.phoneNumber);
      formData.append("Email", clientData.emailAddress ? clientData.emailAddress : "");
      formData.append("OpenTime", openTime);
      formData.append("categoryId", clientData.categoryId);
      formData.append("planId", clientData.planId);
      formData.append("paymentMode", clientData.paymentMode);
      formData.append("transactionId", clientData.paymentMode == "Online" ? clientData.transactionId : "");
      formData.append("CloseTime", closeTime);
      formData.append("Address", clientData.address ? clientData.address : "");
      formData.append("Password", clientData.password);
      formData.append("LogoFile", clientData.logo);
      formData.append("QrCodeFile", clientData.qrcode);
      formData.append("ApproxServeMins", clientData.approxMin);
      formData.append("agentCode", clientData.agentCode);
      formData.append("paidAmount", clientData.paidAmount);
      if (upiIdStatic) {
        const upiName = getUpiName(upiIdStatic);
        formData.append(`ClientUPIs[${0}].upiId`, upiIdStatic);
        formData.append(`ClientUPIs[${0}].Name`, upiName);
      } else if (upiIds && upiIds.length > 0) {
        upiIds.forEach((upi, index) => {
          formData.append(`ClientUPIs[${index}].upiId`, upi.upiId);
          formData.append(`ClientUPIs[${index}].Name`, upi.upiName);
        })
      }

      dispatch(showLoaderAction(true));
      const res = await axios.post(REACT_APP_MY_ENV + "/register-client", formData).then(function (res) {
        if (res.data.status === 200) {
          dispatch(showLoaderAction(false));
          showToast(res.data.message, "success");
          setAddress("")
          setAgentCode("")
          setApproxMin("")
          setCategoryId("")
          setCloseTime("")
          setOpenTime("")
          setConfPassword("")
          setPassword("")
          setImg("")
          setUpiIds("")
          setEmailAddress("")
          dispatch(ClientDataAction(undefined));
          dispatch(UpiIdAction([]));
          navigate('/outlet-sign-in');
        }
        else {
          dispatch(showLoaderAction(false));
          showToast(res.data.message, "error");
        }
      }).catch(function (error) {
        dispatch(showLoaderAction(false));
        const data = {
          Subject: "Front end error from register-client",
          BodyText: error.message
        }
        sendMail(data);
        showToast("Something went wrong, please contact Administrator!", "error")
      })
    }
  };

  function showHidePwd() {
    var passInput = $('.show-pass').parent().find('input');
    var inputType = passInput.attr('type');
    if (inputType == 'password') {
      $('.show-pass').toggleClass('active');
      passInput.attr('type', 'text');
    } else if (inputType == 'text') {
      $('.show-pass').removeClass('active');
      passInput.attr('type', 'password');
    }
  }

  function showHideConfPwd() {
    var passInput = $('.show-pass-conf').parent().find('input');
    var inputType = passInput.attr('type');
    if (inputType == 'password') {
      $('.show-pass-conf').toggleClass('active');
      passInput.attr('type', 'text');
    } else if (inputType == 'text') {
      $('.show-pass-conf').removeClass('active');
      passInput.attr('type', 'password');
    }
  }

  const getCategoryList = async () => {
    const response = await axios.get(REACT_APP_MY_ENV + "/category-list").then(function (response) {
      if (response.data.status === 200) {
        setCategoryList(response.data.data.categoryList)
      } else {
        showToast(response.data.message, "error");
      }
    }).catch(function (error) {
      dispatch(showLoaderAction(false));
      const data = {
        Subject: "Front end error from category-list",
        BodyText: error.message
      }
      sendMail(data);
      showToast("Something went wrong, please contact Administrator!", "error")
    })
  };

  const handleCategoryChange = (e) => {
    setCategoryId(e.target.value);
  };

  const getPlanList = async () => {
    let data ={
      IsServieTrue : false
    }
    const response = await axios.post(REACT_APP_MY_ENV + "/plan-list",data).then(function (response) {
      if (response.data.status === 200) {
        setPlanList(response.data.data.planList)
      } else {
        showToast(response.data.message, "error");
      }
    }).catch(function (error) {
      dispatch(showLoaderAction(false));
      const data = {
        Subject: "Front end error from plan-list",
        BodyText: error.message
      }
      sendMail(data);
      showToast("Something went wrong, please contact Administrator!", "error")
    });
  };

  const handlePlanChange = (e) => {
    setPlanId(e.target.value);
  }

  const handlePaymentModeChange = (e) => {
    setPaymentMode(e.target.value);
  }

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      readQRCode(file)
        .then((result) => {
          const parts = result.split('/');
          const lastPart = parts[parts.length - 1];
          setQrFile(e.target.files[0]);
          setQrFileImg(e.target.files[0]);
          setQrUuid(lastPart)
          handleChange(e, lastPart)
        })
        .catch((error) => {
          showToast("Invalid QR code!", "error");
        });
    }
  };

  function readQRCode(imageFile) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const imageData = new Image();
        imageData.src = e.target.result;
        imageData.onload = () => {
          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");
          canvas.width = imageData.width;
          canvas.height = imageData.height;
          context.drawImage(imageData, 0, 0);
          const imageDataArray = context.getImageData(
            0,
            0,
            imageData.width,
            imageData.height
          ).data;
          const code = jsQR(imageDataArray, imageData.width, imageData.height);
          if (code) {
            resolve(code.data);
          } else {
            reject("No QR code found in the image.");
          }
        };
      };
      reader.readAsDataURL(imageFile);
    });
  }

  const handleChange = (e, qrUuid) => {
    if (e.target.name == 'qrfile') {
      if (e.target.files[0]) {
        dispatch(ClientDataAction({
          ...clientData,
          qrcode: e.target.files[0],
          qrcodeURL: URL.createObjectURL(e.target.files[0]),
          qrCodeQrUuid: qrUuid
        }));
      }
      else {
        dispatch(ClientDataAction({
          ...clientData,
          qrcode: null,
          qrcodeURL: ''
        }));
      }
    } else if (e.target.name == 'logoFile') {
      if (e.target.files[0]) {
        dispatch(ClientDataAction({
          ...clientData,
          logo: e.target.files[0],
          logoURL: URL.createObjectURL(e.target.files[0])
        }));
      }
      else {
        dispatch(ClientDataAction({
          ...clientData,
          logo: null,
          logoURL: ''
        }));
      }
    } else {
      dispatch(ClientDataAction({
        ...clientData,
        [e.target.name]: e.target.value
      }));
    }

  }

  const handleOpenTimeChange = (e) => {
    setOpenTime(e.target.value)
  }

  const handleCloseTimeChange = (e) => {
    setCloseTime(e.target.value);
  }

  const OnChangeHandleUpiId = (e) => {
    setUpiIdStatic(e.target.value);
  }


  return (
    <>
      {showLoader && (<SpinerLoader />)}
      <div class="page account-area outlet-sign-up">
        <div class="page-content">
        <div className="logo-space"><div className="logo-request-singpage">
               <img src={tokenlogo} onError={(error) => { error.target.src = tokenlogo }} /></div></div>
          <div class="fixed-content container pt-0">
            <div class="form-elements">
              <form onSubmit={onSubmit}>
                <div class="navbar navbar-style-1">
                  <div class="navbar-inner navbar-inner-padding">
                    <Link to="/outlet-sign-in" class="link back">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.439312 13.0606L5.75391 18.3752C6.04683 18.6682 6.43069 18.8146 6.81459 18.8146C7.1985 18.8146 7.58236 18.6682 7.87528 18.3752C8.46103 17.7894 8.46103 16.8397 7.87528 16.2539L5.12133 13.5H22.5C23.3284 13.5 24 12.8284 24 12C24 11.1716 23.3284 10.5 22.5 10.5H5.12133L7.87528 7.7461C8.46103 7.1603 8.46103 6.21057 7.87528 5.62477C7.28944 5.03898 6.33975 5.03898 5.75391 5.62477L0.439312 10.9394C-0.146437 11.5251 -0.146437 12.4749 0.439312 13.0606Z"
                          fill="black"
                        />
                      </svg>
                    </Link>
                    <div class="title text-left">Outlet Sign Up</div>
                  </div>
                </div>
                <div class="title-head mb">
                  <p class="profile-font profile-font-padding">Don't have an account, Get registered!</p>
                </div>
                <div class="list">
                  <ul class="row">
                    {clientData.logoURL &&
                      <li class="item-content item-input col-100">
                        <div class="item-inner item-mb">
                          <div class="item-input-wrap profile-image">
                            <img src={clientData.logoURL} alt="img" />
                          </div>
                        </div>
                      </li>
                    }
                    <li class="item-content item-input col-100">
                      <div class="item-inner">
                        <div class="item-title item-label profile-font">
                          Logo
                        </div>
                        <div class="item-input-wrap profile-font">
                          <input type="file" name="logoFile" id="logoFile"
                            onChange={(e) => {
                              uploadHandler(e);
                              handleChange(e);
                            }}
                            autocomplete="off"
                            accept=".jpg, .jpeg, .png, image/heif"
                            className="form-control qrFile"
                          />
                          {Object.keys(logoFileImgError).map((key) => {
                            return (
                              <div style={{ color: "red" }}>
                                {logoFileImgError[key]}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </li>
                    <li class="item-content col-100 item-input item-input-with-value">
                      <div class="item-inner">
                        <div class="item-title item-label profile-font">
                          Outlet Name<span className="danger">*</span>
                        </div>
                        <div class="item-input-wrap profile-font">
                          <input
                            type="text"
                            name="outletName"
                            placeholder="Enter Outlet Name"
                            value={clientData.outletName}
                            autocomplete="off"
                            maxLength="255"
                            onChange={(e) => {
                              setOutlet(e.target.value);
                              handleChange(e);
                            }}
                            class="form-control"
                          />
                          {Object.keys(outletError).map((key) => {
                            return (
                              <div style={{ color: "red" }}>
                                {outletError[key]}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </li>
                    <li class="item-content col-100 item-input item-input-with-value">
                      <div class="item-inner">
                        <div class="item-input-wrap profile-font">
                          <div class="item-title item-label profile-font">
                            Category<span className="danger">*</span>
                          </div>
                          <select
                            name="categoryId"
                            class="form-control"
                            placeholder=""
                            onChange={(e) => {
                              handleCategoryChange(e);
                              handleChange(e);
                            }}
                            value={clientData.categoryId}
                            selected={categoryId}
                          >
                            <option value="" className="text-secondary">Select Category</option>
                            {categoryList.map((category) => (
                              <option key={category.categoryId} value={category.categoryId}>
                                {category.categoryName}
                              </option>
                            ))}
                          </select>
                          {Object.keys(categoryErr).map((key) => {
                            return (
                              <div style={{ color: "red" }}>{categoryErr[key]}</div>
                            );
                          })}
                        </div>
                      </div>
                    </li>
                    <li class="item-content col-100 item-input">
                      <div class="item-inner">
                        <div class="item-title item-label profile-font">
                          Phone Number<span className="danger">*</span>
                        </div>
                        <div class="item-input-wrap profile-font">
                          <input
                            type="text"
                            name="phoneNumber"
                            placeholder="Enter Phone Number"
                            value={clientData.phoneNumber}
                            autocomplete="off"
                            maxLength="10"
                            onChange={(e) => {
                              setPhoneNumber(e.target.value);
                              handleChange(e);
                            }}
                            class="form-control"
                            onKeyPress={(e) => {
                              const isNumeric = /^[0-9\b]+$/.test(e.key);

                              if (!isNumeric) {
                                e.preventDefault();
                              }
                            }}
                          />
                          {Object.keys(phoneNumberError).map((key) => {
                            return (
                              <div style={{ color: "red" }}>
                                {phoneNumberError[key]}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </li>
                    <li class="item-content col-100 item-input">
                      <div class="item-inner">
                        <div class="item-title item-label profile-font">
                          Email Address
                        </div>
                        <div class="item-input-wrap profile-font">
                          <input
                            type="text"
                            name="emailAddress"
                            placeholder="Enter Email Address"
                            id="dz-password"
                            value={clientData.emailAddress}
                            autocomplete="off"
                            maxLength="100"
                            onChange={(e) => {
                              setEmailAddress(e.target.value);
                              handleChange(e);
                            }}
                            class="form-control"
                          />
                          {Object.keys(emailAddressError).map((key) => {
                            return (
                              <div style={{ color: "red" }}>
                                {emailAddressError[key]}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </li>
                    <li class="item-content col-100 item-input">
                      <div class="item-inner">
                        <div class="item-title item-label profile-font">
                          Open Time
                        </div>
                        <div class="item-input-wrap profile-font">
                          <input type="time"
                            id="openTime"
                            name="openTime"
                            value={openTime}
                            maxLength="7"
                            onChange={(e) => {
                              handleOpenTimeChange(e)
                            }}
                            class="form-control" />
                        </div>
                      </div>
                    </li>
                    <li class="item-content col-100 item-input">
                      <div class="item-inner">
                        <div class="item-title item-label profile-font">
                          Close Time
                        </div>
                        <div class="item-input-wrap profile-font">
                          <input type="time"
                            id="closeTime"
                            name="closeTime"
                            value={closeTime}
                            maxLength="7"
                            onChange={(e) => {
                              // setCloseTime(e.target.value);
                              // handleChange(e);
                              handleCloseTimeChange(e)
                            }}
                            class="form-control" />
                        </div>
                      </div>
                    </li>
                    <li class="item-content col-100 item-input item-input-with-value">
                      <div class="item-inner">
                        <div class="item-title item-label profile-font">
                          Approx Serve Minutes<span className="danger">*</span>
                        </div>
                        <div class="item-input-wrap profile-font">
                          <input
                            type="number"
                            name="approxMin"
                            placeholder="Enter Approx Serve Minutes"
                            value={clientData.approxMin}
                            maxLength="6"
                            onChange={(e) => {
                              const inputText = e.target.value;
                              if (inputText.length <= 6) {
                                setApproxMin(e.target.value);
                                handleChange(e);
                              }
                            }}
                            class="form-control"
                          />
                          {Object.keys(approxMinError).map((key) => {
                            return (
                              <div style={{ color: "red" }}>
                                {approxMinError[key]}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </li>
                    <li class="item-content col-100 item-input">
                      <div class="item-inner">
                        <div class="item-title item-label profile-font">
                          Address
                        </div>
                        <div class="item-input-wrap profile-font">
                          <textarea id="address"
                            rows="10"
                            cols="15"
                            name="address"
                            autocomplete="off"
                            value={clientData.address}
                            maxLength="500"
                            placeholder="Enter Address"
                            onChange={(e) => {
                              setAddress(e.target.value);
                              handleChange(e);
                            }}
                            class="form-control" />
                        </div>
                      </div>
                    </li>
                    <li class="item-content col-100 item-input item-input-with-value">
                      <div class="item-inner">
                        <div class="item-input-wrap profile-font">
                          <div class="item-title item-label profile-font">
                            Plan<span className="danger">*</span>
                          </div>
                          <select
                            name="planId"
                            class="form-control"
                            placeholder=""
                            onChange={(e) => {
                              handlePlanChange(e);
                              handleChange(e);
                            }}
                            value={clientData.planId}
                            selected={planId}
                          >
                            <option value="" className="text-secondary">Select Plan</option>
                            {planList.map((plan) => (
                              <option key={plan.id} value={plan.id}>
                                {plan.name}
                              </option>
                            ))}
                          </select>
                          {Object.keys(planError).map((key) => {
                            return (
                              <div style={{ color: "red" }}>{planError[key]}</div>
                            );
                          })}
                        </div>
                      </div>
                    </li>
                    <li class="item-content col-100 item-input">
                      <div class="item-inner">
                        <div class="item-title item-label profile-font">
                          Paid Amount<span className="danger">*</span>
                        </div>
                        <div class="item-input-wrap profile-font">
                          <input
                            type="text"
                            name="paidAmount"
                            placeholder="Enter Paid Amount"
                            id="dz-password"
                            value={clientData.paidAmount}
                            autocomplete="off"
                            maxLength={18}
                            onChange={(e) => {
                              setPaidAmount(e.target.value);
                              handleChange(e);
                            }}
                            class="form-control"
                            onKeyPress={(e) => {
                              // Allow digits (0-9), one decimal point, and backspace
                              const isValidInput =
                                /^\d$/.test(e.key) ||
                                (e.key === "." && e.target.value.indexOf(".") === -1) ||
                                e.key === "Backspace";

                              // Allow only up to two digits after the decimal point
                              const decimalIndex = e.target.value.indexOf(".");
                              if (
                                decimalIndex !== -1 &&
                                e.target.value.length - decimalIndex > 2 &&
                                e.key !== "Backspace"
                              ) {
                                e.preventDefault();
                              }

                              if (!isValidInput) {
                                e.preventDefault();
                              }
                            }}
                          />
                          {Object.keys(paidAmountError).map((key) => {
                            return (
                              <div style={{ color: "red" }}>
                                {paidAmountError[key]}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </li>
                    <li class="item-content col-100 item-input item-input-with-value">
                      <div class="item-inner">
                        <div class="item-input-wrap profile-font">
                          <div class="item-title item-label profile-font">
                            Payment Mode<span className="danger">*</span>
                          </div>
                          <select
                            name="paymentMode"
                            class="form-control"
                            placeholder=""
                            onChange={(e) => {
                              handlePaymentModeChange(e);
                              handleChange(e);
                            }}
                            value={clientData.paymentMode}
                          >
                            <option value="" className="text-secondary">Select Payment Mode</option>
                            {paymentModeList.map((mode, index) => (
                              <option key={index} value={mode}>
                                {mode}
                              </option>
                            ))}
                          </select>
                          {Object.keys(paymentModeError).map((key) => {
                            return (
                              <div style={{ color: "red" }}>{paymentModeError[key]}</div>
                            );
                          })}
                        </div>
                      </div>
                    </li>
                    {paymentMode === "Online" &&
                      <li class="item-content col-100 item-input item-input-with-value">
                        <div class="item-inner">
                          <div class="item-title item-label profile-font">
                            Transaction Id<span className="danger">*</span>
                          </div>
                          <div class="item-input-wrap profile-font">
                            <input
                              type="text"
                              name="transactionId"
                              placeholder="Enter Transaction Id"
                              value={clientData.transactionId}
                              autocomplete="off"
                              maxLength="255"
                              onChange={(e) => {
                                setTransactionId(e.target.value);
                                handleChange(e);
                              }}
                              class="form-control"
                            />
                            {Object.keys(transactionError).map((key) => {
                              return (
                                <div style={{ color: "red" }}>
                                  {transactionError[key]}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </li>}
                    <li class="item-content item-input col-100">
                      <div class="item-inner">
                        <div class="item-title item-label profile-font">
                          Password<span className="danger">*</span>
                        </div>
                        <div class="item-input-wrap profile-font">
                          <input type="password"
                            name="password"
                            id="password"
                            autocomplete="new-password"
                            value={clientData.password}
                            maxLength="16"
                            placeholder="Enter Password"
                            onChange={(e) => {
                              setPassword(e.target.value.trim());
                              handleChange(e);
                            }}
                            className="form-control"
                          />
                          <div class="show-pass">
                            <svg width="24" height="24" onClick={showHidePwd} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M22.919 11.606C22.786 11.3 19.572 4.00002 12 4.00002C4.42801 4.00002 1.21401 11.3 1.08101 11.606C1.02764 11.7305 1.00012 11.8646 1.00012 12C1.00012 12.1355 1.02764 12.2695 1.08101 12.394C1.21401 12.7 4.42801 20 12 20C19.572 20 22.786 12.7 22.919 12.394C22.9724 12.2695 22.9999 12.1355 22.9999 12C22.9999 11.8646 22.9724 11.7305 22.919 11.606ZM12 18C6.60001 18 3.83301 13.411 3.11001 12C3.83501 10.614 6.64801 6.00002 12 6.00002C17.394 6.00002 20.165 10.586 20.89 12C20.164 13.386 17.352 18 12 18Z" fill="#309F5F" />
                              <path d="M12 8C11.2089 8 10.4355 8.2346 9.77772 8.67412C9.11993 9.11365 8.60723 9.73836 8.30448 10.4693C8.00173 11.2002 7.92252 12.0044 8.07686 	12.7804C8.2312 13.5563 8.61216 14.269 9.17157 14.8284C9.73098 15.3878 10.4437 15.7688 11.2196 15.9231C11.9956 16.0775 12.7998 15.9983 13.5307 15.6955C14.2616 15.3928 14.8864 14.8801 15.3259 14.2223C15.7654 13.5645 16 12.7911 16 12C16 10.9391 15.5786 9.92172 14.8284 9.17157C14.0783 8.42143 13.0609 8 12 8ZM12 14C11.6044 14 11.2178 13.8827 10.8889 13.6629C10.56 13.4432 10.3036 13.1308 10.1522 12.7654C10.0009 12.3999 9.96126 11.9978 10.0384 11.6098C10.1156 11.2219 10.3061 10.8655 10.5858 10.5858C10.8655 10.3061 11.2219 10.1156 11.6098 10.0384C11.9978 9.96126 12.3999 10.0009 12.7654 10.1522C13.1308 10.3036 13.4432 10.56 13.6629 10.8889C13.8827 11.2178 14 11.6044 14 12C14 12.5304 13.7893 13.0391 13.4142 13.4142C13.0391 13.7893 12.5304 14 12 14Z" fill="#309F5F" />
                            </svg>
                          </div>
                          {passwordError &&
                            Object.keys(passwordError).map((key) => {
                              return (
                                <div style={{ color: "red" }}>
                                  {passwordError[key]}
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </li>
                    <li class="item-content item-input col-100">
                      <div class="item-inner">
                        <div class="item-title item-label profile-font">
                          Confirm Password<span className="danger">*</span>
                        </div>
                        <div class="item-input-wrap profile-font">
                          <input type="password"
                            name="confirmPassword"
                            id="confirmPassword"
                            placeholder="Enter Confirm Password"
                            maxLength="16"
                            autocomplete="new-password"
                            value={clientData.confirmPassword}
                            onChange={(e) => {
                              setConfPassword(e.target.value.trim());
                              handleChange(e);
                            }}
                            className="form-control"
                          />
                          <div class="show-pass-conf">
                            <svg width="24" height="24" onClick={showHideConfPwd} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M22.919 11.606C22.786 11.3 19.572 4.00002 12 4.00002C4.42801 4.00002 1.21401 11.3 1.08101 11.606C1.02764 11.7305 1.00012 11.8646 1.00012 12C1.00012 12.1355 1.02764 12.2695 1.08101 12.394C1.21401 12.7 4.42801 20 12 20C19.572 20 22.786 12.7 22.919 12.394C22.9724 12.2695 22.9999 12.1355 22.9999 12C22.9999 11.8646 22.9724 11.7305 22.919 11.606ZM12 18C6.60001 18 3.83301 13.411 3.11001 12C3.83501 10.614 6.64801 6.00002 12 6.00002C17.394 6.00002 20.165 10.586 20.89 12C20.164 13.386 17.352 18 12 18Z" fill="#309F5F" />
                              <path d="M12 8C11.2089 8 10.4355 8.2346 9.77772 8.67412C9.11993 9.11365 8.60723 9.73836 8.30448 10.4693C8.00173 11.2002 7.92252 12.0044 8.07686 	12.7804C8.2312 13.5563 8.61216 14.269 9.17157 14.8284C9.73098 15.3878 10.4437 15.7688 11.2196 15.9231C11.9956 16.0775 12.7998 15.9983 13.5307 15.6955C14.2616 15.3928 14.8864 14.8801 15.3259 14.2223C15.7654 13.5645 16 12.7911 16 12C16 10.9391 15.5786 9.92172 14.8284 9.17157C14.0783 8.42143 13.0609 8 12 8ZM12 14C11.6044 14 11.2178 13.8827 10.8889 13.6629C10.56 13.4432 10.3036 13.1308 10.1522 12.7654C10.0009 12.3999 9.96126 11.9978 10.0384 11.6098C10.1156 11.2219 10.3061 10.8655 10.5858 10.5858C10.8655 10.3061 11.2219 10.1156 11.6098 10.0384C11.9978 9.96126 12.3999 10.0009 12.7654 10.1522C13.1308 10.3036 13.4432 10.56 13.6629 10.8889C13.8827 11.2178 14 11.6044 14 12C14 12.5304 13.7893 13.0391 13.4142 13.4142C13.0391 13.7893 12.5304 14 12 14Z" fill="#309F5F" />
                            </svg>
                          </div>
                          {Object.keys(confPasswordError).map((key) => {
                            return (
                              <div style={{ color: "red" }}>
                                {confPasswordError[key]}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </li>
                    <li class="item-content item-input col-100">
                      <div class="item-inner">
                        <div class="item-title item-label profile-font">
                          Qr upload<span className="danger">*</span>
                        </div>
                        <div class="item-input-wrap profile-font">
                          <input type="file" name="qrfile" id="file"
                            onChange={(e) => {
                              handleImageUpload(e);
                            }}
                            autocomplete="off"
                            accept=".jpg, .jpeg, .png, image/svg+xml"
                            className="form-control qrFile"
                          />
                          {Object.keys(qrFileImgError).map((key) => {
                            return (
                              <div style={{ color: "red" }}>
                                {qrFileImgError[key]}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </li>
                    <li class="item-content col-100 item-input item-input-with-value">
                      <div class="item-inner">
                        <div class="item-title item-label profile-font">
                          Upi Ids
                        </div>
                        <div class="item-input-wrap profile-font">
                          <input
                            type="text"
                            placeholder="Add Upi Ids"
                            value={upiIds && upiIds.map((selectedValue) => selectedValue ? selectedValue.upiId : "").join(", ") || upiIdStatic
                            }
                            onChange={OnChangeHandleUpiId}
                            autoComplete="off"
                            maxLength="255"
                            class="form-control"
                          />

                          <Link to="/qr-scanner" state={{ isClient: true, isUpiId: true }} className="qr-code">
                            <svg width="1.80em" height="1.80em" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                              <polygon fill="var(--ci-primary-color, #000000)" points="48 48 176 48 176 16 16 16 16 176 48 176 48 48" class="ci-primary" />
                              <path fill="var(--ci-primary-color, #000000)" d="M176,176V80H80v96h96Zm-64-64h32v32H112Z" class="ci-primary" />
                              <polygon fill="var(--ci-primary-color, #000000)" points="328 48 464 48 464 176 496 176 496 16 328 16 328 48" class="ci-primary" />
                              <path fill="var(--ci-primary-color, #000000)" d="M432,176V80H336v96h96Zm-64-64h32v32H368Z" class="ci-primary" />
                              <polygon fill="var(--ci-primary-color, #000000)" points="176 464 48 464 48 336 16 336 16 496 176 496 176 464" class="ci-primary" />
                              <path fill="var(--ci-primary-color, #000000)" d="M176,336H80v96h96V336Zm-32,64H112V368h32Z" class="ci-primary" />
                              <polygon fill="var(--ci-primary-color, #000000)" points="464 464 328 464 328 496 496 496 496 336 464 336 464 464" class="ci-primary" />
                              <polygon fill="var(--ci-primary-color, #000000)" points="272 304 400 304 400 368 432 368 432 272 272 272 272 304" class="ci-primary" />
                              <polygon fill="var(--ci-primary-color, #000000)" points="432 432 432 400 240 400 240 272 80 272 80 304 208 304 208 432 432 432" class="ci-primary" />
                              <rect width="32" height="96" x="208" y="80" fill="var(--ci-primary-color, #000000)" class="ci-primary" />
                              <polygon fill="var(--ci-primary-color, #000000)" points="80 240 304 240 304 80 272 80 272 208 80 208 80 240" class="ci-primary" />
                              <rect width="96" height="32" x="336" y="208" fill="var(--ci-primary-color, #000000)" class="ci-primary" />
                              <rect width="32" height="32" x="336" y="336" fill="var(--ci-primary-color, #000000)" class="ci-primary" />
                              <rect width="32" height="32" x="272" y="336" fill="var(--ci-primary-color, #000000)" class="ci-primary" />
                            </svg>
                          </Link>
                        </div>
                      </div>
                    </li>
                    <li class="item-content col-100 item-input item-input-with-value">
                      <div class="item-inner">
                        <div class="item-title item-label profile-font">
                          Agent code<span className="danger">*</span>
                        </div>
                        <div class="item-input-wrap profile-font">
                          <input
                            type="text"
                            placeholder="Enter Agent Code"
                            name="agentCode"
                            value={clientData.agentCode}
                            autocomplete="off"
                            maxLength="255"
                            onChange={(e) => {
                              setAgentCode(e.target.value);
                              handleChange(e);
                            }}
                            class="form-control"
                          />
                          {Object.keys(agentCodeError).map((key) => {
                            return (
                              <div style={{ color: "red" }}>
                                {agentCodeError[key]}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="clearfix profile-font">
                  <button
                    type="submit"
                    className="button-large button button-fill"
                  >
                    REGISTER
                  </button>
                </div>
                <div class="text-align-center account-footer profile-font">
                  <p class="mt-0">Already have an account?</p>
                  <Link
                    to="/outlet-sign-in/"
                    class="button-large button button-fill button-gray profile-font"
                  >
                    SIGN IN
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ClientSignUp;