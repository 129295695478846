import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import defaultOutlet from "../../../img/outlet-default.png";
import Sidebar from "../../Master/sidebar";
import moment from "moment/moment";
import axios from "axios";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useDispatch, useSelector } from "react-redux";
import { showLoaderAction } from "../../../actions/index";
import { sendMail, useCatchHandler, showToast } from "../../../utils/utilities";
import { useNotification } from "../../../utils/utilities"
import DetailsPlaceholder from '../../Placeholders/DetailsPlaceholder.js';
const { REACT_APP_MY_ENV } = process.env;

function Outlet() {
  const location = useLocation();
  const [outlet] = useState(location.state?.outlet);
  const [myToken] = useState(location.state?.myToken);
  const [fav] = useState(location.state?.fav);
  const dispatch = useDispatch();
  const showLoaderReducer = useSelector((state) => state.rootReducer.showLoaderReducer)
  const showLoader = showLoaderReducer.show;

  const [isFavourite, setIsFavourite] = useState(false);
  const [favouriteDetail, setFavouriteDetail] = useState(false);
  const [feedBack, setFeedBack] = useState(false);
  const [canGiveFeedback, setCanGiveFeedback] = useState(false);
  const [outletDetail, setOutLetDetail] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [slug, setSlug] = useState();
  const [isFeedbackGiven, setIsFeedbackGiven] = useState(false);
  const sendNotification = useNotification();
  let token = localStorage.getItem("token");
  const catchHandle = useCatchHandler();
  const currentURL = window.location.href;

  useEffect(() => {
    const currentURL = window.location.href;
    const urlParts = currentURL.split('/');
    const slug = urlParts[urlParts.length - 1];
    setSlug(slug)
    OutletDetail(slug)
    // checkLoginStatus();
    const isLogin = localStorage.getItem("isLogin")
    if (isLogin) {
      setIsLoggedIn(true);
      // getFavourite();
    }
    if (fav) {
      setIsFavourite(true);
    }
  }, []);

  const addToFavourite = (id) => async (e) => {
    const data = {
      encryptedClientId: id,
      encryptedUserId: localStorage.getItem("encryptedUserId"),
    };
    dispatch(showLoaderAction(true));
    const addFavouriteOutlet = await axios.post(REACT_APP_MY_ENV + "/add-outlet-to-favourite", data, { headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" } }).then(function (addFavouriteOutlet) {
      if (addFavouriteOutlet.data.status === 200) {
        dispatch(showLoaderAction(false));
        notificationSendForAddToFav()
        showToast(addFavouriteOutlet.data.message, "success");
        setIsFavourite(true);
      } else {
        dispatch(showLoaderAction(false));
        showToast(addFavouriteOutlet.data.message, "error");
      }
    }).catch(function (error) {
      showToast("Something went wrong, please contact Administrator!", "error")
      dispatch(showLoaderAction(false));
      catchHandle(error, "true", currentURL)
      const data = {
        Subject: "Front end error from add-outlet-to-favourite",
        BodyText: error.message
      }
      sendMail(data);
    });
  };

  const ConfirmRemove = (encryptedClientId) => async (e) => {
    confirmAlert({
      title: (
        <span className="confirm-title">
          Confirmation
        </span>
      ),
      message: 'Are you sure you want to remove this outlet from Favourites?',
      buttons: [
        {
          label: 'Yes',
          className: 'red-button',
          onClick: () => removeFavourite(encryptedClientId)
        },
        {
          label: 'Cancel',
          className: 'gray-button',
        }
      ]
    });
  }


  const removeFavourite = async (encryptedClientId) => {
    const data = {
      encryptedUserId: localStorage.getItem("encryptedUserId"),
      encryptedClientId: encryptedClientId,
    };
    dispatch(showLoaderAction(true));
    const removedFavouriteOutlet = await axios.delete(REACT_APP_MY_ENV + "/remove-favourite-outlet", { headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" }, data }).then(function (removedFavouriteOutlet) {
      if (removedFavouriteOutlet.data.status === 200) {
        dispatch(showLoaderAction(false));
        notificationSendForRemoveFromFav()
        showToast(removedFavouriteOutlet.data.message, "success");
        setIsFavourite(false);
        setFavouriteDetail(false);
      } else {
        dispatch(showLoaderAction(false));
        showToast(removedFavouriteOutlet.data.message, "error");
      }
    }).catch(function (error) {
      dispatch(showLoaderAction(false));
      showToast("Something went wrong, please contact Administrator!", "error")
      catchHandle(error, "true", currentURL)
      const data = {
        Subject: "Front end error from remove-favourite-outlet",
        BodyText: error.message
      }
      sendMail(data);
    });
  };

  const getFavourite = async (clientId) => {
    const data = {
      encryptedUserId: localStorage.getItem("encryptedUserId"),
      // encryptedClientId: outlet.encryptedClientId ? outlet.encryptedClientId : (outlet.clientId).toString()
      encryptedClientId: clientId
    };
    const response = await axios.post(REACT_APP_MY_ENV + "/get-favourite-outlet", data, { headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" } }).then(function (response) {
      if (response.data.status === 200) {
        if (response.data.data) {
          setFavouriteDetail(response.data.data.isFavouriteOutlet);
        }
      }
    }).catch(function (error) {
      catchHandle(error, "true", currentURL)
      showToast("Something went wrong, please contact Administrator!", "error")
      const data = {
        Subject: "Front end error from get-favourite-outlet",
        BodyText: error.message
      }
      sendMail(data);
    });
  };

  const notificationSendForAddToFav = () => {
    const data = {
      ClientId: outletDetail.encryptedClientId,
      UserId: localStorage.getItem("encryptedUserId"),
      SentBy: "USER",
      Title: "Added to Favourites",
      Description: `${localStorage.getItem("username")} added our outlet into Favourites`,
    }
    sendNotification(data)
  }

  const notificationSendForRemoveFromFav = () => {
    const data = {
      ClientId: outletDetail.encryptedClientId,
      UserId: localStorage.getItem("encryptedUserId"),
      SentBy: "USER",
      Title: "Removed from Favourites",
      Description: `${localStorage.getItem("username")} removed our outlet from Favourites`,
    }
    sendNotification(data)
  }

  const getFeedBackDetails = async (encryptedClientId) => {
    const data = {
      encryptedUserId: localStorage.getItem("encryptedUserId"),
      encryptedClientId: encryptedClientId,
    };
    const response = await axios.post(REACT_APP_MY_ENV + "/get-feedback-detail", data, { headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" } }).then(function (response) {
      if (response.data.status === 200) {
        if (response.data.data) {
          setFeedBack(true);
        }
      }
    }).catch(function (error) {
      showToast("Something went wrong, please contact Administrator!", "error")
      catchHandle(error, "true",currentURL)
      const data = {
        Subject: "Front end error from get-feedback-detail",
        BodyText: error.message
      }
      sendMail(data);
    });
  };

  const OutletDetail = async (slug) => {
    const data = {
      encryptedUserId: localStorage.getItem("encryptedUserId"),
      isView: true,
      // slug: outlet.slug ? outlet.slug : slug
      slug: slug
    };
    dispatch(showLoaderAction(true));
    const response = await axios.post(REACT_APP_MY_ENV + "/outlet/", data).then(function (response) {
      if (response.data.status === 200) {
        dispatch(showLoaderAction(false));
        if (response.data.data) {
          setCanGiveFeedback(response.data.data.outlet.canUserGiveFeedback);
          setOutLetDetail(response.data.data.outlet)
          // setFavouriteDetail
          setIsFavourite(response.data.data.outlet.isFavourite);
          if(isLoggedIn){
            getFavourite(response.data.data.outlet.encryptedClientId);
          } 
          setIsFeedbackGiven(response.data.data.outlet.isFeedbackGiven)
          // if (localStorage.getItem("encryptedUserId")) {
          //   getFeedBackDetails(response.data.data.outlet.encryptedClientId);
          // }
        }
      } else {
        dispatch(showLoaderAction(false));
      }
    }).catch(function (error) {
      dispatch(showLoaderAction(false));
      showToast("Something went wrong, please contact Administrator!", "error")
      catchHandle(error, "true", currentURL)
      const data = {
        Subject: "Front end error from outlet",
        BodyText: error.message
      }
      sendMail(data);
    });
  };

  return (
    <>
      {showLoader ? (<DetailsPlaceholder />)
      : 
      <div class="page profile-wraper outlet-page userDetails">
      <div className='containerCard '>
        <Sidebar></Sidebar>
        <div class="navbar navbar-style-1 navbar-transparent">
          <div class="navbar-inner navbar-profile">
            <Link to={-1} class="link back">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.439312 13.0606L5.75391 18.3752C6.04683 18.6682 6.43069 18.8146 6.81459 18.8146C7.1985 18.8146 7.58236 18.6682 7.87528 18.3752C8.46103 17.7894 8.46103 16.8397 7.87528 16.2539L5.12133 13.5H22.5C23.3284 13.5 24 12.8284 24 12C24 11.1716 23.3284 10.5 22.5 10.5H5.12133L7.87528 7.7461C8.46103 7.1603 8.46103 6.21057 7.87528 5.62477C7.28944 5.03898 6.33975 5.03898 5.75391 5.62477L0.439312 10.9394C-0.146437 11.5251 -0.146437 12.4749 0.439312 13.0606Z" fill="black" />
              </svg>
            </Link>
            <div class="title profile-font">Details</div>
          </div>
        </div>

        <div class="page-content page-baground-color content-area pt-0">
          <div class="profile-info bg-primary">
            <div class="container ">
              <div class="author">
                <img src={outletDetail.logoFilePathURL ? outletDetail.logoFilePathURL : defaultOutlet} onError={(error) => { error.target.src = defaultOutlet }} alt={outletDetail.name} />
                <div class="badge-lg">
                  {outletDetail.ratings ? outletDetail.ratings.toFixed(1) : <span className="text-12" >No Reviews</span>}
                  <svg height="17" width="24" viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg" fill="#30304d" className="star-mb">
                    <path d="m21.645 8.769-5.817-.85-2.928-5.866a1.042 1.042 0 0 0 -1.79 0l-2.938 5.866-5.817.85a1 1 0 0 0 -.492 1.76l4.805 3.963-1.515 6.79a1 1 0 0 0 1.56 1.03l5.287-3.803 5.287 3.8a1 1 0 0 0 1.56-1.03l-1.515-6.79 4.805-3.963a1 1 0 0 0 -.492-1.76zm-6.059 4.571a1 1 0 0 0 -.339.989l1.076 4.826-3.739-2.689a1 1 0 0 0 -1.168 0l-3.739 2.689 1.076-4.826a1 1 0 0 0 -.339-.989l-3.538-2.918 4.1-.6a1 1 0 0 0 .75-.542l2.274-4.545 2.272 4.545a1 1 0 0 0 .75.542l4.1.6z" fill="#30304d" stroke="" stroke-width="1" />
                  </svg>
                </div>
              </div>
              <div class="profile-content">
                <h2 class="name">{outletDetail.name ? outletDetail.name : ""}</h2>
                <span class="time-text">
                  <i class="las la-clock me-1"></i>
                  {outletDetail.openTime ? moment(outletDetail.openTime, "hh:mm:ss").format("hh:mm A") : ""}
                  {outletDetail.closeTime ? " - " : ""}
                  {outletDetail.closeTime ? moment(outletDetail.closeTime, "hh:mm:ss").format("hh:mm A") : ""}
                  <span>   {outletDetail.outletStatus === 1 ?
                    <i class="text-success fa fa-circle small-icon "></i> :
                    <i class="text-danger-soft fa fa-circle small-icon"></i>
                  }
                  </span>
                </span>
                <p className="">
                  <span>{outletDetail.address === "undefined" ? "" : outletDetail.address}</span>
                </p>
              </div>
              <div class="content-info tabbar">
                {outletDetail.phoneNumber ? (
                  <a href={`tel:${outletDetail.phoneNumber}`} class="tab-link">
                    <svg enable-background="new 0 0 507.983 507.983" height="24" viewBox="0 0 507.983 507.983" width="24" xmlns="http://www.w3.org/2000/svg">
                      <g>
                        <path d="m200.75 148.678c11.79-27.061 5.828-58.58-15.03-79.466l-48.16-48.137c-15.999-16.19-38.808-23.698-61.296-20.178-22.742 3.34-42.496 17.4-53.101 37.794-23.286 43.823-29.276 94.79-16.784 142.817 30.775 121.9 198.319 289.559 320.196 320.104 16.452 4.172 33.357 6.297 50.33 6.326 32.253-.021 64.009-7.948 92.487-23.087 35.138-18.325 48.768-61.665 30.443-96.803-3.364-6.451-7.689-12.352-12.828-17.502l-48.137-48.16c-20.894-20.862-52.421-26.823-79.489-15.03-12.631 5.444-24.152 13.169-33.984 22.787-11.774 11.844-55.201-5.31-98.675-48.76s-60.581-86.877-48.876-98.698c9.658-9.834 17.422-21.361 22.904-34.007zm-6.741 165.397c52.939 52.893 124.14 88.562 163.919 48.76 5.859-5.609 12.688-10.108 20.155-13.275 9.59-4.087 20.703-1.9 28.028 5.518l48.137 48.137c5.736 5.672 8.398 13.754 7.157 21.725-1.207 8.191-6.286 15.298-13.645 19.093-33.711 18.115-73.058 22.705-110.033 12.836-104.724-26.412-260.078-181.765-286.489-286.627-9.858-37.009-5.26-76.383 12.86-110.126 3.823-7.318 10.924-12.358 19.093-13.552 1.275-.203 2.564-.304 3.856-.3 6.714-.002 13.149 2.683 17.869 7.457l48.137 48.137c7.407 7.321 9.595 18.421 5.518 28.005-3.153 7.516-7.652 14.394-13.275 20.294-39.804 39.686-4.18 110.817 48.713 163.918z" />
                      </g>
                    </svg>
                  </a>
                ) : null}

                {outletDetail.address ? (
                  <a target="_blank" href={`https://www.google.com/maps?q=${outletDetail.address}`} class="tab-link">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.9993 5.48404C9.59314 5.48404 7.64258 7.4346 7.64258 9.84075C7.64258 12.2469 9.59314 14.1975 11.9993 14.1975C14.4054 14.1975 16.356 12.2469 16.356 9.84075C16.356 7.4346 14.4054 5.48404 11.9993 5.48404ZM11.9993 12.0191C10.7962 12.0191 9.82096 11.0438 9.82096 9.84075C9.82096 8.6377 10.7962 7.66242 11.9993 7.66242C13.2023 7.66242 14.1776 8.6377 14.1776 9.84075C14.1776 11.0438 13.2023 12.0191 11.9993 12.0191Z" fill="#4A3749" />
                      <path d="M21.793 9.81896C21.8074 4.41054 17.4348 0.0144869 12.0264 5.09008e-05C6.61797 -0.0143851 2.22191 4.35827 2.20748 9.76664C2.16044 15.938 5.85106 21.5248 11.546 23.903C11.6884 23.9674 11.8429 24.0005 11.9991 24C12.1565 24.0002 12.3121 23.9668 12.4555 23.9019C18.1324 21.5313 21.8191 15.9709 21.793 9.81896ZM11.9992 21.7127C7.30495 19.646 4.30485 14.9691 4.38364 9.84071C4.38364 5.63477 7.79323 2.22518 11.9992 2.22518C16.2051 2.22518 19.6147 5.63477 19.6147 9.84071V9.91152C19.6686 15.0154 16.672 19.6591 11.9992 21.7127Z" fill="#4A3749" />
                    </svg>
                  </a>
                ) : null}
                {isLoggedIn && (!isFavourite && !favouriteDetail ? (
                  <a onClick={addToFavourite(outletDetail.encryptedClientId)} state={{ outlet: outlet }} id="addToFavourite" class="tab-link">
                    <svg width="24" fill={favouriteDetail ? "#4A3749" : "#000000"} stroke="none" height="24" viewBox="0 0 512.000000 512.000000" xmlns="http://www.w3.org/2000/svg">
                      <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill={isFavourite ? "#4A3749" : "#000000"} stroke="none">
                        <path
                          d="M1262 4830 c-319 -40 -586 -171 -812 -399 -203 -204 -325 -420 -395
                              -701 -124 -487 -34 -967 264 -1418 191 -289 438 -554 891 -958 288 -257 1167
                              -1007 1210 -1032 40 -24 55 -27 140 -27 85 0 100 3 140 27 43 25 924 776 1210
                              1032 455 406 700 670 891 958 298 451 388 931 264 1418 -70 281 -192 497 -395
                              701 -202 203 -418 320 -701 380 -142 30 -404 33 -528 5 -346 -75 -611 -248
                              -853 -556 l-28 -35 -27 35 c-239 302 -500 475 -833 551 -99 23 -327 33 -438
                              19z m334 -305 c284 -50 529 -214 723 -485 33 -47 74 -103 90 -126 74 -104 228
                              -104 302 0 16 23 57 79 90 126 265 370 634 544 1036 489 446 -61 794 -373 927
                              -832 105 -363 59 -744 -132 -1087 -160 -287 -427 -588 -892 -1005 -225 -201
                              -1171 -1015 -1180 -1015 -10 0 -952 811 -1180 1015 -715 641 -997 1041 -1065
                              1510 -44 303 19 629 172 886 230 387 678 599 1109 524z"
                          fill={favouriteDetail ? "#4A3749" : "#000000"}
                        ></path>
                      </g>
                    </svg>
                  </a>
                ) : (
                  <a onClick={ConfirmRemove(outletDetail.encryptedClientId)} id="removeFavourite" class="tab-link">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                      <path d="M12 4.435c-1.989-5.399-12-4.597-12 3.568 0 4.068 3.06 9.481 12 14.997 8.94-5.516 12-10.929 12-14.997 0-8.118-10-8.999-12-3.568z" fill="#000000" />
                    </svg>
                  </a>
                ))}
                {canGiveFeedback && (
                  <>
                    <Link to={`/outlet-review/${slug}`} state={{ outlet: outlet }} id="addFeedBack" className="tab-link">
                      <svg height="27" width="27" viewBox="0 0 23 23" xmlns="http://www.w3.org/2000/svg" fill={outletDetail.isFeedbackGiven ? "none" : "#4A3749"}>
                        <path d={outletDetail.isFeedbackGiven ? "m12 17.328-5.403 3.286a.75.75 0 0 1-1.12-.813l1.456-6.155-4.796-4.123a.75.75 0 0 1 .428-1.316l6.303-.517 2.44-5.835a.75.75 0 0 1 1.384 0l2.44 5.835 6.303.517a.75.75 0 0 1 .427 1.316l-4.795 4.123 1.456 6.155a.75.75 0 0 1-1.12.813L12 17.328z" :
                          "m21.645 8.769-5.817-.85-2.928-5.866a1.042 1.042 0 0 0 -1.79 0l-2.938 5.866-5.817.85a1 1 0 0 0 -.492 1.76l4.805 3.963-1.515 6.79a1 1 0 0 0 1.56 1.03l5.287-3.803 5.287 3.8a1 1 0 0 0 1.56-1.03l-1.515-6.79 4.805-3.963a1 1 0 0 0 -.492-1.76zm-6.059 4.571a1 1 0 0 0 -.339.989l1.076 4.826-3.739-2.689a1 1 0 0 0 -1.168 0l-3.739 2.689 1.076-4.826a1 1 0 0 0 -.339-.989l-3.538-2.918 4.1-.6a1 1 0 0 0 .75-.542l2.274-4.545 2.272 4.545a1 1 0 0 0 .75.542l4.1.6z"} fill={outletDetail.isFeedbackGiven ? "#000000" : "#4A3749"} />
                      </svg>
                    </Link>
                  </>
                )}
              </div>
            </div>
          </div>
          <div class="fixed-content container">
            <div class="text-align-center">
              {outletDetail.description ? (
                <>
                  <h4 class="ma-0">Description</h4>
                  <p>{outletDetail.description}</p>
                </>
              ) : null}
            </div>
            {outletDetail.outletStatus != 1 ? (
            <a nohref class="color-gray button-large button button-fill pointer-events-none">
              Closed
            </a>
          ) : (
            <Link to={"/get-token/" + outletDetail.qrUuid} class="button-large button button-fill" state={{ outlet: outlet }}>
              {outletDetail.userTokenNumber > 0 ? "View token" : "Get Token"}
            </Link>
          )}
          </div>
        </div> 
      </div>
      </div>
      }
    </>
  );
}

export default Outlet;
