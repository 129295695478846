import $ from "jquery";
<script
  src="https://code.jquery.com/jquery-3.3.1.js"
  integrity="sha256-2Kok7MbOyxpgUVvAk/HJ2jigOSYS2auK4Pfzbm7uH60="
  crossorigin="anonymous"
></script>;

{
  /* <script src="https://code.jquery.com/jquery-3.5.1.min.js" integrity="sha256-9/aliU8dGd2tb6OSsuzixeV4y/faTqgFtohetphbbj0=" crossorigin="anonymous"></script> */
}

$(document).ready(function () {
  $("input[dist-name='only-numeric']").on("keyup", function (e) {
    if ($("input[dist-name='only-numeric']").val() == "") {
      $("input[dist-name='only-numeric']").css("border", "1px solid #d5d5d5");
    } else if (!$.isNumeric($("input[dist-name='only-numeric']").val())) {
      $("input[dist-name='only-numeric']").css("border", "1px solid red");
    } else {
      $("input[dist-name='only-numeric']").css("border", "1px solid green");
    }
  });
});

export function checkLoginStatus(user) {
  if (user === "CLIENT") {
    let isClientLogin = localStorage.getItem("isClientLogin");
    if (!isClientLogin) {
      window.location.href = "/outlet-sign-in";
    }
  } else {
    let isLogin = localStorage.getItem("isLogin");
    if (!isLogin) {
      window.location.href = "/sign-in";
    }
  }
}