import { combineReducers } from "redux";
import clientSettingReducer from "./clientSettingReducer";
import showLoaderReducer from './spinerLoader';
import activeMenu  from './activeMenu';
import notificationCountReducer from './notificationCountReducer.js';
import clientUpiDetailReducer from "./clientUpiDetailReducer.js";
import clientDataReducer from "./clientDataReducer.js";
import clientMenuDetailReducer from "./clientMenuDetailReducer.js" 

const rootReducer = combineReducers({
  clientSettingReducer,
  showLoaderReducer,
  activeMenu,
  notificationCountReducer,
  clientUpiDetailReducer,
  clientDataReducer,
  clientMenuDetailReducer
});

export default rootReducer;
