import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from "react-redux";
import { showLoaderAction, activeMenuAction } from '../../../actions';
import { Link } from 'react-router-dom';
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import $ from "jquery";
import moment from "moment";
import greenTick from "../../../img/check-green.svg";
import clockImg from "../../../img/icons8-clock.svg";
import { uniqBy } from "lodash";
import { useCatchHandler, showToast } from "../../../utils/utilities"
import ReactPaginate from 'react-paginate';
import ClientFooter from "../../Master/ClientFooter";
import { sendMail } from '../../../utils/utilities';
import ListPlaceholder from '../../Placeholders/ListPlaceholder';
import sandClockTimer from "../../../img/sand-clock-timer.png"

TimeAgo.addLocale(en);

const { REACT_APP_MY_ENV } = process.env;

const TokenUsersList = () => {

	let token = localStorage.getItem("clientToken");
	const dispatch = useDispatch();
	const [tokenUsersList, setTokenUsersList] = useState([]);
	const [tokenStatuses] = useState(['All', 'InQueue', 'Completed', 'Removed', 'Cancelled'])
	const [defaultStatus, setDefaultStatus] = useState("All");
	const [isFilterClicked, setIsFilterClicked] = useState(false);
	const [searchDateValue, setSearchDateValue] = useState(moment().format('YYYY-MM-DD'));
	const [searchText, setSearchText] = useState("");
	const [loading, setLoading] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [showLoading, setShowLoading] = useState(true)
	const [onLoading, setOnLoading] = useState(true); 
	const itemsPerPage = 10;
	const [dailyTokensCount, setDailyTokensCount] = useState(0);
	const [onGoingToken, setOnGoingToken] = useState(0)
	const [popoverOpen, setPopoverOpen] = useState(false);
  	const [userOrders, setUserOrders] = useState([])
  	const [grandTotal, setGrandTotal] = useState([])
	const pageCount = Math.ceil(dailyTokensCount / itemsPerPage);

	const showLoaderReducer = useSelector((state) => state.rootReducer.showLoaderReducer)
	const showLoader = showLoaderReducer.show;
	const catchHandle = useCatchHandler();
	const currentURL = window.location.href;
	let isManualFilter = false;
	
	const [scrollPosition, setScrollPosition] = useState(0);
    const scrollRef = useRef(null);

	useEffect(() => {
		dispatch(activeMenuAction('daily-tokens'));
		getTokenUsersList();
		// setIsFilterClicked(false);
		if (!onLoading) {
			if (searchText) {
				const delayDebounceFn = setTimeout(() => {
					handleSearchChange(defaultStatus);
				}, 1000);
				setOnLoading(true);
				return () => clearTimeout(delayDebounceFn);
			}
			else {
				const delayDebounceFn = setTimeout(() => {
					handleSearchChange(defaultStatus);
				}, 1000);
				setOnLoading(true);
				return () => clearTimeout(delayDebounceFn);
			}
		}
	}, [tokenUsersList,searchText, defaultStatus]);

	const handleScroll = () => {
		const container = document.getElementById('scroll');
		if (container.scrollTop + container.clientHeight >= container.scrollHeight - 20 && !loading) {
			if (tokenUsersList.length < dailyTokensCount) {
				setScrollPosition(container.scrollTop);
				getTokenUsersList();
			}
		}
	};

	const callBack = () => {	
		setTokenUsersList([])
	}



	const getTokenUsersList = (searchValue, tokenStatus, searchDate, pageNumber) => {
		setLoading(true)
		const requestData = {
			encryptedClientId: localStorage.getItem("encryptedClientId"),
			// pageNumber: searchText || isManualFilter ? 1 : tokenStatus != "All" ? pageNumber || currentPage : pageNumber ? pageNumber : currentPage,
			pageNumber: pageNumber ? pageNumber : currentPage,
			pageSize: itemsPerPage,
			searchText: isManualFilter ? searchValue : searchText,
			searchDate: searchDate ? searchDate : searchDateValue ? searchDateValue : null,
			status: tokenStatus === "All" ? '' : tokenStatus ? tokenStatus : defaultStatus === "All" ? '' : defaultStatus
		}

		if(showLoading){
			dispatch(showLoaderAction(true));
		}
		if (searchText) {
			dispatch(showLoaderAction(false));
		  }

		axios.post(REACT_APP_MY_ENV + "/get-token-users", requestData, {
			headers: {
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			},
		}).then(function (response) {

			if (response.data.status === 200) {				
				dispatch(showLoaderAction(false));
				setDailyTokensCount(response.data.data.dailytTokensCount)
				// setTokenUsersList(tokenUsersList => [...tokenUsersList, ...response.data.data.tokenUsersList]);
				if(searchText || isFilterClicked){
					const searchInput = document.getElementById("searchDateValue");
					if((searchInput || isFilterClicked) && (pageNumber ? pageNumber : currentPage) > 1){
						setTokenUsersList(uniqBy([...tokenUsersList, ...response.data.data.tokenUsersList], 'id'));
					}
					else{
						setTokenUsersList(response.data.data.tokenUsersList);
					}
				  }
				  else{
					setTokenUsersList(uniqBy([...tokenUsersList, ...response.data.data.tokenUsersList], 'id'));
				  }
				setOnGoingToken(response.data.data.onGoingToken)
				setCurrentPage(currentPage => currentPage + 1);
				setLoading(false)
				setShowLoading(false)
			}
			else {
				dispatch(showLoaderAction(false));
				// setTokenUsersList([]);
				setTimeout(function () {
					$("#no-users-margin").html("No tokens generated for the date!");
				}, 200);
			}
		}).catch(function (error) {
			catchHandle(error, "false", currentURL)
			dispatch(showLoaderAction(false));
			const data = {
				Subject: "Front end error from get-token-users",
				BodyText: error.message
			}
			sendMail(data);
			showToast("Something went wrong, please contact Administrator!", "error")
		});
	}

	const handleFieldChange = (e) => {
		callBack()
		setCurrentPage(1)
		setScrollPosition(0)
		if (e.target.name == "searchDateValue") {
			setSearchDateValue(e.target.value);
			getTokenUsersList('', '', e.target.value,1);
		}
	}

	const onSearchTextChange = (e) => {
		setSearchText(e.target.value)
		setOnLoading(false)
	}

	const handleSearchChange = (defaultStatus) => {
		callBack()
		 setCurrentPage(1)
		 setScrollPosition(0)
		if (searchText) {
			getTokenUsersList(searchText, defaultStatus, searchDateValue, 1);
		} else if (!searchText) {
			setSearchText("");
			getTokenUsersList("","","",1)
		}
	}
	const handleFilterClick = async (e) => {
		isManualFilter = true;
		setCurrentPage(1)
		//  callBack()
		setScrollPosition(0)
		dispatch(showLoaderAction(true));
		if (!isFilterClicked) {
			setIsFilterClicked(true);
			dispatch(showLoaderAction(false));
			setSearchText('')
			getTokenUsersList("", "All","",1)
		}
		else {
			setDefaultStatus('All');
			 setIsFilterClicked(false);
			setSearchText('')
			getTokenUsersList("","","",1)
		}
	}

	const handleFilterChange = (status) => {
		 callBack()
		setCurrentPage(1)
		setScrollPosition(0)
		isManualFilter = true;
		getTokenUsersList(searchText, status, searchDateValue, 1);
		setDefaultStatus(status)
		setLoading(true);
	}

	const handlePageClick = ({ selected }) => {
		setCurrentPage(selected + 1);
		getTokenUsersList(searchText, defaultStatus, searchDateValue, selected + 1)
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		onSearchTextChange({ target: { value: searchText } });
	};

	const CustomTooltip = ({ text, children }) => {
		return (
		  <div className="tooltip-container">
			{text && <span className="tooltip-text">{text}</span>}
			{children}
		  </div>
		);
	  }

	const getUserOrder = (userTokenId) => {
		const data = {
			userTokenId: userTokenId.toString(),
		};
		axios.post(
			REACT_APP_MY_ENV + "/get-user-orders",
			data,
			{
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json",
				},
			}
		).then(function (response) {
			if (response.data.status === 200) {
				dispatch(showLoaderAction(false));
				setPopoverOpen(!popoverOpen);
				setUserOrders(response.data.data.orderList);
				setGrandTotal(response.data.data.grandTotal)
			} else {
				dispatch(showLoaderAction(false));
				showToast("Order details not found!", "error");
			}
		}).catch(function (error) {
			catchHandle(error, "false", currentURL)
			dispatch(showLoaderAction(false));
			const data = {
				Subject: "Front end error from get-client-user",
				BodyText: error.message
			}
			sendMail(data);
			showToast("Something went wrong, please contact Administrator!", "error")
		});
	}

	const togglePopover = (userTokenId) => {
		if (localStorage.getItem("EnableMenu") == "true" && userTokenId) {
			getUserOrder(userTokenId);
		}
		else {
			showToast("No Orders", "error")
		}
	};

	return (
		<>
			{showLoader ? (<ListPlaceholder skeletonName='Daily Tokens' showClientFooter={true} showAvatar={false}/>)
			:
			<div class="page">
				<div className='containerCard'>
					<div class="navbar navbar-style-1">
						<div class="navbar-inner navbar-bg-theme">
							<Link to={-1} class="link back">
								<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M0.439312 13.0606L5.75391 18.3752C6.04683 18.6682 6.43069 18.8146 6.81459 18.8146C7.1985 18.8146 7.58236 18.6682 7.87528 18.3752C8.46103 17.7894 8.46103 16.8397 7.87528 16.2539L5.12133 13.5H22.5C23.3284 13.5 24 12.8284 24 12C24 11.1716 23.3284 10.5 22.5 10.5H5.12133L7.87528 7.7461C8.46103 7.1603 8.46103 6.21057 7.87528 5.62477C7.28944 5.03898 6.33975 5.03898 5.75391 5.62477L0.439312 10.9394C-0.146437 11.5251 -0.146437 12.4749 0.439312 13.0606Z" fill="white" />
								</svg>
							</Link>
							<div class="title text-white">Daily Tokens</div>
						</div>
					</div>

					<div class="page-content inner-page pt-10" id="scroll" onScroll={() => handleScroll()} ref={scrollRef}>
						<div class="searchbar-backdrop"></div>
						<div class="container">
							{localStorage.getItem("ShowFilters") == "true" &&
								<div class="filter-bx">
									<form data-search-container=".search-list" data-search-in=".item-title" class="searchbar searchbar-init search-box list-search-bx margin-0">
										<div class="searchbar-inner">
											<div class="searchbar-input-wrap  searchbar-input-wrap-pad ">
												<input
													type="date"
													className='date-daily-token-input special-date-input'
													name='searchDateValue'
													id='searchDateValue'
													placeholder="Search user by name..."
													onChange={handleFieldChange}
													value={searchDateValue ? searchDateValue : moment().format('YYYY-MM-DD')}
													max={moment().format('YYYY-MM-DD')}
												/>
											</div>
										</div>
									</form>
									<Link to=""
										className={`${!isFilterClicked ? "filter-btn filter-btn-clicked" : "filter-btn"}`}
										onClick={() => { setSearchText(''); handleFilterClick(); }}
									>
										<svg enable-background="new 0 0 507.918 507.918" height="24" viewBox="0 0 507.918 507.918" width="24" xmlns="http://www.w3.org/2000/svg" >
											<g><path d="m184.697 215.473v269.358c0 12.751 10.336 23.087 23.087 23.087h92.349c12.751 0 23.087-10.336 23.087-23.087v-269.358l133.905-178.534c7.65-10.201 5.583-24.672-4.617-32.322-3.996-2.997-8.857-4.617-13.852-4.617h-369.395c-12.751 0-23.087 10.336-23.087 23.087 0 4.995 1.62 9.856 4.617 13.852zm207.784-169.299-110.818 147.758c-2.997 3.996-4.617 8.857-4.617 13.852v253.959h-46.174v-253.958c0-4.995-1.62-9.856-4.617-13.852l-110.82-147.759z" fill="#000000" /></g>
										</svg>
									</Link>
								</div>
							}
						</div>
						<div class="list media-list search-list searchbar-found search-list-bar daily-token-mb-1 daily-token-m-0 ">
							{
								isFilterClicked &&
								<>
									<div class="filter-bx">
										<form onSubmit={handleSubmit} data-search-container=".search-list" data-search-in=".item-title" class="searchbar  searchbar-init search-box daily-token-search-m list-search-bx">
											<div class="searchbar-inner searchbar-container">
												<div class="searchbar-input-wrap searchbar-input-wrap-pad">
													<input type="text" className='date-daily-token-input' autocomplete="off" name='searchText' placeholder="Search customer by name..." onChange={onSearchTextChange} value={searchText} />
												</div>
												<Link to="#"
													className={`${"filter-btn  filter-btn-clicked"}`}
													onClick={() => handleSearchChange(defaultStatus)}>
													<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16"> <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" /> </svg>
												</Link>
											</div>
										</form>
									</div>
									<div
										data-space-between="20"
										data-slides-per-view="auto"
										data-centered-slides="false"
										className="swiper-container swiper-init scrolling-wrapper tabbar tab-style-2 searchbar-container marginEnd-Filter "
									>
										<div className="swiper-wrapper scrolling-wrapper">
											{tokenStatuses.map((status) => (
												<div className="swiper-slide" key={status}>
													<Link id="filterCategory"
														to={""}
														className={`tab-link ${defaultStatus === status ? 'tab-link-active' : ""}`}
														onClick={() => handleFilterChange(status)}
													>
														{status === "InQueue" ? "In-Queue" : status}
													</Link>
												</div>
											))}
										</div>
									</div>
								</>
							}
							<ul>
								{
									tokenUsersList && tokenUsersList.length > 0 ?
										tokenUsersList.map((tokenUser, index) => {
											const createdAt = new Date(tokenUser.date);
											let date;
											const formattedDate = createdAt.toLocaleString('en-US', {
												hour: '2-digit',
												minute: '2-digit',
												hour12: true,
											});
											date = formattedDate;

											return (
													<li key={index}>
													<div class="item-link item-content daily-token-mt-0">
														<div class="item-inner" onClick={() => localStorage.getItem("EnableMenu") == "true" ? togglePopover(tokenUser.id) : null}>
															{localStorage.getItem("EnableMenu") == "true" ?
																<div class="item-title-row">
																	<h6 class="item-title">{tokenUser.name ? tokenUser.name : "No Name"}</h6>
																	<div class="item-after item-subtitle">
																		{date}
																	</div>
																</div> :
																<Link to={"/customer-detail/" + (tokenUser.userId)} state={{ userId: tokenUser.userId }}>
																	<div class="item-title-row">
																		<h6 class="item-title">{tokenUser.name ? tokenUser.name : "No Name"}</h6>
																		<div class="item-after item-subtitle">
																			{date}
																		</div>
																	</div>
																</Link>
															}{localStorage.getItem("EnableMenu") == "true" ? <div className='item-inner-grid'>
															<div className='leftSide-token'>
																<span class=" daily-token-text">Token No. {tokenUser.tokenNumber} {tokenUser.tokenStatus === "InQueue" && onGoingToken == tokenUser.tokenNumber ?
																	<CustomTooltip text="Ongoing"><img src={sandClockTimer} className="daily-token-inq-image" alt="" /></CustomTooltip>
																	:
																	tokenUser.tokenStatus === "InQueue" ?
																		<CustomTooltip text="Ongoing"><img src={clockImg} className="daily-token-inq-image" alt="" /></CustomTooltip>
																		: tokenUser.tokenStatus === "Completed" ? (
																			<CustomTooltip text="Completed"><img src={greenTick} className="daily-token-tick-image" alt="" /></CustomTooltip>
																		) : tokenUser.tokenStatus === "Removed" ? (
																			<CustomTooltip text="No show"><svg xmlns="http://www.w3.org/2000/svg" fill={'#cd5c5c'} className='ps-1' height="13px" viewBox="0 0 512 512"><path d="M367.2 412.5L99.5 144.8C77.1 176.1 64 214.5 64 256c0 106 86 192 192 192c41.5 0 79.9-13.1 111.2-35.5zm45.3-45.3C434.9 335.9 448 297.5 448 256c0-106-86-192-192-192c-41.5 0-79.9 13.1-111.2 35.5L412.5 367.2zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z" /></svg></CustomTooltip>
																		) : tokenUser.tokenStatus === "Cancelled" ? (
																		<CustomTooltip text="Cancelled"><svg xmlns="http://www.w3.org/2000/svg" fill={'#cd5c5c'} height="13px" className="d-flex-icon" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" /></svg></CustomTooltip>
																	) : null}</span>
																</div>
																<div className='rightSide-Token'>
																	{/* {tokenUser.phoneNumber &&
																		<span class=" ">
																			<a className=" get_token_color tab-link Phone-icon-gt " href={`tel:${tokenUser.phoneNumber}`}>
																				<svg enable-background="new 0 0 507.983 507.983" height="18" fill="#777777" viewBox="0 0 507.950 507.950" width="20" xmlns="http://www.w3.org/2000/svg">
																					<g>
																						<path d="m200.75 148.678c11.79-27.061 5.828-58.58-15.03-79.466l-48.16-48.137c-15.999-16.19-38.808-23.698-61.296-20.178-22.742 3.34-42.496 17.4-53.101 37.794-23.286 43.823-29.276 94.79-16.784 142.817 30.775 121.9 198.319 289.559 320.196 320.104 16.452 4.172 33.357 6.297 50.33 6.326 32.253-.021 64.009-7.948 92.487-23.087 35.138-18.325 48.768-61.665 30.443-96.803-3.364-6.451-7.689-12.352-12.828-17.502l-48.137-48.16c-20.894-20.862-52.421-26.823-79.489-15.03-12.631 5.444-24.152 13.169-33.984 22.787-11.774 11.844-55.201-5.31-98.675-48.76s-60.581-86.877-48.876-98.698c9.658-9.834 17.422-21.361 22.904-34.007zm-6.741 165.397c52.939 52.893 124.14 88.562 163.919 48.76 5.859-5.609 12.688-10.108 20.155-13.275 9.59-4.087 20.703-1.9 28.028 5.518l48.137 48.137c5.736 5.672 8.398 13.754 7.157 21.725-1.207 8.191-6.286 15.298-13.645 19.093-33.711 18.115-73.058 22.705-110.033 12.836-104.724-26.412-260.078-181.765-286.489-286.627-9.858-37.009-5.26-76.383 12.86-110.126 3.823-7.318 10.924-12.358 19.093-13.552 1.275-.203 2.564-.304 3.856-.3 6.714-.002 13.149 2.683 17.869 7.457l48.137 48.137c7.407 7.321 9.595 18.421 5.518 28.005-3.153 7.516-7.652 14.394-13.275 20.294-39.804 39.686-4.18 110.817 48.713 163.918z" />
																					</g>
																				</svg>
																			</a>
																		</span>}
																	{tokenUser.emailAddress &&
																		<span className='leftSide-email-token'>
																			<a className=" get_token_color tab-link Phone-icon-gt " href={`mailto:${tokenUser.emailAddress}`}>
																				<svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16"> <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" /> </svg>
																			</a>
																		</span>
																	} */}
																</div>
															</div>
														:	<Link to={"/customer-detail/" + (tokenUser.userId)} state={{ userId: tokenUser.userId }}>
															<div className='item-inner-grid'>
																<div className='leftSide-token'>
																	<span class=" daily-token-text">Token No. {tokenUser.tokenNumber} {tokenUser.tokenStatus === "InQueue" && onGoingToken == tokenUser.tokenNumber ?
																		<CustomTooltip text="Ongoing"><img src={sandClockTimer} className="daily-token-inq-image" alt="" /></CustomTooltip>
																		:
																		tokenUser.tokenStatus === "InQueue" ?
																			<CustomTooltip text="Ongoing"><img src={clockImg} className="daily-token-inq-image" alt="" /></CustomTooltip>
																			: tokenUser.tokenStatus === "Completed" ? (
																				<CustomTooltip text="Completed"><img src={greenTick} className="daily-token-tick-image" alt="" /></CustomTooltip>
																			) : tokenUser.tokenStatus === "Removed" ? (
																				<CustomTooltip text="No show"><svg xmlns="http://www.w3.org/2000/svg" fill={'#cd5c5c'} className='ps-1' height="13px" viewBox="0 0 512 512"><path d="M367.2 412.5L99.5 144.8C77.1 176.1 64 214.5 64 256c0 106 86 192 192 192c41.5 0 79.9-13.1 111.2-35.5zm45.3-45.3C434.9 335.9 448 297.5 448 256c0-106-86-192-192-192c-41.5 0-79.9 13.1-111.2 35.5L412.5 367.2zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z" /></svg></CustomTooltip>
																			) : tokenUser.tokenStatus === "Cancelled" ? (
																			<CustomTooltip text="Cancelled"><svg xmlns="http://www.w3.org/2000/svg" fill={'#cd5c5c'} height="13px" className="d-flex-icon" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" /></svg></CustomTooltip>
																		) : null}</span>
																	</div>
																	<div className='rightSide-Token'>
																		{/* {tokenUser.phoneNumber &&
																			<span class=" ">
																				<a className=" get_token_color tab-link Phone-icon-gt " href={`tel:${tokenUser.phoneNumber}`}>
																					<svg enable-background="new 0 0 507.983 507.983" height="18" fill="#777777" viewBox="0 0 507.950 507.950" width="20" xmlns="http://www.w3.org/2000/svg">
																						<g>
																							<path d="m200.75 148.678c11.79-27.061 5.828-58.58-15.03-79.466l-48.16-48.137c-15.999-16.19-38.808-23.698-61.296-20.178-22.742 3.34-42.496 17.4-53.101 37.794-23.286 43.823-29.276 94.79-16.784 142.817 30.775 121.9 198.319 289.559 320.196 320.104 16.452 4.172 33.357 6.297 50.33 6.326 32.253-.021 64.009-7.948 92.487-23.087 35.138-18.325 48.768-61.665 30.443-96.803-3.364-6.451-7.689-12.352-12.828-17.502l-48.137-48.16c-20.894-20.862-52.421-26.823-79.489-15.03-12.631 5.444-24.152 13.169-33.984 22.787-11.774 11.844-55.201-5.31-98.675-48.76s-60.581-86.877-48.876-98.698c9.658-9.834 17.422-21.361 22.904-34.007zm-6.741 165.397c52.939 52.893 124.14 88.562 163.919 48.76 5.859-5.609 12.688-10.108 20.155-13.275 9.59-4.087 20.703-1.9 28.028 5.518l48.137 48.137c5.736 5.672 8.398 13.754 7.157 21.725-1.207 8.191-6.286 15.298-13.645 19.093-33.711 18.115-73.058 22.705-110.033 12.836-104.724-26.412-260.078-181.765-286.489-286.627-9.858-37.009-5.26-76.383 12.86-110.126 3.823-7.318 10.924-12.358 19.093-13.552 1.275-.203 2.564-.304 3.856-.3 6.714-.002 13.149 2.683 17.869 7.457l48.137 48.137c7.407 7.321 9.595 18.421 5.518 28.005-3.153 7.516-7.652 14.394-13.275 20.294-39.804 39.686-4.18 110.817 48.713 163.918z" />
																						</g>
																					</svg>
																				</a>
																			</span>}
																		{tokenUser.emailAddress &&
																			<span className='leftSide-email-token'>
																				<a className=" get_token_color tab-link Phone-icon-gt " href={`mailto:${tokenUser.emailAddress}`}>
																					<svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16"> <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" /> </svg>
																				</a>
																			</span>
																		} */}
																	</div>
																</div></Link> }
															</div>
														</div>
													</li>
											)
										})
										:
										<h6 id="no-users-margin">Loading...</h6>
								}
							</ul>
						</div>

							<div className="popover popover-menu modal-in popover-on-bottom popover-on-right" style={{ display: popoverOpen ? "block" : "none", top: "100px", left: "50%", transform: "translateX(-50%)" }}>
								<div className="popover-arrow on-top"></div>
								<div className="popover-angle"></div>
								<div className="popover-inner">
									<div className="container-fluid">
										<div className="row">
											<div className="col">
												<div className="navbar">
													<div className="navbar-inner justify-content-between">
														<div className="title"><strong>Order Details</strong></div>
														<div className="popover-close" onClick={() => setPopoverOpen(false)}>
															<svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
																<path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
																<path d="M10 10l4 4m0 -4l-4 4" />
															</svg>
														</div>
													</div>
												</div>

												<div className="containerCard stat-box stat-box-pad state-box-border mb-4">
													<div className="page-content pt-0">
														<div className="list media-list search-list searchbar-found menu-item-card search-list-bar daily-token-mb-1 daily-token-m-0 user-list">
															<div className="item-link item-content title-bottom-border daily-token-mt-0">
																<div className="item-inner height-0">
																	<div className="item-title-row item-details justify-content-between title-center-menu align-items-center">
																		<h4 className="item-title title-font-size">Item</h4>
																		<h4 className="item-title title-font-size">Total</h4>
																	</div>
																</div>
															</div>

															<div className="scrollable">
																{userOrders && userOrders.length > 0 ? (
																	userOrders.map((data, index) => (
																		<ul key={index}>
																			<li>
																				<div className="item-link item-content menu-item-inner client-menu-list daily-token-mt-0">
																					<div className="item-inner menu-item-inner">
																						<div className="item-title-row item-details">
																							<h6 className="item-title item-title-popup text-font-size">{data.itemName}</h6>
																							<h6 className="item-title item-title-popup text-font-size">{data.quantity} x ₹ {data.rate}</h6>
																							<h6 className="item-title item-title-popup text-font-size">₹ {data.rate}</h6>
																						</div>
																						{data.description && <div>
																							<p className="description-title font-size-des">{data.description}</p>
																						</div>}
																					</div>
																				</div>
																			</li>
																		</ul>
																	))
																) : (
																	<span id="no-users">Loading...</span>
																)}
															</div>

															<div className="grand-total-height">
																<div className="item-title-row item-details title-center-menu">
																	<p className="item-title title-gray title-font-size grandtotal-margin">Grand Total</p>
																	<div className="item-title title-font-size grandtotal-margin">₹ {grandTotal}</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

						{/* {
							tokenUsersList && tokenUsersList.length > 0 ?
								<div className='notification-pagination'>
									<ReactPaginate
										pageCount={pageCount}
										pageRangeDisplayed={10}
										marginPagesDisplayed={0}
										onPageChange={handlePageClick}
										previousLabel={"Prev"}
										nextLabel={"Next"}
										breakLabel={"..."}
										containerClassName={"pagination"}
										activeClassName={"active"}
									// onPageActive={currentPage}
									// selectedPageRel={pageCount}
					 	   			    forcePage={ currentPage === 1 ? 0 : currentPage - 1}
									/>
								</div>
								: ""} */}
					</div>
					<ClientFooter />
				</div>
			</div>
			}
		</>
	)
}

export default TokenUsersList