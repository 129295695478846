import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../../App.css";
import "../../../css/framework7-bundle.css";
import "../../../css/app.css";
import "../../../css/font-awesome/css/all.min.css";
import "../../../css/line-awesome/css/line-awesome.min.css";
import "../../../css/style.css";
import "../../../css/perfect-scrollbar.css";
import "../../../img/f7-icon-square.png";
import "../../../img/f7-icon.png";
import axios from "axios";
import $ from "jquery";
import toast from "react-hot-toast";
import { getGreetings, checkUpdate } from "../../../utils/utilities.js";
import defaultOutlet from "../../../img/outlet-default.png";
import { useDispatch, useSelector } from "react-redux";
import { showLoaderAction } from "../../../actions/index";
import { activeMenuAction } from "../../../actions/index";
import Footer from '../../Master/Footer';
import { useCatchHandler, showToast } from "../../../utils/utilities"
import { establishNotificationHubConnection } from "../../../utils/utilities.js";
import { NotificationCountAction } from "../../../actions/index"
import ListPlaceholder from "../../Placeholders/ListPlaceholder.js";

const { REACT_APP_ISHTTPS, REACT_APP_MY_ENV } = process.env;

function Dashboard() {
  const catchHandle = useCatchHandler();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const showLoaderReducer = useSelector((state) => state.rootReducer.showLoaderReducer)
  const showLoader = showLoaderReducer.show;
  const [outlets, setOutlets] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [greetings, setGreetings] = useState([]);
  const [cityId, setCityId] = useState("");
  const [outletLengh, setOutletLengh] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userName] = useState(localStorage.getItem("username"));

  let city = localStorage.getItem("CityId");
  let cityName = localStorage.getItem("CityName");

  const url = window.location.href;
  const urlObject = new URL(url);
  const searchParams = urlObject.searchParams;
  const directUrl = searchParams.get("r");

  const notificationCountReducer = useSelector(
    (state) => state.rootReducer.notificationCountReducer);
  var notificationCount = notificationCountReducer.count;

  let paramsData = {
    clientId: localStorage.getItem("encryptedUserId")
  }

  let objString = '?' + new URLSearchParams(paramsData).toString();

  useEffect(() => {
    checkUpdate();
    if(city== null || city == ""){
      navigate("/city-list")
    }else{
      localStorage.setItem("CityId",city)
      getOutlets(city);
    }
    // getCityList()
    dispatch(activeMenuAction('dashboard'));
    if (localStorage.getItem("isLogin", true)) {
      setIsLoggedIn(true);
    }else{
     
  
    }
    const greetings = getGreetings();
    setGreetings(greetings);
    if (directUrl) {
      navigate(`/${directUrl}`);
    }
    establishNotificationHubConnection(objString, localStorage.getItem("encryptedUserId"), dispatch)
  }, []);

  function getOutlets(city_id) {
    let data = {
      noOfOutlets: 5,
      userId: localStorage.getItem("encryptedUserId"),
      cityId:localStorage.getItem("CityId") ? localStorage.getItem("CityId") : cityId ? cityId : city_id  ? city_id : ""
    };
    dispatch(showLoaderAction(true));
    axios
      .post(REACT_APP_MY_ENV + "/dashboard-outlets", data)
      .then(function (response) {
        if (response.data.status === 500) {
          dispatch(showLoaderAction(false));
          showToast(response.data.message, "error");
        } else {
          dispatch(showLoaderAction(false));
          setOutlets(response.data.data.outlets);
          dispatch(NotificationCountAction(response.data.data.notificationCount));
        }
      }).catch(function (error) {
        dispatch(showLoaderAction(false));
        showToast("Something went wrong, please contact Administrator!", "error")
      });
  }

  function setEncryptedClientId(outlet) {
    localStorage.setItem("EncryptedClientId", outlet.encryptedClientId);
  }

  const handleLinkClick = (e) => {
    if (!isLoggedIn && e.currentTarget.pathname === '/notification-list') {
      e.preventDefault();
      toast.error('Please login first to view this screen!', {
        className: 'custom-toast-error',
        icon: null,
      });
    }
    navigate('/sign-in')
  };

  // const getCityList = async () => {
  //   let data={
  //     SearchText : searchText ? searchText : ""
  //   }
  //     const response = await axios.post(REACT_APP_MY_ENV + "/city-list",data).then(function (response) {
  //       if (response.data.status === 200) {
  //         setCityList(response.data.data.cityList)
  //       } else {
  //         showToast(response.data.message, "error");
  //       }
  //     }).catch(function (error) {
  //       dispatch(showLoaderAction(false));
  //       const data = {
  //         Subject: "Front end error from category-list",
  //         BodyText: error.message
  //       }
  //       // sendMail(data);
  //       showToast("Something went wrong, please contact Administrator!", "error")
  //     })
  //   };;

  // const handleCityNameChange = (e) => {
  //   const selectedCityId = e.target.value;
  //   setCityId(selectedCityId);
  //   localStorage.setItem("CityId", selectedCityId);
  //   getOutlets(selectedCityId)
  // };

  return (
    <>
      {showLoader ? (<ListPlaceholder showAvatar={true} skeletonName="Dashboard" />)
      :
      <div className="page page-home dashboard-page client-dashboard">
        <div className='containerCard'>
          <div className="dashboardHeader navbar-bg-theme">
            <Link
                className="notification-bar"
              >
                <div className="info">
                  {userName &&
                    <span className="text text-white">{greetings}</span>}
                  <h2 className="title text-white">{userName ? userName : greetings}</h2>
                  <Link to={"/city-list"}>
                  <span className="text-white">{cityName} <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.9993 5.48404C9.59314 5.48404 7.64258 7.4346 7.64258 9.84075C7.64258 12.2469 9.59314 14.1975 11.9993 14.1975C14.4054 14.1975 16.356 12.2469 16.356 9.84075C16.356 7.4346 14.4054 5.48404 11.9993 5.48404ZM11.9993 12.0191C10.7962 12.0191 9.82096 11.0438 9.82096 9.84075C9.82096 8.6377 10.7962 7.66242 11.9993 7.66242C13.2023 7.66242 14.1776 8.6377 14.1776 9.84075C14.1776 11.0438 13.2023 12.0191 11.9993 12.0191Z" fill="#ffff"></path>
    <path d="M21.793 9.81896C21.8074 4.41054 17.4348 0.0144869 12.0264 5.09008e-05C6.61797 -0.0143851 2.22191 4.35827 2.20748 9.76664C2.16044 15.938 5.85106 21.5248 11.546 23.903C11.6884 23.9674 11.8429 24.0005 11.9991 24C12.1565 24.0002 12.3121 23.9668 12.4555 23.9019C18.1324 21.5313 21.8191 15.9709 21.793 9.81896ZM11.9992 21.7127C7.30495 19.646 4.30485 14.9691 4.38364 9.84071C4.38364 5.63477 7.79323 2.22518 11.9992 2.22518C16.2051 2.22518 19.6147 5.63477 19.6147 9.84071V9.91152C19.6686 15.0154 16.672 19.6591 11.9992 21.7127Z" fill="#ffff"></path>
  </svg></span>  </Link>

                  
                </div>
                <div className="media">
                {REACT_APP_ISHTTPS == "true" &&
                  <Link to="/qr-scanner" class="icons">
                    <svg width="1.80em" height="1.80em" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                      <polygon fill="var(--ci-primary-color, #ffff)" points="48 48 176 48 176 16 16 16 16 176 48 176 48 48" class="ci-primary" />
                      <path fill="var(--ci-primary-color, #ffff)" d="M176,176V80H80v96h96Zm-64-64h32v32H112Z" class="ci-primary" />
                      <polygon fill="var(--ci-primary-color, #ffff)" points="328 48 464 48 464 176 496 176 496 16 328 16 328 48" class="ci-primary" />
                      <path fill="var(--ci-primary-color, #ffff)" d="M432,176V80H336v96h96Zm-64-64h32v32H368Z" class="ci-primary" />
                      <polygon fill="var(--ci-primary-color, #ffff)" points="176 464 48 464 48 336 16 336 16 496 176 496 176 464" class="ci-primary" />
                      <path fill="var(--ci-primary-color, #ffff)" d="M176,336H80v96h96V336Zm-32,64H112V368h32Z" class="ci-primary" />
                      <polygon fill="var(--ci-primary-color, #ffff)" points="464 464 328 464 328 496 496 496 496 336 464 336 464 464" class="ci-primary" />
                      <polygon fill="var(--ci-primary-color, #ffff)" points="272 304 400 304 400 368 432 368 432 272 272 272 272 304" class="ci-primary" />
                      <polygon fill="var(--ci-primary-color, #ffff)" points="432 432 432 400 240 400 240 272 80 272 80 304 208 304 208 432 432 432" class="ci-primary" />
                      <rect width="32" height="96" x="208" y="80" fill="var(--ci-primary-color, #ffff)" class="ci-primary" />
                      <polygon fill="var(--ci-primary-color, #ffff)" points="80 240 304 240 304 80 272 80 272 208 80 208 80 240" class="ci-primary" />
                      <rect width="96" height="32" x="336" y="208" fill="var(--ci-primary-color, #ffff)" class="ci-primary" />
                      <rect width="32" height="32" x="336" y="336" fill="var(--ci-primary-color, #ffff)" class="ci-primary" />
                      <rect width="32" height="32" x="272" y="336" fill="var(--ci-primary-color, #ffff)" class="ci-primary" />
                    </svg>
                  </Link>
                }
                <Link
                  className="icons"
                  to="/notification-list"
                  onClick={handleLinkClick}
                >
                  {/* <img src={avatar} alt="avatar" onError={(error) => {error.target.src = avatar}} /> */}
                  <svg width="24" id="Capa_1" fill="white" enableBackground="new 0 0 512 512" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="m450.201 407.453c-1.505-.977-12.832-8.912-24.174-32.917-20.829-44.082-25.201-106.18-25.201-150.511 0-.193-.004-.384-.011-.576-.227-58.589-35.31-109.095-85.514-131.756v-34.657c0-31.45-25.544-57.036-56.942-57.036h-4.719c-31.398 0-56.942 25.586-56.942 57.036v34.655c-50.372 22.734-85.525 73.498-85.525 132.334 0 44.331-4.372 106.428-25.201 150.511-11.341 24.004-22.668 31.939-24.174 32.917-6.342 2.935-9.469 9.715-8.01 16.586 1.473 6.939 7.959 11.723 15.042 11.723h109.947c.614 42.141 35.008 76.238 77.223 76.238s76.609-34.097 77.223-76.238h109.947c7.082 0 13.569-4.784 15.042-11.723 1.457-6.871-1.669-13.652-8.011-16.586zm-223.502-350.417c0-14.881 12.086-26.987 26.942-26.987h4.719c14.856 0 26.942 12.106 26.942 26.987v24.917c-9.468-1.957-19.269-2.987-29.306-2.987-10.034 0-19.832 1.029-29.296 2.984v-24.914zm29.301 424.915c-25.673 0-46.614-20.617-47.223-46.188h94.445c-.608 25.57-21.549 46.188-47.222 46.188zm60.4-76.239c-.003 0-213.385 0-213.385 0 2.595-4.044 5.236-8.623 7.861-13.798 20.104-39.643 30.298-96.129 30.298-167.889 0-63.417 51.509-115.01 114.821-115.01s114.821 51.593 114.821 115.06c0 .185.003.369.01.553.057 71.472 10.25 127.755 30.298 167.286 2.625 5.176 5.267 9.754 7.861 13.798z" /></svg>                  
                  {notificationCount > 0 && (
                    <span class="badge notify-badge rounded-pill badge-notification">
                      {notificationCount}
                    </span>
                  )}
                </Link>
              </div>
            </Link>
          </div>
          <div className="page-content inner-page pt-10">
              {/* <div class="container">
                <div class="item-input-wrap profile-font city-drowpdown-widht">
   
                  <select
                    name="categoryId"
                    class="form-control"
                    placeholder=""
                    onChange={(e) => {
                      handleCityNameChange(e);
                    }}
                    value={city}
                    selected={city}
                     >
                    {cityList.map((city) => (
                      <option className="dropdown-city-option" key={city.cityId} value={city.cityId}>
                        {city.cityName}                      </option>
                    ))}
                  </select>
                </div>
              </div> */}
            <div className="container">
              {outlets.length > 0 &&
                outlets.map((outlet, index) => {
                  if (outlet.categoryName === "Featured" && outlet.outlets.length === 0) {
                    return null;
                  }
                  return (
                    <>
                      <div className="title-bar">
                        <h5 className="dz-title">
                          {outlet.categoryName}
                        </h5>
                        {index !== 0 && (
                          <Link
                            to="/outlets"
                            state={{
                              categoryId: outlet.categoryId,
                              dashboard: true,
                            }}
                            className="button text-capitalize"
                          >
                            More
                          </Link>
                        )}
                      </div>
                      <div data-space-between={15}
                        data-slides-per-view="5"
                        data-centered-slides="false"
                        className="swiper-container swiper-init categories-swiper scrolling-wrapper"
                      >
                        <div className="swiper-wrapper scrolling-wrapper outlet-swiper">
                          {outlet.outlets.map((outlets) => {
                            return (
                              <div
                                class="swiper-slide swiper-slide-active"
                                role="group"
                                aria-label="1 / 5"
                              >
                                <div class="item-bx item-grid">
                                  <div class="dz-media">
                                    <Link
                                      to={"/outlet/" + outlets.slug}
                                      state={{ outlet: outlets }}
                                    >
                                      <img
                                        src={outlets.logoFilePathURL}
                                        alt={outlets.name}
                                        onError={(error) => { error.target.src = defaultOutlet }}
                                      />
                                    </Link>
                                  </div>
                                  <div class="dz-info">
                                    <div class="dz-head">
                                      <h6 class="item-title dashboard-title">
                                        <Link
                                          to={"/outlet/" + outlets.slug}
                                          state={{ outlet: outlets }}
                                        >
                                          {outlets.name}
                                        </Link>
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                   </div>
                      </div>
                    </>
                  );
                })}
                {outlets.length === 1 && 
                  <div class=" dz-title"><b>No outlets available for the selected city!</b></div>}
            </div>
          </div>
          <Footer />
        </div>
      </div>
      }
    </>
  );
}

export default Dashboard;
