import React from "react";
import { Link } from "react-router-dom";
import ClientFooter from "../Master/ClientFooter";

const ReportsPlaceholder = ({
  skeletonName = "Dashboard",
  noOfItem = 8,
}) => {
  const itemsArray = Array.from({ length: noOfItem }, (_, index) => index);

  return (
    <>
      <div className="outlet-dashboard page">
        <div className="containerCard">
          <div className="navbar navbar-style-1 page-content">
            <div className="navbar-inner navbar-bg-theme">
              <Link to={"javascript:void(0)"} className="link back">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.439312 13.0606L5.75391 18.3752C6.04683 18.6682 6.43069 18.8146 6.81459 18.8146C7.1985 18.8146 7.58236 18.6682 7.87528 18.3752C8.46103 17.7894 8.46103 16.8397 7.87528 16.2539L5.12133 13.5H22.5C23.3284 13.5 24 12.8284 24 12C24 11.1716 23.3284 10.5 22.5 10.5H5.12133L7.87528 7.7461C8.46103 7.1603 8.46103 6.21057 7.87528 5.62477C7.28944 5.03898 6.33975 5.03898 5.75391 5.62477L0.439312 10.9394C-0.146437 11.5251 -0.146437 12.4749 0.439312 13.0606Z"
                    fill="white"
                  />
                </svg>
              </Link>
              <div className="title text-white">{skeletonName}</div>
            </div>
          </div>
          <div className="page-content inner-page pt-5">
            <div className="container dashboard-container">
              <div className="section mb-100">
                <div className="row mt-0 reports-row">
                  {itemsArray.map((number) => (
                    <div className="col-6">
                      {/* --skeleton design start */}
                      <div
                        class="card card-outline skeleton-text skeleton-effect-wave"
                        style={{ marginLeft: "0px", marginRight: "0px" }}
                      >
                        <div class="card-header">Card Header</div>
                        <div class="card-content card-content-padding">
                          Lorem
                        </div>
                      </div>
                      {/* --skeleton design end */}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <ClientFooter />
        </div>
      </div>
    </>
  );
};

export default ReportsPlaceholder;
