import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "../../../App.css";
import "../../../css/app.css";
import "../../../css/font-awesome/css/all.min.css";
import "../../../css/line-awesome/css/line-awesome.min.css";
import "../../../css/style.css";
import "../../../css/perfect-scrollbar.css";
import "../../../img/f7-icon-square.png";
import "../../../img/f7-icon.png";
import defaultOutlet from '../../../img/outlet-default.png';
import { checkLoginStatus } from '../../../js/main.js';
import axios from "axios";
import moment from "moment";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useDispatch, useSelector } from "react-redux";
import { showLoaderAction } from "../../../actions/index";
import { sendMail, useCatchHandler, showToast } from "../../../utils/utilities"
import { useNotification } from "../../../utils/utilities"
import $ from "jquery";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { activeMenuAction } from "../../../actions/index";
import Footer from '../../Master/Footer';
import ReactPaginate from 'react-paginate';
import { uniqBy } from "lodash";
import ListPlaceholder from "../../Placeholders/ListPlaceholder.js"

const { REACT_APP_MY_ENV } = process.env;

function Favourites() {
  const dispatch = useDispatch();
  const showLoaderReducer = useSelector((state) => state.rootReducer.showLoaderReducer)
  const showLoader = showLoaderReducer.show;
  const [favourites, setFavourites] = useState([]);
  const [outlets, setOutlets] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [dataLength, setDataLength] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [showLoading, setShowLoading] = useState(true)
  const itemsPerPage = 10;
  const [favTotalCount, setFavTotalCount] = useState(0);
  const [onLoading, setOnLoading] = useState(true); 
  const pageCount = Math.ceil(favTotalCount / itemsPerPage);
  
  const catchHandle = useCatchHandler();
  const sendNotification = useNotification();

  let token = localStorage.getItem("token");
  const currentURL = window.location.href;

  const [scrollPosition, setScrollPosition] = useState(0);
  const scrollRef = useRef(null);

  useEffect(() => {
    dispatch(activeMenuAction('favourites'));
    if (localStorage.getItem("isLogin", true)) {
      setIsLoggedIn(true);   
    }
    checkLoginStatus();
    if (favourites.length <= 0)
      getFavourites();
    if (!onLoading) {
      if (searchText) {
        const delayDebounceFn = setTimeout(() => {
          onSearchHandleClick();
        }, 1000);

        return () => clearTimeout(delayDebounceFn);
      }
      else {
        const delayDebounceFn = setTimeout(() => {
          onSearchHandleClick();
        }, 1000);

        return () => clearTimeout(delayDebounceFn);
      }
    }
  }, [searchText]);

  const handleScroll = () => {
		const container = document.getElementById('scroll');
		if (container.scrollTop + container.clientHeight >= container.scrollHeight - 20 && !loading) {
			if (favourites.length < favTotalCount) {
				setScrollPosition(container.scrollTop);
				getFavourites();
			}
		}
	};

  const callBack = () => {
		setFavourites([])
	}

	useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollTop = scrollPosition;
        }
    }, [favourites]);
  
  const getFavourites = (searchText, pageNumber) => {
  setLoading(true)
  let encryptedUserId = localStorage.getItem("encryptedUserId");
  const data = {
    searchText: searchText ? searchText : searchText ? searchText : $("#searchFavourite").val() ? $("#searchFavourite").val() : "",
    pageNumber: pageNumber  ? pageNumber : currentPage,
    pageSize: itemsPerPage,
    EncryptedUserId: encryptedUserId
  }

  if(showLoading){
    dispatch(showLoaderAction(true));
  }

  axios.post(REACT_APP_MY_ENV + "/favourite-outlet-list", data,
    {
      headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' }
    }
  )
    .then(function (response) {
      if (response.data.status == 200) {
        dispatch(showLoaderAction(false));
				// setFavourites(favourites => [...favourites, ...response.data.data.favouriteOutlet]);
        if(searchText){
          const searchInput = document.getElementById("searchFavourite");
          if(searchInput && (pageNumber ? pageNumber : currentPage) > 1){
            setFavourites(uniqBy([...favourites, ...response.data.data.favouriteOutlet], 'favouriteOutletId'));
          }
          else{
            setFavourites(response.data.data.favouriteOutlet);
          }
        }
        else{
          setFavourites(uniqBy([...favourites, ...response.data.data.favouriteOutlet], 'favouriteOutletId'));
        }
				setCurrentPage(currentPage => currentPage + 1);
        setFavTotalCount(response.data.data.favTotalCount)
        setOutlets(response.data.data)
        const dataLength = response.data.data.favouriteOutlet.length;
        setDataLength(dataLength);
        if (dataLength > 0) {
          setShowFilter(true);
        } else {
          setShowFilter(false);
        }
        setLoading(false);
        setShowLoading(false)
      } else {
        dispatch(showLoaderAction(false));
        // setFavourites([]);
        setTimeout(function () {
          $("#no-favourites").html("No favourites added yet!");     
        }, 200);
      }
    }).catch(function (error) {
      catchHandle(error, "true", currentURL)
      dispatch(showLoaderAction(false));
      showToast("Something went wrong, please contact Administrator!", "error")
      const data = {
        Subject: "Front end error from favourite-outlet-list",
        BodyText: error.message
      }
      sendMail(data);
    });
}

  const ConfirmRemove = (favouriteOutletId) => async (e) => {
    confirmAlert({
      title: (
        <span className="confirm-title">
          Confirmation
        </span>
      ),
      message: 'Are you sure you want to remove this outlet from Favourites?',
      buttons: [
        {
          label: 'Yes',
          className: 'red-button',
          onClick: () => removeFavourite(favouriteOutletId)
        },
        {
          label: 'Cancel',
          className: 'gray-button',
        }
      ]
    });
  }

  const removeFavourite = async (favouriteOutletId) => {
    const data = {
      favouriteOutletId: favouriteOutletId
    }
    dispatch(showLoaderAction(true));
    const removedFavouriteOutlet = await axios.delete(REACT_APP_MY_ENV + "/remove-favourite-outlet",
      { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' }, data }
    ).then(function (removedFavouriteOutlet) {
      if (removedFavouriteOutlet.data.status === 200) {
        dispatch(showLoaderAction(false));
        notificationSendForRemoveFromFav();
        showToast(removedFavouriteOutlet.data.message, "success");
        setShowFilter(false)
        getFavourites(searchText);
        setFavourites(favourites =>
          favourites.filter(favourite => favourite.favouriteOutletId !== favouriteOutletId)
        );
      }
    }).catch(function (error) {
      dispatch(showLoaderAction(false));
      showToast("Something went wrong, please contact Administrator!", "error")
      catchHandle(error, "true", currentURL)
      const data = {
        Subject: "Front end error from remove-favourite-outlet",
        BodyText: error.message
      }
      sendMail(data);
    });
  }

  const notificationSendForRemoveFromFav = () => {
    const data = {
      ClientId: favourites[0].encryptedClientId,
      UserId: localStorage.getItem("encryptedUserId"),
      SentBy: "USER",
      Title: "Removed from Favourites",
      Description: `${localStorage.getItem("username")} removed our outlet from Favourites`,
    }
    sendNotification(data)
  }

  const onSearchTextChange = (e) => {
    setSearchText(e.target.value.trim())
    setOnLoading(false)
  }

  const onSearchHandleClick = (e) => {
    callBack()
		setCurrentPage(1)
		setScrollPosition(0)
    let currentPage = 1
    if (searchText) {
      getFavourites(searchText,currentPage)
      dispatch(showLoaderAction(false));
    } else {
      dispatch(showLoaderAction(false));
      setSearchText("")
      getFavourites("", currentPage)
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    onSearchTextChange({ target: { value: searchText } });
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      onSearchHandleClick();
    }
  };

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected + 1);
    getFavourites(searchText, selected + 1)
  };

  return (
    <>
      {showLoader ? (<ListPlaceholder skeletonName={"Favourites"} />)
      :
      <div class="page favourite-page commane-page ">
      <div className="containerCard">
        <div class="navbar navbar-style-1 navbar-bg-theme">
          <div class="navbar-inner">
            <Link to={-1} class="link back">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.439312 13.0606L5.75391 18.3752C6.04683 18.6682 6.43069 18.8146 6.81459 18.8146C7.1985 18.8146 7.58236 18.6682 7.87528 18.3752C8.46103 17.7894 8.46103 16.8397 7.87528 16.2539L5.12133 13.5H22.5C23.3284 13.5 24 12.8284 24 12C24 11.1716 23.3284 10.5 22.5 10.5H5.12133L7.87528 7.7461C8.46103 7.1603 8.46103 6.21057 7.87528 5.62477C7.28944 5.03898 6.33975 5.03898 5.75391 5.62477L0.439312 10.9394C-0.146437 11.5251 -0.146437 12.4749 0.439312 13.0606Z" fill="white" />
              </svg>
            </Link>
            <div class="title text-white">Favourites</div>
          </div>
        </div>

        <div class="page-content inner-page pt-20" id="scroll" onScroll={() => handleScroll()} ref={scrollRef}>
          <div class="searchbar-backdrop"></div>
          {/* { favourites && favourites.length > 0 || !outlets.length > 0 && searchText && searchText.length < 1 && favourites.length > 0 ? ( */}
          {showFilter && (
            <div class="container">
                 		<div class="filter-bx">
              <form onSubmit={handleSubmit} data-search-container=".search-list" data-search-in=".item-title" class="searchbar searchbar-init search-box list-search-bx">
                <div class="searchbar-inner">
                  <div class="searchbar-input-wrap searchbar-input-wrap-pad">
                    <input type="text" className='date-daily-token-input' id="searchFavourite" onChange={onSearchTextChange} placeholder="Search Outlet..." autocomplete="off"  onKeyDown={handleKeyDown}/>
                  </div>
                  <Link to="#"
								className={`${ "filter-btn  filter-btn-clicked"}`}
                onClick={onSearchHandleClick}
                >
               <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16"> <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" /> </svg>
							</Link>
                </div>

              </form>
              {!favourites ?
                <div class="list simple-list searchbar-not-found">
                  <ul>
                    <li>No result found!</li>
                  </ul>
                </div>
                : null
              }
                </div>
            </div>)}

          <div class="list search-list mb-20 container dz-list searchbar-found item-list">
            {favourites && favourites.length > 0 ?
              <ul class="row">
                {favourites && favourites.length > 0 ?
                  favourites.map((favourite) => {
                    return (
                      <li class="store-card card-list col-100 medium-50 mb-15">
                        <div class="card-media">
                          <Link to={"/outlet/" + favourite.slug} state={{ outlet: favourite, fav: true }}><LazyLoadImage className="imgCard" src={favourite.logoFilePathURL ? favourite.logoFilePathURL : defaultOutlet} onError={(error) => { error.target.src = defaultOutlet }} alt={favourite.name} /></Link>
                        </div>
                        <div class="card-info outlet-info title-container">
                          <Link to={"/outlet/" + favourite.slug} state={{ outlet: favourite, fav: true }}>
                            <h6 class="title responsive-title"><Link to={"/outlet/" + favourite.slug} state={{ outlet: favourite, fav: true }}>{favourite.name}</Link></h6>
                            <p class="time">{moment(favourite.openTime, "HH:mm:ss").format("hh:mm A")}-{moment(favourite.closeTime, "HH:mm:ss").format("hh:mm A")}</p>
                          </Link>
                          {favourite.ratings ?
                            (<Link>
                              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.43 5.84598L10.552 5.27932L8.60004 1.36865C8.53844 1.26533 8.45107 1.17978 8.34648 1.12037C8.24189 1.06096 8.12366 1.02972 8.00338 1.02972C7.88309 1.02972 7.76486 1.06096 7.66027 1.12037C7.55568 1.17978 7.46831 1.26533 7.40671 1.36865L5.44804 5.27932L1.57004 5.84598C1.44247 5.86472 1.32308 5.92005 1.22631 6.00526C1.12955 6.09047 1.05957 6.20192 1.02486 6.32609C0.990146 6.45027 0.992187 6.58185 1.03073 6.70489C1.06928 6.82793 1.14268 6.93715 1.24204 7.01932L4.44538 9.66132L3.43538 14.188C3.40586 14.3199 3.41715 14.4577 3.46773 14.583C3.51832 14.7084 3.60582 14.8154 3.71863 14.8899C3.83144 14.9644 3.96422 15.0028 4.09937 15.0001C4.23452 14.9974 4.36566 14.9536 4.47537 14.8747L8.00004 12.3393L11.5247 14.8727C11.6344 14.9516 11.7656 14.9954 11.9007 14.9981C12.0359 15.0008 12.1686 14.9624 12.2815 14.8879C12.3943 14.8134 12.4818 14.7064 12.5324 14.581C12.5829 14.4557 12.5942 14.3179 12.5647 14.186L11.5547 9.65932L14.758 7.01732C14.8574 6.93515 14.9308 6.82593 14.9693 6.70289C15.0079 6.57985 15.0099 6.44827 14.9752 6.32409C14.9405 6.19992 14.8705 6.08847 14.7738 6.00326C14.677 5.91805 14.5576 5.86272 14.43 5.84398V5.84598ZM10.3907 8.89332C10.2959 8.97167 10.2246 9.07473 10.1847 9.1911C10.1448 9.30747 10.1379 9.4326 10.1647 9.55265L10.882 12.77L8.38938 10.9773C8.27596 10.8957 8.13976 10.8518 8.00004 10.8518C7.86032 10.8518 7.72413 10.8957 7.61071 10.9773L5.11804 12.77L5.83538 9.55265C5.8622 9.4326 5.85529 9.30747 5.8154 9.1911C5.77551 9.07473 5.70421 8.97167 5.60938 8.89332L3.25071 6.94798L5.98404 6.54798C6.09033 6.53249 6.1913 6.49154 6.27835 6.42863C6.36541 6.36572 6.43597 6.2827 6.48404 6.18665L8.00004 3.15665L9.51471 6.18665C9.56278 6.2827 9.63334 6.36572 9.7204 6.42863C9.80746 6.49154 9.90842 6.53249 10.0147 6.54798L12.748 6.94798L10.3907 8.89332Z" fill="#464646" />
                              </svg><span className="ratingPadding">{favourite.ratings}</span>
                            </Link>) : "No reviews"}
                          <div className="info-bottom">
                          <Link to={"/outlet/" + favourite.slug} state={{ outlet: favourite, fav: true, outlets: outlets }}>
                                  <li className="price favoriteCardWidth">{favourite.outletStatus === 0 ? "Closed" : "Open"}</li>
                                </Link>
                            <div className="dz-meta fav-meta">
                              <ul>
                              <li>
                                {favourite.outletStatus !== 0 &&
                              
                                    <Link to={"/get-token/" + favourite.qrUuid}>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        height="1.2em"
                                        viewBox="0 0 576 512"
                                        className="get-token-icon"
                                      >
                                        <path d="M64 64C28.7 64 0 92.7 0 128v64c0 8.8 7.4 15.7 15.7 18.6C34.5 217.1 48 235 48 256s-13.5 38.9-32.3 45.4C7.4 304.3 0 311.2 0 320v64c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V320c0-8.8-7.4-15.7-15.7-18.6C541.5 294.9 528 277 528 256s13.5-38.9 32.3-45.4c8.3-2.9 15.7-9.8 15.7-18.6V128c0-35.3-28.7-64-64-64H64zm64 112l0 160c0 8.8 7.2 16 16 16H432c8.8 0 16-7.2 16-16V176c0-8.8-7.2-16-16-16H144c-8.8 0-16 7.2-16 16zM96 160c0-17.7 14.3-32 32-32H448c17.7 0 32 14.3 32 32V352c0 17.7-14.3 32-32 32H128c-17.7 0-32-14.3-32-32V160z" />
                                      </svg>
                                    </Link>
                               
                                }
                              
                                  <label class="bookmark-btn">
                                    <input onChange={ConfirmRemove(favourite.favouriteOutletId)} type="checkbox" checked />
                                    <span class="checkmark"></span>
                                  </label>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </li>)
                  }
                  ) : ""}
              </ul> : <p id="no-favourites" className="nofavrite-margin">Loading...</p>}
          </div>
          <Footer />
          {/* {(favourites && favourites.length > 0) ?
             <ReactPaginate
               pageCount={pageCount}
               pageRangeDisplayed={10}
               marginPagesDisplayed={0}
               onPageChange={handlePageClick}
               previousLabel={"Prev"}
               nextLabel={"Next"}
               breakLabel={"..."}
               containerClassName={"pagination"}
               activeClassName={"active"}
               forcePage={searchText || currentPage === 1 ? 0 : currentPage - 1}
             />
            : ""} */}
        </div>
        </div>
      </div>
      }
    </>
  );
}

export default Favourites;