const initialState = {
    count: 0,
  };
  
  const notificationCountReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'COUNT':
        return {
          ...state,
          count: action.payload,
        };
  
      default:
        return state;
    }
  }
  export default notificationCountReducer