import React from "react";
import { Link } from "react-router-dom";

const CityListPlaceholder = ({
  noOfItem = 4,
}) => {
  const itemsArray = Array.from({ length: noOfItem }, (_, index) => index);

  return (
    <>
      <div className="page page-home dashboard-page client-dashboard">
        <div className="containerCard">
          <div class="navbar navbar-style-1">
            <div class="navbar-inner navbar-bg-theme">
              <Link class="link back"></Link>
              <div class="title text-white">Select Your City</div>
            </div>
          </div>

          <div className="page-content inner-page pt-10">
            <div class="container">
              <div className="section wallet-card-section pt-1">
                <div class="filter-bx search-city-input">
                  <form
                    data-search-container=".search-list"
                    data-search-in=".item-title"
                    class="searchbar  searchbar-city searchbar-init search-box list-search-bx"
                  >
                    <div class="searchbar-inner">
                      <div class="searchbar-input-wrap searchbar-input-wrap-pad">
                        <input
                          type="text"
                          className="date-daily-token-input"
                          id="searchFavourite"
                          placeholder="Search for your..."
                          autocomplete="off"
                        />
                      </div>
                      <Link
                        to="#"
                        className="filter-btn  filter-btn-clicked"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="currentColor"
                          class="bi bi-search"
                          viewBox="0 0 16 16"
                        >
                          <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                        </svg>
                      </Link>
                    </div>
                  </form>
                </div>
                <div className="wallet-card wallet-card-padding">
                  <div className="containerCard stat-box stat-box-pad state-box-border">
                    <div class="page-content pt-0 ">
                      <div class="list media-list search-list searchbar-found search-list-bar daily-token-mb-1 daily-token-m-0 user-list">
                        <ul>
                          {itemsArray.map((number) => (
                            <li class="ms-1 skeleton-text skeleton-effect-wave">
                              <div class="">
                                <div class="">
                                  <div class="">
                                    <p>
                                      Placeholder City Name
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CityListPlaceholder;
