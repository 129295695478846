const initialState = {
  clientMenuDetail: [],
  grandTotal: 0,
  comment: "",
};

const clientMenuDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CLIENTMENUDETAIL':
      const { clientMenuDetail, grandTotal, comment } = action.payload;
      return {
        ...state,
        clientMenuDetail: clientMenuDetail,
        grandTotal: grandTotal,
        comment: comment
      };
    default:
      return state;
  }
};

export default clientMenuDetailReducer;
