import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import $ from "jquery";
import { useDispatch, useSelector } from "react-redux";
import { useCatchHandler, showToast } from "../../../utils/utilities"
import { showLoaderAction } from "../../../actions/index";
import { activeMenuAction } from "../../../actions/index";
import SpinerLoader from "../../SpinerLoader/spinerLoader";
import { sendMail } from "../../../utils/utilities";
const { REACT_APP_MY_ENV } = process.env;

const ClientChangePassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const catchHandle = useCatchHandler();
  const showLoaderReducer = useSelector((state) => state.rootReducer.showLoaderReducer)
  const showLoader = showLoaderReducer.show;
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [oldPasswordError, setOldPasswordError] = useState({});
  const [passwordError, setPasswordError] = useState({});
  const [confirmPasswordError, setConfirmPasswordError] = useState({});

  let token = localStorage.getItem("clientToken");
  const currentURL = window.location.href;

  useEffect(() => {
    dispatch(activeMenuAction('outlet-change-password'));
  
}, [])

  function showHideOldPwd() {
    var passInput = $(".show-old-pass").parent().find("input");
    var inputType = passInput.attr("type");
    if (inputType == "password") {
      $(".show-old-pass").toggleClass("active");
      passInput.attr("type", "text");
    } else if (inputType == "text") {
      $(".show-old-pass").removeClass("active");
      passInput.attr("type", "password");
    }
  }

  function showHidePwd() {
    var passInput = $(".show-pass").parent().find("input");
    var inputType = passInput.attr("type");
    if (inputType == "password") {
      $(".show-pass").toggleClass("active");
      passInput.attr("type", "text");
    } else if (inputType == "text") {
      $(".show-pass").removeClass("active");
      passInput.attr("type", "password");
    }
  }

  function showHideConfPwd() {
    var passInput = $(".show-pass-conf").parent().find("input");
    var inputType = passInput.attr("type");
    if (inputType == "password") {
      $(".show-pass-conf").toggleClass("active");
      passInput.attr("type", "text");
    } else if (inputType == "text") {
      $(".show-pass-conf").removeClass("active");
      passInput.attr("type", "password");
    }
  }

  const formValidation = () => {
    let oldPasswordError = {};
    let passwordError = {};
    let confirmPasswordError = {};
    let isValid = true;

    if (!oldPassword) {
      oldPasswordError.oldPasswordEmpty = "Please enter old password";
      isValid = false;
    }

    if (!password) {
      passwordError.passwordEmpty = "Please enter password";
      isValid = false;
    }

    if (!confirmPassword) {
      confirmPasswordError.confPasswordEmpty = "Please enter confirm password";
      isValid = false;
    } else if (password != confirmPassword) {
      confirmPasswordError.confPasswordNotMatch = "Confirm password does not match";
      isValid = false;
    }

    setOldPasswordError(oldPasswordError);
    setPasswordError(passwordError);
    setConfirmPasswordError(confirmPasswordError);

    return isValid;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const isValid = formValidation();
  if (isValid) {
    const data = {
      EncryptedClientId: localStorage.getItem("encryptedClientId"),
      OldPassword: oldPassword,
      NewPassword: password,
    };
    dispatch(showLoaderAction(true));
      const res = await axios.post(REACT_APP_MY_ENV + "/change-password/", data, { headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" } }).then(function (res) {
      if (res.data.status === 200) {
        dispatch(showLoaderAction(false));
        showToast(res.data.message, "success");
        navigate(-1);
      } else {
        dispatch(showLoaderAction(false));
        showToast(res.data.message, "error");
      }
    }).catch(function (error) {
      catchHandle(error,"false",currentURL)
      const data = {
        Subject: "Front end error from change-password",
        BodyText: error.message
      }
      sendMail(data);
      dispatch(showLoaderAction(false));
      showToast("Something went wrong, please contact Administrator!", "error")
    });
    }
  };

  return (
    <>
    {showLoader && (<SpinerLoader />)}
      <div class="page account-area">
        <div class="page-content">
          <div class="dz-height"></div>
          <div class="fixed-content container">
            <div class="form-elements">
              <form onSubmit={onSubmit}>
              
                <div class="navbar  padding-0">
                <div class="navbar-inner padding-0">
                  <div class="">
                    <Link to={-1} class="paddingBottom back">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.439312 13.0606L5.75391 18.3752C6.04683 18.6682 6.43069 18.8146 6.81459 18.8146C7.1985 18.8146 7.58236 18.6682 7.87528 18.3752C8.46103 17.7894 8.46103 16.8397 7.87528 16.2539L5.12133 13.5H22.5C23.3284 13.5 24 12.8284 24 12C24 11.1716 23.3284 10.5 22.5 10.5H5.12133L7.87528 7.7461C8.46103 7.1603 8.46103 6.21057 7.87528 5.62477C7.28944 5.03898 6.33975 5.03898 5.75391 5.62477L0.439312 10.9394C-0.146437 11.5251 -0.146437 12.4749 0.439312 13.0606Z" fill="black" />
                      </svg>
                    </Link>
                  </div>
                  <h2 class="title">Change Password</h2>
                </div>
              </div>
                <div class="list">
                  <ul class="row">
                    <li class="item-content item-input col-100">
                      <div class="item-inner">
                        <div class="item-title item-label profile-font">Enter Old Password</div>
                        <div class="item-input-wrap profile-font">
                          <input
                            type="password"
                            name="old-password"
                            id="old-password"
                            autocomplete="off"
                            value={oldPassword}
                            maxLength="1000"
                            placeholder="Enter Old Password"
                            onChange={(e) => {
                              setOldPassword(e.target.value);
                            }}
                            className="form-control"
                          />
                          <div className="show-old-pass">
                            <svg width="24" height="24" onClick={showHideOldPwd} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M22.919 11.606C22.786 11.3 19.572 4.00002 12 4.00002C4.42801 4.00002 1.21401 11.3 1.08101 11.606C1.02764 11.7305 1.00012 11.8646 1.00012 12C1.00012 12.1355 1.02764 12.2695 1.08101 12.394C1.21401 12.7 4.42801 20 12 20C19.572 20 22.786 12.7 22.919 12.394C22.9724 12.2695 22.9999 12.1355 22.9999 12C22.9999 11.8646 22.9724 11.7305 22.919 11.606ZM12 18C6.60001 18 3.83301 13.411 3.11001 12C3.83501 10.614 6.64801 6.00002 12 6.00002C17.394 6.00002 20.165 10.586 20.89 12C20.164 13.386 17.352 18 12 18Z" fill="#309F5F" />
                              <path
                                d="M12 8C11.2089 8 10.4355 8.2346 9.77772 8.67412C9.11993 9.11365 8.60723 9.73836 8.30448 10.4693C8.00173 11.2002 7.92252 12.0044 8.07686 	12.7804C8.2312 13.5563 8.61216 14.269 9.17157 14.8284C9.73098 15.3878 10.4437 15.7688 11.2196 15.9231C11.9956 16.0775 12.7998 15.9983 13.5307 15.6955C14.2616 15.3928 14.8864 14.8801 15.3259 14.2223C15.7654 13.5645 16 12.7911 16 12C16 10.9391 15.5786 9.92172 14.8284 9.17157C14.0783 8.42143 13.0609 8 12 8ZM12 14C11.6044 14 11.2178 13.8827 10.8889 13.6629C10.56 13.4432 10.3036 13.1308 10.1522 12.7654C10.0009 12.3999 9.96126 11.9978 10.0384 11.6098C10.1156 11.2219 10.3061 10.8655 10.5858 10.5858C10.8655 10.3061 11.2219 10.1156 11.6098 10.0384C11.9978 9.96126 12.3999 10.0009 12.7654 10.1522C13.1308 10.3036 13.4432 10.56 13.6629 10.8889C13.8827 11.2178 14 11.6044 14 12C14 12.5304 13.7893 13.0391 13.4142 13.4142C13.0391 13.7893 12.5304 14 12 14Z"
                                fill="#309F5F"
                              />
                            </svg>
                          </div>
                          {Object.keys(oldPasswordError).map((key) => {
                              return <div style={{ color: "red" }}>{oldPasswordError[key]}</div>;
                            })}
                        </div>
                      </div>
                    </li>
                    <li class="item-content item-input col-100">
                      <div class="item-inner">
                        <div class="item-title item-label profile-font">Password</div>
                        <div class="item-input-wrap profile-font">
                          <input
                            type="password"
                            name="password"
                            id="password"
                            value={password}
                            autocomplete="new-password"
                            maxLength="1000"
                            placeholder="Enter Password"
                            onChange={(e) => {
                              setPassword(e.target.value);
                            }}
                            className="form-control"
                          />
                          <div class="show-pass">
                            <svg width="24" height="24" onClick={showHidePwd} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M22.919 11.606C22.786 11.3 19.572 4.00002 12 4.00002C4.42801 4.00002 1.21401 11.3 1.08101 11.606C1.02764 11.7305 1.00012 11.8646 1.00012 12C1.00012 12.1355 1.02764 12.2695 1.08101 12.394C1.21401 12.7 4.42801 20 12 20C19.572 20 22.786 12.7 22.919 12.394C22.9724 12.2695 22.9999 12.1355 22.9999 12C22.9999 11.8646 22.9724 11.7305 22.919 11.606ZM12 18C6.60001 18 3.83301 13.411 3.11001 12C3.83501 10.614 6.64801 6.00002 12 6.00002C17.394 6.00002 20.165 10.586 20.89 12C20.164 13.386 17.352 18 12 18Z" fill="#309F5F" />
                              <path
                                d="M12 8C11.2089 8 10.4355 8.2346 9.77772 8.67412C9.11993 9.11365 8.60723 9.73836 8.30448 10.4693C8.00173 11.2002 7.92252 12.0044 8.07686 	12.7804C8.2312 13.5563 8.61216 14.269 9.17157 14.8284C9.73098 15.3878 10.4437 15.7688 11.2196 15.9231C11.9956 16.0775 12.7998 15.9983 13.5307 15.6955C14.2616 15.3928 14.8864 14.8801 15.3259 14.2223C15.7654 13.5645 16 12.7911 16 12C16 10.9391 15.5786 9.92172 14.8284 9.17157C14.0783 8.42143 13.0609 8 12 8ZM12 14C11.6044 14 11.2178 13.8827 10.8889 13.6629C10.56 13.4432 10.3036 13.1308 10.1522 12.7654C10.0009 12.3999 9.96126 11.9978 10.0384 11.6098C10.1156 11.2219 10.3061 10.8655 10.5858 10.5858C10.8655 10.3061 11.2219 10.1156 11.6098 10.0384C11.9978 9.96126 12.3999 10.0009 12.7654 10.1522C13.1308 10.3036 13.4432 10.56 13.6629 10.8889C13.8827 11.2178 14 11.6044 14 12C14 12.5304 13.7893 13.0391 13.4142 13.4142C13.0391 13.7893 12.5304 14 12 14Z"
                                fill="#309F5F"
                              />
                            </svg>
                          </div>
                          {passwordError &&
                            Object.keys(passwordError).map((key) => {
                              return <div style={{ color: "red" }}>{passwordError[key]}</div>;
                            })}
                        </div>
                      </div>
                    </li>
                    <li class="item-content item-input col-100">
                      <div class="item-inner">
                        <div class="item-title item-label profile-font">Confirm Password</div>
                        <div class="item-input-wrap profile-font">
                          <input
                            type="password"
                            name="confirmPassword"
                            id="confirmPassword"
                            autocomplete="new-password"
                            placeholder="Enter Confirm Password"
                            maxLength="1000"
                            value={confirmPassword}
                            onChange={(e) => {
                              setConfirmPassword(e.target.value);
                            }}
                            className="form-control"
                          />
                          <div class="show-pass-conf">
                            <svg width="24" height="24" onClick={showHideConfPwd} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M22.919 11.606C22.786 11.3 19.572 4.00002 12 4.00002C4.42801 4.00002 1.21401 11.3 1.08101 11.606C1.02764 11.7305 1.00012 11.8646 1.00012 12C1.00012 12.1355 1.02764 12.2695 1.08101 12.394C1.21401 12.7 4.42801 20 12 20C19.572 20 22.786 12.7 22.919 12.394C22.9724 12.2695 22.9999 12.1355 22.9999 12C22.9999 11.8646 22.9724 11.7305 22.919 11.606ZM12 18C6.60001 18 3.83301 13.411 3.11001 12C3.83501 10.614 6.64801 6.00002 12 6.00002C17.394 6.00002 20.165 10.586 20.89 12C20.164 13.386 17.352 18 12 18Z" fill="#309F5F" />
                              <path
                                d="M12 8C11.2089 8 10.4355 8.2346 9.77772 8.67412C9.11993 9.11365 8.60723 9.73836 8.30448 10.4693C8.00173 11.2002 7.92252 12.0044 8.07686 	12.7804C8.2312 13.5563 8.61216 14.269 9.17157 14.8284C9.73098 15.3878 10.4437 15.7688 11.2196 15.9231C11.9956 16.0775 12.7998 15.9983 13.5307 15.6955C14.2616 15.3928 14.8864 14.8801 15.3259 14.2223C15.7654 13.5645 16 12.7911 16 12C16 10.9391 15.5786 9.92172 14.8284 9.17157C14.0783 8.42143 13.0609 8 12 8ZM12 14C11.6044 14 11.2178 13.8827 10.8889 13.6629C10.56 13.4432 10.3036 13.1308 10.1522 12.7654C10.0009 12.3999 9.96126 11.9978 10.0384 11.6098C10.1156 11.2219 10.3061 10.8655 10.5858 10.5858C10.8655 10.3061 11.2219 10.1156 11.6098 10.0384C11.9978 9.96126 12.3999 10.0009 12.7654 10.1522C13.1308 10.3036 13.4432 10.56 13.6629 10.8889C13.8827 11.2178 14 11.6044 14 12C14 12.5304 13.7893 13.0391 13.4142 13.4142C13.0391 13.7893 12.5304 14 12 14Z"
                                fill="#309F5F"
                              />
                            </svg>
                          </div>
                          {Object.keys(confirmPasswordError).map((key) => {
                            return <div style={{ color: "red" }}>{confirmPasswordError[key]}</div>;
                          })}
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="clearfix profile-font">
                  <button type="submit" id="yes" className="button-large button button-fill profile-font">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientChangePassword;
