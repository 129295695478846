import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { showLoaderAction } from "../../../actions/index";
import { useCatchHandler, showToast } from "../../../utils/utilities"
import { sendMail } from "../../../utils/utilities";
import DetailsPlaceholder from "../../Placeholders/DetailsPlaceholder";

const { REACT_APP_MY_ENV } = process.env;

const ClientRunningToken = () => {
	const dispatch = useDispatch();
	const showLoaderReducer = useSelector((state) => state.rootReducer.showLoaderReducer)
	const showLoader = showLoaderReducer.show;
	let token = localStorage.getItem("clientToken");
	const catchHandle = useCatchHandler();
	const [outletGoingToken, setOutletGoingToken] = useState("");
	const [isOutletOpened, setIsOutletOpened] = useState(false);
	const currentURL = window.location.href;
	let slug = localStorage.getItem("outletSlug");

	useEffect(() => {
		getOutletStatus()
	}, []);

	const getOutletStatus = async () => {
		const response = await axios.get(
			REACT_APP_MY_ENV + "/client-token/" + slug,
			{
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json",
				},
			}
		).then(function (response) {
			if (response.data.status === 500) {
				showToast(response.data.message, "error");
			} else {

        setIsOutletOpened(response.data.data.isOutletOpened);
				setOutletGoingToken(response.data.data.onGoingToken);

			}
		}).catch(function (error) {
			catchHandle(error, "false", currentURL)
			dispatch(showLoaderAction(false));
			const data = {
				Subject: "Front end error from client-token",
				BodyText: error.message
			}
			sendMail(data);
			showToast("Something went wrong, please contact Administrator!", "error")
		});
	};

	return (
		<>
			{showLoader ? (<DetailsPlaceholder isProfile={true} skeletonName="Profile" />)
				:
				<div class="page profile-wraper rt-page-baground-color">
					<div class="containerCard containerCard-height">
						<div class="profile-content rt-font-size">
							<h3 class="name">Token No</h3>
							<h3 class="name">{outletGoingToken ? outletGoingToken : "0"}</h3>
						</div>
					</div>
				</div>
			}
		</>
	);
};

export default ClientRunningToken;
