import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import "../../../css/style.css";
import "../../../css/custom.css";
import "../../../css/font-awesome/css/all.min.css";
import "../../../css/line-awesome/css/line-awesome.min.css";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { ClientMenuDetailAction, showLoaderAction } from "../../../actions/index";
import SpinerLoader from "../../SpinerLoader/spinerLoader";
import { sendMail, useCatchHandler, showToast } from "../../../utils/utilities"
import { useNotification } from "../../../utils/utilities"
import ClientMenu from "../../Client/ClientMenu/ClientMenu"
import toast from 'react-hot-toast';
import $ from "jquery";
const { REACT_APP_MY_ENV } = process.env;

const GenerateToken = () => {
  const showLoaderReducer = useSelector((state) => state.rootReducer.showLoaderReducer)
  const showLoader = showLoaderReducer.show;

  const clientMenuDetailReducer = useSelector((state) => state.rootReducer.clientMenuDetailReducer)
  const clientMenuDetail = clientMenuDetailReducer.clientMenuDetail;
  const grandTotal = clientMenuDetailReducer.grandTotal;
  const comment = clientMenuDetailReducer.comment;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [outlet] = useState(location.state?.outlet);
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState({});
  const [quantityError, setQuantityError] = useState({});
  const catchHandle = useCatchHandler();
  const sendNotification = useNotification();
  let ClientID = localStorage.getItem("encryptedClientId");
  let token = localStorage.getItem("clientToken");
  const [selectedUserId, setSelectedUserId] = useState('');
  const [showCard, setShowCard] = useState(false);
  const [isPhoneNumber, setIsPhoneNumber] = useState(false);
  const [remark, setRemark] = useState("");

  const [userList, setUserList] = useState([]);
  const [userPhoneList, setUserPhoneList] = useState([]);
  const [searchText, setSearchText] = useState();
  const [qrUuId, setQrUuidId] = useState('');
  let RemarksEnabled = localStorage.getItem("RemarksEnabled")
  const currentURL = window.location.href;

  useEffect(() => {
    const currentURL = window.location.href;
    const urlParts = currentURL.split('/');
    const qrUuidUrl = urlParts[urlParts.length - 1];
    setQrUuidId(qrUuidUrl)
        
    }, [name,phoneNumber]);


  const formValidation = () => {
    let phoneNumberError = {};
    let quantityError = {};

    let isValid = true;

    if (!phoneNumber) {
      phoneNumberError.phoneNumberEmpty = "Please enter the phone number";
      isValid = false;
    } else if (!/^(\+\d{1,3}[- ]?)?\d{10}$/.test(phoneNumber)) {
      phoneNumberError.phoneInvalid =
        "Invalid phone number";
      isValid = false;
    }

    if (localStorage.getItem("EnableMenu") == "true") {
      const allQuantitiesZero = clientMenuDetail.every(item => item.quantity === 0);
      if (allQuantitiesZero) {
        // quantityError.allQuantitiesZero = "Please select atleast one item to get the token!";
        toast.error("Please select atleast one item!");
        isValid = false;
      }
    }

    setQuantityError(quantityError);
    setPhoneNumberError(phoneNumberError);
    return isValid;
  };

  const handleSubmit = (e) => {
    if (formValidation())
      getToken();
  };

  const getToken = async () => {
    const data = {
      name: name ? name : localStorage.getItem("username") ? localStorage.getItem("username") : "",
      phoneNumber: phoneNumber ? phoneNumber : localStorage.getItem("phoneNumber"),
      remarks: remark ? remark :"",
      isClient: true
    };
    dispatch(showLoaderAction(true));
    let response = await axios.post(REACT_APP_MY_ENV + "/token/" + qrUuId, data, { headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" } }).then(function (response) {
      if (response.data.status === 200) {
        // notificationSendForGenerateToken(response.data.data)
        dispatch(showLoaderAction(false));
        if (localStorage.getItem("EnableMenu") == "true") {
          createUserOrder(response.data.data.userId, response.data.data.userTokenId)
        }
        else {
          setTimeout(() => {
            showToast("Token generated successfully!", "success");
            navigate("/outlet-dashboard")
          }, 1000);
        }
      } else {
        dispatch(showLoaderAction(false));
        showToast(response.data.message, "error");
      }
    }).catch(function (error) {
      dispatch(showLoaderAction(false));
      showToast("Something went wrong, please contact Administrator!", "error")
      const data = {
        Subject: "Front end error from token",
        BodyText: error.message
      }
      sendMail(data)
    });
  }

  const notificationSendForGenerateToken = (userDetail) => {
    const data = {
      ClientId: ClientID,
      UserId: userDetail.userId,
      SentBy: "USER",
      Title: "New Token",
      Description: `${userDetail.userName} - Token No. ${userDetail.token}`,
    }
    sendNotification(data)
  }


  const createUserOrder = async (userId, userTokenId) => {
    const data = {
      clientId: ClientID,
      userId: userId,
      userTokenId: userTokenId,
      grandTotal: grandTotal,
      comment: comment,
      userOrderDetails: clientMenuDetail
    };
    dispatch(showLoaderAction(true));
    const response = await axios.post(REACT_APP_MY_ENV + "/create-user-order", data, { headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" } }).then(function (response) {
      if (response.data.status === 200) {
        dispatch(showLoaderAction(false));
        setTimeout(() => {
          showToast("Token generated successfully!", "success");
          navigate("/outlet-dashboard")
        }, 1000);
        dispatch(ClientMenuDetailAction([]));
      } else {
        dispatch(showLoaderAction(false));
        showToast(response.data.message, "error");
        dispatch(ClientMenuDetailAction([]));
      }
    }).catch(function (error) {
      dispatch(showLoaderAction(false));
      showToast("Something went wrong, please contact Administrator!", "error")
      const data = {
        Subject: "Front end error from token",
        BodyText: error.message
      }
      sendMail(data)
    });
  }

  const getUserList = (searchText, isPhoneNumber) => {
    const data = {
      searchText: searchText ? searchText : "",
      pageNumber: 1,
      pageSize: 5,
      ClientId: localStorage.getItem("encryptedClientId"),
    };
  
    axios.post(REACT_APP_MY_ENV + "/user-list", data, {
      headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" }
    }).then(function (response) {
      if (response.data.status === 200) {
    if(isPhoneNumber === true){
      setUserList(response.data.data.usersList);
    }  if(isPhoneNumber === true){
     
      setUserPhoneList(response.data.data.usersList)
    }
      } else {
        dispatch(showLoaderAction(false));
        setUserList([])
        setUserPhoneList([])
      }
    }).catch(function (error) {
      catchHandle(error, "false", currentURL);
      dispatch(showLoaderAction(false));
      const data = {
        Subject: "Front end error from user-list",
        BodyText: error.message
      };
      sendMail(data);
      showToast("Something went wrong, please contact Administrator!", "error");
    });
    
  };

  const handleCustomerChange = (selectedOption) => {
    if (selectedOption) {
      const userId = selectedOption.userId;
      setSelectedUserId(userId);
    }
  };
  const handleuserSelect = async (e) => {
    e.preventDefault();
    
     showCategory();
    if (e.target.name === "searchText") {
      
      if(userList && !userList.length > 0 && !name ){

      }
      if (!e.target.value) {
        setUserList([]);
        setUserPhoneList([])
        setShowCard(false);
        setPhoneNumber('');
        // setSearchText('');
        setName('');
      } else {
        setShowCard(true);
        setIsPhoneNumber(false)
        setName(e.target.value);
        // setSearchText(e.target.value);

         showCategory(false);
         setTimeout(async () => {
          await getUserList(e.target.value, true);
        }, 1000);
         
        if (!$("#autoSerachDropdown").hasClass("show")) {
          $("#autoSerachDropdown").addClass("show");
          $("#autoSerachDropdown").attr("aria-expanded", true);
          $("#dropdownMenu").addClass("show");
        }
      }
    } else {
      setShowCard(false);
      setIsPhoneNumber(false)
      setPhoneNumber('');
      // setSearchText('');
      setName('');
    }
  };
  const handleuserPhoneSelect = async (e) => {
    e.preventDefault();
    
     showCategory();
    if (e.target.name === "searchPhoneNumber") {
      
      if(userPhoneList && !userPhoneList.length > 0 && !phoneNumber){

      }
      if (!e.target.value) {
        setUserPhoneList([])
        setUserList([]);
        setIsPhoneNumber(false);
        setPhoneNumber('');
        // setSearchText('');
        setName('');
      } else {
        setIsPhoneNumber(true);
        setPhoneNumber(e.target.value)
             
         showCategory(false);
         setTimeout(async () => {
          await getUserList(e.target.value, true);
        }, 1000);
         
        if (!$("#autoSerachDropdown").hasClass("show")) {
          $("#autoSerachDropdown").addClass("show");
          $("#autoSerachDropdown").attr("aria-expanded", true);
          $("#dropdownMenu").addClass("show");
        }
      }
    } else {
      setShowCard(false);
      setIsPhoneNumber(false)
      setPhoneNumber('');
      setName('')
      // setSearchText('');
    }
  };

  const showCategory = (show = true) => {
    if (show && !$("#autoSerachDropdown").hasClass("show")) {
      $("#autoSerachDropdown").addClass("show");
      $("#autoSerachDropdown").attr("aria-expanded", true);
      $("#dropdownMenu").addClass("show");
      getUserList(name || phoneNumber);
    } else if ($("#autoSerachDropdown").hasClass("show")) {
      $("#autoSerachDropdown").removeClass("show");
      $("#autoSerachDropdown").attr("aria-expanded", false);
      $("#dropdownMenu").removeClass("show");
    }
  };

  return (
    <>
      {showLoader && (<SpinerLoader />)}
      <div class="page my-token-page commane-page ">
        <div className="containerCard">
          <div class="navbar navbar-style-1 navbar-bg-theme">
            <div class="navbar-inner">
              <Link to={-1} class="link back">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.439312 13.0606L5.75391 18.3752C6.04683 18.6682 6.43069 18.8146 6.81459 18.8146C7.1985 18.8146 7.58236 18.6682 7.87528 18.3752C8.46103 17.7894 8.46103 16.8397 7.87528 16.2539L5.12133 13.5H22.5C23.3284 13.5 24 12.8284 24 12C24 11.1716 23.3284 10.5 22.5 10.5H5.12133L7.87528 7.7461C8.46103 7.1603 8.46103 6.21057 7.87528 5.62477C7.28944 5.03898 6.33975 5.03898 5.75391 5.62477L0.439312 10.9394C-0.146437 11.5251 -0.146437 12.4749 0.439312 13.0606Z"
                    fill="white"
                  />
                </svg>
              </Link>
              <div class="title text-white">Customer Details</div>
            </div>
          </div>
          <div class="page-content inner-page pb-0 pt-0">
            <div class=" mt-0 mb-0  searchbar-found">
              <div class="col-12 inner-page-pad-0 mb-0">
                <div className="page-content inner-page pb-0 pt-10">
                </div>
                <form  className='h-555'>
                  <div className="  pt-1">
                    <div className="">
                      <div class=" searchtextlist">
                        <div className='card mb-0 card-radius z-index-dropdown'>
                          <div className=''>
                            <div class="form-floating">
                            <div className='card-border'>
                              <input
                                type="text"
                                className="form-control input-text-name"
                                id="txtSearchuser"
                                name="searchText"
                                placeholder="Name (optional)"
                                value={name || ""}
                                onChange={handleuserSelect}
                                onClick={showCategory}
                                autoComplete="off"
                                onBlur={(e) => {
                                  setTimeout(function () {
                                    setShowCard(false)
                                    showCategory(false);
                                  }, 200);
                                }}
                              />
                              </div>        {showCard && name  &&   userList && userList.length > 0 && (
                              <div class="card m-0 m-minus">
                                <div className=''>
                                  <div className=''>
                                    <ul className="list-group">
                                      {userList && userList.length > 0
                                        ? userList.map((user) => {
                                          return (
                                            <li
                                              className="list-group-item"
                                              onClick={(e) => {
                                                handleCustomerChange(user);
                                                // setSearchText(user.name);
                                                setName(user.name);
                                                setPhoneNumber(user.phoneNumber);
                                              }}
                                            >
                                              <Link key={user.userId} className="listing" data-selected={user.userId} to="">
                                                <div className="product-info drowpdown-text">
                                                  <p className="search-text-user larger-font  ">
                                                    {user.name} ({user.phoneNumber})
                                                  </p>
                                                </div>
                                              </Link>
                                            </li>
                                          );
                                        })
                                        : ""}
                                    </ul>
                                  </div>
                                </div> </div>)}
                            </div>
                          </div>
                          <div className='form-floating'> 
                          <div class="item-inner">
                          <div class="item-input-wrap profile-font">
                          <div class="item-input-wrap profile-font">
                            <div className='pb-1'>
                          <div className='card-border-phone'>
                                  <input
                                    type="text"
                                    className="form-control larger-font"
                                    id=""
                                    name="searchPhoneNumber"
                                    maxLength={10}
                                    placeholder="Phone Number"
                                    value={phoneNumber}
                                    onChange={handleuserPhoneSelect}
                                    onClick={showCategory}
                                    autoComplete="off"
                                    onBlur={(e) => {
                                      setTimeout(function () {
                                        setIsPhoneNumber(false)
                                        showCategory(false);
                                      }, 200);
                                    }}
                                    onKeyPress={(e) => {
                                      const isNumeric = /^[0-9\b]+$/.test(e.key);

                                      if (!isNumeric) {
                                        e.preventDefault();
                                      }
                                    }} />
                                     {/* <div className=''>
                          {Object.keys(phoneNumberError).map((key) => {
                            return (
                              <div style={{ color: "red" }}>
                                {phoneNumberError[key]}
                              </div>
                            );
                          })}
                          </div> */}
                           </div>
                          
                            {Object.keys(phoneNumberError).map((key) => {
                            return (
                              <span style={{ color: "red" }}>
                                {phoneNumberError[key]}
                              </span>
                            );
                          })}
                          </div>
                          </div>
                          {isPhoneNumber && phoneNumber && userPhoneList && userPhoneList.length > 0 && (
                            <div class="card m-0 m-minus">
                              <div className=''>
                                <div className=''>
                                  <ul className="list-group" id='myUL'>
                                    {userPhoneList && userPhoneList.length > 0
                                      ? userPhoneList.map((user) => {
                                        return (
                                          <li
                                            className="list-group-item"
                                            onClick={(e) => {
                                              handleCustomerChange(user);
                                              // setSearchText(user.name);
                                              setName(user.name);
                                              setPhoneNumber(user.phoneNumber);
                                            }}
                                          >
                                            <Link key={user.userId} className="listing" data-selected={user.userId} to="">
                                              <div className="product-info drowpdown-text">
                                                <p className="search-text-user larger-font">
                                                  {user.name} ({user.phoneNumber})
                                                </p>
                                              </div>
                                            </Link>
                                          </li>
                                        );
                                      })
                                      : ""}
                                  </ul>
                                </div>
                              </div> </div>
                          )}
                      </div> 
                      </div> 
                      </div>
                      {RemarksEnabled === "true" &&
                      <div className='mt-5 border-teaxtarea'> 
                          <div class="item-inner">
                          <div class="item-input-wrap profile-font">
                          <div class="item-input-wrap profile-font">
                          <div className='card-border-textarea'>
                          <textarea id="remark"
                            rows="5"
                            // cols="30"
                            name="remark"
                            autocomplete="off"
                             value={remark}
                            maxLength="500"
                            placeholder="Enter remark..."
                            onChange={(e) => {
                              setRemark(e.target.value);
                              // handleChange(e);
                            }}
                            class="form-control mt-3 w-100" />
                           </div>
                          
                        
                          </div>
                        
                      </div> 
                      </div> 
                      </div>}
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="  mt-0 mb-0 container ">
              <div class="col-12 mb-1">
                <div className="page-content inner-page pt-0">
                  <div class="">
                    <div className="section mb-10">
                      <div className="row-12 mt-2">
                          <div className="section wallet-card-section pt-1">
                            <div className="  mb-0 pt-0">
                              <div class=" mb-0 ">
                                <div className="">
                                  {localStorage.getItem("EnableMenu") == "true" && <ClientMenu />}
                                  {Object.keys(quantityError).map((key) => {
                                    return (
                                      <div style={{ color: "red" }}>
                                        {quantityError[key]}
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container contiainer-padding pb-50">
              <div className={`clearfix profile-font ${localStorage.getItem("EnableMenu") == "true" ? "mt-10" : "mt-5"}`}>
                <button
                  id="yes"
                  onClick={handleSubmit}
                  className="button-large button button-fill profile-font"
                >
                  Get Token
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default GenerateToken