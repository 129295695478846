import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "../../../css/style.css";
import "../../../css/custom.css";
import "../../../css/font-awesome/css/all.min.css";
import "../../../css/line-awesome/css/line-awesome.min.css";
import { showLoaderAction } from "../../../actions/index";
import TimeAgo from "javascript-time-ago";
import ReactTimeAgo from "react-time-ago";
import en from "javascript-time-ago/locale/en.json";
import axios from "axios";
import toast from "react-hot-toast";
import ListPlaceholder from "../../Placeholders/ListPlaceholder.js";
import useRazorPay from "react-razorpay";
import { v4 as uuidv4 } from "uuid";
// import DownImage from "../../../img/1.svg";
// import UpImage from "../../../img/2.svg";
import DownImage from "../../../img/3.svg";
import UpImage from "../../../img/4.svg";
import moment from "moment/moment";
import { uniqBy } from "lodash";
import { LazyLoadImage } from "react-lazy-load-image-component";

import ru from "javascript-time-ago/locale/ru.json";


TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(ru);

const { REACT_APP_MY_ENV, REACT_APP_RAZOR_PAY_KEY } = process.env;

const TransactionHistory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const showLoaderReducer = useSelector((state) => state.rootReducer.showLoaderReducer);
  const showLoader = showLoaderReducer.show;
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [transactionTotalCount, setTransactionTotalCount] = useState(0);
  const [showLoading, setShowLoading] = useState(true)
  const [Razorpay] = useRazorPay();
  const [loading, setLoading] = useState(false);
  const [transectionList, setTransectionList] = useState([]);

	const [scrollPosition, setScrollPosition] = useState(0);
  const scrollRef = useRef(null);

  useEffect(() => {
    const isLogin = localStorage.getItem("isLogin");
    if (isLogin !== "true") {
      navigate("/sign-in");
    } else {
      TransectionList()
    }

  }, []);

  useEffect(() => {
    document.querySelectorAll("input[dist-name='only-numeric']").forEach(function (input) {
      input.addEventListener("keypress", function (e) {
        var keyCode = e.which || e.keyCode;
        if (keyCode < 48 || keyCode > 57) {
          e.preventDefault();
        }
      });
    });
  });

  const handleScroll = () => {
		const container = document.getElementById('scroll');
		if (container.scrollTop + container.clientHeight >= container.scrollHeight - 20 && !loading) {
			if (transectionList.length < transactionTotalCount) {
				setScrollPosition(container.scrollTop);
				TransectionList();
			}
		}
	};

	useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollTop = scrollPosition;
        }
    }, [transectionList]);

  const TransectionList = async (pageNumber) => {
    setLoading(true)
    let data = {
        UserId: localStorage.getItem("encryptedUserId"),
        pageNumber:  pageNumber ? pageNumber : currentPage,
        pageSize: itemsPerPage,
    };

    if(showLoading){
      dispatch(showLoaderAction(true));
    }

    const res = await axios.post(REACT_APP_MY_ENV + "/transaction-list", data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }).then(function (res) {
        if (res.data.status === 200) {
          setTransactionTotalCount(res.data.data.transactionTotalCount)
          // setTransectionList(outlets => [...outlets, ...res.data.data.transactionList]);
          setTransectionList(uniqBy([...transectionList, ...res.data.data.transactionList], 'id'));
          setCurrentPage(currentPage => currentPage + 1);
          setLoading(false)
          setShowLoading(false)
          dispatch(showLoaderAction(false));
        } else {
            dispatch(showLoaderAction(false));
            // setTransectionList([]);
        }
    }).catch(function (error) {
      dispatch(showLoaderAction(false));

      // catchHandle(error,"true",currentURL)
      // const data = {
      //     Subject: "Front end error from feedback-list",
      //     BodyText: error.message
      // }
      // sendMail(data);
    });
  };

  return (
    <>
      {showLoader ? (
        <ListPlaceholder skeletonName={"Wallet"} noOfItem={1} showFooter={false} />
      ) : (
        <div class="page my-token-page commane-page">
          <div className="containerCard">
            <div class="navbar navbar-style-1 navbar-bg-theme">
              <div class="navbar-inner">
                <Link to={"/wallet"} class="link back">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.439312 13.0606L5.75391 18.3752C6.04683 18.6682 6.43069 18.8146 6.81459 18.8146C7.1985 18.8146 7.58236 18.6682 7.87528 18.3752C8.46103 17.7894 8.46103 16.8397 7.87528 16.2539L5.12133 13.5H22.5C23.3284 13.5 24 12.8284 24 12C24 11.1716 23.3284 10.5 22.5 10.5H5.12133L7.87528 7.7461C8.46103 7.1603 8.46103 6.21057 7.87528 5.62477C7.28944 5.03898 6.33975 5.03898 5.75391 5.62477L0.439312 10.9394C-0.146437 11.5251 -0.146437 12.4749 0.439312 13.0606Z" fill="white" />
                  </svg>
                </Link>
                <div class="title text-white">Wallet Transaction History</div>
              </div>
            </div>
            <div class="page-content wallet_screen transaction_history" id="scroll" onScroll={() => handleScroll()} ref={scrollRef}>
              <div class="">
                <div class="container " >
                  <div className="section mb-10">
                    <div className="row-12 mt-2">
                      <div className="col-6 w-40" style={{ textAlign: "center" }}>
                        <div className="stat-box stat-box-pad">

                          <div class="list media-list search-list searchbar-found search-list-bar card-mt-0 ">
                            <ul>
                              {transectionList && transectionList.length > 0 ?
                                transectionList && transectionList.map((data, index) => {
                                  const updatedAt = data.createdAtUtc ? new Date(data.createdAtUtc) : null;
                                  const createdAt = new Date(data.createdAtUtc);
                                  const today = new Date();
                                  let displayDate;
                                  if (updatedAt && updatedAt.toDateString() === today.toDateString()) {
                                    displayDate = <ReactTimeAgo date={updatedAt} locale="en-US" />;
                                  } else {
                                    const formattedDate = updatedAt ? updatedAt.toLocaleString('en-US', {
                                      year: 'numeric',
                                      month: '2-digit',
                                      day: '2-digit',
                                      hour: '2-digit',
                                      minute: '2-digit',
                                      hour12: true,
                                    }) : createdAt.toLocaleString('en-US', {
                                      year: 'numeric',
                                      month: '2-digit',
                                      day: '2-digit',
                                      hour: '2-digit',
                                      minute: '2-digit',
                                      hour12: true,
                                    });
                                    displayDate = formattedDate;
                                  }
                                  return (
                                    <li className="mt-3 mb-3">
                                      <Link to='' class="item-link item-content item-content-pading">
                                        <div class="item-media">
                                          <LazyLoadImage className="imgCard" src={data.type === "CR" ? DownImage : data.type === "DR" ? UpImage : ""} />
                                        </div>
                                        <div class="item-inner">
                                          <div class="item-title-row">
                                            <h6 class="item-title">{data.title ? data.title : "-"} </h6>
                                            <div class="item-after item-subtitle">
                                              {/* {displayDate
                                                    ? moment(displayDate).format(
                                                        moment(displayDate).isSame(moment(), 'day') ? 'hh:mm A' : 'DD/MM/YYYY hh:mm A'
                                                      )
                                                    : "-"} */}
                                            </div>
                                            <div class=" small-subtitle"> {displayDate ? moment(displayDate).format("DD-MM-YYYY hh:mm A") : "-"}</div>
                                          </div>
                                          <div class="item-title-row">
                                            <p class="token-item-text">{data.description ? data.description : "-"} </p>
                                            <div class="token-item-text text-amount-color">  {data.type === "CR" ?  <span className="text-success"> + </span> : <span className="text-danger"> - </span>}  <span className="priceTage">₹</span>  {data.amount}
                                            </div>
                                          </div>
                                        </div>
                                      </Link>
                                    </li>
                                  )
                                })
                                :
                                <p id="no-reviews" className='noreviews-margin'>Loading...</p>
                              }
                            </ul>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>

          </div>
        </div>
      )}
    </>
  );
};

export default TransactionHistory;
