import { React, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
const Footer = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
  const activeMenuReducer = useSelector(
    (state) => state.rootReducer.activeMenu);
  var activeMenu = activeMenuReducer.ActiveMenu;

  useEffect(() => {
    if (localStorage.getItem("isLogin") == "true" || localStorage.getItem("isClientLogin") == "true") {
      setIsLoggedIn(true);
    }
    else{
      // localStorage.clear();
    }
  }, []);

  const handleLinkClick = (e) => {
    if (!isLoggedIn && e.currentTarget.pathname === '/favourites') {
      e.preventDefault();
      toast.error('Please login first to view this screen!', {
        className: 'custom-toast-error',
        icon: null,
      });
    }
    else if(!isLoggedIn && e.currentTarget.pathname === '/my-tokens'){
      e.preventDefault();
      toast.error('Please login first to view this screen!', {
        className: 'custom-toast-error',
        icon: null,
      });
    } else if(!isLoggedIn && e.currentTarget.pathname === '/sidebar'){
      e.preventDefault();
      toast.error('Please login first to view this screen!', {
        className: 'custom-toast-error',
        icon: null,
      });
    }
    navigate('/sign-in')
  };
  return (
    <div className="toolbar tabbar tabbar-labels toolbar-bottom menubar-area mt-5">
      <div className="toolbar-inner">
        <Link to="/dashboard" className={`tab-link ${activeMenu === "dashboard" ? "active" : ""}`}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3 8.40002V21C3 21.2652 3.10536 21.5196 3.29289 21.7071C3.48043 21.8947 3.73478 22 4 22H20C20.2652 22 20.5196 21.8947 20.7071 21.7071C20.8946 21.5196 21 21.2652 21 21V8.40002C21.0001 8.23638 20.96 8.07523 20.8833 7.93069C20.8066 7.78616 20.6956 7.66265 20.56 7.57102L12.56 2.17102C12.3946 2.05924 12.1996 1.99951 12 1.99951C11.8004 1.99951 11.6054 2.05924 11.44 2.17102L3.44 7.57102C3.30443 7.66265 3.19342 7.78616 3.11671 7.93069C3.03999 8.07523 2.99992 8.23638 3 8.40002ZM14 20H10V14H14V20ZM5 8.93202L12 4.20702L19 8.93202V20H16V13C16 12.7348 15.8946 12.4804 15.7071 12.2929C15.5196 12.1054 15.2652 12 15 12H9C8.73478 12 8.48043 12.1054 8.29289 12.2929C8.10536 12.4804 8 12.7348 8 13V20H5V8.93202Z"
              fill="#4A3749"
            />
          </svg>
          <span className='footerLink'>Dashboard</span>
        </Link>
        <Link to="/outlets" className={`tab-link ${activeMenu === "outlets" ? "active" : ""}`}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path d="M22.029,5.994,20.97,1.758A1,1,0,0,0,20,1H4a1,1,0,0,0-.97.758L1.971,5.994A4,4,0,0,0,2.7,9.45a4.033,4.033,0,0,0,.3.3V22a1,1,0,0,0,1,1H20a1,1,0,0,0,1-1V9.753a4.033,4.033,0,0,0,.3-.3A4,4,0,0,0,22.029,5.994ZM10,6l.251-3h3.492L14,6l.066.8A2.027,2.027,0,0,1,12.042,9h-.084A2.027,2.027,0,0,1,9.937,6.8ZM4.28,8.22a2.016,2.016,0,0,1-.369-1.741L4.781,3H8.247L7.9,7.14A2.041,2.041,0,0,1,5.879,9,2.015,2.015,0,0,1,4.28,8.22ZM13,21H11V16h2Zm6,0H15V15a1,1,0,0,0-1-1H10a1,1,0,0,0-1,1v6H5V10.9A3.953,3.953,0,0,0,8.911,9.589c.03.035.051.076.083.11A4.04,4.04,0,0,0,11.958,11h.084a4.04,4.04,0,0,0,2.964-1.3c.032-.034.053-.075.083-.11A3.953,3.953,0,0,0,19,10.9Zm.72-12.78a2.015,2.015,0,0,1-1.6.78A2.041,2.041,0,0,1,16.1,7.14L15.753,3h3.466l.87,3.479A2.016,2.016,0,0,1,19.72,8.22Z" />
          </svg>
          <span className='footerLink'>Outlets</span>
        </Link>
        <Link to="/favourites" onClick={handleLinkClick} className={`tab-link ${activeMenu === "favourites" ? "active" : ""}`}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 512.000000 512.000000"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g
              transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
              fill="#000000"
              stroke="none"
            >
              <path
                d="M1262 4830 c-319 -40 -586 -171 -812 -399 -203 -204 -325 -420 -395
											-701 -124 -487 -34 -967 264 -1418 191 -289 438 -554 891 -958 288 -257 1167
											-1007 1210 -1032 40 -24 55 -27 140 -27 85 0 100 3 140 27 43 25 924 776 1210
											1032 455 406 700 670 891 958 298 451 388 931 264 1418 -70 281 -192 497 -395
											701 -202 203 -418 320 -701 380 -142 30 -404 33 -528 5 -346 -75 -611 -248
											-853 -556 l-28 -35 -27 35 c-239 302 -500 475 -833 551 -99 23 -327 33 -438
											19z m334 -305 c284 -50 529 -214 723 -485 33 -47 74 -103 90 -126 74 -104 228
											-104 302 0 16 23 57 79 90 126 265 370 634 544 1036 489 446 -61 794 -373 927
											-832 105 -363 59 -744 -132 -1087 -160 -287 -427 -588 -892 -1005 -225 -201
											-1171 -1015 -1180 -1015 -10 0 -952 811 -1180 1015 -715 641 -997 1041 -1065
											1510 -44 303 19 629 172 886 230 387 678 599 1109 524z"
                fill="#4A3749"
              ></path>
            </g>
          </svg>
          <span className='footerLink'>Favourites</span>
        </Link>
        <Link to= "/my-tokens"  onClick={handleLinkClick} className={`tab-link ${activeMenu === "my-tokens" ? "active" : ""}`}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="1.4em"
            viewBox="0 0 576 512"
          >
            <path d="M64 64C28.7 64 0 92.7 0 128v64c0 8.8 7.4 15.7 15.7 18.6C34.5 217.1 48 235 48 256s-13.5 38.9-32.3 45.4C7.4 304.3 0 311.2 0 320v64c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V320c0-8.8-7.4-15.7-15.7-18.6C541.5 294.9 528 277 528 256s13.5-38.9 32.3-45.4c8.3-2.9 15.7-9.8 15.7-18.6V128c0-35.3-28.7-64-64-64H64zm64 112l0 160c0 8.8 7.2 16 16 16H432c8.8 0 16-7.2 16-16V176c0-8.8-7.2-16-16-16H144c-8.8 0-16 7.2-16 16zM96 160c0-17.7 14.3-32 32-32H448c17.7 0 32 14.3 32 32V352c0 17.7-14.3 32-32 32H128c-17.7 0-32-14.3-32-32V160z" />
          </svg>
          <span className='footerLink'>My Tokens</span>
        </Link>
        <Link to="/sidebar" onClick={handleLinkClick} className="tab-link">
        <svg width="30" height="20" viewBox="0 0 30 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M24.2222 13C23.4855 13 22.779 12.7073 22.258 12.1864C21.7371 11.6655 21.4444 10.9589 21.4444 10.2222C21.4444 9.48551 21.7371 8.77897 22.258 8.25804C22.779 7.73711 23.4855 7.44445 24.2222 7.44445C24.9589 7.44445 25.6655 7.73711 26.1864 8.25804C26.7073 8.77897 27 9.48551 27 10.2222C27 10.9589 26.7073 11.6655 26.1864 12.1864C25.6655 12.7073 24.9589 13 24.2222 13ZM14.5 13C13.7633 13 13.0568 12.7073 12.5358 12.1864C12.0149 11.6655 11.7222 10.9589 11.7222 10.2222C11.7222 9.48551 12.0149 8.77897 12.5358 8.25804C13.0568 7.7371 13.7633 7.44445 14.5 7.44445C15.2367 7.44445 15.9433 7.7371 16.4642 8.25804C16.9851 8.77897 17.2778 9.48551 17.2778 10.2222C17.2778 10.9589 16.9851 11.6655 16.4642 12.1864C15.9433 12.7073 15.2367 13 14.5 13ZM4.77778 13C4.04107 13 3.33453 12.7073 2.81359 12.1864C2.29266 11.6655 2 10.9589 2 10.2222C2 9.48551 2.29266 8.77897 2.81359 8.25804C3.33453 7.7371 4.04107 7.44445 4.77778 7.44445C5.51449 7.44445 6.22103 7.7371 6.74196 8.25804C7.2629 8.77897 7.55556 9.48551 7.55556 10.2222C7.55556 10.9589 7.2629 11.6655 6.74196 12.1864C6.22103 12.7073 5.51449 13 4.77778 13Z" fill="black"/>
</svg>

          <span className='footerLink'>More</span>
        </Link>
      </div>
    </div>
  )
}

export default Footer