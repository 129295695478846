import React from "react";
import { Link } from "react-router-dom";

const DetailsPlaceholder = ({
  skeletonName = "Details",
  isProfile = false,
  isQrScreen = false,
}) => {
  return (
    <>
      <div class="page profile-wraper outlet-page userDetails">
        <div className="containerCard ">
          <div class="navbar navbar-style-1 navbar-transparent">
            <div class="navbar-inner navbar-profile">
              <Link to={"javascript:void(0)"} class="link back">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.439312 13.0606L5.75391 18.3752C6.04683 18.6682 6.43069 18.8146 6.81459 18.8146C7.1985 18.8146 7.58236 18.6682 7.87528 18.3752C8.46103 17.7894 8.46103 16.8397 7.87528 16.2539L5.12133 13.5H22.5C23.3284 13.5 24 12.8284 24 12C24 11.1716 23.3284 10.5 22.5 10.5H5.12133L7.87528 7.7461C8.46103 7.1603 8.46103 6.21057 7.87528 5.62477C7.28944 5.03898 6.33975 5.03898 5.75391 5.62477L0.439312 10.9394C-0.146437 11.5251 -0.146437 12.4749 0.439312 13.0606Z"
                    fill="black"
                  />
                </svg>
              </Link>
              <div class="title profile-font">{skeletonName}</div>
            </div>
          </div>

          <div class="page-content page-baground-color content-area pt-0">
            <div class="profile-info bg-primary">
              <div class="container ">
                {/* <div class="author"> */}
                {!isQrScreen ?
                  <>
                <div
                  class="skeleton-text skeleton-effect-wave item-media"
                  style={{
                    height: "130px",
                    width: "130px",
                    minWidth: "100px",
                    position: "relative",
                    boxSizing: "border-box",
                    margin: "40px auto",
                  }}
                >
                  <div
                    class="skeleton-block"
                    style={{
                      borderRadius: isProfile ? "50%" : "0px",
                      width: "100%",
                      height: "130px",
                    }}
                  ></div>
                </div>

                <div class="badge-lg" style={{ height: "16%", width: "70%" }}>
                  <span class="skeleton-text skeleton-effect-wave item-subtitle">
                    Subtitlesss
                  </span>
                  <div class="item-inner"></div>
                </div>
                {/* </div> */}
                <div class="profile-content">
                  <div class="list media-list skeleton-text">
                    {/* <ul style={{ paddingLeft: "0px" }}>
                      <li
                        class="skeleton-text skeleton-effect-wave"
                        style={{ background: "#655FFF" }}
                      > */}
                    <div class="skeleton-text skeleton-effect-wave item-inner">
                      <div class="item-subtitle">Subtitle</div>
                      <div class="item-text">Placeholder Text line 2</div>
                      <div class="item-text">
                        Placeholder text line 1 Placeholder
                        <br />
                        Placeholder text
                        <br />
                      </div>
                    </div>
                    {/* </li>
                    </ul> */}
                  </div>
                </div>
                </>
                : 
                <>
                <div className="card skeleton-text">
                 <div
                  class="skeleton-effect-wave item-media"
                  style={{
                    height: "200px",
                    width: "230px",
                    position: "relative",
                    boxSizing: "border-box",
                    margin: "150px auto",
                  }}
                >
                  <div
                    class="skeleton-block"
                    style={{
                      borderRadius: isProfile ? "50%" : "0px",
                      width: "100%",
                      height: "240px",
                    }}
                  ></div>
                </div>
                </div>
                </>
                   }
                <div class="content-info tabbar"></div>
              </div>
            </div>
            <div class="fixed-content container">
              {/* <Link
                to={"javascript:void(0)"}
                class="button-large button button-fill"
              >
                <span class="skeleton-text skeleton-effect-wave item-subtitle">
                  Get Token button
                </span>
              </Link> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailsPlaceholder;
