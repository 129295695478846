const initialState = {
    UpiDetail: []
  };
  
  const clientUpiDetailReducer = (state = initialState, action) => {
    switch (action.type) {
      case "UPIDETAIL":
        if (!action.payload) 
        {
          return initialState;
        } 
        else {
          return {
            ...state,
            UpiDetail: action.payload,
          };
        }
      default:
        return state;
    }
  };
  
  export default clientUpiDetailReducer;