import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../../../App.css";
import "../../../css/app.css";
import "../../../css/font-awesome/css/all.min.css";
import "../../../css/line-awesome/css/line-awesome.min.css";
import "../../../css/style.css";
import "../../../css/perfect-scrollbar.css";
import "../../../img/f7-icon-square.png";
import "../../../img/f7-icon.png";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { showLoaderAction } from "../../../actions/index";
import { sendMail, useCatchHandler, showToast } from "../../../utils/utilities.js"
import { useNotification } from "../../../utils/utilities"
import CityListPlaceholder from "../../Placeholders/CityListPlaceholder.js"

const { REACT_APP_MY_ENV } = process.env;

function CityList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let storedCityId = localStorage.getItem("CityId");
  let cityName = localStorage.getItem("CityName");
  const [cityList, setCityList] = useState([]);
  const [cityId, setCityId] = useState("");
  const [searchText, setSearchText] = useState("");
  const showLoaderReducer = useSelector((state) => state.rootReducer.showLoaderReducer)
  const showLoader = showLoaderReducer.show;
  const catchHandle = useCatchHandler();
  const sendNotification = useNotification();
  let token = localStorage.getItem("token");
  const currentURL = window.location.href;
  const [scrollPosition, setScrollPosition] = useState(0);
  const scrollRef = useRef(null);
  let isLogin = localStorage.getItem("isLogin");
  let isClient = localStorage.getItem('isClientLogin');


  useEffect(() => {
    if(isClient){
      navigate('/outlet-dashboard');
    }
    else if(!storedCityId){
      navigate('/city-list');
    }
    else if(storedCityId && isLogin || storedCityId){
      navigate('/city-list');
    }
    else if(isLogin){
      navigate('/dashboard');
    }
    getCityList()

    if (searchText) {
      const delayDebounceFn = setTimeout(() => {
        onSearchHandleClick();
      }, 1000);

      return () => clearTimeout(delayDebounceFn);
    }
    else {
      const delayDebounceFn = setTimeout(() => {
        onSearchHandleClick();
      }, 1000);

      return () => clearTimeout(delayDebounceFn);
    }

  }, [searchText]);

  const handleScroll = () => {
    const container = document.getElementById('scroll');
    if (container.scrollTop + container.clientHeight >= container.scrollHeight - 20) {
      if (cityList) {
        setScrollPosition(container.scrollTop);
        getCityList();
      }
    }
  };

  useEffect(() => {

    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollPosition;
    }
  }, [cityList]);


  const getCityList = async (Search_Text) => {

    let data = {
      SearchText: Search_Text ? Search_Text : searchText ? searchText : ""
    }
    dispatch(showLoaderAction(true));
    const response = await axios.post(REACT_APP_MY_ENV + "/city-list", data).then(function (response) {
      if (response.data.status === 200) {
        setCityList(response.data.data.cityList)
        setTimeout(() => {
          dispatch(showLoaderAction(false));
        }, 1000);
      } else {
        if (response.data.status === 204) {
          dispatch(showLoaderAction(false));
          setCityList([])
        }
        // console.log(response.data.message, "error")
      }
    }).catch(function (error) {
      dispatch(showLoaderAction(false));
      const data = {
        Subject: "Front end error from category-list",
        BodyText: error.message
      }
      showToast("Something went wrong, please contact Administrator!", "error")
    })
  };

  const handleCityNameChange = (city) => {
    setCityId(city.cityId);
    localStorage.setItem("CityId", city.cityId);
    localStorage.setItem("CityName", city.cityName);
    showToast(`City ${city.cityName} set as your default City`, "success");
    setTimeout(() => {
      navigate("/dashboard");
    }, 1000);
  };

  const onSearchTextChange = (e) => {
    if (e.target.value == "") {
      setSearchText(e.target.value)
    } else {
      setSearchText("")
    }
  }

  const onSearchHandleClick = (e) => {
    if (searchText) {
      getCityList(searchText)
    } else {
      setSearchText("")
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    onSearchTextChange({ target: { value: searchText } });
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      onSearchHandleClick();
    }
  };

  return (
    <>
      {showLoader ? (<CityListPlaceholder  />)
        :
        <div className="page page-home dashboard-page client-dashboard ">
          <div className='containerCard'>
            <div class="navbar navbar-style-1">
              <div class="navbar-inner navbar-bg-theme ">
              <Link  class="link back">
             
             </Link>
                <div class="title text-white">Select Your City</div>
              </div>
            </div>
        
            <div className="page-content inner-page pt-10">
              <div class="container">
                <div className="section wallet-card-section pt-1">
                  <div class="filter-bx search-city-input">
                    <form onSubmit={handleSubmit} data-search-container=".search-list" data-search-in=".item-title" class="searchbar  searchbar-city searchbar-init search-box list-search-bx">
                      <div class="searchbar-inner">
                        <div class="searchbar-input-wrap searchbar-input-wrap-pad">
                          <input type="text" value={searchText} className='date-daily-token-input' id="searchFavourite" onChange={(e) => {
                            setSearchText(e.target.value);
                            getCityList(e.target.value);
                          }} placeholder="Search for your..." autocomplete="off" onKeyDown={handleKeyDown} />
                        </div>
                        <Link to="#"
                          className={`${"filter-btn  filter-btn-clicked"}`}
                          onClick={onSearchHandleClick} >
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16"> <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" /> </svg>
                        </Link>
                      </div>
                    </form>
                  </div>
                  <div className="wallet-card wallet-card-padding">
                    <div className='containerCard stat-box stat-box-pad state-box-border'>
                      <div class="page-content pt-0 ">
                        <div class="list media-list search-list searchbar-found search-list-bar daily-token-mb-1 daily-token-m-0 user-list">
                          <ul>
                            {cityList && cityList.length > 0 ?
                              cityList && cityList.map((city, index) => {
                                return (
                                  <li key={city.cityId} onClick={() => handleCityNameChange(city)}>
                                    <div class="">
                                      <div class="">
                                        <div class="">
                                          <p className={city.cityName === cityName ? "selected-cityName cityName-title" : "cityName-title"}>
                                            {city.cityName}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                )
                              })
                              :
                              <div class="">
                                <div class="">
                                  <div class="">
                                  {cityList.length === 0 && <p class="cityName-title" > No cities found, please contact Administrator!</p>}
                                  </div>
                                </div>
                              </div>
                            }
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
}

export default CityList;