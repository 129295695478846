export const ClientSettingAction = (payload) => {
    return {
      type: 'CLIENTSETTING',
      payload
    };
  };

  export const showLoaderAction = (payload) => {
    return {
      type: 'SHOWLOADER',
      payload
    };
  };

  export const activeMenuAction = (payload) => {
    return {
      type: 'ACTIVEMENU',
      payload
    };
  };

  export const NotificationCountAction = (payload) => {
    return {
      type: 'COUNT',
      payload
    };
  };

  export const UpiIdAction = (payload) => {
    return {
      type: 'UPIDETAIL',
      payload
    }
  };

  export const ClientDataAction = (payload) => {
    return {
      type: 'CLIENTDATA',
      payload
    }
  };

export const ClientMenuDetailAction = (clientMenuDetail, grandTotal, comment) => ({
  type: 'CLIENTMENUDETAIL',
  payload: { clientMenuDetail, grandTotal, comment }
});