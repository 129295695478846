import React from "react";
import Footer from "../Master/Footer";
import { Link } from "react-router-dom";
import ClientFooter from "../Master/ClientFooter";

const ListPlaceholder = ({
  skeletonName = "List",
  showAvatar = true,
  noOfItem = 6,
  showFooter = true,
  showClientFooter = false,
}) => {
  const itemsArray = Array.from({ length: noOfItem }, (_, index) => index);

  return (
    <>
      <div class="page outlets-page commane-page ">
        <div className="containerCard">
          <div class="navbar navbar-bg-theme navbar-style-1">
            <div class="navbar-inner">
              <Link to="javascript:void(0)" class="link back">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.439312 13.0606L5.75391 18.3752C6.04683 18.6682 6.43069 18.8146 6.81459 18.8146C7.1985 18.8146 7.58236 18.6682 7.87528 18.3752C8.46103 17.7894 8.46103 16.8397 7.87528 16.2539L5.12133 13.5H22.5C23.3284 13.5 24 12.8284 24 12C24 11.1716 23.3284 10.5 22.5 10.5H5.12133L7.87528 7.7461C8.46103 7.1603 8.46103 6.21057 7.87528 5.62477C7.28944 5.03898 6.33975 5.03898 5.75391 5.62477L0.439312 10.9394C-0.146437 11.5251 -0.146437 12.4749 0.439312 13.0606Z"
                    fill="white"
                  />
                </svg>
              </Link>
              <div class="title navbar-title-theme">{skeletonName}</div>
            </div>
          </div>

          <div class="page-content inner-page pt-0">
            <div class="list search-list mb-20 container dz-list searchbar-found item-list">
              <div
                data-space-between="20"
                data-slides-per-view="auto"
                data-centered-slides="false"
                className="swiper-container swiper-init scrolling-wrapper tabbar tab-style-2"
              ></div>
              <div class="tabs">
                <div class="tab tab-active">
                  <ul class="row">
                    <div class="list media-list skeleton-text">
                      <ul style={{ paddingLeft: "0px" }}>
                        {itemsArray.map((number) => (
                          <li class="item-content skeleton-text skeleton-effect-wave">
                            {showAvatar && (
                              <div class="item-media">
                                <div
                                  class="skeleton-block"
                                  style={{
                                    borderRadius: "8px",
                                    width: "50px",
                                    height: "50px",
                                  }}
                                ></div>
                              </div>
                            )}
                            <div class="item-inner">
                              <div class="item-title-row">
                                <div class="item-title">Title</div>
                              </div>
                              <div class="item-subtitle">Subtitle</div>
                              <div class="item-text">
                                Placeholder text line 1<br />
                                Text line 2
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
            {showFooter && showClientFooter ?
              <ClientFooter />
              : showFooter ?
            <Footer />
            : null
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default ListPlaceholder;
