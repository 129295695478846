const initialState = {
  ClientSetting: true
};

const clientSettingReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CLIENTSETTING":
      if (!action.payload) 
      {
        return initialState;
      } 
      else {
        return {
          ...state,
          ClientSetting: action.payload,
        };
      }
    default:
      return state;
  }
};

export default clientSettingReducer;