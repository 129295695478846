import React, { useState } from "react";
import { Link } from "react-router-dom";
import $ from 'jquery';
import "../../../App.css";
import "../../../css/framework7-bundle.css";
import "../../../css/app.css";
import "../../../css/font-awesome/css/all.min.css";
import "../../../css/line-awesome/css/line-awesome.min.css";
import "../../../css/style.css";
import "../../../css/perfect-scrollbar.css";
import "../../../img/f7-icon-square.png";
import "../../../img/f7-icon.png";
import "../../../js/main.js";
import axios from "axios";
import tokenlogo from "../../../img/logo-new.png";
import { useCatchHandler, showToast } from "../../../utils/utilities"
import { useDispatch, useSelector } from "react-redux";
import { showLoaderAction } from "../../../actions/index";
import SpinerLoader from "../../SpinerLoader/spinerLoader";
import { sendMail } from "../../../utils/utilities";
const { REACT_APP_MY_ENV } = process.env;

function ForgetPassword() {
  const dispatch = useDispatch();
  const catchHandle = useCatchHandler();
  const showLoaderReducer = useSelector((state) => state.rootReducer.showLoaderReducer)
  const showLoader = showLoaderReducer.show;
  const [clientId, setClientId] = useState("");
  const [clientIdError, setClientIdError] = useState({});

  const onSubmit = async (e) => {
    e.preventDefault();
    const isValid = formValidation();

    if (isValid) {
      dispatch(showLoaderAction(true));
      const res = await axios.post(REACT_APP_MY_ENV + "/forget-password/"+clientId).then(function (res) {
      if (res.data.status === 200) {
        dispatch(showLoaderAction(false));
        setClientId("")
				showToast(res.data.message, "success");
        $("form")[0].reset();
      } else {
        dispatch(showLoaderAction(false));
        showToast(res.data.message, "error");
      }}).catch(function (error) {
        const data = {
          Subject: "Front end error from forget-password",
          BodyText: error.message
        }
        sendMail(data);
        dispatch(showLoaderAction(false));
        showToast("Something went wrong, please contact Administrator!", "error")
      });
    }
  };

  const formValidation = () => {
    let clientIdError = {};
    let isValid = true;

    if (!clientId) {
      clientIdError.clientIdEmpty =
        "Please enter email address or phone number";
      isValid = false;
    } else if (
      isNaN(clientId) &&
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(clientId)
    ) {
      clientIdError.emailInvalid = "Please enter valid email address";
      isValid = false;
    } else if (
      !isNaN(clientId) &&
      !/^(\+\d{1,3}[- ]?)?\d{10}$/.test(clientId)
    ) {
      clientIdError.phoneInvalid = "Please enter valid phone number";
      isValid = false;
    }

    setClientIdError(clientIdError);
    return isValid;
  };

  return (
    <>
    {showLoader && (<SpinerLoader />)}
      <div className="page account-area">
        <div className="page-content">
        <div className="logo-space"><div className="logo-request-singpage">
               <img src={tokenlogo} onError={(error) => { error.target.src = tokenlogo }}/></div></div>
          <div className="fixed-content container">
            <div className="tabs">
              <div className="tab tab-active form-elements tabs">
                <form onSubmit={onSubmit} className="tab tab-active">
                <div class="title-head d-flex-arrow">
                  <div>
                    <Link to={-1} class="paddingBottom mt-1 me-1">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.439312 13.0606L5.75391 18.3752C6.04683 18.6682 6.43069 18.8146 6.81459 18.8146C7.1985 18.8146 7.58236 18.6682 7.87528 18.3752C8.46103 17.7894 8.46103 16.8397 7.87528 16.2539L5.12133 13.5H22.5C23.3284 13.5 24 12.8284 24 12C24 11.1716 23.3284 10.5 22.5 10.5H5.12133L7.87528 7.7461C8.46103 7.1603 8.46103 6.21057 7.87528 5.62477C7.28944 5.03898 6.33975 5.03898 5.75391 5.62477L0.439312 10.9394C-0.146437 11.5251 -0.146437 12.4749 0.439312 13.0606Z" fill="black" />
                      </svg>
                    </Link>
                  </div>
                  <div>
                  <h2 className="title">Forget Password</h2>
                  </div>
                </div>
                  <div className="list">
                    <ul className="row">
                      <li className="item-content item-input col-100">
                        <div className="item-inner">
                          <div className="item-title item-label profile-font">
                            Email/Phone
                          </div>
                          <div className="item-input-wrap profile-font">
                            <input
                              type="text"
                              placeholder="Enter Email/Phone"
                              value={clientId}
                              autocomplete="new-password"
                              onChange={(e) => {
                                setClientId(e.target.value);
                              }}
                              className="form-control"
                            />
                            {clientIdError &&
                              Object.keys(clientIdError).map((key) => {
                                return (
                                  <div style={{ color: "red" }}>
                                    {clientIdError[key]}
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
									<div class="clearfix">
									<button
                      type="submit"
                      className="button-large button button-fill"
                    >SUBMIT</button>
										<p class="form-text">Sign in to your registered account <Link to="/outlet-sign-in"  data-route-tab-id="tabA1" class="tab-link underline">Login here</Link></p>
									</div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgetPassword;