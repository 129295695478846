import React from 'react';
import { Route, Routes } from 'react-router-dom';
import SignIn from '../User/Auth/SignIn';
import SignUp from '../User/Auth/SignUp';
import VerifyOTP from '../User/Auth/VerifyOtp';
import Dashboard from '../User/Dashboard/Dashboard';
import UpdateProfile from '../User/Profile/UpdateProfile';
import Outlets from '../User/Outlets/Outlets';
import Outlet from '../User/Outlets/Outlet';
import Favourites from '../User/Favourites/Favourites';
import MyTokens from '../User/MyToken/MyTokens';
import OutletReview from '../User/Outlets/OutletReview';
import ClientSignUp from '../Client/Auth/SignUp';
import ClientSignIn from '../Client/Auth/SignIn';
import ForgetPassword from '../Client/Auth/ForgetPassword';
import ResetPassword from '../Client/Auth/resetPassword';
import ClientDashboard from '../Client/Dashboard/ClientDashboard';
import GetToken from '../User/Outlets/GetToken';
import ClientViewProfile from '../Client/Profile/ClientViewProfile'
import ClientChangePassword from '../Client/Auth/ClientChangePassword'
import ClientUpdateProfile from '../Client/Profile/ClientUpdateProfile'
import GenerateToken from '../User/Outlets/GenerateToken';
import Sidebar from '../Master/sidebar';
import ClientSidebar from '../Master/ClientSidebar';
import ClientFeedBackList from '../Client/FeedbackList/FeedBackList';
import Notification from '../Notification/Notifications';
import TokenUsersList from '../Client/TokenUsersList/TokenUsersList';
import FeedBackList from '../User/FeedBackList/FeedBackList'
import GetTokenReports from '../Client/Reports/GetTokenReports';
import QrScanner from '../User/Auth/QrScanner';
import MyQr from '../Client/MyQr/MyQr';
import CustomersList from '../Client/Users/CustomersList';
import CustomersDetail from '../Client/Users/CustomersDetail';
import OutletRequest from '../OutletReqeust/outletRequest';
import Wallet from '../User/Wallet/Wallet.js';
import TransactionHistory from '../User/Wallet/TransectionHistory.js';
import AdsComponent from '../Ads/AdsComponent.js';
import ClientRunningToken from '../Client/Dashboard/RunningToken.js';
import CityList from '../User/Dashboard/CityList.js';

const FormRoute = () => {
  return (
    <Routes>
      <Route path='/' element={<Dashboard />} />
      <Route path='/dashboard' element={<Dashboard />} />
      <Route path='/sign-in/*' element={<SignIn />} />
      <Route path='/sign-up' element={<SignUp />} />
      <Route path='/verify-otp' element={<VerifyOTP />} />
      <Route path='/ads' element={<AdsComponent />} />
      <Route path='/outlets' element={<Outlets />} />
      <Route path='/outlet/:slug' element={<Outlet />} />
      <Route path='/update-profile' element={<UpdateProfile />} />
      <Route path='/favourites' element={<Favourites />} />
      <Route path='/my-tokens' element={<MyTokens />} />
      <Route path='/outlet-review/:slug' element={<OutletReview />} />
      <Route path='/forget-password' element={<ForgetPassword />} />
      <Route path='/forget-password/:id' element={<ResetPassword />} />
      <Route path='/get-token/:id' element={<GetToken />} />
      <Route path='/generate-token/:id' element={<GenerateToken />} />
      <Route path='/sidebar' element={<Sidebar />} />
      <Route path='/my-reviews' element={<FeedBackList />} />
      <Route path='/qr-scanner' element={<QrScanner />} />
      <Route path='/wallet' element={<Wallet />} />
      <Route path='/city-list' element={<CityList />} />

      <Route path='/outlet-sidebar' element={<ClientSidebar />} />
      <Route path='/outlet-sign-up' element={<ClientSignUp />} />
      <Route path='/outlet-sign-in/*' element={<ClientSignIn />} />
      <Route path='/outlet-dashboard' element={<ClientDashboard />} />
      <Route path='/outlet-view-profile' element={<ClientViewProfile />} />
      <Route path='/outlet-update-profile' element={<ClientUpdateProfile />} />
      <Route path='/outlet-change-password' element={<ClientChangePassword />} />
      <Route path='/reviews' element={<ClientFeedBackList />} />
      <Route path='/daily-tokens' element={<TokenUsersList />} />
      <Route path='/notification-list' element={<Notification />} />
      <Route path='/get-reports' element={<GetTokenReports />} />
      <Route path='/my-qr' element={<MyQr />} />
      <Route path='/customers' element={<CustomersList />} />
      <Route path='/customer-detail/:id' element={<CustomersDetail />} />
      <Route path='/running-token' element={<ClientRunningToken/>}/>
      <Route path='/outlet-request/:requestFrom' element={<OutletRequest />} />
      <Route path='/transaction-history' element={<TransactionHistory />} />
    </Routes>
  );
};

export default FormRoute;