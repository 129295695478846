import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../../../App.css";
import "../../../css/framework7-bundle.css";
import "../../../css/app.css";
import "../../../css/font-awesome/css/all.min.css";
import "../../../css/line-awesome/css/line-awesome.min.css";
import "../../../css/style.css";
import "../../../css/perfect-scrollbar.css";
import "../../../img/f7-icon-square.png";
import "../../../img/f7-icon.png";
import defaultOutlet from "../../../img/outlet-default.png";
import axios from "axios";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useDispatch, useSelector } from "react-redux";
import { checkLoginStatus } from "../../../js/main";
import { showLoaderAction } from "../../../actions/index";
import { PullToRefresh } from 'react-js-pull-to-refresh';
import { PullDownContent, ReleaseContent, RefreshContent } from 'react-js-pull-to-refresh';
import $ from "jquery"
import { sendMail, showToast, useCatchHandler } from "../../../utils/utilities"
import { LazyLoadImage } from "react-lazy-load-image-component";
import ReactPaginate from 'react-paginate';
import { useNotification } from "../../../utils/utilities"
import moment from "moment";
import { activeMenuAction } from "../../../actions/index";
import Footer from '../../Master/Footer';
import * as signalR from "@microsoft/signalr";
import { uniqBy } from "lodash";
import ListPlaceholder from "../../Placeholders/ListPlaceholder.js"
// import AdsComponent from "../../Ads/AdsComponent.js";

const { REACT_APP_MY_ENV, REACT_APP_TOKEN_HUB_URL } = process.env;

function MyTokens() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const showLoaderReducer = useSelector((state) => state.rootReducer.showLoaderReducer)
  const showLoader = showLoaderReducer.show;
  const [tokenStatus, setTokenStatus] = useState(false);
  const [inQueueTokens, setInQueueTokens] = useState([]);
  const [otherTokens, setOtherTokens] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isInQToken, setIsInQToken] = useState(false);
  const [isOtherToken, setIsOtherToken] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [showLoading, setShowLoading] = useState(true)
  const [ongoingToken, setOngoingToken] = useState();
  const catchHandle = useCatchHandler();
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [myTokensTotalCount, setMyTokensTotalCount] = useState(0);
  const [onLoading, setOnLoading] = useState(true); 
  const pageCount = Math.ceil(myTokensTotalCount / itemsPerPage);
  const sendNotification = useNotification();

  let token = localStorage.getItem("token");

  const currentURL = window.location.href;

  const [scrollPosition, setScrollPosition] = useState(0);
  const scrollRef = useRef(null);

  let paramsData = {
    clientId: localStorage.getItem("encryptedUserId")
  }

  let objString = '?' + new URLSearchParams(paramsData).toString();

  const connection = new signalR.HubConnectionBuilder()
    .withUrl(REACT_APP_TOKEN_HUB_URL + objString)
    .configureLogging(signalR.LogLevel.Information)
    .build();

  useEffect((e) => {
    dispatch(activeMenuAction('my-tokens'));
    if (localStorage.getItem("isLogin", true)) {
      setIsLoggedIn(true);
    }
    checkLoginStatus();
    if (checkLoginStatus) {
      if (otherTokens.length <= 0){
        getTokens(searchText, 1);
      }
    }
    else {
      navigate('/sign-in')
    }

    establishHubConnection();

    if (!onLoading) {
      if (searchText) {
        const delayDebounceFn = setTimeout(() => {
          onSearchHandleClick();
        }, 1000);

        return () => clearTimeout(delayDebounceFn);
      }
      else {
        const delayDebounceFn = setTimeout(() => {
          onSearchHandleClick();
        }, 1000);

        return () => clearTimeout(delayDebounceFn);
      }
    }

  }, [tokenStatus, searchText]);

  const establishHubConnection = async () => {
    try {
      const userId = localStorage.getItem("encryptedUserId");
      await connection.start();
      if (connection.state === signalR.HubConnectionState.Connected) {
        connection.invoke("SetOngoingToken", userId, 0, 0, 0, "");
      } else {
        console.error("SignalR connection is not in the 'Connected' state.");
      }
    } catch (error) {
      console.error("SignalR connection error: ", error);
    }
  }

  connection.on("GetOngoingToken", (userId, clientId, updatedOngoingToken, estimatedServingTime, status) => {
    setOngoingToken(updatedOngoingToken);
    var targetObject = inQueueTokens.find(item => item.clientId == clientId);

    if (targetObject) { 
      inQueueTokens.find(item => item.clientId == clientId).ongoingToken = updatedOngoingToken;
      inQueueTokens.find(item => item.clientId == clientId).estimatedServingTime = estimatedServingTime > 0 ? (estimatedServingTime).toString() + " mins" : estimatedServingTime < 0 ? "Your turn passed" : (estimatedServingTime).toString() + " mins";
      setInQueueTokens(inQueueTokens);
    }

    var targetToken = inQueueTokens.find(item => item.clientId == clientId && item.token == updatedOngoingToken);
    if(targetToken){
      let updatedToken= inQueueTokens.find(item => item.clientId == clientId && item.token == updatedOngoingToken).status = status;
      if (inQueueTokens !== -1 && updatedToken==="Completed") {
        const updatedInQueueTokens = [...inQueueTokens];
        updatedInQueueTokens.splice(updatedToken, 1);
        setInQueueTokens(updatedInQueueTokens);
        const updatedOtherTokens = [...otherTokens, updatedOngoingToken]; 
        setOtherTokens(updatedOtherTokens)
        getTokens()
      }
    }
  });

  const handleScroll = () => {
		const container = document.getElementById('scroll');
		if (container.scrollTop + container.clientHeight >= container.scrollHeight - 20 && !loading) {
			if (otherTokens.length < myTokensTotalCount) {
				setScrollPosition(container.scrollTop);
				getTokens(searchText);
			}
		}
	};

  const callBack = () => {
		setOtherTokens([])
    setInQueueTokens([])
	}

	useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollTop = scrollPosition;
        }
    }, [otherTokens,inQueueTokens]);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected + 1);
    setTokenStatus(false)
    getTokens(searchText, selected + 1)
  };

  const cancelToken = async (slug, tokenNumber) => {
    const outletSlug = slug;
    let data = {
      encryptedUserId: localStorage.getItem("encryptedUserId"),
      slug: slug
    };
    dispatch(showLoaderAction(true));
    const response = await axios.post(REACT_APP_MY_ENV + "/cancel-token/" + outletSlug, data, { headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" } }).then(function (response) {
      if (response.data.status == 500) {
        dispatch(showLoaderAction(false));
        showToast(response.data.message, "error");
      } else if (response.data.status == 200) {
        dispatch(showLoaderAction(false));
        setTokenStatus(false)
        notificationSendForCancelToken(tokenNumber)
        showToast(response.data.message, "success");
        getTokens(searchText);
        setInQueueTokens(inQueueTokens =>
          inQueueTokens.filter(inQueueTokens => inQueueTokens.slug !== slug)
        );
      } else {
        dispatch(showLoaderAction(false));
        showToast(response.data.message, "error");
      }
    }).catch(function (error) {
      dispatch(showLoaderAction(false));
      showToast("Something went wrong, please contact Administrator!", "error")
      catchHandle(error, "true",currentURL)
      const data = {
        Subject: "Front end error from cancel-token",
        BodyText: error.message
      }
      sendMail(data)
    });    
  };

  const ConfirmRemove = (slug, tokenNumber) => async (e) => {
    confirmAlert({
      title: (
        <span className="confirm-title">
          Confirmation
        </span>
      ),
      message: "Are you sure you want to cancel this token",
      buttons: [
        {
          label: 'Yes',
          className: 'red-button',
          onClick: () => cancelToken(slug, tokenNumber),
        },
        {
          label: 'Cancel',
          className: 'gray-button',
        }
      ]
    });
  };

  const notificationSendForCancelToken = (tokenNumber) => {
    const data = {
      ClientId: inQueueTokens[0].clientId.toString(),
      UserId: localStorage.getItem("encryptedUserId"),
      SentBy: "USER",
      Title: "Token Cancelled",
      Description: `${localStorage.getItem("username")} - Token No. ${tokenNumber}`,
      RedirectURL: "/outlet-dashboard"
    }
    sendNotification(data)
  }

  function getTokens(search_text, pageNumber) {
    setLoading(true)
    let data = {
      searchText: search_text ? search_text : searchText ? searchText : $("#searchOutlet").val() ? $("#searchOutlet").val() : "",
      encryptedUserId: localStorage.getItem("encryptedUserId"),
      // pageNumber: search_text && searchText && $("#searchOutlet").val() && pageNumber ? pageNumber : currentPage,
      pageNumber: pageNumber ? pageNumber : currentPage,
      pageSize: itemsPerPage,
    };
    if (!searchText) {
      dispatch(showLoaderAction(!data.searchText));
    }
    if(showLoading){
      dispatch(showLoaderAction(true));
    }
    axios
      .post(REACT_APP_MY_ENV + "/my-tokens", data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        if (response.data.status === 200) {
          setMyTokensTotalCount(response.data.data.myTokensCount)
          // setOtherTokens(otherTokens => [...otherTokens, ...response.data.data.otherTokens]);
          if(searchText){
            const searchInput = document.getElementById("searchOutlet");
            if(searchInput && (pageNumber ? pageNumber : currentPage) > 1){
              setOtherTokens(uniqBy([...otherTokens, ...response.data.data.otherTokens], 'id'));
            }
            else{
              setOtherTokens(response.data.data.otherTokens);
            }
          }
          else{
            setOtherTokens(uniqBy([...otherTokens, ...response.data.data.otherTokens], 'id'));
          }
          if (response.data.data.inQueueTokens.length > 0) {
            setInQueueTokens(response.data.data.inQueueTokens);
          }
          setCurrentPage(currentPage => currentPage + 1);
          setTokenStatus(true)
          setLoading(false)
          if (response.data.data.inQueueTokens.length > 0) {
            setIsInQToken(true);
          }
          if (response.data.data.otherTokens.length > 0) {
            setIsOtherToken(true);
          }
          setTimeout(function () {
            // $("#no-users").html("No tokens yet!");
          }, 200);
          setShowLoading(false)
        }
        else {
          // setInQueueTokens([])
          // setOtherTokens([]);
          setTimeout(function () {
            // $("#no-users").html("No tokens yet!");
          }, 200);
        }
      }).catch(function (error) {
        dispatch(showLoaderAction(false));
		    showToast("Something went wrong, please contact Administrator!", "error")
        catchHandle(error, "true", currentURL)
      });

    dispatch(showLoaderAction(false));
  }

  const handleRefresh = () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
        getTokens(searchText)
      }, 2000); // Simulate a 2-second delay for demonstration
    });
  };

  const onSearchHandleClick = (e) => {
    callBack()
		setCurrentPage(1)
		setScrollPosition(0)
    let currentPage = 1
    if (searchText) {
      getTokens(searchText, currentPage)
      dispatch(showLoaderAction(false));
    } else {
      dispatch(showLoaderAction(false));
      setSearchText("")
      getTokens("", currentPage)
    }
  }
  
  const onSearchTextChange = (e) => {
    setSearchText(e.target.value.trim());
    setOnLoading(false)
  };
  const onCardClick = (token) => {
    navigate("/outlet/" + token.slug, {
      state: {
        outlet: token, myToken: "myToken"
      }
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    onSearchTextChange({ target: { value: searchText } });
  };
  
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      onSearchHandleClick();
    }
  };

  return (
    <>
      {showLoader ? (<ListPlaceholder skeletonName={"My Tokens"} />)
      :
      <div class="page my-token-page commane-page">
      <div className="containerCard">
        <div class="navbar navbar-style-1 navbar-bg-theme">
          <div class="navbar-inner">
            <Link to='/dashboard' class="link back">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.439312 13.0606L5.75391 18.3752C6.04683 18.6682 6.43069 18.8146 6.81459 18.8146C7.1985 18.8146 7.58236 18.6682 7.87528 18.3752C8.46103 17.7894 8.46103 16.8397 7.87528 16.2539L5.12133 13.5H22.5C23.3284 13.5 24 12.8284 24 12C24 11.1716 23.3284 10.5 22.5 10.5H5.12133L7.87528 7.7461C8.46103 7.1603 8.46103 6.21057 7.87528 5.62477C7.28944 5.03898 6.33975 5.03898 5.75391 5.62477L0.439312 10.9394C-0.146437 11.5251 -0.146437 12.4749 0.439312 13.0606Z"
                  fill="white"
                />
              </svg>
            </Link>
            <div class="title text-white">My Tokens</div>
          </div>
        </div>

        <div class="page-content inner-page pt-20" id="scroll" onScroll={() => handleScroll()} ref={scrollRef}>
          <div class="searchbar-backdrop"></div>
          {(
            (isInQToken === false && isOtherToken === true) || (isInQToken === true && isOtherToken === false) ||
            (isInQToken === true && isOtherToken === true)) &&
            (<div class="container">
          <div class="filter-bx">
            <form
              onSubmit={handleSubmit}
              data-search-container=".search-list"
              data-search-in=".item-title"
              class="searchbar searchbar-init search-box list-search-bx"
            >
              <div class="searchbar-inner">
                <div class="searchbar-input-wrap searchbar-input-wrap-pad">
                  <input  className='date-daily-token-input' type="text" placeholder="Search Outlet..." id="searchOutlet" onChange={onSearchTextChange} autocomplete="off" onKeyDown={handleKeyDown} value={searchText}  />
                </div>
                <Link to="#"
								className={`${ "filter-btn  filter-btn-clicked"}`}
                onClick={onSearchHandleClick}
                >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16"> <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" /> </svg>
							</Link>
              </div>
            </form>
            </div>
          </div>
 )} 
{((isInQToken === false && isOtherToken === false) ) && (
  <p className="user-margin no-token-msg">You have not any tokens yet!</p>
)}
          <div class="list search-list mt-0 mb-10 container dz-list searchbar-found item-list">
            {inQueueTokens && inQueueTokens.length > 0 ?
              inQueueTokens.map((inQueueToken) => {
                return (
                  <div class="m-ticket mb-20">
                    <p class="m-ticket-vt">Token<strong>{inQueueToken.token}</strong></p>
                    <div class="movie-details" onClick={() => onCardClick(inQueueToken)}>
                      <LazyLoadImage src={inQueueToken.logoFilePathURL ? inQueueToken.logoFilePathURL : defaultOutlet} alt={inQueueToken.outletName} onError={(error) => { error.target.src = defaultOutlet }} class="poster" />
                      <div class="movie">
                        <h4 className="outlet-name">{inQueueToken.outletName}</h4>
                        {inQueueToken.outletStatus === "Closed" ? 
                        <p>
                      <i class="las la-clock"></i> {inQueueToken.outletOpenTime ? moment(inQueueToken.outletOpenTime, "HH:mm:ss").format("HH:mm A") : ""}
                      {inQueueToken.outletCloseTime ? " - " : ""}
                      {inQueueToken.outletCloseTime ? moment(inQueueToken.outletCloseTime, "HH:mm:ss").format("HH:mm A") : ""}</p> : ""}
                        {inQueueToken.outletStatus === "Open" ?
                       <div className="OngoingToken">Running Token <strong>{inQueueToken.ongoingToken}</strong></div>
                          : <p className="outlet-address">{inQueueToken.outletAddress === "undefined" ? "": inQueueToken.outletAddress}</p>}
                      </div>
                    </div>
                    {inQueueToken.outletStatus === "Open" ?
                      <div class="info-cancel no-margin">
                        {inQueueToken.estimatedServingTime === "0 mins" || inQueueToken.ongoingToken == inQueueToken.token ? "Your Turn" : inQueueToken.estimatedServingTime == "Your turn passed" || inQueueToken.token < inQueueToken.ongoingToken ? "Ouch..! Your Turn Passed" : `Your Turn will be in approx. ${inQueueToken.estimatedServingTime}`}
                        {/* {inQueueToken.ongoingToken == inQueueToken.token ? "Your Turn" : `Your Turn will be in approx. ${inQueueToken.estimatedServingTime}`} */}
                      </div> : <span className="info-cancel">Closed</span>}
                    {inQueueToken.outletStatus === "Open" ? (
                      <div class="total-amount">
                        <p class="pl-15"><i class="las la-clock" /> In-Queue</p>
                        <p class="pr-15 link text-danger" onClick={ConfirmRemove(inQueueToken.slug, inQueueToken.token)}>Cancel</p>
                      </div>
                    ) : (<div class="total-amount">
                      <p class="pr-15 ms-1"><a className="m-auto get_token_color tab-link Phone-icon-gt" href={`tel:${inQueueToken.outletContactNumber}`}>
                        <svg enable-background="new 0 0 507.983 507.983" height="11" fill="#777777" viewBox="0 0 507.983 507.983" width="22" xmlns="http://www.w3.org/2000/svg">
                          <g>
                            <path d="m200.75 148.678c11.79-27.061 5.828-58.58-15.03-79.466l-48.16-48.137c-15.999-16.19-38.808-23.698-61.296-20.178-22.742 3.34-42.496 17.4-53.101 37.794-23.286 43.823-29.276 94.79-16.784 142.817 30.775 121.9 198.319 289.559 320.196 320.104 16.452 4.172 33.357 6.297 50.33 6.326 32.253-.021 64.009-7.948 92.487-23.087 35.138-18.325 48.768-61.665 30.443-96.803-3.364-6.451-7.689-12.352-12.828-17.502l-48.137-48.16c-20.894-20.862-52.421-26.823-79.489-15.03-12.631 5.444-24.152 13.169-33.984 22.787-11.774 11.844-55.201-5.31-98.675-48.76s-60.581-86.877-48.876-98.698c9.658-9.834 17.422-21.361 22.904-34.007zm-6.741 165.397c52.939 52.893 124.14 88.562 163.919 48.76 5.859-5.609 12.688-10.108 20.155-13.275 9.59-4.087 20.703-1.9 28.028 5.518l48.137 48.137c5.736 5.672 8.398 13.754 7.157 21.725-1.207 8.191-6.286 15.298-13.645 19.093-33.711 18.115-73.058 22.705-110.033 12.836-104.724-26.412-260.078-181.765-286.489-286.627-9.858-37.009-5.26-76.383 12.86-110.126 3.823-7.318 10.924-12.358 19.093-13.552 1.275-.203 2.564-.304 3.856-.3 6.714-.002 13.149 2.683 17.869 7.457l48.137 48.137c7.407 7.321 9.595 18.421 5.518 28.005-3.153 7.516-7.652 14.394-13.275 20.294-39.804 39.686-4.18 110.817 48.713 163.918z" />
                          </g>
                        </svg>
                        Call
                      </a></p><p class="pr-15 link text-danger" onClick={ConfirmRemove(inQueueToken.slug, inQueueToken.token)}>Cancel</p>
                    </div>)}
                  </div>
                )
              }) :null
            }
             {/* <AdsComponent dataAdSlot='8381943899' /> */}
            {(inQueueTokens.length === 0 && otherTokens.length === 0) && (isInQToken === true && isOtherToken === true)  || (isInQToken === false && isOtherToken === true && inQueueTokens.length === 0 && otherTokens.length === 0) || (isInQToken === true && isOtherToken === false && inQueueTokens.length === 0 && otherTokens.length === 0)? <span>No tokens yet!</span> : ""}
              <ul class="row">
            {otherTokens ?
              otherTokens.map((tokens) => {
                const formattedDateTime = moment(tokens.tokenDateTime).format("DD/MM/YYYY HH:mm A");
                return (
                
                    <li class="store-card card-list col-100 medium-50 mb-15" onClick={() => onCardClick(tokens)}>
                      <div class="card-media token-card-media">
                        <Link to={"/outlet/" + tokens.slug} state={{ outlet: tokens, myToken: "myToken" }}>
                          <LazyLoadImage src={tokens.logoFilePathURL ? tokens.logoFilePathURL : defaultOutlet} onError={(error) => { error.target.src = defaultOutlet }} alt={tokens.outletName} />
                        </Link>
                      </div>
                      <div class="card-info token-info title-container">
                        <h6 class="title my-token-title responsive-title">
                          <Link to={"/outlet/" + tokens.slug} state={{ outlet: tokens, myToken: "myToken" }} >
                            {tokens.outletName}
                          </Link>
                        </h6>
                        <span className="my-token-date">
                          {formattedDateTime}
                        </span>
                        <p class="time">
                          Token No. {tokens.token ? tokens.token : 0}
                        </p>
                        {tokens.status == 0 ?
                          <>
                            <p class="time">
                              Running Token :{" "}
                              {tokens.ongoingToken ? tokens.ongoingToken : 0}
                            </p>
                            <p class="time">
                              Total Token :{" "}
                              {tokens.totalTokens ? tokens.totalTokens : 0}
                            </p>
                          </> : null}
                        <div class="dz-meta">
                          <ul>
                            <li class="price">
                              {tokens.status === 0 ? (
                                <>
                                  <i class="las la-clock"></i>In-Queue
                                </>
                              ) : tokens.status === 1 ? (
                                <>
                                  <i class="las la-check-circle"></i>Completed
                                </>
                              ) : tokens.status === 2 ? (
                                <>
                                  <i class="las la-minus-circle"></i>No Show
                                </>
                              ) : tokens.status === 3 ? (
                                <>
                                  <i class="las la-ban"></i>Cancelled
                                </>
                              ) : null}
                            </li>
                            {tokens.status !== 3 && tokens.status !== 1 && tokens.status !== 2 ?
                              <li>
                                <label class="bookmark-btn">
                                  <a nohref onClick={ConfirmRemove(tokens.slug)}>
                                    <i class="las la-trash-alt"></i>
                                  </a>
                                </label>
                              </li> : null}
                          </ul>
                        </div>
                      </div>
                    </li>
                 
                );
              })
              :
              <div class="simple-list">
                <ul>
                  <li></li>
                </ul>
              </div>}
              </ul>
          </div>
          {/* </PullToRefresh> */}
          {/* {(inQueueTokens && inQueueTokens.length > 0) || (otherTokens && otherTokens.length > 0) ?
             
              <ReactPaginate
                pageCount={pageCount}
                pageRangeDisplayed={10}
                marginPagesDisplayed={0}
                onPageChange={handlePageClick}
                previousLabel={"Prev"}
                nextLabel={"Next"}
                breakLabel={"..."}
                containerClassName={"pagination"}
                activeClassName={"active"}
              />
          
            : ""} */}
          <Footer />
          </div>
        </div>
      </div>
      }
    </>
  );
}

export default MyTokens;