import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import FormRoute from './components/Routes/FormRoute';
import { Toaster } from 'react-hot-toast'; // Imported toast globally
import "./App.css";
import "./css/framework7-bundle.css";
import "./css/app.css";
import "./css/font-awesome/css/all.min.css";
import "./css/line-awesome/css/line-awesome.min.css";
import "./css/style.css";
import "./css/perfect-scrollbar.css";
import "./img/f7-icon-square.png";
import "./img/f7-icon.png";
import "./js/main.js";
// import checkConnectivity from './components/NoInternet/checkConnectivity.js';   // check connectivity with network
// import NoInternet from './components/NoInternet/NoInternet.js'; // No internet component

const App = () => {
  // const isOnline = checkConnectivity();
  return (
    <Router>
      <FormRoute />
      {/* {isOnline ? (
        <>
        <FormRoute />
        <NoInternet isVisible={false}/>
        </>
      ) : (
        <NoInternet isVisible={true} onRetry={() => window.location.reload()} />
      )} */}
      <Toaster />
    </Router>
  );
};

export default App;